import React from 'react';
import {
    Box,

    useColorMode,

} from '@chakra-ui/react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './calendar.css';

import styled from '@emotion/styled';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';


const CalenderWrapper = styled.div`
    .rbc-selected-cell {
        background-color: ${(props) => (props.colorMode === 'dark' ? 'rosybrown' : 'lavender')};
    }

    .rbc-off-range-bg {
        background-color: ${(props) => props.colorMode === 'dark' && '#8e207675'};
    }

    .rbc-calendar {
        width: 100% !important;
        z-index: 9999;
    }

    .rbc-event-content {
        font-size: 13px;
        font-weight: 500;
    }

    .rbc-btn-group {
        > button {
            color: ${(props) => props.colorMode === 'dark' && 'white'};
        }

        .rbc-active {
            background: ${(props) => props.colorMode === 'dark' && '#03bda1'};
        }
    }
`;

const CalendarComponent = ({
    events,
    setModalData,
    modalContent,
    modalTitle,
    setSelectedDayModal,
    selectable,
    modalSize,
    refresh,
    setRefresh,
    setShowCalendar,
    availabilityData,
}) => {
    const { colorMode } = useColorMode();
    return (

            <WithCalendar
                colorMode={colorMode}
                setModalData={setModalData}
                setSelectedDayModal={setSelectedDayModal}
                events={events}
                selectable={selectable}
                availabilityData={availabilityData}
                setRefresh={setRefresh}
                refresh={refresh}
            />
    );
};

const WithCalendar = ({
    colorMode,
    events,
    selectable,
    availabilityData,
}) => {
    const customDayPropGetter = (date) => {
        if (availabilityData && availabilityData.length > 0) {
            const isAvailable = availabilityData.filter(
                (ev) => moment(ev.start).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'),
            );
            //if (moment(date).format('YYYY-MM-DD') === moment(CURRENT_DATE).format('YYYY-MM-DD'))
            return {
                className: 'special-day',
                style: {
                    //background: colorMode === 'dark' ? '#03bda1' : null,
                    backgroundColor: isAvailable.length > 0 ? '#dcffe8' : null,
                    //   opacity: colorMode === "dark" ? "0.8" : null,
                },
            };
        } else return null;
    };

    // eslint-disable-next-line no-unused-vars
    const eventPropGetter = (props) => {
        let style = {
            backgroundColor: '#cee2ff',
            //   opacity: 0.8,
            color: 'black',
            marginBottom: '2px',
        };
        return {
            style: style,
        };
    };

    const localizer = momentLocalizer(moment);
 


    return (
        <Box width="100%">
        <CalenderWrapper colorMode={colorMode}>
            <Calendar
                popup
                localizer={localizer}
                events={events ? events : []}
                startAccessor="start_date"
                endAccessor="end_date"
                style={{ height: 700 }}
                dayPropGetter={customDayPropGetter}
                eventPropGetter={eventPropGetter}
                selectable={selectable}
                onSelectEvent={(event) => {
                    window.open(`/events/edit/${event.id}`, '_blank');
                }}
                views={['month', 'week', 'agenda']}

            />
        </CalenderWrapper>
        </Box>
    );
};

export default CalendarComponent;
