import { Field } from '@builtbypixel/nucleus';

import React from 'react';
import FormSection from '../../../components/FormSection';

const Reviews = () => {
    return (
        <>
            <FormSection title="Course Reviews" mt="0px">
                <Field
                    name="reviews"
                    component="repeater"
                    rules={{ required: true }}
                    fieldArray={[
                        {
                            name: 'review_details.course_rating',
                            component: 'number',
                            type: 'number',
                            placeholder: '5',
                            min: 1,
                            max: 5,
                            label: 'Course Rating',
                            rules: { required: true },
                        },
                        {
                            name: 'review_details.course_content',
                            component: 'text',
                            type: 'number',
                            placeholder: 'Course Content',
                            label: 'Course Content',
                            rules: { required: true },
                        },
                        {
                            name: 'review_details.course_trainer',
                            component: 'text',
                            type: 'number',
                            placeholder: 'Course Trainer',
                            label: 'Course Trainer',
                            rules: { required: true },
                        },
                        {
                            name: 'review_details.comments',
                            component: 'textarea',
                            placeholder: 'Comments',
                            label: 'Comments',
                            rules: { required: true },
                        },
                    ]}
                />
            </FormSection>
        </>
    );
};

export default Reviews;
