import React from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';
import setup from './setup';
import { Button } from '@chakra-ui/react';

// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const List = () => {
    const columns = [
        {
            Header: 'Accreditor Name',
            accessor: 'name',
            Cell: ({ row }) => (
                <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
                    <Button variant="link" colorScheme="blue" rounded="md">
                        {row.original.name}
                    </Button>
                </LinkButton>
            ),
        },
    ];

    return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
