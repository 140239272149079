import React, { useState, useCallback, useEffect } from 'react';
import {
    Flex,
    Input,
    Text,
    Button,
    useDisclosure,
    InputGroup,
    InputRightElement,
    Spinner,
    useOutsideClick,
} from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useHttp } from '@builtbypixel/nucleus';
import debounce from 'lodash.debounce';
import CustomTable from '../../../../../components/common/CustomTable';

const UserSearch = ({
    eventIndex,
    delegateIndex,
    setDelegateValue,
    delegate_cancellations,
    type,
    broker,
}) => {
    const Http = useHttp();
    const ref = React.useRef();
    useOutsideClick({
        ref: ref,
        handler: () => setResults([]),
    });

    const { onClose } = useDisclosure();
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState(null);
    const [searchTerm, setSearchTerm] = useState(null);

    const [results, setResults] = useState([]);

    const { setValue } = useFormContext();

    const selectUser = (user) => {
        console.log(user);
        setValue(`events[${eventIndex}].delegates[${delegateIndex}]`, { ...user, from_api: true });
        onClose();
        setResults([]);
        setSearch(null);
        setSearchTerm(null);
    };

    const columns = [
        {
            Header: 'Name',
            accessor: 'first_name',
            Cell: ({ row }) => (
                <>
                    <Text fontWeight="bold">
                        {row.original.first_name} {row.original.last_name}
                    </Text>
                </>
            ),
        },
        {
            Header: 'Type',
            accessor: 'user_type',
            Cell: ({ row }) => <>{row.original.user_type.name}</>,
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: '',
            accessor: 'id',
            Cell: ({ row }) => (
                <Button
                    onClick={() =>
                        selectUser({
                            email: row.original.email,
                            first_name: row.original.first_name,
                            last_name: row.original.last_name,
                        })
                    }
                    size="xs"
                    colorScheme="blue"
                    ml="auto"
                >
                    Select User
                </Button>
            ),
        },
    ];

    const changeSearch = () => {
        setSearchTerm(search);
    };

    const _searchUsers = useCallback(debounce(changeSearch, 500), [search]);

    useEffect(() => {
        const searchUsers = () => {
            setLoading(true);
            Http.get('/business-personal-users', {
                params: {
                    search: searchTerm,
                },
            }).then((res) => {
                setResults(res.data.data);

                setLoading(false);
                if (res.data.data.length === 0) {
                    setValue(`events[${eventIndex}].delegates[${delegateIndex}].email`, search);
                }
            });
        };
        if (searchTerm) {
            searchUsers();
        }
    }, [searchTerm, Http]);

    useEffect(() => {
        if (search && search.length > 3) {
            _searchUsers();
        }
        if (search && search.length === 0) {
            _searchUsers();
        }

        // Cancel the debounce on useEffect cleanup.
        return _searchUsers.cancel;
    }, [_searchUsers, search]);

    const email = useWatch({
        name: `events[${eventIndex}].delegates[${delegateIndex}].email`,
    });

    const first_name = useWatch({
        name: `events[${eventIndex}].delegates[${delegateIndex}].first_name`,
    });

    const last_name = useWatch({
        name: `events[${eventIndex}].delegates[${delegateIndex}].last_name`,
    });

    const stripSpecialChars = (str) => str.replace(/[^a-zA-Z0-9. ]/g, '');
    // useEffect(() => {
    //     if (type === 'edit' && broker !== null && broker?.domain !== null) {
    //         setValue(
    //             `events[${eventIndex}].delegates[${delegateIndex}].email`,
    //             `${first_name?.replaceAll(' ', '_')}_${last_name?.replaceAll(
    //                 ' ',
    //                 '_',
    //             )}@${stripSpecialChars(broker?.domain)}`,
    //         );
    //     }
    // }, [first_name, last_name]);
    useEffect(() => {
        if (type === 'edit' && broker !== null) {
            setValue(
                `events[${eventIndex}].delegates[${delegateIndex}].email`,
                `${first_name?.replaceAll(' ', '_')}${last_name && '_'}${last_name?.replaceAll(
                    ' ',
                    '_',
                )}@${stripSpecialChars(broker?.domain ? broker?.domain : 'MarbleTraining.co.uk')}`,
            );
        }
    }, [first_name, last_name]);

    return (
        <Flex direction="column" w="100%" pos="relative">
            <InputGroup>
                <Input
                    isFullWidth
                    size="sm"
                    value={
                        type === 'edit' && broker !== null && broker?.domain !== null
                            ? email
                            : search
                            ? search
                            : email
                    }
                    variant="filled"
                    noMargin
                    placeholder="Enter the user email address"
                    onChange={(e) => {
                        if (type === 'edit' && broker !== null && broker?.domain !== null) {
                            //
                            setValue(
                                `events[${eventIndex}].delegates[${delegateIndex}].email`,
                                e.target.value,
                            );
                            setSearch(e.target.value);
                        } else {
                            setSearch(e.target.value);
                        }
                    }}
                    isDisabled={
                        (delegate_cancellations !== undefined && delegate_cancellations !== 0) ||
                        type === 'cancelled'
                    }
                    // onBlur={(e) =>
                    //     setValue(
                    //         `events[${eventIndex}].delegates[${delegateIndex}].email`,
                    //         e.target.value,
                    //     )
                    // }
                />
                {email}
                <InputRightElement children={loading ? <Spinner /> : null} />
            </InputGroup>

            {results.length !== 0 && (
                <Flex
                    w="100%"
                    minWidth="800px"
                    pos="absolute"
                    left="0px"
                    top="100%"
                    p="5px"
                    rounded="md"
                    shadow="md"
                    bg="white"
                    zIndex={9900}
                    border="1px"
                    borderColor="gray.200"
                    ref={ref}
                >
                    <CustomTable data={results} columns={columns} />
                </Flex>
            )}
            {/* <Modal isOpen={isOpen} isCentered scrollBehavior="inside" onClose={onClose}>
                <UserForm email={searchTerm} selectUser={selectUser} onClose={onClose} />
            </Modal> */}
        </Flex>
    );
};

export default UserSearch;
