import React from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';
import setup from './setup';
import { Button, Image } from '@chakra-ui/react';

// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const List = () => {
    const columns = [
        {
            Header: 'Course Name',
            accessor: 'course_name',
            Cell: ({ row }) => (
                <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
                    <Button variant="link" colorScheme="blue" rounded="md">
                        {row.original.course?.title
                            ? row.original.course?.title
                            : ' - No Course Assigned -'}
                    </Button>
                </LinkButton>
            ),
        },
        {
            Header: 'Name',
            accessor: 'users_name',
        },
        {
            Header: 'Client',
            accessor: 'company_name',
        },
    ];

    return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
