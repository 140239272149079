import { Field } from '@builtbypixel/nucleus';
import { Grid, Stack } from '@chakra-ui/react';
import React from 'react';
import FormSection from '../../../components/FormSection';
import useSWR from 'swr';
import { useGet } from '../../../Nucleus';

const Details = () => {
    const { data: providers } = useSWR(`/providers?simple=true&pagination=999&page=1`, useGet);
    const { data: courses } = useSWR(`/all-courses`, useGet);

    return (
        <>
            <FormSection title="Course Details" mt="0px">
                <Grid gap="15px" templateColumns="2fr 1fr">
                    <Stack spacing="10px" w="100%">
                        <Field
                            name="course_title"
                            component="text"
                            label="Course Title"
                            placeholder="Course Title"
                            rules={{ required: true }}
                            noMargin
                        />
                        <Field
                            name="course_header.description"
                            component="richtext"
                            label="Header Summary"
                            placeholder="Header Summary"
                            help="You should not use more than 500 characters."
                            rules={{ required: true }}
                        />
                        <Field
                            name="course_document"
                            component="file"
                            max={1}
                            label="Upload Course PDF Document"
                        />
                    </Stack>
                    <Grid gap="0px" templateColumns="1fr">
                        <Field
                            name="course_code"
                            component="text"
                            label="Course Code"
                            placeholder="Course Code"
                            rules={{ required: true }}
                            noMargin
                        />
                        <Field
                            name="provider"
                            component="select"
                            label="Provider"
                            placeholder="Provider"
                            options={providers ? providers.data : []}
                            labelKey="name"
                            valueKey="id"
                            rules={{ required: true }}
                            noMargin
                        />

                        <Field
                            name="linked_courses"
                            component="select"
                            isMulti={true}
                            options={courses?.data}
                            labelKey="title"
                            valueKey="id"
                            label="Linked Courses"
                            placeholder="Select Linked Courses"
                        />
                    </Grid>
                    <Grid gap="15px" templateColumns="1fr 1fr 1fr 100px 100px 100px">
                        <Field
                            name="duration.days"
                            component="text"
                            label="Duration (days)"
                            placeholder="Duration (days)"
                        />
                        <Field
                            name="duration.start_time"
                            component="mask"
                            mask="99:99"
                            label="Start Time"
                            placeholder="Start Time"
                            defaultValue="09:00"
                        />
                        <Field
                            name="duration.end_time"
                            component="mask"
                            mask="99:99"
                            label="End Time"
                            placeholder="End Time"
                            defaultValue="17:00"
                        />
                        <Field
                            name="published"
                            component="switch"
                            size="lg"
                            label="Is Visible?"
                            placeholder="Is Visible?"
                        />
                        <Field
                            name="featured"
                            component="switch"
                            size="lg"
                            label="Is Featured?"
                            placeholder="Is Featured?"
                        />
                        <Field
                            name="uchu"
                            component="switch"
                            size="lg"
                            label="Uchu?"
                            help="add bookings to uchu"
                            placeholder="Is Featured?"
                        />
                    </Grid>
                </Grid>
            </FormSection>
            <FormSection title="Course Tile" mt="20px">
                <Grid gap="15px" templateColumns="1fr 1fr">
                    <Stack spacing="0px" w="100%">
                        <Field
                            name="general_course_information.image"
                            component="file"
                            label="Course Image"
                            placeholder="Course Image"
                        />
                    </Stack>

                    <Stack spacing="0px" w="100%">
                        <Field
                            name="general_course_information.title"
                            component="text"
                            label="Tile Title"
                            placeholder="Tile Title"
                            rules={{ required: true }}
                        />
                        <Field
                            name="general_course_information.paragraph"
                            component="richtext"
                            label="Tile Description"
                            placeholder="Tile Description"
                            rules={{ required: true }}
                        />
                        <Field
                            name="general_course_information.subheading"
                            component="text"
                            label="Tile Sub Heading (max 150 characters)"
                            placeholder="Enter Tile Sub Heading"
                            maxLength={150}
                            rules={{ required: true }}
                        />
                    </Stack>
                </Grid>
            </FormSection>
        </>
    );
};

export default Details;
