import React, { useMemo } from "react";
import { AutoFields, EditView } from "@builtbypixel/nucleus";
import setup from "./setup";
const EditForm = () => {
  const fields = useMemo(
    () => [
      {
        group: "Our Clients",
        fields: [
          {
            name: "name",
            component: "text",
            placeholder: "Cient name",
            label: "Client name",
            isRequired: true,
            rules: { required: true },
          },
          {
            name: "url",
            component: "text",
            placeholder: "Client website",
            label: "Client website",
            isRequired: true,
            rules: { required: true },
          },
          {
            name: "logo",
            component: "file",
            placeholder: "Client Logo",
            label: "Client Logo",
            isRequired: true,
            rules: { required: true },
          },
        ],
      },
    ],
    []
  );
  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
