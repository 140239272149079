import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import { Grid, Flex } from '@chakra-ui/react';

import AuthChecker from '../components/AuthChecker';
import MobileHeader from '../components/MobileHeader';
import Page from '../components/Page';
import Menu from '../components/Menu';
import SideBar from '../components/Sidebar';
import { useContext } from 'react';
import { NucleusContext } from '../Core';
import { useQuery } from '@builtbypixel/nucleus';

const PrivateRoute = ({ component: Component, title, ...rest }) => {
    const { links } = useContext(NucleusContext);
    let query = useQuery();

    const formOnly = query.get('popup');

    return (
        <Route
            {...rest}
            render={(props) => (
                <AuthChecker isPrivate>
                    <MobileHeader />
                    <Grid
                        w="100vw"
                        templateColumns={formOnly ? '1fr' : { base: '1fr', xl: '60px 200px 1fr' }}
                        maxWidth="100vw"
                    >
                        {!formOnly && (
                            <>
                                <SideBar />
                                <Menu links={links} />
                            </>
                        )}

                        <Flex
                            direction="column"
                            w="100%"
                            flex={1}
                            borderLeft="1px"
                            borderColor="gray.300"
                        >
                            <Page title={title} auth>
                                <Component {...props} />
                            </Page>
                        </Flex>
                    </Grid>
                </AuthChecker>
            )}
        />
    );
};

export default PrivateRoute;
