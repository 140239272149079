const menuLinks = [
    {
        group: 'Dashboard',
        link: '/dashboard',
        items: [],
        permissions: ['Super Admin', 'Admin'],
    },
    {
        group: 'Templates',
        items: [
            {
                title: 'Providers',
                href: '/providers',
                permissions: ['Super Admin', 'Admin', 'Seo'],
            },
            {
                title: 'Course Features',
                href: '/course-features',
                permissions: ['Super Admin', 'Admin'],
            },
            {
                title: 'Delivery methods',
                href: '/delivery-methods',
                permissions: ['Super Admin', 'Admin'],
            },
        ],
        permissions: ['Super Admin', 'Admin', 'Seo'],
    },
    {
        group: 'Courses',
        link: '/courses',
        permissions: ['Super Admin', 'Admin', 'Seo'],

        items: [
            // {
            //   title: "Course",
            //   href: "/courses",
            // },
            // {
            //   title: "Clone Courses",
            //   href: "/clone-courses",
            // },
        ],
    },
    {
        group: 'Document Upload Types',
        link: '/document-upload-types',
        items: [],
        permissions: ['Super Admin', 'Admin'],
    },
    {
        group: 'Events',
        items: [
            {
                title: 'Events',
                href: '/events',
                permissions: ['Super Admin', 'Admin'],
            },
            {
                title: 'Event Statuses',
                href: '/event-statuses',
                permissions: ['Super Admin', 'Admin'],
            },
        ],
        permissions: ['Super Admin', 'Admin'],
    },
    {
        group: 'Refunds',
        items: [
            {
                title: 'Refunds',
                href: '/refunds',
                permissions: ['Super Admin', 'Admin'],
            },
            {
                title: 'Refund Statuses',
                href: '/refund-statuses',
                permissions: ['Super Admin', 'Admin'],
            },
        ],
    },
    {
        group: 'Free Trials',
        link: '/free-trials',
        items: [],
        permissions: ['Super Admin', 'Admin'],
    },
    {
        group: 'Bookings',
        items: [
            { title: 'Bookings', href: '/bookings', permissions: ['Super Admin', 'Admin'] },
            {
                title: 'Booking Statuses',
                href: '/booking-statuses',
                permissions: ['Super Admin', 'Admin'],
            },
            {
                title: 'Delegate Statuses',
                href: '/delegate-statuses',
                permissions: ['Super Admin', 'Admin'],
            },
        ],
        permissions: ['Super Admin', 'Admin'],
    },
    {
        group: 'Customer Certifcates',
        link: '/customer-certificates',
        items: [],
        permissions: ['Super Admin', 'Admin'],
    },
    {
        group: 'Invoices',
        link: '/invoices',
        items: [],
        permissions: ['Super Admin', 'Admin'],
    },
    {
        group: 'Credit Notes',
        link: '/credit-notes',
        items: [],
        permissions: ['Super Admin', 'Admin'],
    },
    {
        group: 'Locations & Venues',

        items: [
            { title: 'Locations', href: '/locations', permissions: ['Super Admin', 'Admin'] },
            { title: 'Venues', href: '/venues', permissions: ['Super Admin', 'Admin'] },
        ],
        permissions: ['Super Admin', 'Admin'],
    },
    {
        group: 'Trainers',
        items: [
            {
                title: 'Trainers',
                href: '/trainers',
                permissions: ['Super Admin', 'Admin'],
            },
            {
                title: 'Trainer Statuses',
                href: '/trainer-statuses',
                permissions: ['Super Admin', 'Admin'],
            },
            {
                title: 'Trainers Calendars',
                href: '/trainers-calendars',
                permissions: ['Super Admin', 'Admin'],
            },
            {
                title: 'Trainer Types',
                href: '/trainer-types',
                permissions: ['Super Admin', 'Admin'],
            },
            {
                title: 'Trainer Price Types',
                href: '/trainer-prices',
                permissions: ['Super Admin', 'Admin'],
            },
            {
                title: 'Trainer Document Types',
                href: '/trainer-document-types',
                permissions: ['Super Admin', 'Admin'],
            },
        ],
        permissions: ['Super Admin', 'Admin'],
    },

    {
        group: 'Customers',
        items: [
            {
                title: 'Customer Types',
                href: '/customer-types',
                permissions: ['Super Admin', 'Admin'],
            },
            {
                title: 'Businesses',
                href: '/businesses',
                permissions: ['Super Admin', 'Admin'],
            },
            {
                title: 'Personal',
                href: '/Personal',
                permissions: ['Super Admin', 'Admin'],
            },
            {
                title: 'Brokers',
                href: '/Brokers',
                permissions: ['Super Admin', 'Admin'],
            },
        ],
        permissions: ['Super Admin', 'Admin'],
    },
    // {
    //   group: "Notifications",
    //   link: "/notifications",
    //   items: [],
    // },
    {
        group: 'Enquiries',
        items: [
            {
                title: 'Enquiries',
                href: '/enquiries',
                permissions: ['Super Admin', 'Admin'],
            },
            {
                title: 'Enquiry Types',
                href: '/enquiry-types',
                permissions: ['Super Admin', 'Admin'],
            },
            {
                title: 'Enquiry Statuses',
                href: '/enquiry-statuses',
                permissions: ['Super Admin', 'Admin'],
            },
        ],
        permissions: ['Super Admin', 'Admin'],
    },
    {
        group: 'Clients',
        items: [
            {
                title: 'Our Clients',
                href: '/our-clients',
                permissions: ['Super Admin', 'Admin'],
            },
            {
                title: 'Client Reviews',
                href: '/client-reviews',
                permissions: ['Super Admin', 'Admin'],
            },
        ],
    },
    {
        group: 'Pages',
        link: '/pages',
        items: [],
        permissions: ['Super Admin', 'Admin', 'Seo'],
    },
    {
        group: 'Custom Pages',
        link: '/custom-pages',
        items: [],
        permissions: ['Super Admin', 'Admin'],
    },
    {
        group: 'Jobs',
        items: [
            {
                title: 'Jobs',
                href: '/jobs',
                permissions: ['Super Admin', 'Admin'],
            },
            {
                title: 'Applications',
                href: '/job-applications',
                permissions: ['Super Admin', 'Admin'],
            },
        ],
        permissions: ['Super Admin', 'Admin'],
    },
    {
        group: 'Themes',
        link: '/themes',
        items: [],
        permissions: ['Super Admin', 'Admin'],
    },
    {
        group: 'Blog',
        items: [
            {
                title: 'All Articles',
                href: '/blogs',
                permissions: ['Super Admin', 'Admin', 'Seo'],
            },
            {
                title: 'Blog Categories',
                href: '/blog-categories',
                permissions: ['Super Admin', 'Admin', 'Seo'],
            },
        ],
        permissions: ['Super Admin', 'Admin', 'Seo'],
    },
    {
        group: 'Custom Downloads',
        items: [
            {
                title: 'Download Page Settings',
                href: '/download-page-settings',
                permissions: ['Super Admin', 'Admin', 'Seo'],
            },
            {
                title: 'Downloads',
                href: '/downloads',
                permissions: ['Super Admin', 'Admin', 'Seo'],
            },
            {
                title: 'Download Categories',
                href: '/download-categories',
                permissions: ['Super Admin', 'Admin', 'Seo'],
            },
        ],
        permissions: ['Super Admin', 'Admin', 'Seo'],
    },
    {
        group: 'Settings',
        items: [
            {
                title: 'Accreditations',
                href: '/accreditations',
                permissions: ['Super Admin', 'Admin'],
            },
            {
                title: 'Course Reviews',
                href: '/course-reviews',
                permissions: ['Super Admin', 'Admin'],
            },
            {
                title: 'Course Document Types',
                href: '/course-document-types',
                permissions: ['Super Admin', 'Admin'],
            },
            {
                title: 'Duration Satuses',
                href: '/duration-statuses',
                permissions: ['Super Admin', 'Admin'],
            },
            {
                title: 'User Document Types',
                href: '/user-document-types',
                permissions: ['Super Admin', 'Admin'],
            },
            {
                title: 'Vat Rates',
                href: '/vat-rates',
                permissions: ['Super Admin', 'Admin'],
            },
        ],
        permissions: ['Super Admin', 'Admin'],
    },
];

export default menuLinks;
