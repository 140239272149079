import React, { useState, useEffect } from 'react';
import { Field, useHttp } from '@builtbypixel/nucleus';
import { FaCheckCircle } from 'react-icons/fa';
import { MdRadioButtonUnchecked, MdErrorOutline } from 'react-icons/md';
import { Box, Icon } from '@chakra-ui/react';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';

const EmailField = ({ email, name, help }) => {
    const [exists, setExists] = useState(null);
    const [isValidEmail, setIsValidEmail] = useState(null);
    const Http = useHttp();
    const { type } = useParams();
    const {
        formState: { errors },
        setError,
        clearErrors,
    } = useForm({ mode: 'onBlur' });

    const checkEmail = async () => {
        Http.post(`/check-email-unique/${email}`)
            .then((res) => {
                setExists(res.data.data.exists);

                res.data.data.exists &&
                    setError(name, {
                        type: 'custom',
                        message: 'This email already exists',
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    let regEx =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    useEffect(() => {
        if (exists !== null) setExists(null);
        clearErrors(`${name}`); // for clear single input error
        setIsValidEmail(null);
    }, [email]);

    return (
        <>
            <Field
                name={name}
                label="Email Address"
                component="text"
                onBlur={() => {
                    regEx.test(email) === true && checkEmail();
                    setIsValidEmail(regEx.test(email));
                }}
                type="email"
                rules={{
                    required: true,
                    pattern: {
                        value: regEx,
                        message: 'Please enter a valid email',
                    },
                }}
                type="email"
                help={
                    help
                        ? help
                        : type === 'edit' && exists === false
                        ? 'After editing the email please remember to press Save before attempting to reset password.'
                        : exists
                        ? 'This email already exists'
                        : isValidEmail === false
                        ? 'email must be of valid format: e.g. user@example.com'
                        : null
                }
            />
            <Box ml="0.5em">
                {isValidEmail === false ? (
                    <Icon color="red">
                        <MdErrorOutline fontSize="22px" />
                    </Icon>
                ) : exists === null ? (
                    <Icon color="rgba(0,0,0,0.1)">
                        <MdRadioButtonUnchecked fontSize="22px" />
                    </Icon>
                ) : exists === true ? (
                    <Icon color="red">
                        <MdErrorOutline fontSize="22px" />
                    </Icon>
                ) : (
                    <Icon color="success">
                        <FaCheckCircle fontSize="22px" />
                    </Icon>
                )}
            </Box>
        </>
    );
};

export default EmailField;
