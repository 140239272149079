import React, { useMemo } from 'react'
import { AutoFields, EditView } from '@builtbypixel/nucleus'

import setup from './setup'

const EditForm = () => {
   const fields = useMemo(
      () => [
         {
            group: 'Main Details',
            fields: [
               {
                  name: 'title',
                  component: 'text',
                  placeholder: 'Article Title',
                  label: 'Article Title',
                  isRequired: true,
                  rules: { required: true }
               },
               {
                  name: 'date',
                  component: 'date',
                  valueKey: 'id',
                  label: 'Date',
                  isRequired: true,
                  rules: { required: true }
               },
               {
                  name: 'image',
                  component: 'file',
                  max: 1,
                  label: 'Event Image',
                  isRequired: true,
                  rules: { required: true }
               },
               {
                  name: 'content.description',
                  component: 'richtext',
                  label: 'Article Content',
                  isRequired: true,
                  rules: { required: true }
               }
            ]
         }
      ],
      []
   )
   return (
      <EditView setup={setup}>
         <AutoFields fields={fields} />
      </EditView>
   )
}

export default EditForm