import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { totalDatesPerMonthView } from './dates';

dayjs.extend(customParseFormat);

export const getSpecificDate = (month, dayOfMonth, year) => {
    return dayjs(`${month}/${dayOfMonth}/${year}`);
};

export const getDayOfMonth = (date) => dayjs(date).day();

export const getMonth = (date) => dayjs(date).month();

export const getYear = (date) => dayjs(date).year();

export const getToday = () => dayjs();

export const getReadableWeekday = (date) => dayjs(date).format('dddd');

export const getReadableMonthDate = (date) => dayjs(date).format('MMMM Do');

export const getMonthDayYear = (date) => dayjs(date).format('MM-DD-YYYY');

const getDatesInMonthDisplay = (date) => {
    const daysInMonth = dayjs(date).daysInMonth();

    const firstWeekday = dayjs(date).startOf('month').day();

    const result = [];

    // Based on index number of firstWeekday, add number of previous month's overflow dates
    const prevMonthYear = dayjs(date, 'MM/YYYY').subtract(1, 'month');

    const prevDaysInMonth = prevMonthYear.daysInMonth();

    for (let i = firstWeekday - 1; i >= 0; i--) {
        result.push({
            currentMonth: false,
            date: getSpecificDate(
                prevMonthYear.month() + 1,
                prevDaysInMonth - i,
                prevMonthYear.year(),
            ),
        });
    }

    // Add all current month dates
    for (let j = 1; j <= daysInMonth; j++) {
        result.push({
            currentMonth: true,
            date: getSpecificDate(date.month() + 1, j, date.year()),
        });
    }

    // Overflow dates for next month to meet totalDatesPerMonthView requirement
    if (result.length < totalDatesPerMonthView) {
        const daysToAdd = totalDatesPerMonthView - result.length;
        const nextMonthYear = dayjs(date).add(1, 'month');

        for (let k = 1; k <= daysToAdd; k++) {
            result.push({
                currentMonth: false,
                date: getSpecificDate(nextMonthYear.month() + 1, k, nextMonthYear.year()),
            });
        }
    }

    return result;
};

const getDatesInWeekDisplay = (date) => {
    let startWeek = dayjs(date).startOf('week');

    const result = [];

    // Add all current week dates
    for (let j = 1; j <= 7; j++) {
        result.push({
            currentMonth: true,
            date: getSpecificDate(startWeek.month() + 1, dayjs(startWeek).date(), startWeek.year()),
        });
        startWeek = dayjs(startWeek).add(1, 'day');
    }

    return result;
};

const getDayDisplay = (date) => {
    const result = [];

    // Add all current week dates

    result.push({
        currentMonth: true,
        date: getSpecificDate(date.month() + 1, dayjs(date).date(), date.year()),
    });

    return result;
};

const getMonthSet = (selectDate) => {
    const month = getMonth(selectDate) + 1;
    const result = {
        current: selectDate,
        prev: getSpecificDate(month - 1, 1, getYear(selectDate)),
        next: getSpecificDate(month + 1, 1, getYear(selectDate)),
    };

    if (month === 1) {
        result.prev = getSpecificDate(12, 1, getYear(selectDate) - 1);
    }

    if (month === 12) {
        result.next = getSpecificDate(1, 1, getYear(selectDate) + 1);
    }

    return result;
};

const presetDateTracker = (dates) => {
    const result = {};

    if (dates && Array.isArray(dates)) {
        dates.forEach((date) => {
            const dateStr = getMonthDayYear(date);

            if (!result[dateStr]) {
                result[dateStr] = 1;
            } else {
                result[dateStr] += 1;
            }
        });
    }

    return result;
};

export {
    getDayDisplay,
    getDatesInWeekDisplay,
    getDatesInMonthDisplay,
    getMonthSet,
    presetDateTracker,
};
