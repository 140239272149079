import React, { useEffect, useState } from 'react';
import { LinkButton, ListWrapper, useGet } from '@builtbypixel/nucleus';
import setup from './setup';
import moment from 'moment';
import { Box, Button, Text, Stack, Tooltip, IconButton } from '@chakra-ui/react';
import useSWR from 'swr';
import { FaFileInvoice } from 'react-icons/fa';
import { useHttp } from '../../Nucleus';

// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const List = () => {
    const [allUsers, setAllUsers] = useState([]);

    const getURL = process.env.REACT_APP_API;
    const Http = useHttp();
    const { data: students } = useSWR('/students', useGet);
    const { data: businesses } = useSWR('/businesses', useGet);
    const { data: bookingStatus } = useSWR('booking-statuses', useGet);
    const { data: InvoiceStatus } = useSWR('invoice-statuses', useGet);

    const downloadInvoice = (invoiceId) =>{
        Http.get(`/generate-invoice-by-id/${invoiceId}`,{responseType: 'blob'}).then((response)=>{
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `invoice-${invoiceId}.pdf`); 
            document.body.appendChild(link);
            link.click();
        }).catch((err)=>{
            console.log(err);
        })
    }

    useEffect(() => {
        if (students && businesses && !allUsers.length === 0) {
            const newUser = [];
            const mergeUser = [...students.data, ...businesses.data];
            console.log(mergeUser, 'mergeUser');

            const allEmails = () => {
                mergeUser.map((user) => {
                    const userDetails = {
                        id: user.id,
                        email: user.email,
                    };
                    newUser.push(userDetails);
                });
                return newUser;
            };

            setAllUsers(allEmails);

            console.log(allEmails);
        }
    }, [students, businesses, allUsers]);

    useEffect(() => {
        console.log(allUsers);
    }, [allUsers]);

    const columns = [
        {
            Header: 'Actions',
            accessor: 'download',
            Cell: ({ row }) => (
                <Stack isInline spacing="15px">
                    <Tooltip label="Download invoice" _hover={{ cursor: 'pointer' }}>
                        <Text
                            as="a"
                            onClick={()=>downloadInvoice(row.original.id)}
                        >
                            <IconButton
                                size="sm"
                                variant="link"
                                icon={<FaFileInvoice fontSize="18px" />}
                            />
                        </Text>
                    </Tooltip>
                </Stack>
            ),
            disableSortBy: true,
        },
        {
            Header: 'Invoice Number',
            accessor: 'book ID',
            Cell: ({ row }) => (
                <LinkButton target="_blank" to={`${setup.model}/edit/${row.original.id}`}>
                    <Button variant="link" colorScheme="blue" size="sm" rounded="md">
                    <>{row.original.order?.reference ? `${row.original.order?.id} / ${row.original.order?.reference}` : 'N/A'}</>
                    </Button>
                </LinkButton>
            ),
        },
        {
            Header: 'Invoice Id',
            accessor: 'id'
         
        },
        {
            Header: 'Booking Reference',
            accessor: 'order.reference',
            Cell: ({ row }) =>
                <>{row.original.order?.reference ? `${row.original.order?.id} / ${row.original.order?.reference}` : 'N/A'}</>
        },
        {
            Header: 'Booking type',
            accessor: 'nucleus',
            Cell: ({ row }) => (
                <>{row.original.order && row.original.order.nucleus ? 'Nucleus' : 'Website'}</>
            ),
        },
        {
            Header: 'Customer',
            accessor: 'customer',
            Cell: ({ row }) => (   
                <>
                    <Box>{row.original.order?.user.email ? row.original.order?.user.email : null}</Box>
                    {row.original.order?.user?.user_type.name !== 'Business' && (
                        <Box>
                            <LinkButton target="_blank" to={`Personal/edit/${row.original.order.user?.id}`}>
                                <Button size="sm" variant="link" colorScheme="blue" rounded="md">
                                    <Text color="gray.500" paddingTop="5px">
                                        {row.original.order?.user?.name}
                                    </Text>
                                </Button>
                            </LinkButton>
                        </Box>
                    )}
                    {row.original.order?.user.company_name && (
                        <Box>
                            <LinkButton to={`businesses/edit/${row.original.order.user?.id}`}>
                                <Button size="sm" variant="link" colorScheme="blue" rounded="md">
                                    <Text color="gray.500" paddingTop="5px">
                                        {row.original.order?.user.company_name}
                                    </Text>
                                </Button>
                            </LinkButton>
                        </Box>
                    )}
                </>
            ),
        },
        {
            Header: 'Booking user',
            accessor: 'booking_user',
            Cell: ({ row }) => (   
                <>
                    <Box>{row.original.booked_by?.email ? row.original.booked_by?.email : null}</Box>
                    <Box>
                        <Text color="gray.500" paddingTop="5px">
                            {row.original.booked_by?.first_name} {' '}
                            {row.original.booked_by?.last_name}
                        </Text>
                    </Box>
                </>
            ),
        },

        {
            Header: 'Total',
            accessor: 'order.total',
            Cell: ({ row, value }) => (row.original.order ? `£${value.toFixed(2)}` : `£${row.original.total.toFixed(2)}`),
        },
        {
            Header: 'Status',
            accessor: 'status.name',
        },
        {
            Header: 'Invoice Raised',
            accessor: 'invoice_date',
            Cell: ({ value }) => moment(value).format('DD/MM/YYYY'),
        },
        {
            Header: 'Invoice Due',
            accessor: 'due_date',
        },
    ];

    const filters = [
        {
            component: 'select',
            label: 'Booking Status',
            name: 'status',
            placeholder: 'Select Status',
            options: InvoiceStatus?.data,
            labelKey: 'name',
            valueKey: 'id',
            defaultValue: { id: 2, name: 'Paid' },
        },
        {
            component: 'date',
            label: 'Invoice Raised',
            name: 'invoice_date',
            placeholder: 'Invoice Raised',
        },
        {
            component: 'date',
            label: 'Invoice Due',
            name: 'due_date',
            placeholder: 'Invoice Due',
        },
    ];

    return <ListWrapper setup={setup} filters={filters} columns={columns} extraMenu={extraMenu} />;
};

export default List;
