import React from 'react';
import { Image, Link } from '@chakra-ui/react';
import { BiFile } from 'react-icons/bi';
import { useState } from 'react';
import { useEffect } from 'react';
const ImageOrFile = ({ url }) => {
    const [fileType, setFileType] = useState(null);
    useEffect(() => {
        if (url) {
            setFileType(url.slice(-4));
        }
    }, [url]);

    return (
        <Link href={url} target="_blank">
            {fileType === '.png' || fileType === 'jpeg' ? (
                <Image src={url} maxHeight="250px" />
            ) : (
                <BiFile size="3em" />
            )}
        </Link>
    );
};

export default ImageOrFile;
