import React from 'react';
import { Text, Flex } from '@chakra-ui/react';

const DataView = ({ title, value }) => {
    return (
        <Flex direction="column" align="center" bg="#edf2f7" p="1em">
            <Text fontSize="1.4em">{title}</Text>
            <Text fontSize="2em" fontWeight="bold">
                {value}
            </Text>
        </Flex>
    );
};

export default DataView;
