import { Field } from '@builtbypixel/nucleus';

import React from 'react';
import FormSection from '../../../components/FormSection';

const Content = () => {
    return (
        <>
            <FormSection title="Course Content" mt="0px">
                <Field
                    name="content.learning_outcomes"
                    component="richtext"
                    label="Learning Outcomes"
                    placeholder="Learning Outcomes"
                    rules={{ required: true }}
                />
                <Field
                    name="content.entry_requirements"
                    component="richtext"
                    label="Entry Requirements"
                    placeholder="Entry Requirements"
                    rules={{ required: true }}
                />
                <Field
                    name="content.course_structure"
                    component="richtext"
                    label="Course Structure"
                    placeholder="Course Structure"
                    rules={{ required: true }}
                />
                <Field
                    name="content.results"
                    component="richtext"
                    label="Results"
                    placeholder="Results"
                    rules={{ required: true }}
                />
                <Field 
                    name="content.additional_text"
                    component="richtext"
                    label="Additional Course Information"
                    placeholder="Enter Information here..."
                />
                <Field
                    name={`content.faqs`}
                    component="repeater"
                    label="FAQ's"
                    placeholder="FAQ's"
                    layout="vertical"
                    fieldArray={[
                        {
                            component: 'text',
                            name: 'question',
                            placeholder: 'Question',
                            label: 'Question',
                        },
                        {
                            component: 'richtext',
                            name: 'answer',
                            placeholder: 'Answer',
                            label: 'Answer',
                        },
                    ]}
                />
            </FormSection>
        </>
    );
};

export default Content;
