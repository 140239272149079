const Button = {
    variants: {
        primary: () => ({
            bg: 'primary',
            color: 'white',
            _hover: {
                bg: '#2D2F31',
            },
        }),
        success: () => ({
            bg: 'success',
            color: 'white',
            _hover: {
                bg: '#229652',
            },
        }),
    },
};

export default Button;
