const modelName = 'Bookings';
const modelNameSingular = 'Booking';
const endpoint = '/bookings';

const setup = {
    title: modelName,
    singular: modelNameSingular,
    model: modelName.replace(/\s+/g, '-').toLowerCase(),
    canDelete: false,
    canAddNew: true,
    buttonText: 'Create Booking',
    secondaryButton: true,
    secondaryButtonText: 'Create Broker Booking',
    canSearch: true,
    endpoint,
    accessor: 'id',
};

export default setup;
