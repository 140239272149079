import React, { useEffect, useState } from 'react';
import Section from '../Section';
import { Grid, Text, Badge, Flex, Skeleton, Stack, Box, Switch, Image } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { bookingAmountState } from '../../atom';
import { useFormContext, useWatch } from 'react-hook-form';
import debounce from 'lodash.debounce';
import { useHttp } from '@builtbypixel/nucleus';
import { useParams } from 'react-router';
import moment from 'moment';

const CostSummary = () => {
    const Http = useHttp();
    const { setValue } = useFormContext();
    const [loading, setLoading] = useState(false);
    const events = useWatch({ name: 'events' });
    const customer = useWatch({ name: 'customer' });
    const creditUsed = useWatch({ name: 'credit_used' });
    const creditAmountUsed = useWatch({ name: 'credit_amount' });
    const creditTotal = useWatch({ name: 'credit_total' });
    const broker = useWatch({ name: 'broker' });
    const paymentStatus = useWatch({ name: 'status' });

    const [data, setData] = useState(null);
    const [total, setTotal] = useRecoilState(bookingAmountState);
    const { id, type } = useParams();

    const asyncSearch = debounce(() => {
        const isBroker = broker || typeof customer?.broker_type?.name === 'string';
        Http.post('/calculate-event-price', {
            customer,
            events,
            isBroker,
            order_id: id,
        }).then((res) => {
            setData(res.data);
            setTotal(Number(res.data.total).toFixed(2));
            setLoading(false);
        });
    }, 500);

    useEffect(() => {
        if (customer?.id && events && events.length !== 0) {
            asyncSearch();
        }
    }, [events, customer?.id, total]);

    const status = useWatch({ name: 'status' });
    const show_certificates = useWatch({ name: 'show_certificates' });

    const toggle = (event) => {
        setValue('show_certificates', event.target.checked);
    };

    return (
        <Section title="Booking Summary" mb="0px">
            <Flex direction="column" w="100%">
                {type === 'edit' && status && (
                    <Badge
                        mb="20px"
                        fontSize="20px"
                        textAlign="center"
                        rounded="lg"
                        py="10px"
                        variant={status.name === 'Cancelled' && 'solid'}
                        colorScheme={
                            status.name === 'Provisional' || status.name === 'Awaiting Payment'
                                ? 'orange'
                                : status.name === 'Completed'
                                ? 'green'
                                : status.name === 'Failed Payment'
                                ? 'red'
                                : status.name === 'Cancelled'
                                ? 'red'
                                : 'gray'
                        }
                    >
                        {status.name}
                    </Badge>
                )}

                {loading && (
                    <>
                        <Stack w="100%" mb="25px">
                            <Skeleton height="20px" />
                            <Skeleton height="20px" w="70%" />
                            <Skeleton height="20px" />
                        </Stack>
                        <Stack w="100%">
                            <Skeleton height="20px" />
                            <Skeleton height="20px" w="70%" />
                            <Skeleton height="20px" />
                        </Stack>
                    </>
                )}
                {broker !== undefined && broker !== null && (
                    <Flex direction={'column'} mb={6}>
                        <Box bg="gray.300" px="10px" py="5px" roundedTop="md">
                            <Text fontWeight={'bold'}>Broker Details</Text>
                        </Box>
                        <Box p="10px" gap="5px" w="100%" roundedBottom="md" bg="gray.100">
                            <Flex direction={'column'} mb={'5px'}>
                                <Text fontSize="12px" color="gray.700">
                                    Name
                                </Text>
                                <Text fontWeight="bold">
                                    {broker?.first_name} {broker?.last_name}
                                </Text>
                            </Flex>
                            <Flex direction={'column'} mb={'5px'}>
                                <Text fontSize="12px" color="gray.700">
                                    Email
                                </Text>
                                <Text fontWeight="bold">{broker?.email}</Text>
                            </Flex>
                            <Flex direction={'column'} mb={'5px'}>
                                <Text fontSize="12px" color="gray.700">
                                    Commission
                                </Text>
                                <Text fontWeight="bold">{broker?.commission}%</Text>
                            </Flex>
                            <Flex direction={'column'} mb={'5px'}>
                                <Text fontSize="12px" color="gray.700">
                                    Domain
                                </Text>
                                <Text fontWeight="bold">{broker?.domain}</Text>
                            </Flex>
                            <Flex direction={'column'} mb={'5px'}>
                                <Text fontSize="12px" color="gray.700">
                                    Type
                                </Text>
                                <Text fontWeight="bold">{broker?.broker_type?.name}</Text>
                            </Flex>
                            <Flex direction={'column'} mb={'5px'} mt={'15px'}>
                                <Text fontSize="12px" color="gray.700">
                                    Show Certificates for delegates
                                </Text>
                                <Switch
                                    mt={2}
                                    size="md"
                                    isChecked={show_certificates}
                                    defaultChecked={show_certificates}
                                    onChange={toggle}
                                    position="relative"
                                />
                            </Flex>
                        </Box>
                    </Flex>
                )}
                {data &&
                    data.events &&
                    data.events?.length !== 0 &&
                    data.events?.map((event, i) => (
                        <Flex direction="column" key={`item-${i}`}>
                            <Text
                                fontWeight="bold"
                                bg="gray.300"
                                px="10px"
                                py="5px"
                                roundedTop="md"
                            >
                                <Flex>
                                    {event?.course?.title}
                                    {event?.is_bundle && (
                                        <Image
                                            ml="0.5em"
                                            src="/bundle_icon.png"
                                            height="25px"
                                            width="25px"
                                        />
                                    )}
                                </Flex>
                                <br />
                                {/* {event?.is_bundle && (
                                <Text as="span" fontWeight="normal" fontSize="0.9em">
                                   Bundle
                                </Text>
                                )} */}
                                <br />
                                <Text as="span" fontWeight="normal" fontSize="0.9em">
                                    {event?.course?.provider
                                        ? event?.course?.provider?.name
                                        : event?.provider?.name}
                                </Text>
                                <br />
                                <Text as="span" fontWeight="normal" fontSize="0.9em">
                                    {moment(event?.start_date, 'DD/MM/YYYY - HH:mm').format(
                                        'DD-MM-YYYY - HH:mm a',
                                    )}
                                </Text>
                                {event?.locations && (
                                    <>
                                        <br />
                                        <Text color="gray.600" fontWeight="normal" fontSize="11px">
                                            {event.locations?.location_name} -{' '}
                                            {event.venues && event.venues?.[0]?.title}
                                        </Text>
                                    </>
                                )}
                                {event?.cancellations?.length === event.quantity && (
                                    <Badge colorScheme={'red'}>Booking Cancelled</Badge>
                                )}
                            </Text>
                            <Grid
                                p="10px"
                                gap="5px"
                                w="100%"
                                roundedBottom="md"
                                bg="gray.100"
                                templateColumns="1fr 150px"
                                key={`summary-event-${i}`}
                                mb="20px"
                            >
                                <Text fontSize="12px" color="gray.700">
                                    Course Cost
                                </Text>
                                <Text fontWeight="bold" textAlign="right">
                                    £{event.original_price} x {event.quantity}
                                </Text>
                                {!!event?.discounted_amount && (
                                    <>
                                        <Text fontSize="12px" color="gray.700">
                                            Discount
                                        </Text>
                                        <Text fontWeight="bold" textAlign="right">
                                            -£
                                            {Number(event?.discounted_amount).toFixed(2)}
                                        </Text>
                                    </>
                                )}
                                {!!event?.commission_value && (
                                    <>
                                        <Text fontSize="12px" color="gray.700">
                                            Commission
                                        </Text>
                                        <Text fontWeight="bold" textAlign="right">
                                            -£
                                            {Number(event?.commission_value).toFixed(2)}
                                        </Text>
                                    </>
                                )}

                                <Text fontSize="12px" color="gray.700">
                                    Sub-total
                                </Text>
                                <Text fontWeight="bold" textAlign="right">
                                    £{Number(event?.sub_total).toFixed(2)}
                                </Text>
                                {paymentStatus?.name === 'Awaiting Payment' &&
                                    event.cancellations?.length > 0 && (
                                        <>
                                            <Text fontSize="12px" color="red.600">
                                                Cancellation Refund
                                            </Text>
                                            <Text
                                                fontWeight="bold"
                                                textAlign="right"
                                                color="red.600"
                                            >
                                                £
                                                {Number(
                                                    event.cancellations?.[0]?.refund_amount,
                                                ).toFixed(2)}{' '}
                                                x {event.cancellations.length}
                                            </Text>
                                        </>
                                    )}
                            </Grid>
                        </Flex>
                    ))}
                {/* {console.log(data, '----data')} */}
                {data && (
                    <Grid
                        p="15px"
                        gap="7px"
                        w="100%"
                        rounded="md"
                        bg="blue.100"
                        templateColumns="1fr 150px"
                    >
                        {broker !== undefined && broker !== null ? (
                            <>
                                <Text color="blue.700">Commission Discount</Text>
                                <Text textAlign="right"> - {broker?.commission}%</Text>
                            </>
                        ) : null}

                        <Text color="blue.700">Sub Total</Text>
                        <Text textAlign="right">£{Number(data.sub_total).toFixed(2)}</Text>
                        {data?.total_cancellation && paymentStatus?.name === 'Awaiting Payment' && (
                            <>
                                <Text color="red.600">Refund Total</Text>
                                <Text fontWeight="bold" textAlign="right" color="red.600">
                                    £{Number(data?.total_cancellation).toFixed(2)}
                                </Text>
                            </>
                        )}
                        <Text color="blue.700">VAT</Text>
                        <Text textAlign="right">£{Number(data.vat).toFixed(2)}</Text>
                        {creditUsed === true && (
                            <>
                                <Text color="blue.700">Credit Used</Text>
                                <Text textAlign="right">
                                    £{Number(creditAmountUsed).toFixed(2)}
                                </Text>
                            </>
                        )}
                        <Text fontWeight="bold" fontSize="15px">
                            Total to pay
                        </Text>
                        {type === 'create' ? (
                            <Text textAlign="right" fontWeight="bold" fontSize="15px">
                                £{Number(total).toFixed(2)}
                            </Text>
                        ) : (
                            <Text textAlign="right" fontWeight="bold" fontSize="15px">
                                £
                                {Number(
                                    paymentStatus?.name === 'Awaiting Payment'
                                        ? data?.total
                                        : creditTotal,
                                ).toFixed(2)}
                            </Text>
                        )}
                    </Grid>
                )}
            </Flex>
        </Section>
    );
};

export default CostSummary;
