import React, { useState } from 'react';

import {
    Text,
    Modal,
    ModalBody,
    ModalHeader,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    Button,
} from '@chakra-ui/react';

import EventsView from '../../../containers/EventsNew';
import ListView from '../../../containers/EventsNew/List';
import FormSection from '../../../components/FormSection';

import moment from 'dayjs';

import { useParams } from 'react-router';
import { useFormContext } from 'react-hook-form';

const Events = ({ id: passedId }) => {
    const { id } = useParams();
    const { getValues } = useFormContext();

    const [view, setView] = useState(null);

    const columns = [
        {
            Header: 'ID',
            accessor: 'id',
        },
        {
            Header: 'Course',
            accessor: 'course.title',
        },
        {
            Header: 'Location',
            accessor: 'locations.location_name',
        },
        {
            Header: 'Venue',
            accessor: 'venues[0].title',
        },
        {
            Header: 'Delivery',
            accessor: 'learningItem.name',
        },
        {
            Header: 'Date',
            accessor: 'start_date',
            Cell: ({ value }) => <Text>{moment(value, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YYYY - HH:mm')}</Text>,
        },
        {
            Header: 'Duration',
            accessor: 'duration',
        },

        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <Button colorScheme="blue" size="sm" onClick={() => setView(row.original.id)}>
                    Quick View
                </Button>
            ),
        },
    ];

    return (
        <>
            <FormSection title="User Events" mt="0px">
                <ListView
                    tableOnly
                    columns={columns}
                    setup={{
                        endpoint: `/user-events/${passedId ? passedId : id}`,
                        title: 'Events',
                        canSelect: false,
                    }}
                />
                <Modal
                    scrollBehavior="inside"
                    size="xl"
                    isOpen={view !== null}
                    isCentered
                    onClose={() => setView(null)}
                    motionPreset="none"
                >
                    <ModalOverlay />
                    <ModalContent maxWidth="97%">
                        <ModalCloseButton />
                        <ModalHeader borderBottom="1px" borderColor="gray.200">
                            Event {view} for {getValues('first_name')} {getValues('last_name')}
                        </ModalHeader>

                        <ModalBody p="0px">
                            <EventsView viewOnly id={view} />
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </FormSection>
        </>
    );
};

export default Events;
