import React, { useMemo } from 'react';

import { EditView, AutoFields } from '@builtbypixel/nucleus';
import setup from '../setup';

const EditForm = () => {
    const fields = useMemo(
        () => [
            {
                group: 'Location',
                fields: [
                    {
                        name: 'content.bannerPosition',
                        component: 'select',
                        placeholder: 'Banner position',
                        options: [{ name: 'left' }, { name: 'right' }, { name: 'middle' }],
                        default: 'left',
                        labelKey: 'name',
                        valueKey: 'id',
                        label: 'Banner position',
                        variant: 'outline',
                        rules: { required: true },
                        isRequired: true,
                    },

                    {
                        name: 'content.contactBanner.title',
                        component: 'text',
                        placeholder: 'Contact Banner title',
                        label: 'Contact Banner title',
                        rules: { required: true },
                        isRequired: true,
                    },
                    {
                        name: 'content.contactBanner.text',
                        component: 'text',
                        placeholder: 'Contact Banner text',
                        label: 'Contact Banner text',
                        rules: { required: true },
                        isRequired: true,
                    },
                ],
            },
        ],
        [],
    );
    return (
        <EditView setup={setup}>
            <AutoFields fields={fields} />
        </EditView>
    );
};

export default EditForm;
