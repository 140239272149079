import Dashboard from './Dashboard';

const otherRoutes = [
    {
        path: '/dashboard',
        title: 'Dashboard',
        exact: true,
        auth: true,
        component: Dashboard,
        permissions: ['Super Admin', 'Admin'],
    },
];

const routes = [...otherRoutes];

export default routes;
