import React from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';
import setup from './setup';
import { Button } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

const List = () => {
    let history = useHistory();
    const cloneCourse = (id) => {
        //route to the create page
        history.push(`/courses-new/create?id=${id}`);
    };

    const extraMenu = () => {
        return (
            <Button
                rounded="lg"
                variant="primary"
                onClick={() => history.push('/courses-new/clone-search')}
            >
                Search course to clone
            </Button>
        );
    };

    const columns = [
        {
            Header: 'Course Code',
            accessor: 'code',
            Cell: ({ row }) => (
                <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
                    <Button variant="link" variantColor="secondary" rounded="md">
                        {row.original.code}
                    </Button>
                </LinkButton>
            ),
        },
        {
            Header: 'Provider',
            accessor: 'provider.name',
        },
        {
            Header: 'Title',
            accessor: 'title',
        },
        {
            Header: 'Featured',
            accessor: 'featured',
            Cell: ({ row }) => <>{row.original.featured === true ? 'Yes' : 'No'}</>,
        },
        {
            Header: 'Published',
            accessor: 'published',
            Cell: ({ row }) => <>{row.original.published === true ? 'Yes' : 'No'}</>,
        },
        {
            Header: 'Clone course',
            accessor: 'id',
            Cell: ({ row }) => (
                <>
                    <Button
                        rounded="lg"
                        variant="primary"
                        onClick={() => cloneCourse(row.original.id)}
                    >
                        Clone
                    </Button>
                </>
            ),
        },
    ];

    return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
