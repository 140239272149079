const modelName = 'Duration Statuses';
const modelNameSingular = 'Duration Status';
const endpoint = '/duration-statuses';

const setup = {
    title: modelName,
    singular: modelNameSingular,
    model: modelName.replace(/\s+/g, '-').toLowerCase(),
    canDelete: false,
    canAddNew: true,
    canSearch: false,
    endpoint,
    accessor: 'id',
};

export default setup;
