import React, { useState } from 'react';

import {
    Text,
    Badge,
    Modal,
    ModalBody,
    ModalHeader,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    Button,
} from '@chakra-ui/react';

import View from '../../../containers/Bookings';
import ListView from '../../../containers/Bookings/List';
import FormSection from '../../../components/FormSection';

import moment from 'dayjs';

import { useParams } from 'react-router';
import { useFormContext } from 'react-hook-form';

const Bookings = () => {
    const { id } = useParams();
    const { getValues } = useFormContext();

    const [view, setView] = useState(null);

    const columns = [
        {
            Header: 'Purchase Date',
            accessor: 'purchase_date',
            Cell: ({ row }) => <>{moment(row.original.created_at).format('DD/MM/YYYY HH:mm:ss')}</>,
        },
        {
            Header: 'Course',
            accessor: 'course1',
            Cell: ({ row }) => (
                <>
                    {row.original.nucleus ? (
                        <>
                            <Text fontWeight="bold" as="span">
                                {row.original.event ? row.original.event.course.title : 'Not found'}
                            </Text>
                            <br />
                            {row.original.event
                                ? row.original.event.learningItem.name
                                : 'Not found'}
                        </>
                    ) : (
                        <>
                            {row.original.courses.length === 1
                                ? row.original.courses[0].name
                                : `Total courses: ${row.original.courses.length}`}
                        </>
                    )}
                </>
            ),
        },
        {
            Header: 'Origin',
            accessor: 'nucleus',
            Cell: ({ row }) => (
                <>
                    {row.original.nucleus ? 'Nucleus' : 'Website'}{' '}
                    <Text color="gray.500">
                        {row.original.booked_by?.first_name} {row.original.booked_by?.last_name}
                    </Text>
                </>
            ),
        },

        {
            Header: 'Booking Total',
            accessor: 'amount_paid',
            Cell: ({ row }) => <>{`£${row.original.total}`}</>,
        },
        {
            Header: 'Delegates',
            accessor: 'delegates_count',
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value }) => {
                if (value.id === 2) {
                    return <Badge colorScheme="green">{value.name}</Badge>;
                }
                if (value.id === 3) {
                    return <Badge colorScheme="red">{value.name}</Badge>;
                }
                if (value.id === 4) {
                    return <Badge colorScheme="orange">{value.name}</Badge>;
                } else {
                    return <Badge>{value.name}</Badge>;
                }
            },
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <Button colorScheme="blue" size="sm" onClick={() => setView(row.original.id)}>
                    Quick View
                </Button>
            ),
        },
    ];

    return (
        <>
            <FormSection title="Bookings" mt="0px">
                <ListView
                    tableOnly
                    columns={columns}
                    setup={{
                        endpoint: `/user-bookings/${id}`,
                        title: 'Bookings',
                        canSelect: false,
                    }}
                />
                <Modal
                    scrollBehavior="inside"
                    size="xl"
                    isOpen={view !== null}
                    isCentered
                    onClose={() => setView(null)}
                    motionPreset="none"
                >
                    <ModalOverlay />
                    <ModalContent maxWidth="97%">
                        <ModalCloseButton />
                        <ModalHeader borderBottom="1px" borderColor="gray.200">
                            Booking #{view} for {getValues('first_name')} {getValues('last_name')}
                        </ModalHeader>

                        <ModalBody p="0px">
                            <View viewOnly id={view} />
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </FormSection>
        </>
    );
};

export default Bookings;
