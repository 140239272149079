const modelName = 'Refund Statuses';
const modelNameSingular = 'Refunds Status';
const endpoint = '/refund-statuses';

const setup = {
    title: modelName,
    singular: modelNameSingular,
    model: modelName.replace(/\s+/g, '-').toLowerCase(),
    canDelete: true,
    canAddNew: true,
    canSearch: true,
    endpoint,
    accessor: 'id',
};

export default setup;
