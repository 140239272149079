import {
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Flex,
    Text,
    Badge,
    Stack,
    Alert,
    AlertIcon,
    Button,
    Box,
} from '@chakra-ui/react';
import React from 'react';
import { useWatch, useFormContext } from 'react-hook-form';
import moment from 'moment';
import { LinkButton } from '@builtbypixel/nucleus';
import DelegatesList from './DelegatesList';
import { PopConfirm } from '../../../../Nucleus';
import { HiOutlineExternalLink } from 'react-icons/hi';

const EventView = () => {
    const events = useWatch({ name: 'events' });
    const { setValue } = useFormContext();

    const getUnconfirmedDelegates = (event) => {
        let arr = [];
        event.delegates.forEach((d) => {
            if (!d.first_name || !d.last_name || !d.email) {
                arr.push(1);
            }
        });

        return arr.length;
    };

    const getCancelledDelegates = (event) => {
        if (event.cancellations.length > 0) {
            return event.cancellations.length;
        } else {
            return null;
        }
    };

    const removeEvent = (i) => {
        const _events = [...events];
        _events.splice(i, 1);
        setValue('events', _events);
    };

    return (
        <Tabs variant="enclosed">
            <TabList>
                {events.map((event, i) => (
                    <Tab key={`event-tab-${i}`}>
                        <Flex direction="column" align="flex-start">
                            <Flex w="100%" justifyContent="space-between" alignItems={'center'}>
                                <Text fontWeight="bold">{event.course.title}</Text>
                                <LinkButton to={`/events/edit/${event.id}`} target="_blank">
                                    <HiOutlineExternalLink />
                                </LinkButton>
                            </Flex>

                            {event?.is_bundle && <Text>Bundle</Text>}

                            <Text fontSize="12px">{event.course?.provider?.name}</Text>

                            {event.locations && event.venues && (
                                <Text fontSize="12px">
                                    {event.locations.location_name} - {event.venues[0].title}
                                </Text>
                            )}
                            <Text fontSize="12px">{event.learningItem.name}</Text>
                            {event.start_date && (
                                <Text fontSize="0.9em">
                                    {moment(event.start_date, 'DD-MM-YYYY HH:mm:ss').format(
                                        'DD/MM/YYYY - HH:mm',
                                    )}
                                </Text>
                            )}
                            <Stack spacing="5px" isInline mt="5px">
                                <Badge variant="solid" colorScheme="blue">
                                    {event.quantity} Delegates
                                </Badge>
                                {/* {event.delegates_count > 0 && (
                                    <Badge
                                        colorScheme={'green'}
                                    >
                                        {`${event.quantity - event.cancellations.length} Confirmed`}
                                    </Badge>
                                )} */}
                                {event.quantity !== event.cancellations.length && (
                                    <Badge
                                        colorScheme={getUnconfirmedDelegates(event) > 0 && 'red'}
                                    >
                                        {getUnconfirmedDelegates(event) > 0 &&
                                            `${getUnconfirmedDelegates(event)} Unconfirmed`}
                                    </Badge>
                                )}
                                {event.cancellations.length > 0 && (
                                    <Badge colorScheme={getCancelledDelegates(event) > 0 && 'red'}>
                                        {getCancelledDelegates(event)} Cancelled
                                    </Badge>
                                )}
                            </Stack>
                        </Flex>
                    </Tab>
                ))}
            </TabList>
            <TabPanels>
                {events.map((event, i) => (
                    <TabPanel px="0px" key={`event-${i}`}>
                        {getUnconfirmedDelegates(event) > 0 && (
                            <Alert status="warning" fontWeight="bold" mb={2}>
                                <AlertIcon />
                                This event has {getUnconfirmedDelegates(event)} unconfirmed
                                delegates.
                            </Alert>
                        )}
                        {getCancelledDelegates(event) > 0 && (
                            <Alert status="error" fontWeight="bold" mb={5}>
                                <AlertIcon />
                                This event has {getCancelledDelegates(event)} cancelled delegate(s).
                            </Alert>
                        )}
                        <DelegatesList index={i} event={event} order_line={event.order_line_id} />
                        <Box maxWidth={'190px'}>
                            <PopConfirm
                                onConfirm={() => removeEvent(i)}
                                title="Are you sure you want to remove this event?"
                            >
                                <Button size="sm" colorScheme="red" variant="outline">
                                    Remove event from booking
                                </Button>
                            </PopConfirm>
                        </Box>
                    </TabPanel>
                ))}
            </TabPanels>
        </Tabs>
    );
};

export default EventView;
