import React from 'react';
import { Flex, Tabs, TabPanels, TabPanel } from '@chakra-ui/react';
import EditMenu from './Menu';
import { EditView } from '@builtbypixel/nucleus';

import setup from './setup';
import Details from './components/Details';
import Events from './components/Events';
import Bookings from './components/Bookings';
import Summary from './components/Summary';
import Certificates from './components/Certificates';

const EditForm = ({ onSubmit, viewOnly, id }) => {
    return (
        <EditView setup={setup} isFullWidth onSubmit={onSubmit} viewOnly={viewOnly} id={id}>
            <Flex w="100%" minHeight="100vh" bg="white">
                <Tabs flex={1} minHeight="100vh" isLazy>
                    <EditMenu />
                    <TabPanels bg="blackAlpha.100" px="0px" minHeight="100vh">
                        <TabPanel>
                            <Details />
                        </TabPanel>
                        <TabPanel>
                            <Events id={id} />
                        </TabPanel>
                        <TabPanel>
                            <Bookings id={id} />
                        </TabPanel>
                        <TabPanel>
                            <Certificates id={id} />
                        </TabPanel>
                        <TabPanel>
                            <Summary id={id} />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Flex>
        </EditView>
    );
};

export default EditForm;
