import React from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';

import setup from './setup';
import { Button, Switch } from '@chakra-ui/react';
import { BsCheck } from 'react-icons/bs';

// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const List = () => {
    const columns = [
        {
            Header: 'Event Status',
            accessor: 'name',
            Cell: ({ row }) => (
                <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
                    <Button variant="link" colorScheme="blue" rounded="md">
                        {row.original.name}
                    </Button>
                </LinkButton>
            ),
        },
        {
            Header: 'On Site',
            accessor: 'on_site',
            Cell: ({ value }) => value && <BsCheck fontSize="24px" />,
        },
    ];

    return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
