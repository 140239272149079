import React from 'react';
import { useWatch, useFieldArray } from 'react-hook-form';
import { Flex, Stack, Text, Box } from '@chakra-ui/react';
import { Field } from '@builtbypixel/nucleus';
import moment from 'moment';

const SplitDates = () => {
    const { fields } = useFieldArray({ name: 'dates' });
    const startDateField = useWatch({ name: 'start_date_field' });
    const endDateField = useWatch({ name: 'end_date_field' });

    return (
        <Flex direction="column" w="100%">
            <Flex direction="column" w="100%" rounded="md" bg="#deeef7" p="10px" mb="10px">
                <Stack spacing="10px">
                    {startDateField &&
                        endDateField &&
                        fields.map((date, i) => <DateRow date={date} key={date.id} i={i} />)}
                </Stack>
            </Flex>
        </Flex>
    );
};

const DateRow = ({ date, i }) => {
    const startTime = useWatch({ name: 'start_time' });
    const endTime = useWatch({ name: 'end_time' });

    return (
        <Flex align="center" bg="white" rounded="md" px="10px" py="5px" w="100%">
            <Box>
                <Field
                    name={`dates.${i}.switchChecked`}
                    defaultValue={date.switchChecked}
                    component="switch"
                    noMargin
                />
            </Box>
            <Text fontSize="0.9em" w="300px" ml="30px">
                {moment(date.day).format('dddd Do MMMM yyyy')}
            </Text>
            <Stack isInline spacing="10px" align="center" ml="auto">
                <Text fontSize="12px" color="gray.600">
                    Start Time
                </Text>
                <Box maxWidth="100px">
                    <Field
                        name={`dates.${i}.start_time`}
                        defaultValue={startTime}
                        component="mask"
                        mask="99:99"
                        noMargin
                        size="sm"
                    />
                </Box>
                <Text fontSize="12px" color="gray.600">
                    End Time
                </Text>
                <Box maxWidth="100px">
                    <Field
                        name={`dates.${i}.end_time`}
                        defaultValue={endTime}
                        component="mask"
                        mask="99:99"
                        noMargin
                        size="sm"
                    />
                </Box>
            </Stack>
        </Flex>
    );
};

export default SplitDates;
