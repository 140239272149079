import React, {useState} from 'react';
import FormSection from '../../../../components/FormSection';
import {
    Text,
    Button,
    Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody, Switch, ModalFooter, useDisclosure
} from '@chakra-ui/react';
import { PopConfirm } from '../../../../Nucleus';
import { useFormContext } from 'react-hook-form';

const CancelEvent = () => {
    const { submit, setValue, register } = useFormContext();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [emailDelegates, setEmailDelegates] = useState(false);

    const cancelEvent = () => {
        setValue('event_status', { id: 8, name: 'Cancelled' });
        submit();
    };

    const toggle = () => {
        setEmailDelegates((prevState) => !prevState);
    };

    return (
        <FormSection title="Cancel Event">
            <Flex direction="column" align="flex-start">
                <Text>This action cannot be undone</Text>
                <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Cancel Event</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Flex direction="column">
                                <Text mb="20px" fontSize="1.2rem">
                                    Email Delegates?
                                </Text>
                                <Switch
                                    size="lg"
                                    {...register('send_del_emails')}
                                    defaultChecked={emailDelegates}
                                    isChecked={emailDelegates}
                                    onChange={toggle}
                                />
                            </Flex>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme="green" onClick={cancelEvent} w="100%">
                                Confirm
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                <PopConfirm
                    onConfirm={onOpen}
                    title="Are you sure you want to cancel this event?"
                >
                    <Button colorScheme="red" mt="15px">
                        Cancel Event
                    </Button>
                </PopConfirm>
            </Flex>
        </FormSection>
    );
};

export default CancelEvent;
