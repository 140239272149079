import React from 'react';

import StartEndDates from './StartEndDates';

const Dates = () => {
    return (
        <div>
            <StartEndDates />
        </div>
    );
};

export default Dates;
