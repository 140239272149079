import React, { useState, useEffect } from 'react';
import {
    ModalBody,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    Button,
    ModalHeader,
    ModalCloseButton,
    Grid,
    Text,
    Alert,
    AlertIcon,
} from '@chakra-ui/react';
import useSWR from 'swr';
import { Field, useGet, useHttp } from '@builtbypixel/nucleus';
import { useWatch, FormProvider, useForm, useFormContext } from 'react-hook-form';
import AddressFields from '../../../../components/AddressFields';

const UserForm = ({ email, onClose }) => {
    const Http = useHttp();

    const [loading, setLoading] = useState();
    const [error, setError] = useState(false);
    const { setValue } = useFormContext();

    const methods = useForm();
    const { handleSubmit } = methods;

    const createUser = (data) => {
        setLoading(true);

        let endpoint = data.user_type.name === 'Personal' ? '/students' : '/businesses';

        Http.post(`${endpoint}`, data)
            .then((res) => {
                setError(false);
                setLoading(false);
                setValue('customer', res.data.data);
                onClose();
            })
            .catch((error) => {
                setError(error?.response?.data?.errors?.message);
                setLoading(false);
            });
    };

    return (
        <>
            <ModalOverlay />
            <ModalContent maxWidth="900px">
                <ModalCloseButton />
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(createUser)}>
                        <Form isLoading={loading} email={email} error={error} />
                    </form>
                </FormProvider>
            </ModalContent>
        </>
    );
};

const Form = ({ isLoading, email, error }) => {
    const { data: userTypes } = useSWR('/user-types', useGet);
    const { data: countries } = useSWR('/countries', useGet);
    const type = useWatch({ name: 'user_type' });
    const newEmailAddress = useWatch({ name: 'email' });

    console.log(useWatch());
    const { setValue } = useFormContext();

    useEffect(() => {
        if (newEmailAddress && newEmailAddress.length > 0) {
            setValue('customer.email', newEmailAddress);
        }
    }, [newEmailAddress]);

    return (
        <>
            <ModalHeader>Create New Customer</ModalHeader>
            <ModalBody>
                <Grid templateColumns="1fr 1fr" gap="0px 20px" mb="15px">
                    <Field
                        noMargin
                        component="select"
                        name="user_type"
                        options={
                            userTypes ? userTypes.data.filter((e) => e.name !== 'Trainer') : []
                        }
                        label="Account Type"
                        labelKey="name"
                        valueKey="id"
                        rules={{ required: true }}
                    />
                    <Field
                        noMargin
                        component="text"
                        name="email"
                        label="Email Address"
                        rules={{ required: true }}
                        defaultValue={email}
                    />
                </Grid>
                {type && type.name === 'Personal' && (
                    <Grid templateColumns="1fr 1fr" gap="0px 20px" mb="15px">
                        <Field
                            noMargin
                            component="text"
                            name="first_name"
                            label="First Name"
                            rules={{ required: true }}
                        />
                        <Field
                            noMargin
                            component="text"
                            name="last_name"
                            label="Last Name"
                            rules={{ required: true }}
                        />
                    </Grid>
                )}
                {type && type.name === 'Business' && (
                    <Grid templateColumns="1fr 1fr" gap="20px" mb="15px">
                        <Field
                            noMargin
                            component="text"
                            name="first_name"
                            label="Administrator First Name"
                            rules={{ required: true }}
                        />
                        <Field
                            noMargin
                            component="text"
                            name="last_name"
                            label="Administrator Last Name"
                            rules={{ required: true }}
                        />
                        <Field
                            noMargin
                            component="text"
                            name="company_name"
                            label="Company Name"
                            rules={{ required: true }}
                        />
                        <Field
                            name="payment_terms"
                            label="Payment Terms (days)"
                            component="text"
                            help="number of days payment must be made by"
                        />
                    </Grid>
                )}
                <Grid templateColumns="1fr 1fr" gap="0px 20px" mb="15px">
                    <Field
                        noMargin
                        component="text"
                        type="tel"
                        name="phone_number"
                        label="Phone Number"
                        rules={{ required: true }}
                    />
                    <Field
                        noMargin
                        component="text"
                        type="tel"
                        name="mobile"
                        label="Mobile Number"
                    />
                </Grid>
                <AddressFields />
                {error && (
                    <Alert status="error">
                        <AlertIcon /> An error occurred creating this user. {error}
                    </Alert>
                )}
            </ModalBody>
            <ModalFooter>
                <Button
                    isLoading={isLoading}
                    isDisabled={isLoading}
                    type="submit"
                    colorScheme="blue"
                >
                    Create Customer
                </Button>
            </ModalFooter>
        </>
    );
};

export default UserForm;
