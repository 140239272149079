import React from 'react';
import { Flex, Text, Button } from '@chakra-ui/react';
import { LinkButton } from '@builtbypixel/nucleus';
import { useWatch } from 'react-hook-form';
import { useState } from 'react';
import CustomTable from '../../../../components/common/CustomTable';

const UploadedDocuments = () => {
    const columns = [
        {
            Header: 'Title',
            id: 'course.title',
            accessor: 'course.title',
        },
        {
            Header: 'Date',
            id: 'start_date',
            Cell: ({ row }) => (
                <Text>{row.original.start_date ? row.original.start_date : '- '}</Text>
            ),
        },
        {
            Header: 'Documents',
            id: 'trainer_completion_uploads',
            Cell: ({ row }) => (
                <Flex direction="column" align={'start'}>
                    {row.original?.trainer_completion_uploads.map((doc, i) => (
                        <LinkButton to={doc?.document?.[0]?.url} target="_blank">
                            <Button variant="link" colorScheme="blue" rounded="md" my="2px">
                                <Text>
                                    {i + 1}. {doc.name}
                                </Text>
                            </Button>
                        </LinkButton>
                    ))}
                </Flex>
            ),
        },
        // {
        //     Header: 'Due Date',
        //     id: 'invoice.id',
        //     accessor: 'due_date',

        //     Cell: ({ row }) => <Text>{moment(row.original.due_date).format('MMMM Do, YYYY')}</Text>,
        // },

        // {
        //     Header: 'Actions',
        //     accessor: 'document',

        //     Cell: ({ value }) => (
        //         <a target="_blank" rel="noreferrer" href={value?.[0]?.url}>
        //             <Button>Download Invoice</Button>{' '}
        //         </a>
        //     ),
        // },
    ];
    const events = useWatch({ name: 'trainer_events' });

    const [typedDocs, setTypedDocs] = useState([]);
    const [eventDocs, setEventDocs] = useState([]);

    console.log('!!!!!!!events', events);
    return (
        <Flex bg="white" rounded="md" p="15px" align="flex-start">
            <Flex w="100%" direction="column" bg="white" rounded="md" p="15px" align="flex-start">
                <CustomTable data={events ? events : []} columns={columns} />
            </Flex>
        </Flex>
    );
};

export default UploadedDocuments;
