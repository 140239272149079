import React, { useMemo } from 'react';
import { AutoFields, EditView } from '@builtbypixel/nucleus';
import { useHttp } from '@builtbypixel/nucleus';
import setup from './setup';
import { cancellations } from './fields/cancellations';
import { rebookings } from './fields/rebookings';
import * as yup from 'yup';
const EditForm = () => {
    const [features, setFeatures] = React.useState([]);

    const useValidation = () => {
        return yup.object().shape({
            name: yup.string().required(),
            cancellations: yup
                .array()
                .min(1)
                .of(
                    yup.object().shape({
                        cancellation: yup.object().shape({
                            days: yup.string().required('Please provide the days'),
                            discount: yup.string().required('Please provide the discount'),
                        }),
                    }),
                ),
            features: yup.array().min(1),
            transfers: yup
                .array()
                .min(1)
                .of(
                    yup.object().shape({
                        transfer: yup.object().shape({
                            days: yup.string().required('Please provide the days'),
                            discount: yup.string().required('Please provide the discount'),
                        }),
                    }),
                ),
        });
    };

    const Http = useHttp();

    React.useEffect(() => {
        Http.get(`/features`).then((res) => {
            setFeatures(res.data.data);
        });
        return () => {};
    }, []);

    const fields = useMemo(
        () => [
            {
                group: 'General',
                tab: 'General',
                fields: [
                    {
                        name: 'name',
                        component: 'text',
                        placeholder: 'Name',
                        label: 'Name',
                        // isRequired: true,
                        // rules: { required: true },
                    },
                    {
                        name: 'price',
                        component: 'text',
                        type: 'number',
                        placeholder: 'Price',
                        label: 'Price',
                        help: 'This is the price excluding VAT',
                    },
                    {
                        name: 'active',
                        component: 'switch',
                        placeholder: 'Active',
                        label: 'Active',
                    },
                    {
                        name: 'continuous',
                        component: 'switch',
                        placeholder: 'continuous',
                        label: 'Continuous',
                    },
                    {
                        name: 'elearning',
                        component: 'switch',
                        placeholder: 'Elearning',
                        label: 'Elearning',
                        condition: {
                            watchField: 'continuous',
                            matchValue: true,
                            operator: '===',
                            childComponent: 'switch',
                        },
                    },
                    {
                        name: 'showLatestDates',
                        label: 'Show Latest Dates',
                        component: 'switch',
                        width: '95%',
                        help: 'Show the latest 5 available course dates to book on the website',
                    },
                ],
            },
            {
                group: 'Features',
                tab: 'Features',
                fields: [
                    {
                        name: 'features',
                        component: 'select',
                        placeholder: 'Features',
                        label: 'Features',
                        options: features,
                        isMulti: true,
                        labelKey: 'name',
                        valueKey: 'id',
                    },
                ],
            },
            {
                group: 'Costs',
                tab: 'Costs',
                fields: [
                    {
                        name: 'costs',
                        component: 'repeater',
                        fieldArray: [
                            {
                                name: 'type',
                                component: 'text',
                                placeholder: 'Name',
                                label: 'Name',
                                isRequired: true,
                                rules: { required: true },
                            },
                            {
                                name: 'price',
                                component: 'text',
                                placeholder: 'Cost',
                                label: 'Cost',
                                help: 'Excluding VAT',
                            },
                        ],
                        placeholder: 'cost',
                        label: 'Cost',
                        // isRequired: true,
                        // rules: { required: true },
                    },
                ],
            },
            cancellations,
            rebookings,
        ],
        [features],
    );
    return (
        <EditView setup={setup} validationSchema={useValidation()}>
            <AutoFields fields={fields} />
        </EditView>
    );
};

export default EditForm;
