import React from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';
import { IconButton, Switch } from '@chakra-ui/react';
import { FiEdit } from 'react-icons/fi';
import setup from './setup';

// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const List = () => {
    const columns = [
        {
            Header: 'ID',
            accessor: 'id',
        },
        { Header: 'Course Name', accessor: 'course.course_name' },
        {
            Header: 'Course Rating',
            accessor: 'course_rating',
        },
        {
            Header: 'Course Content',
            accessor: 'course_content',
        },
        {
            Header: 'Course Trainer',
            accessor: 'course_trainer',
        },
    ];

    return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
