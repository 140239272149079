import React, { useEffect, useRef } from 'react';
import { Flex, Spinner, Box, Text } from '@chakra-ui/react';

const Sage3DSecure = ({ setSubmitting, sageInfo }) => {
    const submit = useRef();

    useEffect(() => {
        setSubmitting(true);

        submit.current.click();
    }, [setSubmitting]);

    return (
        <Flex w="100%" justify="center" pt="40px" direction="column" align="center" py="70px">
            <Text mb="20px" fontSize="1.2em">
                Please wait. Do not leave or refresh this page...
            </Text>
            <Spinner />
            <Box display="none">
                <form action={sageInfo.acsUrl} method="post" target="result">
                    <input type="hidden" name="creq" value={sageInfo.cReq} />
                    <input type="hidden" name="threeDSSessionData" value={sageInfo.transactionId} />
                    <p>Please click button below to proceed to 3D secure.</p>
                    <input type="submit" value="Go" ref={submit} />
                </form>
            </Box>
        </Flex>
    );
};

export default Sage3DSecure;
