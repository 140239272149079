import React from 'react';
import { Grid, Flex, Text, Box } from '@chakra-ui/react';
import { Field, useGet } from '@builtbypixel/nucleus';
import useSWR from 'swr';
const AccessTime = ({ name }) => {
    const { data: durationStatus } = useSWR('/duration-statuses', useGet);

    return (
        <Flex direction="column" bg="white" rounded="md" my="25px">
            <Text fontSize="14px" fontWeight="bold" mb="10px">
                Access time
            </Text>
            <Grid templateColumns="1fr 1fr 1fr 1fr 1fr" gap="20px">
                {durationStatus && durationStatus.data.length > 0 && (
                    <>
                        {durationStatus.data.map((each, i) => (
                            <Box key={`${name}.elearning.accesstime_link-${i}`}>
                                <Field
                                    name={`${name}.access_time[0].${each.name}`}
                                    component="text"
                                    type="number"
                                    label={each.name}
                                    noMargin
                                    placeholder={each.name}
                                />
                            </Box>
                        ))}
                    </>
                )}
            </Grid>
        </Flex>
    );
};

export default AccessTime;
