import React, { useMemo } from 'react';
import { AutoFields, EditView } from '@builtbypixel/nucleus';
import { useState, useEffect } from 'react';
import { useHttp } from '@builtbypixel/nucleus';
import setup from './setup';
import { useParams } from 'react-router-dom';

const EditForm = () => {
  const [parentCat, setParentCat] = useState([]);
  const { id } = useParams();

  const Http = useHttp();

  useEffect(() => {
    Http.get(`/parent-categories/${id}`).then((res) => {
      setParentCat(res.data.data);
    });
    return () => {};
  }, []);
  const fields = useMemo(
    () => [
      {
        group: 'Category',
        fields: [
          {
            name: 'category_name',
            component: 'text',
            label: 'Category Name',
            rules: { required: true },
            isRequired: true,
          },
          {
            name: 'active',
            component: 'switch',
            label: 'Active',
          },
          {
            name: 'parent',
            component: 'select',
            placeholder: 'Parent',
            options: parentCat,
            labelKey: 'name',
            valueKey: 'id',
            label: 'Parent Category',
            variant: 'outline',
          },
        ],
      },
    ],
    [parentCat]
  );
  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
