import React from 'react';
import { SWRConfig } from 'swr';
import { NucleusCore } from '@builtbypixel/nucleus';
import config from './config';
import routes from './routes';
import links from './links';
import theme from './theme';
import { ChakraProvider } from '@chakra-ui/react';
// import 'react-date-range/dist/styles.css';
// import 'react-date-range/dist/theme/default.css';
import 'react-datepicker/dist/react-datepicker.min.css';

function App() {
    return (
        <SWRConfig
            value={{
                revalidateOnFocus: false,
                shouldRetryOnError: false,
            }}
        >
            <ChakraProvider theme={theme}>
                <NucleusCore config={config} routes={routes} links={links} />
            </ChakraProvider>
        </SWRConfig>
    );
}

export default App;
