const modelName = 'Refunds';
const modelNameSingular = 'Refund';
const endpoint = '/refunds';

const setup = {
    title: modelName,
    singular: modelNameSingular,
    model: modelName.replace(/\s+/g, '-').toLowerCase(),
    canDelete: true,
    canAddNew: false,
    canSearch: false,
    endpoint,
    accessor: 'id',
};

export default setup;
