import React from 'react';
import {
    Flex,
    Text,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Grid,
} from '@chakra-ui/react';
import { Field, useGet } from '@builtbypixel/nucleus';
import useSWR from 'swr';
import { useWatch } from 'react-hook-form';
import { useEffect, useState } from 'react';
import Document from './Document';
import EventDocument from './EventDocument';

const UploadedDocuments = () => {
    const { data: documentTypes } = useSWR('/trainer-document-types', useGet);
    const { data: documentStatuses } = useSWR('/document-statuses', useGet);

    const uploads = useWatch({ name: 'trainer_uploads' });

    const [typedDocs, setTypedDocs] = useState([]);
    const [eventDocs, setEventDocs] = useState([]);

    useEffect(() => {
        if (uploads?.length > 0) {
            setTypedDocs(uploads.filter((each) => each.trainer_document_type));

            setEventDocs(uploads.filter((each) => each.event_id));
        }
    }, [uploads]);

    return (
        <Flex bg="white" rounded="md" p="15px" align="flex-start" direction="column">
            <Text fontSize="17px" fontWeight="bold" mb={'25px'}>
                Required Documents
            </Text>
            <Field
                label="Required documents for this trainer"
                name="trainer_required_uploads"
                component="select"
                options={documentTypes?.data}
                required={false}
                labelKey="name"
                valueKey="id"
                isMulti="true"
            />
            <Flex direction={'column'} w="100%">
                {documentStatuses &&
                    typedDocs.map((file, i) => (
                        <Document
                            file={file}
                            key={`type-doc-${i}`}
                            documentStatuses={documentStatuses}
                        />
                    ))}
            </Flex>
        </Flex>
    );
};

export default UploadedDocuments;
