import { atom } from 'recoil';
import dayjs from 'dayjs';

export const calendarState = atom({
    key: 'calendarState',
    default: {
        selectedDate: dayjs(),
        mode: 'month',
        events: [],
        dragDates: [],
        startDrag: null,
        endDrag: null,
        dragActive: false,
        selectedDates: [],
        id: null,
    },
    dangerouslyAllowMutability: true,
});
