import React from 'react';
import { Flex, Text, Box } from '@chakra-ui/react';
import GeneratePDF from '../common/GeneratePDF';
import GenerateCSV from '../common/GenerateCSV';

const FormSection = ({
    title,
    AddHeaders,
    exportPDFButton,
    exportCSVButton,
    exportData,
    children,
    ...rest
}) => {
    let _title = typeof title === 'string' && title?.replaceAll(' ', '_');
    return (
        <Flex direction="column" bg="white" rounded="md" p="15px" mt="25px" {...rest}>
            <Flex justify={(AddHeaders || exportPDFButton || exportCSVButton) && 'space-between'}>
                {title && (
                    <Text fontSize={{ base: '1em', xl: '17px' }} fontWeight="bold" mb="15px">
                        {title}
                    </Text>
                )}
                {AddHeaders && <AddHeaders />}
                <Flex align="center">
                    {exportPDFButton && (
                        <GeneratePDF
                            title={title}
                            data={exportData[_title]?.data}
                            columns={exportData[_title]?.columns}
                        />
                    )}
                    <Box mx="1em"></Box>
                    {exportCSVButton && (
                        <GenerateCSV
                            title={title}
                            data={exportData[_title]?.data}
                            columns={exportData[_title]?.columns}
                        />
                    )}
                </Flex>
            </Flex>
            {children}
        </Flex>
    );
};

export default FormSection;
