import React from 'react';
import { Box } from '@chakra-ui/react';
import { calendarState } from './atom';
import { weekdays } from '../../../../../utils/dates';
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs';

const WeekdayIndicator = ({ showFirstColumn }) => {
    const calendar = useRecoilValue(calendarState);

    const weekdayIcons = weekdays.map((day, key) => {
        return (
            <Box
                key={`weekday-${key}`}
                gridColumn={key + 1}
                gridRow={1}
                fontWeight="bold"
                py="4px"
                px="10px"
            >
                {day}
            </Box>
        );
    });

    return calendar.mode === 'day' ? dayjs(calendar.selectedDate).format('dddd') : weekdayIcons;
};

export default WeekdayIndicator;
