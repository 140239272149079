import React, { useMemo } from 'react';
import { AutoFields, EditView } from '@builtbypixel/nucleus';
import setup from './setup';

const EditForm = () => {
  const fields = useMemo(
    () => [
      {
        group: 'Course Features',
        fields: [
          {
            name: 'name',
            component: 'text',
            label: 'Name',
            rules: { required: true },
            isRequired: true,
          },
        ],
      },
    ],
    []
  );
  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
