import React, { useState } from 'react';
import { Grid, Button, Text } from '@chakra-ui/react';
import { useHttp } from '@builtbypixel/nucleus';

const ExportCustomer = ({type}) => {
    const Http = useHttp();

    const exportCustomer = () => {
        Http.get(`/export-${type}-customers`,{responseType: 'blob'}).then((response)=>{
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${type}-customers.xlsx`); 
            document.body.appendChild(link);
            link.click();
        }).catch((err)=>{
            console.log(err);
        });
    }

    return (
        <Button onClick={exportCustomer}>
            <Text textTransform="capitalize" transform='capitalize'>
                Export {type} Customers
            </Text>
        </Button>
    )
}

export default ExportCustomer;