import React, { useEffect, useState } from 'react';
import { getDatesInMonthDisplay } from '../../../../../utils/date-utils';
import { useFormContext } from 'react-hook-form';
import { Grid, Flex, useToast, Spinner, Box, Text } from '@chakra-ui/react';
import WeekdayIndicator from './WeekdayIndicator';
import Header from './Header';
import { useRecoilState } from 'recoil';
import { calendarState } from './atom';
import Day from './Day';
import dayjs from 'dayjs';
import { useParams } from 'react-router';
import { useGet, useHttp } from '@builtbypixel/nucleus';
import useSWR, { useSWRConfig } from 'swr';

var duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

const EventCalendar = ({ events }) => {
    const { id } = useParams();
    const toast = useToast();
    const Http = useHttp();
    const { data: trainerData } = useFormContext();
    const [calendar, setCalendar] = useRecoilState(calendarState);
    const [dates, setDates] = useState([]);

    const { data, isValidating } = useSWR(`/trainer-availability/${id}`, useGet);

    const { mutate } = useSWRConfig();

    useEffect(() => {
        setDates(getDatesInMonthDisplay(calendar.selectedDate));
    }, [calendar.selectedDate]);

    /* eslint-disable */
    useEffect(() => {
        if (calendar.startDrag && calendar.endDrag) {
            let _dates = [];
            let all_dates = [...calendar.selectedDates];

            const diff = calendar.startDrag.add(1, 'day').diff(calendar.endDrag, 'd');

            for (let step = 0; step >= diff - 1; step--) {
                _dates.push({
                    start: calendar.startDrag.subtract(step, 'd').startOf('day'),
                    end: calendar.startDrag.subtract(step, 'd').endOf('day'),
                });
                all_dates.push({
                    start: calendar.startDrag.subtract(step, 'd').startOf('day'),
                    end: calendar.startDrag.subtract(step, 'd').endOf('day'),
                });

                const calendarDateIndex = dates.findIndex((e) =>
                    e.date.isSame(calendar.startDrag.subtract(step, 'd')),
                );
                const firstDate = dates.findIndex((e) => e.date.isSame(_dates[0].start, 'day'));

                let calendarDates = [...dates];

                calendarDates[firstDate] = {
                    ...calendarDates[firstDate],
                    isSelected: true,
                };
                calendarDates[calendarDateIndex] = {
                    ...calendarDates[calendarDateIndex],
                    isSelected: true,
                };
                setDates(calendarDates);
            }

            setCalendar((old) => ({
                ...old,
                startDrag: null,
                endDrag: null,
            }));

            const factoredForApi = _dates.map((e) => ({
                start: dayjs(e.start).format(),
                end: dayjs(e.end).format(),
            }));

            Http.post(`/trainer-availability/${id}`, { dates: factoredForApi }).then(() => {
                toast({
                    status: 'success',
                    title: 'Success',
                    description: 'Dates saved successfully',
                    position: 'bottom-right',
                });
                mutate(`/trainer-availability/${id}`);
            });
        }
    }, [
        calendar.startDrag,
        calendar.endDrag,
        setCalendar,
        calendar.selectedDates,
        id,
        Http,
        mutate,
    ]);

    /* eslint-enable */

    useEffect(() => {
        if (data && data.data.length !== 0) {
            setCalendar((old) => ({
                ...old,
                startDrag: null,
                endDrag: null,
                id: id,
                selectedDates: data.data?.map((e) => ({
                    start: dayjs(e.start),
                    end: dayjs(e.end),
                    id: e.id,
                })),
            }));
        }
    }, [data, setCalendar]);

    const getEvents = (date) => {
        let eventsOnDate = [];
        if (trainerData?.trainer_events) {
            trainerData.trainer_events.forEach((_ev) => {
                _ev.dates.forEach((_date) => {
                    const start = dayjs(_date.start);
                    if (date.isSame(start, 'day')) {
                        eventsOnDate.push({ ..._ev, event_id: _date.id });
                    }
                });
            });
        }
        return eventsOnDate;
    };

    return (
        <Flex w="100%" direction="column">
            {!calendar | !data ? (
                <Box
                    width="100%"
                    alignItems="center"
                    textAlign="center"
                    paddingTop="20px"
                    paddingBottom="20px"
                    height="100%"
                >
                    <Spinner />
                    <Text>Availability loading</Text>
                </Box>
            ) : (
                <>
                    <Header isLoading={isValidating} />
                    <Grid templateColumns="repeat(7, minmax(0, 1fr))" gap="0px" w="100%">
                        <WeekdayIndicator />
                    </Grid>
                    <Grid templateColumns={'repeat(7, minmax(0, 1fr))'} gap="0px">
                        {dates?.map((day, i) => (
                            <Day key={`date-${i}`} day={day} events={getEvents(day.date)} />
                        ))}
                    </Grid>{' '}
                </>
            )}
        </Flex>
    );
};

export default EventCalendar;
