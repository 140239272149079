import React, { useMemo } from 'react';
import useValidation from './validation';

import { EditView, AutoFields } from '@builtbypixel/nucleus';
import setup from '../setup';
import { seo } from '../../../components/Seo';

const EditForm = () => {
    const Fields = useMemo(
        () => [
            {
                group: 'Contact Details',
                tab: 'Address',
                fields: [
                    {
                        name: 'content.heading',
                        component: 'text',
                        placeholder: 'Title',
                        label: 'Title',
                    },
                    {
                        name: 'content.contact.name',
                        component: 'text',
                        placeholder: 'Company Name',
                        label: 'Company Name',
                    },
                    {
                        name: 'content.contact.address.line_one',
                        component: 'text',
                        placeholder: 'Address line 1',
                        label: 'Address line 1',
                    },
                    {
                        name: 'content.contact.address.line_two',
                        component: 'text',
                        placeholder: 'Address line 2',
                        label: 'Address line 2',
                    },
                    {
                        name: 'content.contact.address.line_three',
                        component: 'text',
                        placeholder: 'Address line 3',
                        label: 'Address line 3',
                    },
                    {
                        name: 'content.contact.address.town',
                        component: 'text',
                        placeholder: 'City',
                        label: 'City',
                    },
                    {
                        name: 'content.contact.address.postcode',
                        component: 'text',
                        placeholder: 'Postcode',
                        label: 'Postcode',
                    },
                    {
                        name: 'content.contact.address.country',
                        component: 'text',
                        placeholder: 'Country',
                        label: 'Country',
                        defaultValue: {
                            code: 'GB',
                            created_at: null,
                            currency: 'GBP',
                            currency_name: 'British pound',
                            currency_symbol: '£',
                            dial_code: '+44',
                            id: 229,
                            name: 'United Kingdom',
                            updated_at: null,
                        },
                    },
                    {
                        name: 'content.contact.phone',
                        component: 'text',
                        placeholder: 'Telephone',
                        label: 'Telephone',
                    },
                ],
            },
            {
                tab: 'About',
                fields: [
                    {
                        name: 'content.form_title',
                        component: 'text',
                        placeholder: 'Contact form Title',
                        label: 'Contact form Title',
                    },
                    {
                        name: 'content.office_hours',
                        component: 'repeater',
                        placeholder: 'Office Hours',
                        label: 'Office Hours',
                        defaultValue: [],
                        fieldArray: [
                            {
                                name: 'time_span',
                                component: 'text',
                                placeholder: 'Opening Day(s)',
                                label: 'Opening Day(s)',
                            },
                            {
                                name: 'status',
                                component: 'text',
                                placeholder: 'Opening hours',
                                label: 'Opening Hours',
                            },
                        ],
                    },
                    {
                        name: 'content.highlights',
                        component: 'repeater',
                        placeholder: 'Marble Traing highlights',
                        label: 'Marble Training highlight',
                        defaultValue: [],
                        fieldArray: [
                            {
                                name: 'title',
                                component: 'text',
                                placeholder: 'Hightlight title',
                                label: 'Hightlight title',
                            },
                            {
                                name: 'text',
                                component: 'textarea',
                                placeholder: 'Hightlight text',
                                label: 'Hightlight text',
                            },
                        ],
                    },
                ],
            },
            seo,
        ],
        [],
    );
    return (
        <EditView setup={setup} validationSchema={useValidation()}>
            <AutoFields fields={Fields} />
        </EditView>
    );
};

export default EditForm;
