import { Field } from '@builtbypixel/nucleus';
import { Flex, Text, Box } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';

const MiscCosts = () => {
    return (
        <Flex w="100%" direction="column" bg="white" rounded="md" p="15px" align="flex-start">

            <Field
                label="Add your Miscellaneous costs here"
                name="misc_costs"
                component="repeater"
                required={false}
                help="Add your Miscellaneous costs here"
                fieldArray={[
                    {
                        name: 'name',
                        component: 'text',
                        label: 'Description',
                        rules: { required: true },
                    },
                    {
                        name: 'price',
                        component: 'text',
                        type: "number",
                        label: 'Price'
                    },
                    {
                        name: 'bundle_price',
                        component: 'text',
                        type: "number",
                        label: 'Bundle Price'
                    },
                ]}
            />
                
        </Flex>
    );
};

export default MiscCosts;
