import { Flex, SimpleGrid, Text } from '@chakra-ui/react';
import React from 'react';
import DatePickerComponent from '../../../Nucleus/Fields/DatePicker';

const Toolbar = ({ startDate, endDate, setEndDate, setStartDate, handleGetData }) => {
    return (
        <SimpleGrid w="100%" columns={4} gap="20px">
            <Flex direction="column">
                <Text w="150px" fontSize="0.9em" mb="5px">
                    Start Date
                </Text>
                <DatePickerComponent
                    onChange={(e) => setStartDate(e)}
                    placeholder="Start Date"
                    value={startDate}
                    isClearable={false}
                />
            </Flex>
            <Flex direction="column">
                <Text w="150px" fontSize="0.9em" mb="5px">
                    End Date
                </Text>
                <DatePickerComponent
                    onChange={(e) => setEndDate(e)}
                    placeholder="End Date"
                    value={endDate}
                    isClearable={false}
                />
            </Flex>
        </SimpleGrid>
    );
};

export default Toolbar;
