import React, { useEffect, useState } from 'react';

import {
    Text,
    Badge,
    Button,
    useToast,
    Stack,
    Tooltip,
    IconButton,
    Box,
    Flex,
} from '@chakra-ui/react';
import PopConfirm from '../../../Nucleus/components/PopConfirm';

import FormSection from '../../../components/FormSection';
import { useParams } from 'react-router';
import { useFormContext } from 'react-hook-form';
import { Table, LinkButton, Field } from '../../../Nucleus';
import useSWR, { useSWRConfig } from 'swr';
import { useGet, useHttp } from '@builtbypixel/nucleus';

import { FaFileInvoice } from 'react-icons/fa';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import StatusDropdown from './StatusDropdown';
import formatCurrency from '../../../utils/formatCurrency';
import { useWatch } from 'react-hook-form';

const Summary = ({ id: passedId, type, id: bookingId }) => {
    const { id } = useParams();
    const Http = useHttp();
    const { getValues } = useFormContext();
    const toast = useToast();
    const [status, setStatus] = useState(null);
    const [selectValue, setSelectvalue] = useState('');

    const { mutate } = useSWRConfig();

    const { data: summary } = useSWR(
        type === 'order'
            ? `/booking-summary/${bookingId ? bookingId : id}`
            : `/account-summary/${id}`,
        useGet,
    );
    const { data: statuses } = useSWR(`/invoice-statuses`, useGet);
    // const outstanding_figures = useSWR(
    //     type === 'order'
    //         ? `/booking-outstanding/${bookingId ? bookingId : id}`
    //         : `/account-outstanding/${id}`,
    // );
    const outstanding_balance = { data: summary?.data.balance_outstanding };
    const user_type = useWatch({ name: 'user_type' });

    const update_status = (e, invoice_id, type) => {
        var status_object = {
            id: parseInt(e.target.value),
            name:
                e.target.value === '1'
                    ? 'Overdue'
                    : e.target.value === '2'
                    ? 'Paid'
                    : e.target.value === '3'
                    ? 'Unpaid'
                    : null,
        };
        setSelectvalue(status_object);
        Http.put(`/update-summary`, { status: status_object, Number: invoice_id, type })
            .then((res) => {
                toast({
                    status: 'success',
                    title: 'Status Updated',
                });
                mutate(`/account-summary/${id}`);

                user_type.name === 'Personal'
                    ? mutate(`/students/${id}`)
                    : user_type.name === 'Business'
                    ? mutate(`/businesses/${id}`)
                    : mutate(`/brokers/${id}`);
            })
            .catch((error) => {
                toast({
                    status: 'error',
                    message: 'Error cloning course',
                });
            });
    };

    const downloadCreditNote = (creditNoteId) => {
        Http.get(`/generate-credit-note/${creditNoteId}`, { responseType: 'blob' })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `credit-note-${creditNoteId}.pdf`);
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const downloadInvoice = (invoiceId, orderId) => {
        Http.get(`/generate-invoice-by-id/${invoiceId}`, { responseType: 'blob' })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `invoice-${orderId}.pdf`);
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const renderRowSubComponent = ({ row }) => {
        return (
            <Box
                style={
                    row.isExpanded && {
                        transform: 'scale(1)',
                        background: '#bedaf6',
                    }
                }
                padding={5}
            >
                <Text fontSize={16} fontWeight={500}>
                    Comments:
                </Text>
                <Box mt={'5px'}>
                    <Text fontSize={14}>{row.original.Comment}</Text>
                </Box>
            </Box>
        );
    };

    const [tranferringToSage, setTransferringToSage] = useState(false);

    const handleSageTransfer = (invoiceID, bookingId) => {
        //ID = invoice id
        //NUMBER = booking id

        setTransferringToSage(true);
        Http.get(`transfer-invoice-to-sage/${invoiceID}`)
            .then((res) => {
                mutate(`/booking-summary/${bookingId}`);
                setTransferringToSage(false);
            })
            .catch((err) => {
                console.log(err);
                setTransferringToSage(false);
            });
    };

    const columns = [
        {
            Header: 'Actions',
            accessor: '',
            disableSortBy: true,
            Cell: ({ row }) => {
                const downloadSelector = () => {
                    row.original.Type === 'Sales Invoice'
                        ? downloadInvoice(row.original.ID, row.original.Number)
                        : downloadCreditNote(row.original.Number);
                };
                return (
                    <Stack isInline spacing="15px">
                        <Tooltip
                            label={`Download  ${row.original.Type}`}
                            _hover={{ cursor: 'pointer' }}
                        >
                            <Text as="a" onClick={() => downloadSelector()}>
                                <IconButton
                                    size="sm"
                                    variant="link"
                                    icon={<FaFileInvoice fontSize="18px" />}
                                />
                            </Text>
                        </Tooltip>
                    </Stack>
                );
            },
        },
        {
            Header: 'Sage',
            accessor: 'transferred_to_sage',
            Cell: ({ row }) => {
                console.log(row, '!!!! ROOOW');
                const alreadyInSage = row?.original?.transferred_to_sage;

                //ID = invoice id
                //NUMBER = booking id
                return (
                    <PopConfirm
                        onConfirm={() =>
                            handleSageTransfer(row?.original?.ID, row?.original?.Number)
                        }
                        title={
                            alreadyInSage
                                ? 'Re-transfer to sage again?'
                                : 'Are you sure you want to transfer this invoice to sage?'
                        }
                    >
                        <Button
                            size="sm"
                            colorScheme={alreadyInSage ? 'green' : 'blue'}
                            disabled={tranferringToSage}
                            isLoading={tranferringToSage}
                        >
                            {' '}
                            {alreadyInSage ? 'In Sage' : 'Transfer'}
                        </Button>
                    </PopConfirm>
                );
            },
        },
        {
            Header: 'Number',
            accessor: 'Number',
            Cell: ({ value }) => (
                <LinkButton target="_blank" to={`/bookings/edit/${value}`}>
                    <Button variant="link" colorScheme="blue" rounded="md">
                        {value}
                    </Button>
                </LinkButton>
            ),
        },
        {
            Header: 'PO',
            accessor: 'PO_number',
        },
        {
            Header: 'Date',
            accessor: 'Date',
            Cell: ({ value }) => <Text>{value}</Text>,
        },
        {
            Header: 'Type',
            accessor: 'Type',
        },
        {
            Header: 'Total',
            accessor: 'Total',
        },
        {
            Header: 'Status',
            accessor: 'Status.name',
            Cell: ({ row }) =>
                row.original.Type === 'Sales Credit Note' ? (
                    'Credit Note'
                ) : row.original.Status.name === 'Unpaid' ||
                  row.original.Status.name === 'Pending' ||
                  row.original.Status.name === 'Overdue' ? (
                    <StatusDropdown
                        statuses={statuses}
                        update_status={update_status}
                        row={row}
                    ></StatusDropdown>
                ) : (
                    <Text>{row.original.Status.name}</Text>
                ),
        },
        {
            Header: 'Outstanding',
            accessor: 'Outstanding',
            Cell: ({ row }) =>
                row.original.Type === 'Sales Invoice' && row.original.Outstanding !== 'N/A' ? (
                    <Text color={'green'}>{`${row.original.Outstanding}`}</Text>
                ) : row.original.Outstanding !== 'N/A' ? (
                    <Text color={'red'}>{`-${row.original.Outstanding}`}</Text>
                ) : (
                    <Text color={'red'}>{`${row.original.Outstanding}`}</Text>
                ),
        },

        {
            Header: 'Due Date',
            accessor: 'Due Date',
            Cell: ({ value }) => <Text>{value}</Text>,
        },
        {
            Header: 'View Comments',
            Cell: ({ row }) => {
                console.log(row.original.Comment);
                return (
                    <>
                        {row.original.Comment !== null && row.original.Comment !== '' ? (
                            <Box {...row.getToggleRowExpandedProps()} mr="1em">
                                <Flex>
                                    {row.isExpanded ? (
                                        <>
                                            <Button
                                                variant="link"
                                                leftIcon={<AiOutlineArrowDown />}
                                            >
                                                Close
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <Button variant="link" leftIcon={<AiOutlineArrowUp />}>
                                                View
                                            </Button>
                                        </>
                                    )}
                                </Flex>
                            </Box>
                        ) : (
                            <Box>
                                <Text>No comments added</Text>
                            </Box>
                        )}
                    </>
                );
            },
        },
    ];

    const AddHeaders = () => {
        let credit_balance = getValues('credit_terms');
        return (
            <Flex>
                <Flex fontSize={{ base: '1em', xl: '17px' }} fontWeight="bold" mb="15px">
                    <Text>Credit Balance:</Text>
                    <Text mx="0.2em">{credit_balance ? formatCurrency(credit_balance) : 0}</Text>
                </Flex>
                <Box width="3em"></Box>
                <Flex fontSize={{ base: '1em', xl: '17px' }} fontWeight="bold" mb="15px">
                    <Text>Outstanding Balance:</Text>
                    <Text
                        color={
                            outstanding_balance?.data < 0
                                ? 'red'
                                : outstanding_balance?.data > 0
                                ? 'green'
                                : null
                        }
                        mx="0.2em"
                    >
                        {`${formatCurrency(outstanding_balance?.data)}`}
                    </Text>
                </Flex>
            </Flex>
        );
    };

    return (
        <FormSection
            title={`Summary #${bookingId ? bookingId : id}`}
            mt="0px"
            AddHeaders={AddHeaders}
        >
            <Table
                data={{ data: summary?.data.summary ?? [] }}
                columns={columns}
                renderRowSubComponent={renderRowSubComponent}
            ></Table>
        </FormSection>
    );
};

export default Summary;
