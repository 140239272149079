import React from 'react';
import { Flex, IconButton, Button, Text, Spinner } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { calendarState } from './atom';
import dayjs from 'dayjs';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

const Header = ({ isLoading }) => {
    const [calendar, setCalendar] = useRecoilState(calendarState);

    return (
        <Flex align="center" rounded="md" p="10px" mt="10px">
            <Text fontSize="18px" fontWeight="bold">
                {calendar.mode === 'month' && dayjs(calendar.selectedDate).format('MMMM YYYY')}
            </Text>
            {isLoading && <Spinner ml="20px" />}
            <Flex justify="space-between" ml="auto">
                <Flex>
                    <IconButton
                        bg="white"
                        variant="outline"
                        roundedRight="0px"
                        onClick={() =>
                            setCalendar((cal) => ({
                                ...cal,
                                selectedDate: dayjs(cal.selectedDate).subtract(1, calendar.mode),
                            }))
                        }
                        icon={<MdKeyboardArrowLeft />}
                    />
                    <Button
                        variant="outline"
                        bg="white"
                        rounded="none"
                        borderLeft="0px"
                        borderRight="0px"
                        onClick={() =>
                            setCalendar((cal) => ({
                                ...cal,
                                selectedDate: dayjs(),
                            }))
                        }
                    >
                        Today
                    </Button>
                    <IconButton
                        variant="outline"
                        bg="white"
                        roundedLeft="0px"
                        onClick={() =>
                            setCalendar((cal) => ({
                                ...cal,
                                selectedDate: dayjs(cal.selectedDate).add(1, calendar.mode),
                            }))
                        }
                        icon={<MdKeyboardArrowRight />}
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};

export default Header;
