import React, { useEffect, useState, useCallback } from 'react';
import { Flex, Input, Text, Textarea } from '@chakra-ui/react';
import debounce from 'lodash.debounce';
import { AiFillCloseCircle, AiFillCheckCircle } from 'react-icons/ai';

const ReasonField = ({ index, arr, setArr }) => {
    const [value, setValue] = useState(arr?.data?.[index]?.reason);
    const [ready, setReady] = useState(true);

    const handleValue = () => {
        let x = arr.data;
        if (index || index === 0) {
            x[index] = {
                ...x[index],
                reason: value,
            };

            setArr({ ...arr, data: x });
        }
    };

    useEffect(() => {
        if (value !== arr?.data?.[index]?.reason) {
            clearTimeout(5000);

            submitValue(value);
            timeoutText();
        }

        return submitValue.cancel;
    }, [value]);

    const submitValue = useCallback(
        debounce(() => handleValue(), 5000),
        [value],
    );

    const timeoutText = () => {
        let timeleft = 10;

        const timer = setInterval(function () {
            if (timeleft <= 0) {
                clearInterval(timer);

                setReady(true);
            } else {
                setReady(false);
            }
            timeleft -= 1;
        }, 1000);
    };

    return (
        <Flex direction="column">
            <Textarea
                bg="white"
                width="fit-content"
                value={value}
                onChange={(e) => setValue(e.target.value)}
            />
            <Flex alignSelf="flex-end" width="100$">
                {ready ? (
                    <AiFillCheckCircle fontSize="1.5em" color="green" />
                ) : (
                    <AiFillCloseCircle fontSize="1.5em" color="red" />
                )}
            </Flex>
        </Flex>
    );
};

export default ReasonField;
