import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalHeader,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Image,
    ModalFooter,
    Flex,
    Box,
    Text,
} from '@chakra-ui/react';
import { AiOutlineIdcard } from 'react-icons/ai';
import { GoDash } from 'react-icons/go';
import { Field, useHttp } from '@builtbypixel/nucleus';
import { useParams } from 'react-router-dom';

import Slider from '../../../../components/Slider';
const IdUploader = ({ user, isOpen, onOpen, onClose, arr, setArr, index, onSuccess, idTypes }) => {
    const { id } = useParams();
    const [idUploadType, setIdUploadType] = useState([]);
    const [name, setName] = useState(null);
    const [file, setFile] = useState(null);

    const Http = useHttp();

    useEffect(() => {
        if (idTypes.length > 0) {
            const selectedIDType = idTypes.find((el) => el.name === 'Photo ID');
            setIdUploadType(selectedIDType);
        }
    }, [idTypes]);
    const handleImageUploaded = (imageObject) => {
        setFile(imageObject);
        // setName(name + imageObject.original_filename);
    };

    const handleChangeType = (option) => {
        setIdUploadType(option);
    };

    const handleDoSubmit = () => {
        
        const userID = user?.id;

        Http.post(`upload-documents/${userID}`, {
            type: idUploadType,
            filename: name,
            event: { id: id },
            document: file,
        }).then((res) => {
            onSuccess();
            onClose();
        });
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg" scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Flex alignItems="center">
                        <Text mr="15px">
                            {' '}
                            Upload ID for{' '}
                            {user?.first_name + ' ' + user?.last_name}{' '}
                        </Text>
                    </Flex>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Field
                        component="standardfile"
                        name="files"
                        max={1}
                        onChange={(e) => {
                            handleImageUploaded(e);
                        }}
                    />
                    <Field
                        component="select"
                        name="type"
                        max={1}
                        options={idTypes}
                        labelKey={'name'}
                        valueKey="id"
                        value={idUploadType}
                        onChange={(e) => {
                            handleChangeType(e);
                        }}
                    />
                    <Field
                        placeholder="Enter file name"
                        component="text"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e)}
                    />
                </ModalBody>
                <ModalFooter justify="flex-end">
                    <Button
                        onClick={() => handleDoSubmit()}
                        colorScheme="green"
                        disabled={!file && !name}
                    >
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default IdUploader;
