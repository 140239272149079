import React from 'react';
import { useWatch } from 'react-hook-form';
import Section from '../Section';
import { Text, Stack } from '@chakra-ui/react';

const BookedBy = () => {
    const user = useWatch({ name: 'booked_by' });

    return user ? (
        <>
            <Section title="Booked by" w="100%">
                <Stack w="100%" spacing="10px">
                    <Text fontSize="17px">
                        {user.first_name} {user.last_name}
                    </Text>
                    <Text>{user.email}</Text>
                </Stack>
            </Section>
        </>
    ) : null;
};

export default BookedBy;
