import React, { useMemo, useState, useEffect } from 'react'
import { AutoFields, EditView, useHttp } from '@builtbypixel/nucleus'

import setup from './setup'

const EditForm = () => {

    const Http = useHttp();
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        Http.get(`/roles`).then((res) => {
        setRoles(res.data.data);
        });
    }, [Http, setRoles])  

   const fields = useMemo(
      () => [
         {
            group: 'Administrator',
            fields: [
                {
                    name: 'first_name',
                    component: 'text',
                    placeholder: 'First Name',
                    label: 'First Name',
                    isRequired: true,
                    rules: { required: true }
                },
                {
                    name: 'last_name',
                    component: 'text',
                    placeholder: 'Last Name',
                    label: 'Last Name',
                    isRequired: true,
                    rules: { required: true }
                },
                {
                    name: 'email',
                    component: 'text',
                    type: 'email',
                    placeholder: 'Email Address',
                    label: 'Email Address',
                    isRequired: true,
                    rules: { required: true }
                },
                {
                    name: 'roles',
                    component: 'select',
                    placeholder: 'Roles',
                    label: 'Roles',
                    options: roles,
                    isMulti: true,
                    labelKey: 'name',
                    valueKey: 'id',
                    isRequired: true,
                    rules: {required: true}
                },
                {
                    name: 'password',
                    component: 'text',
                    type: 'password',
                    placeholder: 'Password',
                    label: 'Password'
                },
                {
                    name: 'password_confirmation',
                    component: 'text',
                    type: 'password',
                    placeholder: 'Confirm Password',
                    label: 'Confirm Password'
                },
            ]
         }
      ],
      [roles]
   )
   return (
      <EditView setup={setup}>
         <AutoFields fields={fields} />
      </EditView>
   )
}

export default EditForm
