import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import initcap from '../../../utils/initcap';

const renderToolTip = function ({ active, payload }) {
    if (!active) return null;
    return (
        <Box bg="white" p="10px" border="solid 1px" borderColor={payload?.[0]?.color}>
            {payload?.map((each, i) => (
                <Flex key={'tool-tip-three' + i}>
                    <Text color={each?.color} fontWeight="semibold">
                        {initcap(each.name)}:
                    </Text>{' '}
                    {each.dataKey === 'sales' ? (
                        <Text ml="5px">
                            {' '}
                            £
                            {parseFloat(each?.value).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}{' '}
                        </Text>
                    ) : (
                        <Text ml="5px">{each.value}</Text>
                    )}
                </Flex>
            ))}
        </Box>
    );
};

export default renderToolTip;
