import { extendTheme } from '@chakra-ui/react';
import Button from './components/Button';
const colors = {
    darkBorder: 'rgba(0,0,0,0.2)',
    primary: 'rgba(0,0,0,1)',
};

const overrides = {
    styles: {
        global: () => ({
            'html, body': {
                fontSize: '14px',
                bg: 'white',
                maxWidth: '100vw',
                overflowX: 'hidden',
            },
        }),
    },
    colors,
    space: {
        field: '40px',
    },
    components: { Button },
    config: {
        cssVarPrefix: 'nucleus',
    },
};
export default extendTheme(overrides);
