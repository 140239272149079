import React from 'react';
import Duration from './Duration';
import AccessTime from './AccessTime';

const ElearningTimes = () => {
    return (
        <div>
            <Duration />
            <AccessTime />
        </div>
    );
};

export default ElearningTimes;
