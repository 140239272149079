import { Flex, SimpleGrid, Text } from '@chakra-ui/react';
import React, { useState, useEffect, useRef } from 'react';
import FormSection from '../../../components/FormSection';
import { useHttp } from '@builtbypixel/nucleus';
import LoadingBar from 'react-top-loading-bar';
import formatCurrency from '../../../utils/formatCurrency';

const OverallFigures = ({ startDate, endDate }) => {
    const Http = useHttp();
    const ref = useRef(null);
    const [data, setData] = useState();

    const fetchData = () => {
        ref.current.continuousStart();
        Http.get(`dashboard-overall-figures?start_date=${startDate}&end_date=${endDate}`)
            .then((res) => {
                setData(res.data.data);
                ref.current.complete();
            })
            .catch((err) => {
                ref.current.complete();
            });
    };

    useEffect(() => {
        fetchData();
    }, [startDate, endDate]);

    return (
        <>
            <SimpleGrid columns={6} gap="0px 20px">
                <FormSection>
                    <DataView title="Bookings" value={data?.bookings} />{' '}
                </FormSection>
                <FormSection>
                    <DataView title="Delegates" value={data?.delegates} />{' '}
                </FormSection>
                <FormSection>
                    <DataView title="Learners" value={data?.learners} />
                </FormSection>
                <FormSection>
                    <DataView title="New Learners" value={data?.document_uploads_customer} />
                </FormSection>
                <FormSection>
                    <DataView title="Businesses" value={data?.business} />
                </FormSection>
                <FormSection>
                    <DataView title="New Businesses" value={data?.document_uploads_trainer} />
                </FormSection>
                <FormSection>
                    <DataView title="Courses Run" value={data?.events} />
                </FormSection>
                <FormSection>
                    <DataView title="Enquiries" value={data?.enquiries} />
                </FormSection>
                <FormSection>
                    <DataView title="Free Trials" value={data?.free_trials} />
                </FormSection>
                <FormSection>
                    <DataView title="Total Revenue" value={`${formatCurrency(data?.revenue)}`} />
                </FormSection>
                <FormSection>
                    <DataView
                        title="Total Credit"
                        value={`${formatCurrency(data?.total_credit)}`}
                    />
                </FormSection>
                <FormSection>
                    <DataView
                        title="Final Balance"
                        value={`${formatCurrency(data?.final_balance)}`}
                    />
                </FormSection>
            </SimpleGrid>
            <LoadingBar color="#4880C8" ref={ref} height={3} />
        </>
    );
};

const DataView = ({ title, value }) => {
    return (
        <Flex direction="column" align="center">
            <Text fontSize="1.4em">{title}</Text>
            <Text fontSize="2em" fontWeight="bold">
                {value}
            </Text>
        </Flex>
    );
};

export default OverallFigures;
