import React, { useMemo, useEffect, useState } from "react";
import { AutoFields, EditView, useHttp } from "@builtbypixel/nucleus";
import setup from "./setup";
const EditForm = () => {
  const [clients, setClient] = useState([]);

  const Http = useHttp();

  useEffect(() => {
    Http.get(`/clients`).then((res) => {
      setClient(res.data.data);
    });
    return () => {};
  }, []);

  const fields = useMemo(
    () => [
      {
        group: "Client Reviews",
        fields: [
          {
            name: "client",
            component: "select",
            options: clients,
            placeholder: "Client Name",
            labelKey: "name",
            valueKey: "id",
            label: "Client Name",
            variant: "outline",
            isRequired: true,
            rules: { required: true },
            isDisabled: true,
          },
          {
            name: "name",
            component: "text",
            placeholder: "Individual name",
            label: "Individual name",
            isRequired: true,
            rules: { required: true },
            disabled: true,
          },
          {
            name: "rating",
            component: "text",
            placeholder: "Rating (enter 1 to 5, with 5 being best)",
            label: "Rating (enter 1 to 5, with 5 being best)",
            isRequired: true,
            rules: { required: true },
            disabled: true,
          },
          {
            name: "content",
            component: "textarea",
            placeholder: "Review text",
            label: "Review text",
            isRequired: true,
            rules: { required: true },
            disabled: true,
          },
        ],
      },
    ],
    [clients]
  );
  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
