import React, { useState, useEffect } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import SwitchToggle from '../components/SwtichToggle';
import ChartView from './ChartView';
import TableView from './TableView';

import useSWR from 'swr';
import moment from 'moment';
import { FormProvider, useForm } from 'react-hook-form';
import LoadingBar from 'react-top-loading-bar';
import { useGet, useHttp } from '@builtbypixel/nucleus';
import { createContext } from 'react';
import Filters from './Filters';
import { useRef } from 'react';

export const FiguresContext = createContext();

const CourseSales = ({ deliveryMethods, setExportData, exportData, title }) => {
    const Http = useHttp();
    const ref = useRef(null);

    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
    const currentDay = moment().format('YYYY-MM-DD');

    const [toggleView, setToggleView] = useState(false);
    const [startDate, setStartDate] = useState(currentDay);
    const [endDate, setEndDate] = useState(currentDay);
    const [userID, setUserID] = useState(null);
    const [courseID, setCourseID] = useState(null);
    const [origin, setOrigin] = useState(null);
    const [loading, setLoading] = useState(false);

    const { data } = useSWR(`/dashboard-users`, useGet);
    const { data: courses } = useSWR(`/all-courses`, useGet);

    const [results, setResults] = useState(null);
    const [filters, setFilters] = useState({
        startDate: null,
        endDate: null,
        userID: null,
        courseID: null,
        origin: null,
    });

    useEffect(() => {
        if (!filters.startDate) {
            setFilters((old) => ({
                ...old,
                startDate: currentDay,
            }));
        }
        if (!filters.endDate) {
            setFilters((old) => ({
                ...old,
                endDate: currentDay,
            }));
        }
        if (filters?.startDate) {
            fetchData();
        }
    }, [filters, currentDay, endOfMonth, startOfMonth]);

    const fetchData = () => {
        ref.current.continuousStart();
        setLoading(true);
        Http.get(
            `/course-sales?${filters.startDate ? 'start_date=' + filters.startDate : ''}${
                filters.endDate ? '&end_date=' + filters.endDate : ''
            }${filters.userID ? '&user=' + filters.userID : ''}
         ${filters.origin ? '&origin=' + filters.origin : ''}
         ${filters.courseID ? '&course=' + filters.courseID : ''}
         ${filters.deliveryMethodID ? '&delivery_method=' + filters.deliveryMethodID : ''}
         `,
        )
            .then((_data) => {
                setLoading(false);
                setResults(_data.data);
                ref.current.complete();
            })
            .catch((err) => setLoading(false));
    };

    const toggle = () => {
        setToggleView((prevState) => !prevState);
    };

    const methods = useForm();

    return (
        <FiguresContext.Provider value={{ filters, setFilters }}>
            <LoadingBar color="#4880C8" ref={ref} height={3} />
            <FormProvider {...methods}>
                {/* <Flex>
                    <SwitchToggle toggle={toggle} toggleView={toggleView} />
                </Flex> */}

                <Box mb={5} mt={5}>
                    <Filters
                        toggle={toggle}
                        toggleView={toggleView}
                        setUserID={setUserID}
                        userID={userID}
                        setCourseID={setCourseID}
                        courseID={courseID}
                        origin={origin}
                        setOrigin={setOrigin}
                        setStartDate={setStartDate}
                        startDate={startDate}
                        setEndDate={setEndDate}
                        endDate={endDate}
                        users={data?.data}
                        courses={courses?.data}
                        deliveryMethods={deliveryMethods}
                    />
                </Box>

                <Box>
                    {toggleView ? (
                        <ChartView loading={loading} data={results} />
                    ) : (
                        <Box mb={5} mt={5}>
                            <TableView
                                data={results}
                                loading={loading}
                                setExportData={setExportData}
                                exportData={exportData}
                                title={title}
                            />
                        </Box>
                    )}
                </Box>
            </FormProvider>
        </FiguresContext.Provider>
    );
};

export default CourseSales;
