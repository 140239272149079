import React, { useMemo, useEffect, useState } from 'react';
import { AutoFields, EditView } from '@builtbypixel/nucleus';
import setup from './setup';
import { useHttp } from '@builtbypixel/nucleus';

const EditForm = () => {
    const [documentCategories, setDocumentCategories] = useState([]);
    const Http = useHttp();
    useEffect(() => {
        Http.get('/download-categories').then((res) => {
            setDocumentCategories(res.data.data);
        });
    }, []);

    const fields = useMemo(
        () => [
            {
                group: 'Document Details',
                fields: [
                    {
                        name: 'order',
                        component: 'number',
                        placeholder: '10',
                        label: 'Order',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'category',
                        component: 'select',
                        options: documentCategories,
                        placeholder: 'Category',
                        labelKey: 'name',
                        valueKey: 'id',
                        label: 'Category',
                        variant: 'outline',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'title',
                        component: 'text',
                        placeholder: 'Title',
                        label: 'Title',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'file',
                        component: 'standardfile',
                        placeholder: 'Upload',
                        label: 'Upload',
                        isRequired: true,
                        rules: { required: true },
                        max: 1,
                    },
                ],
            },
        ],
        [documentCategories],
    );
    return (
        <EditView setup={setup}>
            <AutoFields fields={fields} />
        </EditView>
    );
};

export default EditForm;
