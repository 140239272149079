import { Flex, Text, Tooltip, useToast, Button } from '@chakra-ui/react';
import React from 'react';
import dayjs from 'dayjs';
import { useRecoilState } from 'recoil';
import { calendarState } from './atom';
import { FaRegCheckCircle } from 'react-icons/fa';
import { BiArrowToRight } from 'react-icons/bi';
import { useSWRConfig } from 'swr';
import { useParams } from 'react-router';
import { useHttp } from '@builtbypixel/nucleus';
import { PopConfirm } from '@builtbypixel/nucleus';
import EventPopup from './EventPopup';

var isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
dayjs.extend(isSameOrAfter);

const Day = ({ day, events }) => {
    const { id } = useParams();
    const toast = useToast();
    const Http = useHttp();
    const { mutate } = useSWRConfig();

    const [calendar, setCalendar] = useRecoilState(calendarState);

    const isInSelected = () => {
        let arr = [];
        calendar.selectedDates.forEach((e) => {
            if (dayjs(e.start).isSame(day.date, 'day')) {
                arr.push(1);
            }
        });
        return arr.length !== 0;
    };

    const toggleDragging = () => {
        if (isInSelected()) {
            return null;
        } else {
            if (day.date.isSameOrAfter(dayjs(), 'day')) {
                if (!calendar.startDrag) {
                    setCalendar((old) => ({
                        ...old,
                        dragActive: true,
                        startDrag: day.date,
                        endDrag: null,
                    }));
                } else {
                    setCalendar((old) => ({
                        ...old,
                        dragActive: false,
                        endDrag: day.date,
                    }));
                }
            }
        }
    };

    const deleteDate = () => {
        let dates = [...calendar.selectedDates];

        const index = calendar.selectedDates.findIndex((e) => e.start.isSame(day.date));
        const toDelete = dates[index].id;

        dates.splice(index, 1);
        setCalendar((old) => ({
            ...old,
            selectedDates: dates,
        }));

        // setValue('availability', factoredForApi);
        Http.delete(`/trainer-availability/${id}`, { data: { id: [toDelete] } }).then(() => {
            toast({
                status: 'success',
                title: 'Success',
                description: 'Dates deleted successfully',
                position: 'bottom-right',
            });
            mutate(`/trainer-availability/${id}`);
        });
    };

    const getHover = () => {
        if (calendar.dragActive) {
            if (!isInSelected() && calendar.startDrag && !calendar.startDrag.isSame(day.date)) {
                return 'blue.50';
            }
            if (
                day.date.isAfter(calendar.selectedDates[0]) &&
                !calendar.startDrag.isSame(day.date)
            ) {
                return 'blue.600';
            }
        } else {
            if (events.length === 0 || !isInSelected()) {
                return 'orange.50';
            }
        }
    };

    const getLabel = () => {
        if (events.length !== 0) {
            return null;
        }
        if (day.date.isBefore(dayjs())) {
            return null;
        }
        if (calendar.dragActive) {
            return 'Select End Date';
        }
        if (isInSelected() && day.date.isAfter(dayjs())) {
            return 'Remove Date';
        }
        return 'Add New Date(s)';
    };

    return (
        <Tooltip label={getLabel()}>
            <Flex
                minHeight="130px"
                pos="relative"
                opacity={day.date.isSameOrAfter(dayjs(), 'day') ? 1 : 0.5}
                borderRight="1px"
                borderBottom="1px"
                borderColor={'gray.200'}
                pt="20px"
                cursor="pointer"
                _hover={{
                    bg: getHover(),
                    border:
                        day.date.isSameOrAfter(dayjs(), 'day') &&
                        calendar.startDrag &&
                        !calendar.startDrag.isSame(day.date) &&
                        '2px',
                    borderColor:
                        calendar.startDrag && !calendar.startDrag.isSame(day.date) && 'blue.300',
                }}
                onClick={() => toggleDragging()}
                bg={isInSelected() ? 'green.50' : 'white'}
            >
                <Text pos="absolute" right="10px" top="7px" color="gray.900">
                    {dayjs(day.date).format('DD')}
                </Text>
                {events.length !== 0 && <EventPopup event={events[0]} />}
                {isInSelected() && events.length === 0 && (
                    <div>
                        <PopConfirm onConfirm={() => deleteDate()}>
                            <Flex
                                w="90%"
                                rounded="md"
                                border="1px"
                                borderColor="green.300"
                                bg="green.100"
                                h="70%"
                                left="5%"
                                bottom="5%"
                                pos="absolute"
                                align="center"
                                justify="center"
                                fontSize="22px"
                                color="green.400"
                                direction="column"
                                _hover={{
                                    bg: day.date.isAfter(dayjs()) && 'red.100',
                                    borderColor: day.date.isAfter(dayjs()) && 'red.300',
                                    color: day.date.isAfter(dayjs()) && 'red.300',
                                }}
                            >
                                <FaRegCheckCircle />
                                <Text fontSize="11px" color="blackAlpha.800">
                                    Available
                                </Text>
                            </Flex>
                        </PopConfirm>
                    </div>
                )}

                {calendar.startDrag && calendar.startDrag.isSame(day.date) && (
                    <Flex
                        w="90%"
                        rounded="md"
                        border="2px"
                        borderColor="purple.400"
                        bg="purple.100"
                        h="90%"
                        left="5%"
                        bottom="5%"
                        pos="absolute"
                        align="center"
                        justify="center"
                        fontSize="22px"
                        color="purple.800"
                        direction="column"
                    >
                        <Flex align="center">
                            <Text fontSize="14px" color="blue.800" pr="10px">
                                Select End Date
                            </Text>
                            <BiArrowToRight />
                        </Flex>
                    </Flex>
                )}
            </Flex>
        </Tooltip>
    );
};

export default Day;
