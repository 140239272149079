import React, { useState } from 'react';
import { BiAtom, BiExit, BiRefresh } from 'react-icons/bi';
import { HiOutlineRefresh } from 'react-icons/hi';

import { FiUsers } from 'react-icons/fi';
import {
    Flex,
    Icon,
    IconButton,
    Tooltip,
    Stack,
    useColorModeValue,
    useToast,
    Spinner,
} from '@chakra-ui/react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import SiteModal from './Modal';
import { logout } from '../../helpers';
import { use100vh } from 'react-div-100vh';
import { globalAtom, menuAtom } from '../../state/global';
import { useHttp } from '../../hooks';

const SideBar = () => {
    const [globalState, setGlobalState] = useRecoilState(globalAtom);
    const menuState = useRecoilValue(menuAtom);

    const history = useHistory();
    const height = use100vh();

    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const bg = useColorModeValue('white', 'gray.900');
    const icon = useColorModeValue('primary', 'white');
    const Http = useHttp();
    const toast = useToast();

    const startPriceCron = () => {
        setGlobalState((old) => {
            return { ...old, loadingCron: true };
        });

        Http.post('/update_events_availability_cron')
            .then((res) => {
                setGlobalState((old) => {
                    return { ...old, loadingCron: false };
                });
                return toast({
                    status: 'success',
                    title: res.data,
                    position: 'top',
                });
            })
            .catch((err) => {
                setGlobalState((old) => {
                    return { ...old, loadingCron: false };
                });
            });
    };

    return (
        <Flex
            h={height}
            w="60px"
            direction="column"
            justify="space-between"
            align="center"
            pt="15px"
            pb="5px"
            px="8px"
            bg={bg}
            zIndex={1002}
            position={{ xs: 'fixed', md: 'relative' }}
            left={0}
            top={0}
            transform={{
                xs: menuState ? 'translateX(0%)' : 'translateX(-100%)',
                md: 'none',
            }}
            transition="0.3s all ease-in-out"
            borderRight="1px"
            borderColor={borderColor}
            display={{ base: 'none', xl: 'flex' }}
        >
            <Flex mb={20}>
                <Icon fontSize="26px" color={icon}>
                    <BiAtom />
                </Icon>
            </Flex>

            <Stack spacing="10px" flex={1} justifyContent="flex-end">
                {/* <IconButton rounded='full' size='lg' variant='ghost'>
               <Tooltip title='Content Manager' placement='right'>
                  <FiLayers fontSize={20} />
               </Tooltip>
            </IconButton> */}

                <Tooltip label="Sync Data" placement="right">
                    <IconButton
                        rounded="full"
                        size="lg"
                        variant="ghost"
                        onClick={() => startPriceCron()}
                        mt="auto"
                        icon={
                            globalState?.loadingCron ? (
                                <Spinner />
                            ) : (
                                <HiOutlineRefresh fontSize={20} />
                            )
                        }
                    />
                </Tooltip>

                <IconButton
                    rounded="full"
                    size="lg"
                    variant="ghost"
                    onClick={() => history.push('/administrators')}
                >
                    <FiUsers fontSize={20} />
                </IconButton>

                {globalState.config?.siteSwitcher.show && <SiteModal />}

                {/* <Tooltip
               label={colorMode === 'light' ? 'Dark Mode' : 'Light Mode'}
               placement='right'
            >
               <IconButton
                  rounded='full'
                  size='lg'
                  variant='ghost'
                  onClick={() => toggleColorMode()}
                  mt='auto'
                  icon={<FiSun fontSize={20} />}
               />
            </Tooltip> */}

                <Tooltip label="Logout" placement="right">
                    <IconButton
                        rounded="full"
                        size="lg"
                        variant="ghost"
                        onClick={() => logout()}
                        mt="auto"
                        icon={<BiExit fontSize={20} />}
                    />
                </Tooltip>
            </Stack>
        </Flex>
    );
};

export default SideBar;
