import React, { useMemo, useEffect, useState } from 'react';
import { AutoFields, EditView } from '@builtbypixel/nucleus';
import { useHttp } from '@builtbypixel/nucleus';

import setup from './setup';

const EditForm = () => {
    const [enquiryStatuses, setenquiryStatuses] = useState([]);

    const Http = useHttp();

    useEffect(() => {
        Http.get('/enquiry-statuses').then((res) => {
            setenquiryStatuses(res.data.data);
        });
    }, []);

    const fields = useMemo(
        () => [
            {
                group: 'Enquiry',
                fields: [
                    {
                        name: 'name',
                        component: 'text',
                        placeholder: 'Name',
                        label: 'Name',
                        isDisabled: 'true',
                    },

                    {
                        name: 'email',
                        component: 'text',
                        type: 'email',
                        placeholder: 'Email',
                        label: 'Email',
                        isDisabled: 'true',
                    },
                    {
                        name: 'subject_type',
                        placeholder: 'Enquiry Type',
                        component: 'text',
                        label: 'Enquiry Type',
                        isDisabled: 'true',
                    },
                    {
                        name: 'message',
                        component: 'textarea',
                        placeholder: 'Message',
                        label: 'Message',
                        isDisabled: 'true',
                    },
                    {
                        name: 'status',
                        component: 'select',
                        options: enquiryStatuses,
                        placeholder: 'Type',
                        labelKey: 'name',
                        valueKey: 'id',
                        label: 'Enquiry Status',
                        variant: 'outline',
                    },
                ],
            },
        ],
        [enquiryStatuses],
    );
    return (
        <EditView setup={setup}>
            <AutoFields fields={fields} />
        </EditView>
    );
};

export default EditForm;
