import React from 'react';
import { Field } from '../../../../Nucleus';
import { Alert, AlertIcon } from '@chakra-ui/react';

const Content = ({ counter }) => {
    return (
        <>
            <Alert mb="20px">
                <AlertIcon />
                Information here overrides the default course information
            </Alert>
            <Field
                name={`learningItem[${counter}].information_modal.learning_outcomes`}
                component="richtext"
                label="Learning Outcomes"
                placeholder="Learning Outcomes"
            />
            <Field
                name={`learningItem[${counter}].information_modal.entry_requirements`}
                component="richtext"
                label="Entry Requirements"
                placeholder="Entry Requirements"
            />
            <Field
                name={`learningItem[${counter}].information_modal.course_structure`}
                component="richtext"
                label="Course Structure"
                placeholder="Course Structure"
            />
            <Field
                name={`learningItem[${counter}].information_modal.results`}
                component="richtext"
                label="Results"
                placeholder="Results"
            />
            <Field
                name={`learningItem[${counter}].information_modal.faqs`}
                component="repeater"
                label="FAQ's"
                placeholder="FAQ's"
                layout="vertical"
                defaultValue={[]}
                fieldArray={[
                    {
                        component: 'text',
                        name: 'question',
                        placeholder: 'Question',
                        label: 'Question',
                    },
                    {
                        component: 'richtext',
                        name: 'answer',
                        placeholder: 'Answer',
                        label: 'Answer',
                    },
                ]}
            />
        </>
    );
};

export default Content;
