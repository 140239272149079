import * as yup from 'yup';

const useValidation = () => {
    return yup.object().shape({
        cta: yup
            .array()
            .min(1)
            .of(
                yup.object().shape({
                    title: yup.string().required('Please provide a name'),
                    paragraph: yup.string().required('Please provide text'),
                    button: yup.object().shape({
                        text: yup.string().required('Please provide text for the button'),
                        route: yup.string().required('Please provide a route e.g. /contact'),
                    }),
                }),
            ),
        highlights: yup
            .array()
            .min(1)
            .of(
                yup.object().shape({
                    title: yup.string().required('Please provide a title'),
                    paragraph: yup.string().required('Please provide text'),
                    icon: yup.string().required('Please provide button text'),
                }),
            ),

        location_name: yup.string().required('Please add a location name'),
    });
};

export default useValidation;
