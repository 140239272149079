const modelName = 'Pages';
const modelNameSingular = 'Page';
const endpoint = '/pages';

const setup = {
    title: modelName,
    singular: modelNameSingular,
    model: modelName.replace(/\s+/g, '-').toLowerCase(),
    canDelete: false,
    canAddNew: false,
    canSearch: true,
    endpoint,
    accessor: 'id',
};

export default setup;
