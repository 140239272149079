import React, { useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Checkbox } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

const DelegatesCheckbox = ({ delegate, eventId, quantity }) => {
    const { setValue, getValues } = useFormContext();
    const { id: bookingId } = useParams();

    const [payload, setPayload] = useState({});
    const [currentEvent, setCurrentEvent] = useState(null);

    useEffect(() => {
        setPayload(getValues('cancel'));
    }, [getValues('cancel')]);

    useEffect(() => {
        if (!currentEvent) {
            let event = payload?.events?.find((item) => item.id === eventId);
            setCurrentEvent(event);
        }
    }, [payload]);

    const handleCheckbox = (type) => {
        console.log({ type, payload });

        if (type === 'add') {
            let data = payload;
            data.events[currentEvent.index].delegates = [
                ...data.events[currentEvent.index].delegates,
                { id: delegate.id },
            ];
            data.events[currentEvent.index].selected =
                data.events[currentEvent.index].delegates.length > 0 ? true : false;
            setValue('cancel', data);
            setValue(
                'selectedDelegates',
                getValues('selectedDelegates')
                    ? [...getValues('selectedDelegates'), delegate.id]
                    : [delegate.id],
            );
        } else {
            let data = payload;
            let delegates = data.events[currentEvent.index].delegates.filter(
                (del) => del.id !== delegate.id,
            );
            data.events[currentEvent.index].delegates = delegates;
            data.events[currentEvent.index].selected =
                data.events[currentEvent.index].delegates.length > 0 ? true : false;

            setValue('cancel', data);
            setValue(
                'selectedDelegates',
                getValues('selectedDelegates')
                    ? getValues('selectedDelegates').filter((item) => item !== delegate.id)
                    : delegates.map((item) => item.id),
            );
        }
        console.log(getValues('selectedDelegates'), 'selected delegate IDs array');
    };

    return (
        <Checkbox
            defaultChecked={
                getValues('selectedDelegates') &&
                getValues('selectedDelegates').includes(delegate.id)
            }
            onChange={(e) => {
                e.target.checked ? handleCheckbox('add') : handleCheckbox('remove');
            }}
            borderColor="#0088f799"
        ></Checkbox>
    );
};

export default DelegatesCheckbox;
