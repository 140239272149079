import axios from 'axios';
/*
 * API reference
 * https://docs.ideal-postcodes.co.uk/api
 */

export const findAddress = async (queryString) => {
    const res = await axios.get(
        // `https://api.postcodes.io/postcodes/${queryString}?callback=print`
        `https://api.getAddress.io/find/${queryString}?api-key=jMx_LQklN02ddB6reA18eA36844&expand=true  `,
    );

    return res;
};
