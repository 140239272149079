import React, { useMemo } from 'react';
import { EditView, AutoFields } from '@builtbypixel/nucleus';
import setup from '../setup';
import * as yup from 'yup';
import {seo} from '../../../components/Seo';

const EditForm = () => {
    const useValidation = () => {
        return yup.object().shape({
            content: yup.object().shape({
                heading: yup.object().shape({
                    title: yup.object().shape({
                        text: yup.string().required('Please provide a title'),
                    }),
                    description: yup.object().shape({
                        text: yup.string().required('Please provide a description'),
                    }),
                }),
                details: yup.object().shape({
                    title: yup.object().shape({
                        text: yup.string().required('please provide a title'),
                    }),
                    details: yup.object().shape({
                        text: yup.array().min(1),
                    }),
                }),
            }),
        });
    };

    const fields = useMemo(
        () => [
            {
                tab: 'Free Trial',
                fields: [
                    {
                        name: 'content.heading.title.text',
                        component: 'text',
                        placeholder: 'Heading',
                        label: 'Heading',
                    },
                    {
                        name: 'content.heading.description.text',
                        component: 'richtext',
                        placeholder: 'Description',
                        label: 'Description',
                    },
                    {
                        name: 'content.details.title.text',
                        component: 'text',
                        placeholder: 'Title',
                        label: 'Title',
                    },
                    {
                        name: 'content.details.details.text',
                        component: 'repeater',
                        placeholder: 'Free trial details',
                        label: 'Free trial details',
                        defaultValue: [],
                        fieldArray: [
                            {
                                name: 'content.details.details.text.description',
                                component: 'text',
                                placeholder: 'Description',
                                label: 'Description',
                            },
                        ],
                    },
                ],
            },
            seo
        ],
        [],
    );
    return (
        <EditView setup={setup} validationSchema={useValidation()}>
            <AutoFields fields={fields} />
        </EditView>
    );
};

export default EditForm;
