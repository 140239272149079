import React, { useMemo } from 'react';

import { EditView, AutoFields } from '@builtbypixel/nucleus';
import setup from '../setup';

const EditForm = () => {
    const headingFields = useMemo(
        () => [
            {
                group: 'Forgot password',
                fields: [
                    {
                        name: 'content.page_title',
                        component: 'text',
                        placeholder: 'Heading Title',
                        label: 'Heading Title',
                        rules: { required: true },
                        isRequired: true,
                    },
                ],
            },
        ],
        [],
    );

    return (
        <EditView setup={setup}>
            <AutoFields fields={headingFields} />
        </EditView>
    );
};

export default EditForm;
