import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';
const Slider = (props) => {
    const {currentSlide, setCurrentSlide} = props
    const [loaded, setLoaded] = useState(false);
    const [sliderRef, instanceRef] = useKeenSlider({
        initial: 0,
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel);
        },
        created() {
            setLoaded(true);
        },
    });

    return (
        <>
            <Box position="relative" width="100%" color={props.backgroundColor}>
                <Box ref={sliderRef} className="keen-slider" paddingBottom="10px">
                    {props.children}
                </Box>
                {loaded && instanceRef.current && (
                    <>
                        <HiOutlineChevronLeft
                            bg="yellow"
                            width={'25px'}
                            left
                            onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()}
                            disabled={currentSlide === 0}
                            style={{
                                position: 'absolute',
                                top: '40%',
                                left: '-20px',
                                fontSize: '24px',
                                cursor: 'pointer',
                                display: currentSlide === 0 ? 'none' : 'block',
                            }}
                        />

                        <HiOutlineChevronRight
                            onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
                            disabled={
                                currentSlide === instanceRef.current.track.details.slides.length - 1
                            }
                            style={{
                                position: 'absolute',
                                top: '40%',
                                right: '-20px',
                                fontSize: '24px',
                                cursor: 'pointer',
                                display:
                                    currentSlide ===
                                    instanceRef.current.track.details.slides.length - 1
                                        ? 'none'
                                        : 'block',
                            }}
                        />
                    </>
                )}
            </Box>
        </>
    );
};

export default Slider;
