const modelName = 'Vat Rates';
const modelNameSingular = 'Vat Rate';
const endpoint = '/vat-rates';

const setup = {
    title: modelName,
    singular: modelNameSingular,
    model: modelName.replace(/\s+/g, '-').toLowerCase(),
    canDelete: false,
    canAddNew: false,
    canSearch: false,
    endpoint,
    accessor: 'id',
};

export default setup;
