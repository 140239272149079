import React, { useState } from 'react';

import {
    Text,
    Modal,
    ModalBody,
    ModalHeader,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    Button,
} from '@chakra-ui/react';

import UserView from '../../../containers/PersonalUser';
import EventsView from '../../../containers/EventsNew';
import ListView from '../../../containers/EventsNew/List';
import FormSection from '../../../components/FormSection';

import moment from 'dayjs';

import { useParams } from 'react-router';
import { useFormContext } from 'react-hook-form';

const Events = () => {
    const { id } = useParams();
    const { getValues } = useFormContext();

    const [view, setView] = useState(null);
    const [user, setUser] = useState(null);

    const columns = [
        {
            Header: 'ID',
            accessor: 'id',
        },
        {
            Header: 'Course',
            accessor: 'course.title',
        },
        {
            Header: 'Location',
            accessor: 'locations.location_name',
        },
        {
            Header: 'Venue',
            accessor: 'venues[0].title',
        },
        {
            Header: 'Delivery',
            accessor: 'learningItem.name',
        },
        {
            Header: 'Date',
            accessor: 'start_date',
            Cell: ({ value, row }) => (
                <Text>
                    {moment(row.original.start_date, 'DD-MM-YYYY HH:mm:ss').format(
                        'DD/MM/YYYY - HH:mm',
                    )}
                </Text>
            ),
        },
        {
            Header: 'Duration',
            accessor: 'duration',
        },
        {
            Header: 'Delegates',
            accessor: 'delegates',
            Cell: ({ value }) => (
                <>
                    {value.map((del, i) => (
                        <Text
                            display="block"
                            color="blue.500"
                            _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
                            onClick={() => setUser(del)}
                            key={`delegate-${i}`}
                            pb="3px"
                        >
                            {del.first_name} {del.last_name}
                        </Text>
                    ))}
                </>
            ),
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <Button colorScheme="blue" size="sm" onClick={() => setView(row.original.id)}>
                    Quick View
                </Button>
            ),
        },
    ];

    return (
        <>
            <FormSection title="User Events" mt="0px">
                <ListView
                    tableOnly
                    columns={columns}
                    setup={{ endpoint: `/user-events/${id}`, title: 'Events', canSelect: false }}
                />
                <Modal
                    scrollBehavior="inside"
                    size="xl"
                    isOpen={view !== null || user !== null}
                    isCentered
                    onClose={() => {
                        setView(null);
                        setUser(null);
                    }}
                    motionPreset="none"
                >
                    <ModalOverlay />
                    <ModalContent maxWidth="97%">
                        <ModalCloseButton />
                        <ModalHeader borderBottom="1px" borderColor="gray.200">
                            {view &&
                                `Event ${view} for ${getValues('first_name')} ${getValues(
                                    'last_name',
                                )}`}
                            {user && `${user.first_name} ${user.last_name}`}
                        </ModalHeader>

                        <ModalBody p="0px">
                            {view && <EventsView viewOnly id={view} />}
                            {user && <UserView viewOnly id={user.id} />}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </FormSection>
        </>
    );
};

export default Events;
