import React, { useEffect, useState, useRef } from 'react';
import { Field } from '@builtbypixel/nucleus';
import {
    Box,
    Alert,
    AlertIcon,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Grid,
} from '@chakra-ui/react';
import InformationList from './InformationList';
import RepeaterList from './RepeaterList';

import { useHttp } from '@builtbypixel/nucleus';

import { useGet } from '@builtbypixel/nucleus';
import useSWR from 'swr';
import ElearningTimes from './ElearningTimes';

const Item = ({ field, counter, type }) => {
    const [features, setFeatures] = useState([]);
    const Http = useHttp();
    const ref = useRef();

    const { data: documentTypes } = useSWR(`/user-upload-types`, useGet);
    const { data: courseDocumentTypes } = useSWR(`/course-document-types`, useGet);
    const { data: trainerDocumentTypes } = useSWR(`/trainer-document-types`, useGet);

    useEffect(() => {
        if (features.length === 0) {
            Http.get(`/features`).then((res) => {
                setFeatures(res.data.data);
            });
        }
    }, [Http, features.length]);

    return (
        <>
            <Tabs>
                <TabList mb="25px">
                    <Tab>General Information</Tab>
                    <Tab>Details</Tab>
                    <Tab>Costs</Tab>
                    <Tab>Learner Materials</Tab>
                    <Tab>Trainer Materials</Tab>
                    <Tab>Required Documents</Tab>
                    <Tab>Centre Documents</Tab>
                    <Tab>Refund Terms</Tab>
                    <Tab>Post Purchase Learner Information</Tab>
                    <Tab>Custom Course Learning Instructions</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel p="0px">
                        {!field.elearning ? (
                            <Grid gap="15px" templateColumns="250px 150px 150px 150px 180px">
                                <Field
                                    name={`learningItem[${counter}].duration.days`}
                                    component="text"
                                    label="Duration (days)"
                                    placeholder="Duration (days)"
                                    rules={{ required: true }}
                                />
                                <Field
                                    name={`learningItem[${counter}].duration.start_time`}
                                    component="mask"
                                    mask="99:99"
                                    label="Start Time"
                                    placeholder="Start Time"
                                    rules={{ required: true }}
                                    defaultValue="09:00"
                                />
                                <Field
                                    name={`learningItem[${counter}].duration.end_time`}
                                    component="mask"
                                    mask="99:99"
                                    defaultValue="17:00"
                                    label="End Time"
                                    placeholder="End Time"
                                    rules={{ required: true }}
                                />
                                <Field
                                    label="Price"
                                    name={`learningItem.${counter}.price`}
                                    component="number"
                                    type="number"
                                    defaultValue={field.price}
                                    rules={{ required: true }}
                                />
                                <Field
                                    label="Default Spaces Available"
                                    name={`learningItem.${counter}.availability`}
                                    component="text"
                                    type="number"
                                    min={1}
                                    defaultValue={field.availability ? field.availability : 0}
                                    rules={{ required: true }}
                                />
                            </Grid>
                        ) : (
                            <>
                                <Field
                                    label="Price"
                                    name={`learningItem.${counter}.price`}
                                    component="number"
                                    type="number"
                                    defaultValue={field.price}
                                    maxWidth="200px"
                                    rules={{ required: true }}
                                />
                                <ElearningTimes name={`learningItem.${counter}`} />
                            </>
                        )}
                        <Box width="90%" ref={ref}>
                            <Field
                                label="Features"
                                component="select"
                                name={`learningItem.${counter}.features`}
                                labelKey="name"
                                valueKey="id"
                                placeholder="Features"
                                isMulti={true}
                                required={true}
                                options={features}
                                defaultValue={field.features}
                                width="95%"
                            />
                        </Box>
                        <Box>
                            <Field
                                label="Show Latest Dates"
                                component="switch"
                                name={`learningItem.${counter}.showLatestDates`}
                                width="95%"
                                help="Show the latest 5 available course dates to book on the website"
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel p="0px">
                        <InformationList counter={counter} />
                    </TabPanel>
                    <TabPanel p={0}>
                        <RepeaterList
                            fieldID={field.id}
                            counter={counter}
                            field={field}
                            type={type}
                        ></RepeaterList>
                    </TabPanel>
                    <TabPanel p="0px">
                        <Alert mb="15px" mt="25px">
                            <AlertIcon />
                            Learner materials - These are documents that are specific to learners on
                            this delivery method and course
                        </Alert>

                        <Field
                            label="Add Learner materials"
                            name={`learningItem.${counter}.documents`}
                            component="repeater"
                            required={false}
                            help="These are materials that are specific to the delivery method for this course"
                            fieldArray={[
                                {
                                    name: 'name',
                                    component: 'text',
                                    label: 'File name',
                                },
                                {
                                    name: 'document',
                                    component: 'file',
                                    label: 'Upload file',
                                },
                                {
                                    name: 'type',
                                    component: 'select',
                                    label: 'Select Type',
                                    options: courseDocumentTypes?.data,
                                    labelKey: 'name',
                                    valueKey: 'id',
                                },
                            ]}
                        />
                    </TabPanel>
                    <TabPanel p="0px">
                        <Alert mb="15px" mt="25px">
                            <AlertIcon />
                            Trainer materials - These are documents that are specific to trainers on
                            this delivery method and course
                        </Alert>

                        <Field
                            label="Add Trainer documents"
                            name={`learningItem.${counter}.trainer_documents`}
                            component="repeater"
                            required={false}
                            help="These are materials that are specific to the delivery method for this course"
                            fieldArray={[
                                {
                                    name: 'name',
                                    component: 'text',
                                    label: 'File name',
                                },
                                {
                                    name: 'document',
                                    component: 'file',
                                    label: 'Upload file',
                                },
                                {
                                    name: 'type',
                                    component: 'select',
                                    label: 'Select Type',
                                    options: trainerDocumentTypes?.data,
                                    labelKey: 'name',
                                    valueKey: 'id',
                                },
                            ]}
                        />
                    </TabPanel>

                    <TabPanel p="0px">
                        <Alert mb="15px" mt="25px">
                            <AlertIcon />
                            Required documents - These are documents that a user needs to upload.
                        </Alert>

                        <Field
                            label="Add Required documents"
                            name={`learningItem.${counter}.required_documents`}
                            component="select"
                            required={false}
                            labelKey="name"
                            valueKey="id"
                            isMulti={true}
                            help="These are uploads that are specific to the delivery method for this course"
                            options={documentTypes?.data}
                        />
                    </TabPanel>
                    <TabPanel p="0px">
                        <Field
                            label="Add Centre documents"
                            name={`learningItem.${counter}.centre_documents`}
                            component="repeater"
                            required={false}
                            fieldArray={[
                                {
                                    name: 'name',
                                    component: 'text',
                                    label: 'File name',
                                },
                                {
                                    name: 'document',
                                    component: 'file',
                                    label: 'Upload file',
                                },
                            ]}
                        />
                    </TabPanel>
                    <TabPanel p="0px">
                        <Field
                            label="Add Refund details"
                            name={`learningItem.${counter}.cancellations`}
                            component="repeater"
                            required={false}
                            fieldArray={[
                                {
                                    name: 'cancellation.days',
                                    component: 'text',
                                    type: 'number',
                                    label: 'Days',
                                },
                                {
                                    name: 'cancellation.discount',
                                    type: 'number',
                                    component: 'text',
                                    label: 'Refund Percentage',
                                },
                            ]}
                        />
                    </TabPanel>
                    <TabPanel p="0px">
                        <Field
                            name={`learningItem.${counter}.post_learner_information.content`}
                            label="Post Purchase Learner Information"
                            placeholder="Enter here..."
                            component="richtext"
                        />
                    </TabPanel>
                    <TabPanel p="0px">
                        <Field
                            name={`learningItem.${counter}.learning_instructions.content`}
                            component="richtext"
                            label="Custom Course Learning Instructions"
                            placeholder="Enter here..."
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
            {/* {type === 'edit' && (
                <AddEventPanel parent={`learningItem.${counter}`} deliveryMethod={field} />
            )} */}
        </>
    );
};

export default Item;
