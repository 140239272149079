import React from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';
import { Button, Text } from '@chakra-ui/react';
import { FiEdit } from 'react-icons/fi';
import setup from './setup';

// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const List = () => {
    const columns = [
        {
            Header: () => null,
            id: 'edit',
            Cell: ({ row }) => (
                <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
                    <Button variant="primary" rounded="md">
                        <FiEdit fontSize={18} style={{ marginRight: 10 }} />
                        <Text fontColor="white">{row.original.rate}%</Text>
                    </Button>
                </LinkButton>
            ),
        },
    ];

    return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
