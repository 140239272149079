import React from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';
import { Link } from 'react-router-dom';
import setup from './setup';
import { Button, Text, Badge, Flex, Grid, Box, Image } from '@chakra-ui/react';
import moment from 'moment';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { FaCheck } from 'react-icons/fa';
// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const List = (props) => {
    const getUnconfirmedDelegates = (events) => {
        let arr = [];
        events.forEach((event) => {
            if (event.quantity - event.delegates.length > 0) {
                arr.push(1);
            }
        });

        return arr.length;
    };

    const columns = [
        {
            Header: 'Booking ID',
            accessor: 'id',
            Cell: ({ row }) => (
                <LinkButton
                    target="_blank"
                    to={`${setup.model}/edit/${row.original[setup.accessor]}`}
                >
                    <Button size="sm" variant="link" colorScheme="blue" rounded="md">
                        <Flex align="center">
                            {row.original.id} / {row.original.reference}
                            {row.original.is_bundle && (
                                <Image
                                    ml="0.5em"
                                    src="/bundle_icon.png"
                                    height="25px"
                                    width="25px"
                                />
                            )}
                        </Flex>
                    </Button>
                </LinkButton>
            ),
        },
        // {
        //     Header: 'Has Bundle',
        //     accessor: 'is_bundle',
        //     Cell: ({ value }) => {
        //         if (value === true) {
        //             return <Image ml="0.5em" src="/bundle_icon.png" height="25px" width="25px" />;
        //         } else return null;
        //     },
        // },
        {
            Header: 'Purchase Date',
            accessor: 'purchase_date',
            Cell: ({ row }) => <>{moment(row.original.created_at).format('DD/MM/YYYY HH:mm:ss')}</>,
        },
        {
            Header: 'Courses',
            accessor: 'events',
            Cell: ({ value, row }) => (
                <Flex direction="column" {...row.getToggleRowExpandedProps()}>
                    <Button
                        colorScheme="blue"
                        variant="ghost"
                        justifyContent="flex-start"
                        size="sm"
                        rightIcon={row.isExpanded ? <FiChevronUp /> : <FiChevronDown />}
                    >
                        {value.length} courses(s)
                    </Button>
                </Flex>
            ),
        },

        {
            Header: 'Origin',
            accessor: 'nucleus',
            Cell: ({ row }) => (
                <>
                    {row.original.nucleus ? 'Nucleus' : 'Website'}{' '}
                    <Text color="gray.500">
                        {row.original.booked_by?.first_name} {row.original.booked_by?.last_name}
                    </Text>
                </>
            ),
        },

        {
            Header: 'Customer',
            accessor: 'customer.email',
            Cell: ({ row }) => {
                const userType =
                    row?.original?.customer?.user_type?.name?.toLowerCase() === 'broker'
                        ? 'brokers'
                        : 'personal';
                return (
                    <>
                        <Box>
                            {row.original.customer?.email ? row.original.customer?.email : null}
                        </Box>
                        {row.original.customer?.user_type.name !== 'Business' && (
                            <Box>
                                <LinkButton
                                    target="_blank"
                                    to={`${userType}/edit/${row.original.customer?.id}`}
                                >
                                    <Button
                                        size="sm"
                                        variant="link"
                                        colorScheme="blue"
                                        rounded="md"
                                    >
                                        <Text color="gray.500" paddingTop="5px">
                                            {row.original.customer?.first_name}{' '}
                                            {row.original.customer?.last_name}
                                        </Text>
                                    </Button>
                                </LinkButton>
                            </Box>
                        )}
                        {row.original.customer?.company_name && (
                            <Box>
                                <LinkButton to={`businesses/edit/${row.original.customer?.id}`}>
                                    <Button
                                        size="sm"
                                        variant="link"
                                        colorScheme="blue"
                                        rounded="md"
                                    >
                                        <Text color="gray.500" paddingTop="5px">
                                            {row.original.customer.company_name}
                                        </Text>
                                    </Button>
                                </LinkButton>
                            </Box>
                        )}
                    </>
                );
            },
        },

        {
            Header: 'Booking Total',
            accessor: 'amount_paid',
            Cell: ({ row }) => (
                <Flex>
                    {`£${row.original.total}`}
                    {row.original?.invoice?.transferred_to_sage && (
                        <Badge variant="solid" size="sm" colorScheme="green" ml="1ch">
                            In Sage
                        </Badge>
                    )}
                </Flex>
            ),
        },
        {
            Header: 'Delegates',
            accessor: 'delegates_count',
            Cell: ({ row }) => (
                <>
                    <Text>
                        {getUnconfirmedDelegates(row.original.events) > 0 ? (
                            <Badge variant="solid" colorScheme="red">
                                Unconfirmed Delegates
                            </Badge>
                        ) : (
                            <Badge
                                variant="solid"
                                bg="transparent"
                                color="green.600"
                                display="flex"
                                alignItems="center"
                            >
                                <FaCheck style={{ marginRight: 5 }} />
                                All Confirmed
                            </Badge>
                        )}
                    </Text>
                </>
            ),
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value }) => {
                if (value.id === 2) {
                    return <Badge colorScheme="green">{value.name}</Badge>;
                }
                if (value.id === 3) {
                    return <Badge colorScheme="red">{value.name}</Badge>;
                }
                if (value.id === 6) {
                    return (
                        <Badge colorScheme="red" variant="solid">
                            {value.name}
                        </Badge>
                    );
                }
                if (value.id === 4) {
                    return <Badge colorScheme="orange">{value.name}</Badge>;
                } else {
                    return <Badge>{value.name}</Badge>;
                }
            },
        },
    ];

    const renderRowSubComponent = React.useCallback(
        ({ row }) => (
            <Flex w="100%" direction="column" p="5px">
                {row.original.events.map((event, i) => (
                    <Link
                        to={`${setup.model}/edit/${row.original[setup.accessor]}`}
                        key={`booking-${row.id}-event-${i}`}
                    >
                        <Grid
                            rounded="md"
                            shadow="sm"
                            mb="3px"
                            py="5px"
                            px="25px"
                            align="center"
                            templateColumns="repeat(6, 1fr)"
                            border="1px"
                            borderColor="gray.300"
                            w="100%"
                            fontSize="0.9em"
                            bg="white"
                        >
                            <Flex justifyContent="flex-start">
                                <Text fontWeight="bold" pr="15px">
                                    {event.course.title}
                                </Text>
                            </Flex>
                            <Flex justifyContent="flex-start">
                                <Text>{event.course.provider?.name}</Text>
                            </Flex>
                            {event.locations ? (
                                <Flex justifyContent="flex-start">
                                    <Text pr="10px">{event.locations.location_name}</Text>
                                    <Text>{event.venues[0].title}</Text>
                                </Flex>
                            ) : (
                                <Flex justifyContent="flex-start">
                                    <Text>{event.learningItem.name}</Text>
                                </Flex>
                            )}
                            <Flex justifyContent="flex-start">
                                <Text>
                                    {moment(event.start_date, 'DD-MM-YYYY HH:mm:ss').format(
                                        'DD/MM/YYYY - HH:mm',
                                    )}
                                </Text>
                            </Flex>
                            <Flex justifyContent="flex-start" align="center">
                                <Badge colorScheme="blue" mr="5px">
                                    {event.quantity} delegates
                                </Badge>
                                {event.delegates_count > 0 && (
                                    <Badge colorScheme={'green'}>
                                        {`${
                                            event.quantity -
                                            event.cancellations.length -
                                            getUnconfirmedDelegates.length
                                        } Confirmed`}
                                    </Badge>
                                )}
                                {event.quantity !== event.cancellations.length && (
                                    <Badge
                                        colorScheme={
                                            event.quantity - event.delegates.length > 0
                                                ? 'red'
                                                : 'green'
                                        }
                                        mr="5px"
                                    >
                                        {event.quantity - event.delegates.length > 0 &&
                                            `${
                                                event.quantity -
                                                event.delegates.length -
                                                event.cancellations.length
                                            } unconfirmed`}
                                        {/* // : event.quantity - event.cancellations.length > 0 ? `${event.quantity - event.cancellations.length} Confirmed` : ''} */}
                                    </Badge>
                                )}
                                {event.cancellations.length > 0 && (
                                    <Badge colorScheme="red" mr="5px">
                                        {event.quantity - event.cancellations.length > 0
                                            ? `${event.cancellations.length} cancelled`
                                            : 'All Delegates Cancelled'}
                                    </Badge>
                                )}
                            </Flex>
                            <Flex justifyContent="flex-start" ml={5}>
                                <Text>£{event.total}</Text>
                            </Flex>
                        </Grid>
                    </Link>
                ))}
            </Flex>
        ),
        [],
    );

    return (
        <ListWrapper
            renderRowSubComponent={renderRowSubComponent}
            setup={setup}
            columns={columns}
            extraMenu={extraMenu}
            {...props}
        />
    );
};

export default List;
