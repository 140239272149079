import React, { useMemo } from 'react';

import { EditView, AutoFields } from '@builtbypixel/nucleus';
import setup from '../setup';
import {seo} from '../../../components/Seo';

const EditForm = () => {
    const headingFields = useMemo(
        () => [
            {
                tab: 'Jobs',
                fields: [
                    {
                        name: 'content.heading.title.text',
                        component: 'text',
                        placeholder: 'Heading text',
                        label: 'Heading text',
                        rules: { required: true },
                        isRequired: true,
                    },

                    {
                        name: 'content.heading.description.summary',
                        component: 'richtext',
                        placeholder: 'Heading description',
                        label: 'Heading description',
                        rules: { required: true },
                        isRequired: true,
                    },
                    {
                        name: 'content.listTitle.text',
                        component: 'text',
                        placeholder: 'List Title',
                        label: 'List Title',
                        rules: { required: true },
                        isRequired: true,
                    },
                ],
            },
            
            seo
            
        ],
        [],
    );

    return (
        <EditView setup={setup}>
            <AutoFields fields={headingFields} />
        </EditView>
    );
};

export default EditForm;
