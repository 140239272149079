import ListView from '../../EventsNew/List';
import React from 'react';

import { useParams } from 'react-router';
import FormSection from '../../../components/FormSection';

const Events = () => {
    const { id } = useParams();
    return (
        <FormSection title="Course Events" mt="0px">
            <ListView setup={{ endpoint: `/events-by-course/${id}`, canSearch: true }} tableOnly />
        </FormSection>
    );
};

export default Events;
