import React, { useMemo, useEffect, useState } from 'react';
import { EditView } from '@builtbypixel/nucleus';
import setup from './setup';
import DynamicFields from './DynamicFields';
import { Box } from '@chakra-ui/react';
import { useHttp } from '@builtbypixel/nucleus';
import { useParams } from 'react-router-dom';
import { useFormContext, useWatch } from 'react-hook-form';

const EditForm = () => {
    return (
        <EditView setup={setup} isFullWidth>
            <FieldComponent />
        </EditView>
    );
};

const FieldComponent = () => {
    const [categories, setCategories] = useState([]);
    const [features, setFeatures] = useState([]);
    const [courseTypes, setCourseTypes] = useState([]);
    const [locations, setLocations] = useState([]);

    const { setValue } = useFormContext();
    //const selected = useWatch({ name: name });

    const onRowSelect = (rows) => {
        // setValue(name, rows);
    };

    const { id } = useParams();

    const Http = useHttp();

    useEffect(() => {
        Http.get(`/categories`).then((res) => {
            setCategories(res.data.data);
        });
        Http.get(`/features`).then((res) => {
            setFeatures(res.data.data);
        });
        Http.get(`/course-types`).then((res) => {
            setCourseTypes(res.data.data);
        });
        Http.get(`/locations`).then((res) => {
            setLocations(res.data.data);
        });
        return () => {};
    }, [Http]);

    const customField = (option) => {
        return (
            <>
                {option.location_type.location_type} - {option.location_name}
            </>
        );
    };
    const fields = useMemo(
        () => [
            {
                tab: 'General',
                fields: [
                    {
                        name: 'course_title',
                        component: 'text',
                        placeholder: 'Course Title',
                        label: 'Course Title',
                        rules: { required: true },
                        isRequired: true,
                    },
                    {
                        name: 'course_header.description',
                        component: 'textarea',
                        placeholder: 'Course Header description',
                        label: 'Course Header description',
                    },
                    {
                        name: 'approved',
                        component: 'text',
                        placeholder: 'Approved By',
                        label: 'Approved By',
                        rules: { required: true },
                        isRequired: true,
                    },
                    {
                        name: 'content',
                        component: 'repeater',
                        placeholder: 'Course Content',
                        label: 'Course content',
                        defaultValue: [],
                        fieldArray: [
                            {
                                name: 'entry_requirements',
                                component: 'textarea',
                                placeholder: 'Entry Requirements',
                                label: 'Entry Requirements',
                                rules: { required: true },
                                isRequired: true,
                            },
                            {
                                name: 'learning_outcomes',
                                component: 'textarea',
                                placeholder: 'Learning Outcomes',
                                label: 'Learning Outcomes',
                                rules: { required: true },
                                isRequired: true,
                            },
                            {
                                name: 'course_structure',
                                component: 'textarea',
                                placeholder: 'Course structure',
                                label: 'Course Structure',
                                rules: { required: true },
                                isRequired: true,
                            },
                            {
                                name: 'results',
                                component: 'textarea',
                                placeholder: 'Results and Certificates',
                                label: 'Results and Certificates',
                                rules: { required: true },
                                isRequired: true,
                            },
                            {
                                name: 'faqs',
                                component: 'textarea',
                                placeholder: 'FAQs',
                                label: 'FAQs',
                                rules: { required: true },
                                isRequired: true,
                            },
                        ],
                    },

                    {
                        name: 'featured',
                        component: 'switch',
                        placeholder: 'Featured',
                        label: 'Featured',
                    },
                ],
            },
            {
                tab: 'Course Category',
                fields: [
                    {
                        name: 'category_name',
                        component: 'select',
                        placeholder: 'Course Category',
                        options: categories,
                        labelKey: 'category_name',
                        valueKey: 'id',
                        label: 'Course Category',
                        variant: 'outline',
                        rules: { required: true },
                        isRequired: true,
                    },
                ],
            },
            {
                tab: 'Attributes',
                fields: [
                    {
                        name: 'course_type_columns',
                        component: 'repeater',
                        placeholder: 'Course Attributes',
                        label: 'Course Attributes',
                        defaultValue: [],
                        fieldArray: [
                            {
                                name: 'type_id',
                                component: 'select',
                                placeholder: 'Course Type',
                                options: courseTypes,
                                labelKey: 'name',
                                valueKey: 'id',
                                label: 'Course Type',
                                variant: 'outline',
                                rules: { required: true },
                                isRequired: true,
                            },
                            {
                                name: 'feature_ids',
                                component: 'select',
                                placeholder: 'Course Features',
                                options: features,
                                isMulti: true,
                                labelKey: 'name',
                                valueKey: 'id',
                                label: 'Course Features',
                                variant: 'outline',
                                rules: { required: true },
                                isRequired: true,
                            },
                            {
                                name: 'information_modal',
                                component: 'repeater',
                                placeholder: 'Course information',
                                label: 'Course Information',
                                defaultValue: [],
                                fieldArray: [
                                    {
                                        name: 'introduction',
                                        component: 'textarea',
                                        placeholder: 'Introduction',
                                        label: 'Introduction',
                                        rules: { required: true },
                                        isRequired: true,
                                    },
                                    {
                                        name: 'duration',
                                        component: 'textarea',
                                        placeholder: 'Course duration',
                                        label: 'Duration',
                                        rules: { required: true },
                                        isRequired: true,
                                    },
                                    {
                                        name: 'structure',
                                        component: 'textarea',
                                        placeholder: 'Structure',
                                        label: 'Structure',
                                        rules: { required: true },
                                        isRequired: true,
                                    },
                                    {
                                        name: 'materials',
                                        component: 'textarea',
                                        placeholder: 'Materials',
                                        label: 'Materials',
                                        rules: { required: true },
                                        isRequired: true,
                                    },
                                    {
                                        name: 'objectives',
                                        component: 'textarea',
                                        placeholder: 'Objectives',
                                        label: 'Objectives',
                                        rules: { required: true },
                                        isRequired: true,
                                    },
                                    {
                                        name: 'assessment',
                                        component: 'textarea',
                                        placeholder: 'Assessment',
                                        label: 'Assesement',
                                        rules: { required: true },
                                        isRequired: true,
                                    },
                                    {
                                        name: 'support',
                                        component: 'textarea',
                                        placeholder: 'Support',
                                        label: 'Support',
                                        rules: { required: true },
                                        isRequired: true,
                                    },
                                ],
                            },
                            {
                                name: 'dates',
                                component: 'repeater',
                                placeholder: 'Course Dates',
                                label: 'Course Dates',
                                defaultValue: [],
                                fieldArray: [
                                    {
                                        name: 'locations',
                                        component: 'select',
                                        placeholder: 'Locations',
                                        options: locations,
                                        isMulti: true,
                                        formatOptionLabel: customField,
                                        label: 'Location',
                                        variant: 'outline',
                                        rules: { required: true },
                                        isRequired: true,
                                    },
                                    {
                                        name: 'start_date',
                                        component: 'date',
                                        placeholder: 'Start Date',
                                        label: 'Start Date',
                                    },
                                    {
                                        name: 'duration',
                                        component: 'text',
                                        placeholder: 'Duration',
                                        label: 'Duration',
                                    },
                                    {
                                        name: 'availability',
                                        component: 'text',
                                        type: 'number',
                                        placeholder: 'Availability',
                                        label: 'Availability',
                                    },
                                    {
                                        name: 'price',
                                        component: 'text',
                                        type: 'number',
                                        placeholder: 'Price (£)',
                                        label: 'Price (£)',
                                    },
                                    {
                                        name: 'discount',
                                        component: 'text',
                                        type: 'number',
                                        placeholder: 'Discount (%)',
                                        label: 'Discount (%)',
                                    },
                                    {
                                        name: 'discount_start',
                                        component: 'date',
                                        placeholder: 'Discount start date',
                                        label: 'Discount start date',
                                    },
                                    {
                                        name: 'discount_end',
                                        component: 'date',
                                        placeholder: 'Discount end date',
                                        label: 'Discount end date',
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

            {
                tab: 'Course information tile',
                fields: [
                    {
                        name: 'general_course_information.title',
                        component: 'text',
                        placeholder: 'Course information tile header',
                        label: 'Course information tile header',
                        rules: { required: true },
                        isRequired: true,
                    },
                    {
                        name: 'general_course_information.paragraph',
                        component: 'textarea',
                        placeholder: 'Course Information tile description',
                        label: 'Course Information tile description',
                        rules: { required: true },
                        isRequired: true,
                    },
                    {
                        name: 'general_course_information.image',
                        component: 'file',
                        placeholder: 'Course tile image',
                        label: 'Course tile image',
                        rules: { required: true },
                        isRequired: true,
                    },
                ],
            },
            {
                tab: 'Images',

                fields: [
                    {
                        name: 'training_logo',
                        component: 'file',
                        max: 2,
                        label: 'Training Icon',
                    },
                ],
            },
            {
                tab: 'Refunds',
                fields: [
                    {
                        name: 'cancellations',
                        component: 'repeater',
                        placeholder: 'Cancellations',
                        label: 'Refunds',
                        defaultValue: [],
                        fieldArray: [
                            {
                                name: 'cancellation.days',
                                component: 'text',
                                type: 'number',
                                placeholder: 'Days before start',
                                label: 'Days before start',
                            },
                            {
                                name: 'cancellation.discount',
                                component: 'text',
                                type: 'number',
                                placeholder: 'Discount',
                                label: 'Discount',
                            },
                        ],
                    },
                ],
            },
            {
                tab: 'Transfers',
                fields: [
                    {
                        name: 'transfers',
                        component: 'repeater',
                        placeholder: 'Transfers',
                        label: 'Transfers',
                        defaultValue: [],
                        fieldArray: [
                            {
                                name: 'transfer.days',
                                component: 'text',
                                type: 'number',
                                placeholder: 'Days before start',
                                label: 'Days before start',
                            },
                            {
                                name: 'transfer.discount',
                                component: 'text',
                                type: 'number',
                                placeholder: 'Discount',
                                label: 'Discount',
                            },
                        ],
                    },
                ],
            },

            {
                tab: 'Reviews',
                fields: [
                    {
                        name: 'reviews',
                        component: 'repeater',
                        placeholder: 'Reviews',
                        label: 'Reviews',
                        defaultValue: [],
                        fieldArray: [
                            {
                                name: 'review_details.course_rating',
                                component: 'text',
                                type: 'number',
                                placeholder: 'Course Rating',
                                label: 'Course Rating',
                            },
                            {
                                name: 'review_details.course_content',
                                component: 'text',
                                type: 'number',
                                placeholder: 'Course Content',
                                label: 'Course Content',
                            },
                            {
                                name: 'review_details.course_trainer',
                                component: 'text',
                                type: 'number',
                                placeholder: 'Course Trainer',
                                label: 'Course Trainer',
                            },
                            {
                                name: 'review_details.comments',
                                component: 'textarea',
                                placeholder: 'Comments',
                                label: 'Comments',
                            },
                        ],
                    },
                ],
            },
        ],
        [categories, features, courseTypes, locations],
    );

    return (
        <Box w="100%">
            <DynamicFields fields={fields} />
        </Box>
    );
};

export default EditForm;
