import React, { useMemo } from 'react'
import { AutoFields, EditView } from '@builtbypixel/nucleus'
import setup from './setup'
const EditForm = () => {


   const fields = useMemo(
      () => [
         {
            group: 'Course Types',
            fields: [
             
               {
                  name: 'name',
                  component: 'text',
                  placeholder: 'Course Type',
                  label: 'Course Type',
                  isRequired: true,
                  rules: { required: true }
               }
            ]
         }
      ],
      []
   )
   return (
      <EditView setup={setup}>
         <AutoFields fields={fields} />
      </EditView>
   )
}

export default EditForm
