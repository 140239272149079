import { Stack, Button, Text, Flex } from '@chakra-ui/react';
import React from 'react';
import Section from '../Section';

import { Field } from '@builtbypixel/nucleus';

const AdditionalBookingDetails = () => {
    return (
        <Section title="Additional Booking Details" mt="20px" mb="0">
            <Flex w="100%" direction="column">
                <Stack spacing="25px" w="100%">
                    <Field component="text" name="po_number" label="PO Number" size="sm" />
                    <Field component="textarea" name="notes" label="Notes" size="sm" />
                </Stack>
            </Flex>
        </Section>
    );
};

export default AdditionalBookingDetails;
