import { createPageRoutes } from '@builtbypixel/nucleus';
import List from './List';
import Form from './';

import setup from './setup';

import Home from './Home';
import About from './About';
import Jobs from './Jobs';
import FreeTrial from './FreeTrial';
import TermsAndConditions from './TermsAndConditions';
import Location from './Location';
import Login from './Login';
import Contact from './Contact';
import ForgotPassword from './ForgotPassword';
import Dashboard from './Dashboard';
import AllCoursesPage from './AllCoursesPage';

const pages = [
    {
        id: 1,
        component: Home,
        title: 'Home',
    },
    {
        id: 2,
        component: About,
        title: 'About',
    },
    {
        id: 3,
        component: Login,
        title: 'Login',
    },
    {
        id: 4,
        component: ForgotPassword,
        title: 'Forgot password',
    },
    {
        id: 5,
        component: TermsAndConditions,
        title: 'Terms & Conditions',
    },
    {
        id: 6,
        component: Location,
        title: 'Locations',
    },
    {
        id: 8,
        component: Jobs,
        title: 'Jobs',
    },
    {
        id: 12,
        component: FreeTrial,
        title: 'Free trial',
    },
    {
        id: 13,
        component: Dashboard,
        title: 'Dashboard',
    },
    {
        id: 14,
        component: Contact,
        title: 'Contact',
    },
    {
        id: 19,
        component: AllCoursesPage,
        title: 'All Courses Page',
    },
];

const crud = createPageRoutes({ list: List, pages, setup, edit: Form });

const otherRoutes = [];

const routes = [...crud, ...otherRoutes];

export default routes;
