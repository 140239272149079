import React, { useMemo, useEffect, useState } from 'react';
import { EditView } from '@builtbypixel/nucleus';
import setup from './setup';
import DynamicFields from './DynamicFields';
import { Box } from '@chakra-ui/react';
import { useHttp } from '@builtbypixel/nucleus';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

const EditForm = () => {
    const [published, setPublished] = useState(false);
    return (
        <EditView setup={setup} isFullWidth>
            <FieldComponent />
        </EditView>
    );
};

const FieldComponent = () => {
    const [categories, setCategories] = useState([]);
    const [features, setFeatures] = useState([]);
    const [courseTypes, setCourseTypes] = useState([]);
    const [locations, setLocations] = useState([]);
    const [trainers, setTrainers] = useState([]);

    const Http = useHttp();

    useEffect(() => {
        return () => {};
    }, []);

    const fields = useMemo(() => [], []);

    return (
        <Box w="100%">
            <DynamicFields fields={fields} />
        </Box>
    );
};

export default EditForm;
