import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Image as UIImage,
  useDisclosure,
  Flex
} from '@chakra-ui/react'

import { Image, Transformation, Video } from 'cloudinary-react'

const ImagePreview = ({ url, children, public_id, format }) => {

  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <React.Fragment>
      <Flex onClick={onOpen} curosr='pointer'>
        {children}
      </Flex>
      <Modal isCentered size='6xl' isOpen={isOpen} onClose={onClose} style={{ marginTop: "20rem" }}>
        <ModalOverlay />

        <ModalContent style={{ height: '90%' }}>
          <ModalCloseButton />
          <Flex
            w='100%'
            h='100%'
            p='40px'
            overflow='hidden'
            align='center'
            justify='center'
          >
            {public_id ? (
              <>
              {format === 'mp4' 
                ? <Video publicId={public_id} controls={true}>
                    <Transformation height="360" width="480" overlay="text:arial_40:preview" duration="10" />
                  </Video>
                : <Image publicId={public_id} secure='true'>
                    <Transformation width='1000' crop='thumb' fetchFormat='auto' />
                  </Image>
              }
              </>
            ) : (
              <UIImage src={url} boxSize='100%' objectFit='contain' />
            )}
          </Flex>
        </ModalContent>
      </Modal>
    </React.Fragment>
  )
}

export default ImagePreview
