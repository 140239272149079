import React from 'react';
import { Flex, Text, Alert } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { BiUserVoice } from 'react-icons/bi';

import { useParams } from 'react-router';
import { useEffect } from 'react';

const EventCosts = () => {
    const { type } = useParams();
    const data = useWatch(
        'provider',
        'learningItem',
        'venue',
        'trainers',
        'dates',
        'event_type',
        'discount',
        'discount_type',
        'price',
        'availability',
    );

    const totalPPCosts = () => {
        let costs = [];
        if (data.provider) {
            if (data.provider.costs) {
                data.provider.costs.forEach((cost) => {
                    costs.push(cost.price);
                });
            }
        }
        if (data.learningItem) {
            if (data.learningItem.costs) {
                data.learningItem.costs.forEach((cost) => {
                    costs.push(cost.price);
                });
            }
        }

        // sorry matt - ruining your nice code lol
        return costs.length > 0 ? costs.reduce((a, b) => a + b) : 0;
    };

    const getEventDuration = () => {
        var getDay = data?.duration?.slice(0, 2);
        return getDay;
    };

    const totalVenueCosts = () => {
        let costs = [];
        if (data?.venues[0] > 0) {
            data?.venues[0].costs?.forEach((cost) => {
                var check_pay_status =
                    cost.status.name === 'Per Day' ? getEventDuration() : data?.delegate_count;
                costs.push(cost.price * check_pay_status);
            });
        }

        // console.log(parseInt(costs.reduce((a, b) => a + b)));
        // console.log(costs, 'show costs');

        const totalVenueCost = parseInt(costs?.reduce((a, b) => a + b, 0));

        return totalVenueCost;
    };

    const trainerCost = () => {
        let costs = [];
        if (data.dates && data.trainers && data.learningItem?.name !== 'E-Learning') {
            const days =
                type === 'create'
                    ? data.dates.filter((d) => d.switchChecked).length
                    : data.dates.length;

            data.trainers.forEach((t) => {
                costs.push(parseInt(t.price));
            });

            const total = costs.length !== 0 ? costs.reduce((a, b) => a + b) * days : 0;
            return total;
        }

        return 0;
    };

    const discountTotal = () => {
        const type = data.discount_type;

        if (type) {
            // If it's a percentage discount
            if (type && type.id === 1) {
                return (data.discount / 100) * data.price;
            } else {
                return parseInt(data.discount);
            }
        } else {
            return 0;
        }
    };

    const getTotalEventCost = () => {
        const PP = data.provider ? parseInt(totalPPCosts() * data.availability) : 0;
        const trainer = data.trainers ? parseInt(trainerCost()) : 0;
        const venue = data.venues ? parseInt(totalVenueCosts()) : 0;

        if (data.provider && data.availability) {
            return PP + trainer + venue;
        }

        return 0;
    };

    const getMiscCosts = () => {
        let costs = [];
        if (data?.misc_costs?.length > 0) {
            data.misc_costs.forEach((t) => {
                costs.push(parseFloat(t.price));
            });

            const total = costs?.length !== 0 ? costs.reduce((a, b) => a + b) : 0;
            return isNaN(total) ? 0 : total;
        }

        return 0;
    };

    const getProjectedProfit = () => {
        if (totalPPCosts && data.availability) {
            const discount = discountTotal() ? discountTotal() * data.availability : 0;
            const misc_costs = getMiscCosts() ? getMiscCosts() : 0;

            const costs = getTotalEventCost() + discount + misc_costs;

            const total = parseInt(data.availability * data.price - costs);
            const revenue = parseInt(data.availability * data.price);
            const margin = (total / revenue) * 100;
            return { total, margin };
        }
        return { total: 0, margin: 0 };
    };

    return (
        <Flex w="100%" direction="column">
            <Flex w="100%" direction="column" pos="sticky" top="0px">
                {data?.provider && data.availability && (
                    <Section>
                        <SectionTitle>Ancilliary costs</SectionTitle>

                        <>
                            {data?.provider?.costs.map((cost, i) => (
                                <Row
                                    key={`prov-cost-${i}`}
                                    label={cost.type}
                                    value={`£${cost.price.toFixed(2)} pp`}
                                />
                            ))}
                        </>

                        {data?.learningItem && (
                            <>
                                {data?.learningItem?.costs.map((cost, i) => (
                                    <Row
                                        key={`prov-cost-${i}`}
                                        label={cost.type}
                                        value={`£${cost.price.toFixed(2)} pp`}
                                    />
                                ))}
                                <Row
                                    isTotal
                                    p="10px"
                                    label="Total"
                                    value={`£${totalPPCosts().toFixed(2)} pp`}
                                />
                            </>
                        )}
                    </Section>
                )}

                {Array.isArray(data?.venues) && (
                    <Section>
                        <SectionTitle>Venue costs</SectionTitle>

                        <>
                            {data?.venues[0]?.costs?.map((cost, i) => (
                                <Row
                                    key={`prov-cost-${i}`}
                                    label={cost.type}
                                    value={
                                        cost.status?.id === 1
                                            ? `£${cost.price.toFixed(2) * getEventDuration()}`
                                            : `£${cost.price.toFixed(2) * data.delegate_count}`
                                    }
                                />
                            ))}
                        </>
                    </Section>
                )}

                {data?.trainers && (
                    <Section>
                        <SectionTitle>Trainer costs</SectionTitle>

                        <>
                            {data?.trainers?.map((cost, i) => (
                                <Row
                                    key={`prov-cost-${i}`}
                                    label={
                                        <Flex align="center">
                                            <BiUserVoice fontSize="18px" style={{ opacity: 0.7 }} />{' '}
                                            <Text pl="10px" as="span">
                                                {cost.name}
                                            </Text>
                                        </Flex>
                                    }
                                    value={`£${parseInt(cost.price).toFixed(2)} p/day`}
                                />
                            ))}
                            <Row
                                isTotal
                                p="10px"
                                label="Total"
                                value={`£${trainerCost().toFixed(2)}`}
                            />
                        </>
                    </Section>
                )}

                {data?.discount && data.availability && (
                    <Section>
                        <SectionTitle>Discounts</SectionTitle>

                        <>
                            <Row
                                isTotal
                                p="10px"
                                label="Total"
                                value={`- £${discountTotal().toFixed(2)}`}
                            />
                            <Row
                                isTotal
                                bg="orange.100"
                                p="10px"
                                label="Potential Discount Impact"
                                value={`- £${(discountTotal() * data.availability).toFixed(2)}`}
                            />
                        </>
                    </Section>
                )}
                {data?.provider && data.availability && (
                    <Section>
                        <SectionTitle>Current Profit/Loss</SectionTitle>
                        <Row
                            label="Spaces sold"
                            value={data.delegate_count ? `${data.delegate_count}` : 0}
                        />
                        <Row
                            label="Current Revenue"
                            value={data.revenue ? `£${Number(data.revenue).toFixed(2)}` : 0}
                        />
                        <Row
                            label="Current Event Costs"
                            value={
                                data.total_event_cost
                                    ? `- £${Number(data.total_event_cost).toFixed(2)}`
                                    : 0
                            }
                        />
                        <Row
                            label="Current Event Losses"
                            value={data.profit_loss ? `£${Number(data.profit_loss).toFixed(2)}` : 0}
                            bg={
                                !data?.profit_loss
                                    ? 'unset'
                                    : data?.profit_loss?.toString()?.includes('-')
                                    ? 'red.100'
                                    : 'green.100'
                            }
                            isTotal
                        />
                    </Section>
                )}

                {data?.learningItem?.name !== 'E-Learning' && data?.provider && data.availability && (
                    <Section>
                        <SectionTitle>Projection</SectionTitle>
                        <Row
                            label="Revenue"
                            value={`£${parseInt(data.availability * data.price).toFixed(2)}`}
                        />
                        <Row
                            label={`Event Costs`}
                            value={`- £${getTotalEventCost(data.provider).toFixed(2)}`}
                        />
                        <Row
                            label={`Discounts`}
                            value={`- £${(discountTotal() * data.availability).toFixed(2)}`}
                        />
                        <Row label={`Misc Costs`} value={`£${getMiscCosts().toFixed(2)}`} />
                        <Alert>
                            <Text>Projected Profit</Text>
                            <Flex ml="auto" direction="column">
                                <Text textAlign="right" fontWeight="bold" ml="auto" fontSize="16px">
                                    £{getProjectedProfit().total.toFixed(2)}
                                </Text>
                                <Text fontSize="11px" textAlign="right">
                                    {getProjectedProfit().margin.toFixed(2)}%
                                </Text>
                            </Flex>
                        </Alert>
                    </Section>
                )}
            </Flex>
        </Flex>
    );
};

const Row = ({ label, value, isTotal, bg }) => (
    <Flex px="15px" py="5px" align="center" bg={bg}>
        <Text fontSize="12px" color="gray.700" flex={2}>
            {label}
        </Text>
        <Text
            fontWeight={isTotal ? 'bold' : 'normal'}
            fontSize="12px"
            textAlign="right"
            flex={1}
            ml="auto"
        >
            {value}
        </Text>
    </Flex>
);

const Section = ({ children }) => (
    <Flex direction="column" rounded="md" pb="15px" borderBottom="1px" borderColor="gray.200">
        {children}
    </Flex>
);
const SectionTitle = ({ children }) => (
    <Text fontWeight="bold" fontSize="1.1em" px="15px" py="10px">
        {children}
    </Text>
);

export default EventCosts;
