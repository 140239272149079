import React, { useState } from 'react';
import { LinkButton, ListWrapper, useHttp } from '@builtbypixel/nucleus';
import setup from './setup';
import { Button, Flex, Text, useToast } from '@chakra-ui/react';

// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const List = () => {
    const Http = useHttp();
    const toast = useToast();
    const baseUrl = process.env.REACT_APP_LOGIN_AS;

    // for the login as customer
    const [loadingCustomer, setLoadingCustomer] = useState(null);

    const loginAsCustomer = (email) => {
        setLoadingCustomer(email);
        Http.post('login-as-user', { email })
            .then((res) => {
                setLoadingCustomer(false);
                setTimeout(() => {
                    window.open(baseUrl + 'dashboard?loginAs=' + res.data.success.token);
                }, 500);
            })
            .catch(() => {
                setLoadingCustomer(false);
                toast({
                    status: 'error',
                    message: 'Error logging into users account',
                    position: 'top',
                });
            });
    };

    const columns = [
        {
            Header: 'Email',
            accessor: 'email',
            Cell: ({ row }) => (
                <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
                    <Button variant="link" colorScheme="blue" rounded="md">
                        {row.original.email}
                    </Button>
                </LinkButton>
            ),
        },
        {
            Header: 'Name',
            accessor: 'first_name',
            Cell: ({ row }) => (
                <Flex>
                    <Text>{row.original.first_name}</Text>{' '}
                    <Text ml="1ch">{row.original.last_name}</Text>
                </Flex>
            ),
        },
        {
            Header: 'City',
            accessor: 'address.address_city',
        },

        {
            Header: 'Status',
            accessor: 'status.name',
        },

        {
            Header: 'Full Day Price',
            accessor: 'prices',
            Cell: ({ row }) => {
                const fullDayPrice = row.original.prices.find(
                    (price) => price.type.name === 'Full-Day',
                );
                return <Text>£ {fullDayPrice?.price}</Text>;
            },
        },
        // {
        //     Header: 'Half Day Price',
        //     accessor: 'price',
        //     Cell: ({ row }) => {
        //         const fullDayPrice = row.original.prices.find(
        //             (price) => price.type.name === 'Half-Day',
        //         );
        //         return <Text>£ {fullDayPrice?.price}</Text>;
        //     },
        // },
        {
            Header: 'Login As Trainer',
            Cell: ({ row }) => (
                <Button
                    isLoading={loadingCustomer === row.original.email ? true : false}
                    isDisabled={loadingCustomer === row.original.email ? true : false}
                    variant="outline"
                    rounded="md"
                    size="sm"
                    onClick={() => loginAsCustomer(row.original.email)}
                >
                    Login As Trainer
                </Button>
            ),
        },
    ];

    return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
