import { createCrudRoutes } from '@builtbypixel/nucleus';
import List from './List';
import Entry from './';

import setup from './setup';

const crud = createCrudRoutes({
    setup,
    name: setup.title,
    edit: Entry,
    list: List,
    view: null,
    permissions: ['Super Admin', 'Admin'],
});

const otherRoutes = [
    {
        path: '/bookings',
        title: 'All Bookings',
        exact: true,
        auth: true,
        component: List,
    },
    {
        path: '/bookings/:type(create)/:broker',
        title: 'Create New Booking',
        exact: true,
        auth: true,
        component: Entry,
    },
    {
        path: '/bookings/:type(create)/:eventId?/:userId?',
        title: 'Create New Booking',
        exact: true,
        auth: true,
        component: Entry,
    },
    {
        path: '/bookings/:type(edit)/:id',
        title: 'Edit Booking',
        exact: true,
        auth: true,
        component: Entry,
    },
];

const routes = [...otherRoutes];

export default routes;
