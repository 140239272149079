import React, { useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
    Button,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Flex,
    Box,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Badge,
    useToast,
} from '@chakra-ui/react';
import { PopConfirm, Table, LinkButton, Field } from '../../../../Nucleus';
import { TiCancel } from 'react-icons/ti';
import moment from 'moment';
import { useParams } from 'react-router';
import { BsFillDashSquareFill, BsFillPlusSquareFill } from 'react-icons/bs';
import DelegatesCheckbox from './components/DelegatesCheckbox';
import useSWR from 'swr';
import { useGet, useHttp } from '@builtbypixel/nucleus';
import QuantityInput from './components/QuantityInput';

const columns = [
    {
        Header: 'Booking ID',
        id: 'expander',
        accessor: 'id',
        Cell: ({ row }) => (
            <Flex>
                <LinkButton to={`bookings/edit/${row.original.id}`}>
                    <Button variant="link" variantColor="secondary" rounded="md">
                        {row.original.id}
                    </Button>
                </LinkButton>
            </Flex>
        ),
    },
    {
        Header: 'Course',
        accessor: 'courses',
        Cell: ({ row }) => (
            <>
                {row.original.course ? row.original.course.title : 'Not found'}
                <br />
                {row.original.learningItem ? row.original.learningItem.name : 'Not found'}
            </>
        ),
    },
    {
        Header: 'Purchase Date',
        accessor: 'purchase_date',
        Cell: ({ row }) => <>{moment(row.original.created_at).format('DD/MM/YYYY')}</>,
    },
    {
        Header: 'Total Paid',
        accessor: 'amount_paid',
        Cell: ({ row }) => <>{`£${Number(row.original.total).toFixed(2)}`}</>,
    },
    {
        Header: 'Status',
        accessor: 'event_status.name',
    },
    {
        Header: 'Cancellation',
        Cell: ({ row }) => {
            return (
                <>
                    {/* {row.original.delegates_count === 0 ? (
                        <QuantityInput row={row} />
                    ) : ( */}
                    <Box {...row.getToggleRowExpandedProps()} mr="1em">
                        {/* {row.original.delegates_count > 0 && ( */}
                        <Flex>
                            {row.isExpanded ? (
                                <>
                                    <BsFillDashSquareFill
                                        style={{
                                            height: '1.2em',
                                            width: '1.2em',
                                        }}
                                    />
                                    <Text ml="0.5em">Select Delegates</Text>
                                </>
                            ) : (
                                <>
                                    <BsFillPlusSquareFill
                                        style={{
                                            height: '1.2em',
                                            width: '1.2em',
                                        }}
                                    />
                                    <Text ml="0.5em">Select Delegates</Text>
                                </>
                            )}
                        </Flex>
                        {/* )} */}
                    </Box>
                    {/* )} */}
                </>
            );
        },
    },
];

const renderRowSubComponent = ({ row }) => {
    let eventId = row.original.id;

    const columns = [
        {
            Header: 'First Name',
            accessor: 'first_name',
            Cell: ({ row }) => (
                <>
                    {row.original.first_name === '' ? (
                        <Text>N/A</Text>
                    ) : (
                        <Text>{row.original.first_name}</Text>
                    )}
                </>
            ),
        },
        {
            Header: 'Last Name',
            accessor: 'last_name',
            Cell: ({ row }) => (
                <>
                    {row.original.last_name === '' ? (
                        <Text>N/A</Text>
                    ) : (
                        <Text>{row.original.last_name}</Text>
                    )}
                </>
            ),
        },
        {
            Header: 'Email',
            Cell: ({ row }) => (
                <>
                    {row.original.email === '' ? (
                        <Text>N/A</Text>
                    ) : (
                        <Text>{row.original.email}</Text>
                    )}
                </>
            ),
        },
        {
            Header: 'Delegate Select',
            Cell: ({ row }) => (
                <Flex>
                    <DelegatesCheckbox
                        eventId={eventId}
                        delegate={row.original}
                        quantity={row.original.quantity}
                    />
                    {/* {row?.original?.cancellations?.length === 0
                        ? <DelegatesCheckbox eventId={eventId} delegate={row.original} />
                        : <Badge colorScheme={'red'}>Cancelled</Badge>
                    } */}
                </Flex>
            ),
        },
    ];

    return (
        <div
            style={
                row.isExpanded && {
                    background: '#bedaf6',
                    transform: 'scale(1)',
                    // marginLeft: '2em',
                }
            }
        >
            <Table
                columns={columns}
                data={{
                    data: row.original.delegates.filter((del) => {
                        if (del?.cancellations?.length === 0) {
                            return del;
                        } else if (!del?.cancellations) {
                            return del;
                        }
                    }),
                }}
                // data={{ data: row.original.delegates }}
                hidePagination={true}
                canSelect={false}
            ></Table>
        </div>
    );
};

const CancelBooking = () => {
    const { submit, setValue, getValues } = useFormContext();
    const { id } = useParams();

    const cancelBooking = () => {
        // setValue('status', { id: 6, name: 'Cancelled' });
        handleSubmit();
    };
    let eventsWatch = getValues('events');

    const Http = useHttp();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [activePage, setActivePage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [selectedRefund, setSelectedRefund] = useState(null);
    const [refundAmount, setRefundAmount] = useState(0);
    const [refundTotalWithVAT, setRefundTotalVAT] = useState(0);
    const [refundTotalNoVAT, setRefundTotalNoVAT] = useState(0);
    const [originalValue, setOriginalValue] = useState(0);
    const [refundReferenceText, setRefundReferenceText] = useState('');
    const [success, setSuccess] = useState(null);
    const [checked, setChecked] = useState(null);
    const [exclude_emails, setExclude_emails] = useState(false);

    const { data: cancelStatuses } = useSWR(`/cancellation-status`, useGet);
    const { data: booking } = useSWR(`/bookings/${id}`, useGet);
    const toast = useToast();

    const handleSubmit = () => {
        setLoading(true);
        let payload = getValues('cancel');
        payload.events = payload.events.filter((e) => e.selected);
        payload.type = selectedRefund;
        payload.exclude_emails = exclude_emails;
        payload.nucleus = true;
        payload.refund_reference = getValues('refund_reference');
        payload.refundAmount =
            originalValue === refundTotalNoVAT ? originalValue : refundTotalNoVAT;

        Http.post(`/cancel-booking`, payload)
            .then((res) => {
                setLoading(false);
                onClose();
                setActivePage(1);
                setSuccess(true);
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            })
            .catch((err) => {
                setLoading(false);
                toast({
                    status: 'error',
                    message: err.message,
                });
            });
    };

    useEffect(() => {
        if (activePage === 2) {
            let payload = getValues('cancel');
            Http.post('/refund-amount', payload).then((res) => {
                setOriginalValue(res.data.data.totalWithVat);
                setRefundAmount(res.data.data.totalNoVat);
                setRefundTotalVAT(res.data.data.totalWithVat);
                setRefundTotalNoVAT(res.data.data.totalNoVat);
            });
        }
    }, [activePage]);

    useEffect(() => {
        setValue('cancel', {
            booking: { id },
            events: eventsWatch.map((event, index) => {
                return { id: event.id, delegates: [], index, selected: false };
            }),
        });
    }, [eventsWatch]);
    const handleChangeExclude = () => {
        setExclude_emails(!exclude_emails);
    };
    return (
        <>
            <Button
                ml="20px"
                leftIcon={<TiCancel fontSize="20px" />}
                variant="link"
                colorScheme="red"
                mr="20px"
                onClick={onOpen}
            >
                Cancel Booking
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} size="6xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Cancellations</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {activePage === 1 && eventsWatch ? (
                            <Table
                                columns={columns}
                                data={{ data: eventsWatch }}
                                renderRowSubComponent={renderRowSubComponent}
                                canSelect={false}
                                hidePagination={true}
                                hideHeaders={['Quantity']}
                            ></Table>
                        ) : activePage === 1 ? (
                            <Text>NO BOOKINGS FOUND</Text>
                        ) : null}
                        {activePage === 2 && (
                            <Flex direction="column">
                                <Text mb="0.2em">Select Refund Type</Text>
                                <Field
                                    component="select"
                                    name="cancel.type"
                                    options={cancelStatuses?.data || []}
                                    valueKey="id"
                                    labelKey="name"
                                    onChange={(val) => setSelectedRefund(val)}
                                    value={selectedRefund}
                                    required={true}
                                />
                                <Flex direction="column">
                                    <Text mb="0.2em">Refund/Credit Amount (Excluding VAT)</Text>
                                    <NumberInput
                                        precision={2}
                                        onChange={(valueString) => setRefundTotalNoVAT(valueString)}
                                        value={refundTotalNoVAT}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>
                                </Flex>
                                <Box mt={8}>
                                    <Text mb="0.2em">
                                        Enter Additional Information Here (Optional)
                                    </Text>
                                    <Field
                                        name="refund_reference"
                                        component="textarea"
                                        placeholder="Enter here..."
                                    />
                                </Box>
                                <Box mt={8}>
                                    <Text mb="0.2em">Do not send email confirmation</Text>
                                    <Field
                                        onChange={() => handleChangeExclude()}
                                        name="exclude_emails"
                                        component="switch"
                                        placeholder="Enter here..."
                                        value={exclude_emails}
                                    />
                                </Box>
                            </Flex>
                        )}
                    </ModalBody>

                    <ModalFooter>
                        {activePage > 1 && (
                            <Button
                                colorScheme="blue"
                                mr={3}
                                onClick={() => setActivePage(activePage - 1)}
                            >
                                Back
                            </Button>
                        )}
                        {activePage === 1 ? (
                            <Button
                                colorScheme="green"
                                mr={3}
                                onClick={() => setActivePage(activePage + 1)}
                                isLoading={loading}
                                isDisabled={
                                    activePage === 1
                                        ? eventsWatch?.length === 0
                                            ? true
                                            : false
                                        : selectedRefund === null
                                }
                            >
                                Continue
                            </Button>
                        ) : (
                            <PopConfirm
                                onConfirm={() => cancelBooking()}
                                title="Are you sure you want to cancel this booking?"
                            >
                                <Button
                                    isLoading={loading}
                                    disabled={!selectedRefund}
                                    colorScheme="red"
                                    mr="20px"
                                >
                                    Cancel Booking
                                </Button>
                            </PopConfirm>
                        )}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CancelBooking;
