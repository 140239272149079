import { Field } from '@builtbypixel/nucleus';

import React from 'react';
import FormSection from '../../../components/FormSection';

const Content = () => {
    return (
        <>
            <FormSection title="Location Content" mt="0px">
                <Field
                    name="location_content.box_1.title"
                    component="text"
                    label="Box 1 Title"
                    placeholder="Box 1 Title"
                    rules={{ required: true }}
                />
                <Field
                    name="location_content.box_1.description"
                    component="richtext"
                    label="Box 1 Description"
                    placeholder="Box 1 Description"
                    rules={{ required: true }}
                />
                 <Field
                    name="location_content.box_2.title"
                    component="text"
                    label="Box 2 Title"
                    placeholder="Box 2 Title"
                    rules={{ required: true }}
                />
                <Field
                    name="location_content.box_2.description"
                    component="richtext"
                    label="Box 2 Description"
                    placeholder="Box 2 Description"
                    rules={{ required: true }}
                />
                <Field
                    name="location_content.box_3.title"
                    component="text"
                    label="Box 3 Title"
                    placeholder="Box 3 Title"
                    rules={{ required: true }}
                />
                <Field
                    name="location_content.box_3.description"
                    component="richtext"
                    label="Box 3 Description"
                    placeholder="Box 3 Description"
                    rules={{ required: true }}
                />
                
            </FormSection>
        </>
    );
};

export default Content;
