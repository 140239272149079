import React, { useState, useEffect } from 'react';
import Field from './Field';
import { useQuery } from '@builtbypixel/nucleus';
import { Text, Flex, Container, Button, Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import groupBy from 'underscore/modules/groupBy.js';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import ConditionalField from '../../Fields/ConditionalField';

const AutoForm = (props) => {
    let query = useQuery();

    const formOnly = query.get('popup');
    const { type } = useParams();
    const { fields, isFullWidth } = props;
    const [_tabs, setTabs] = useState(null);
    const [menu, showMenu] = useState(false);

    const [display, setDisplay] = useState(0);

    useEffect(() => {
        if (fields) {
            setTabs(groupBy(fields, 'tab'));
        }
    }, [fields]);

    const showField = (field) => {
        if (field.editOnly === undefined && field.createOnly === undefined) {
            return true;
        }
        if (field.editOnly && type === 'edit') {
            return true;
        }
        if (field.createOnly && type === 'create') {
            return true;
        }
        return false;
    };

    return (
        <React.Fragment>
            {_tabs && Object.keys(_tabs)[0] !== 'undefined' ? (
                <Flex overflowX="hidden" w="100%">
                    {_tabs && (
                        <Flex
                            w="100%"
                            justify="flex-start"
                            direction={{ base: 'column', md: 'row' }}
                            maxWidth={
                                formOnly ? '100%' : { base: 'none', xl: 'calc(100vw - 260px)' }
                            }
                        >
                            <Flex p="10px" w="100%" display={{ base: 'block', md: 'none' }}>
                                <Button
                                    isFullWidth
                                    onClick={() => showMenu(!menu)}
                                    rightIcon={
                                        menu ? (
                                            <MdKeyboardArrowUp fontSize="20px" />
                                        ) : (
                                            <MdKeyboardArrowDown fontSize="20px" />
                                        )
                                    }
                                >
                                    Menu
                                </Button>
                            </Flex>
                            <Flex
                                direction="column"
                                w="100%"
                                maxWidth={{ base: '100%', sm: '100%', md: '200px' }}
                                h="100%"
                                minHeight="100vh"
                                overflow="hidden"
                                flexShrink={0}
                                flex={1}
                                bg="white"
                                borderRight="1px"
                                borderTop="1px"
                                borderColor="gray.200"
                            >
                                {Object.keys(_tabs).map((t, i) => (
                                    <Box
                                        p="5px"
                                        w="100%"
                                        borderBottom="1px"
                                        borderColor="gray.200"
                                        key={`form-tab-${i}`}
                                    >
                                        <Box
                                            as="button"
                                            justify="flex-start"
                                            textAlign="left"
                                            w="100%"
                                            fontWeight="semibold"
                                            rounded="5px"
                                            py="6px"
                                            px="8px"
                                            opacity={display === i ? 1 : 0.7}
                                            onClick={() => setDisplay(i)}
                                            bg={display === i ? 'blackAlpha.200' : 'transparent'}
                                            color="gray.900"
                                            fontSize="13px"
                                            _hover={{ bg: display !== i && 'blackAlpha.200' }}
                                        >
                                            {t}
                                        </Box>
                                    </Box>
                                ))}
                            </Flex>

                            {Object.keys(_tabs).map(
                                (t, i) =>
                                    display === i && (
                                        <Flex
                                            direction="column"
                                            align="flex-start"
                                            display={display === i ? 'flex' : 'none'}
                                            key={`form-tabpanel-${i}`}
                                            w={{
                                                base: formOnly
                                                    ? 'calc(100vw - 200px)'
                                                    : 'calc(100vw - 200px)',

                                                xl: formOnly
                                                    ? 'calc(100vw - 200px)'
                                                    : 'calc(100vw - 460px)',
                                            }}
                                            maxWidth={{
                                                base: formOnly
                                                    ? 'calc(100vw - 200px)'
                                                    : 'calc(100vw - 200px)',
                                                xl: formOnly
                                                    ? 'calc(100vw - 200px)'
                                                    : 'calc(100vw - 460px)',
                                            }}
                                            bg="white"
                                        >
                                            {Object.values(_tabs)[i].map((fGroup, idx) => (
                                                <Container
                                                    key={`card=${i}-${idx}`}
                                                    p={{ base: 2, sm: 3, md: 5 }}
                                                    borderTop="1px"
                                                    borderColor="gray.200"
                                                    w="100%"
                                                    maxWidth={isFullWidth ? 'none' : '700px'}
                                                    variant="card"
                                                    rounded="0px"
                                                    mx="0px"
                                                >
                                                    {fGroup.group && (
                                                        <Text mb={5} fontSize={20} id={`test-${i}`}>
                                                            {fGroup.group}
                                                        </Text>
                                                    )}

                                                    {fGroup.component
                                                        ? fGroup.component
                                                        : fGroup.fields.map((field, ix) => {
                                                              if (showField(field)) {
                                                                  return field.condition ? (
                                                                      <ConditionalField
                                                                          key={`field-${ix}`}
                                                                          {...field}
                                                                          index={ix}
                                                                          showField={showField(
                                                                              field,
                                                                          )}
                                                                      />
                                                                  ) : (
                                                                      <Field
                                                                          key={`field-${ix}`}
                                                                          {...field}
                                                                          showField={showField(
                                                                              field,
                                                                          )}
                                                                      />
                                                                  );
                                                              } else {
                                                                  return null;
                                                              }
                                                          })}
                                                </Container>
                                            ))}
                                        </Flex>
                                    ),
                            )}
                        </Flex>
                    )}
                </Flex>
            ) : (
                fields &&
                fields.map((group, index) => (
                    <Flex
                        p="0px"
                        w="100%"
                        minHeight="100vh"
                        justify="flex-start"
                        key={`field-${index}`}
                    >
                        <Container
                            p={{ base: '10px', sm: '15px' }}
                            w="100%"
                            maxWidth="none"
                            variant="card"
                            rounded="none"
                        >
                            <Flex direction="column" maxWidth={isFullWidth ? '100%' : '600px'}>
                                {group.group && (
                                    <Text mb={5} fontSize={20} id={`test-${index}`}>
                                        {group.group}
                                    </Text>
                                )}

                                {group.fields.map((field, i) => {
                                    if (showField(field)) {
                                        return field.condition ? (
                                            <ConditionalField
                                                key={`field-${i}`}
                                                {...field}
                                                index={i}
                                            />
                                        ) : (
                                            <Field key={`field-${i}`} {...field} index={i} auto />
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </Flex>
                        </Container>
                    </Flex>
                ))
            )}
        </React.Fragment>
    );
};

export default AutoForm;
