import {
    Grid,
    Modal,
    Box,
    ModalBody,
    ModalOverlay,
    ModalCloseButton,
    ModalContent,
    Button,
    useDisclosure,
    Flex,
    Form,
    Alert,
    AlertIcon,
    ModalFooter,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { BiCloudUpload, BiDownload } from 'react-icons/bi';
import useSWR from 'swr';
import { useGet, Field, useHttp } from '@builtbypixel/nucleus';
import { useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';

const BulkUploadModal = ({ event }) => {
    const Http = useHttp();

    const { isOpen, onClose, onOpen } = useDisclosure();

    const files = useWatch({ name: 'files' });

    const [loading, setLoading] = useState(null);
    const [success, setSucess] = useState(null);
    const [error, setError] = useState(null);

    const type = event ? 'event' : 'course';

    const { data: courseTemplate } = useSWR(`/bulk-upload-${type}-template`, useGet);

    const downloadTemplate = () => {
        return fetch(courseTemplate.data.url).then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = event ? 'EventTemplate.xlsx' : 'CourseTemplate.xlsx';
                a.click();
            });
        });
    };

    const uploadTemplate = () => {
        setLoading(true);
        event
            ? Http.post(`/bulk-upload-event`, {
                  event: files,
              })
                  .then((res) => {
                      setSucess(true);
                      setError(false);
                      setLoading(false);
                  })
                  .catch((err) => {
                      setError(true);
                      setSucess(false);
                      setLoading(false);
                  })
            : Http.post(`/bulk-upload-course`, {
                  course: files,
              })
                  .then((res) => {
                      setSucess(true);
                      setError(false);
                      setLoading(false);
                  })
                  .catch((err) => {
                      setError(true);
                      setSucess(false);
                      setLoading(false);
                  });
    };

    return (
        <>
            <Button leftIcon={<BiCloudUpload />} onClick={onOpen}>
                {event ? 'Bulk Upload Events' : 'Bulk Upload Courses'}
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} isCentered size="5xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody p="0">
                        <Grid templateColumns="repeat(2, 1fr)" gap={1}>
                            <Flex
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                w="300px"
                                bg="lightgray"
                            >
                                <BiDownload fontSize={'24px'} />
                                {event ? (
                                    <Link
                                        to="/files/EventTemplate.xlsx"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download
                                    >
                                        Download Event Template
                                    </Link>
                                ) : (
                                    <Link
                                        to="/files/CourseTemplate.xlsx"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download
                                    >
                                        Download Course Template
                                    </Link>
                                )}

                                {/* <Button leftIcon={<BiDownload />} bg='transparent' onClick={downloadTemplate}>Download {event ? 'Event' : 'Course'} Template</Button> */}
                            </Flex>
                            <Box
                                mt={5}
                                display="flex"
                                flexDirection="column"
                                alignItems="left"
                                justifyContent="center"
                                bg="transparent"
                            >
                                <Field component="standardfile" name="files" max={1} />
                                <Alert
                                    width={'90%'}
                                    mb={5}
                                    title="Upload Succesful"
                                    status="warning"
                                    hasIcon={true}
                                >
                                    <AlertIcon />
                                    Only upload a file that is in .xlsx format
                                </Alert>
                                <Button
                                    mb={5}
                                    leftIcon={<BiCloudUpload />}
                                    type="submit"
                                    width={200}
                                    onClick={() => uploadTemplate()}
                                >
                                    {loading
                                        ? 'Uploading...'
                                        : event
                                        ? 'Upload Events'
                                        : 'Upload Courses'}
                                </Button>
                            </Box>
                        </Grid>
                    </ModalBody>
                    {success && (
                        <Alert mt={5} title="Upload Succesful" status="success" hasIcon={true}>
                            <AlertIcon />
                            {event ? 'Events' : 'Courses'} have been uploaded succesfully
                        </Alert>
                    )}
                    {error && (
                        <Alert mt={5} title="Error" status="error" hasIcon={true}>
                            <AlertIcon />
                            Error uploading {event ? 'events' : 'courses'}
                        </Alert>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
};

export default BulkUploadModal;
