const modelName = 'Job Applications';
const modelNameSingular = 'Job Application';
const endpoint = '/job-applications';

const setup = {
    title: modelName,
    singular: modelNameSingular,
    model: modelName.replace(/\s+/g, '-').toLowerCase(),
    canDelete: false,
    canEdit: false,
    canAddNew: false,
    canSearch: true,
    endpoint,
    accessor: 'id',
};

export default setup;
