import { Field } from '@builtbypixel/nucleus';
import React from 'react';
import useSWR from 'swr';
import FormSection from '../../../../components/FormSection';
import { useGet } from '../../../../Nucleus';

const PricingCreate = () => {
    const { data } = useSWR(`/price-types`, useGet);

    return (
        <FormSection title="Trainer Pricing">
            <Field
                name="prices"
                component="repeater"
                placeholder="Prices"
                label="Prices"
                fieldArray={[
                    {
                        name: 'type',
                        label: 'name',
                        component: 'select',
                        options: data ? data.data : [],
                        labelKey: 'name',
                        valueKey: 'id',
                        placeholder: 'name',
                    },
                    {
                        name: 'price',
                        component: 'text',
                        type: 'number',
                        placeholder: 'price',
                        label: 'price',
                    },
                ]}
            />
        </FormSection>
    );
};

export default PricingCreate;
