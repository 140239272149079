import { AutoFields } from '@builtbypixel/nucleus';
import React from 'react';


const DynamicFields = ({ fields }) => {

    return (
        <AutoFields
            isFullWidth
            auto={false}
            fields={[
                ...fields,
               
            ]}
      
        />
    );
};

export default DynamicFields;
