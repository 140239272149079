import React from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';
import { Button } from '@chakra-ui/react';
import setup from './setup';
import { saveAs } from 'file-saver';

// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const List = () => {
    const columns = [
        {
            Header: 'Order',
            accessor: 'order',
        },
        {
            Header: () => null,
            id: 'edit',
            Cell: ({ row }) => (
                <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
                    <Button variant="link" colorScheme="blue" rounded="md">
                        {row.original.title}
                    </Button>
                </LinkButton>
            ),
        },
        {
            Header: 'Category',
            accessor: 'category.name',
        },
        {
            Header: 'File',
            accessor: 'file',
            Cell: ({ value }) => (
                <Button
                    colorScheme="blue"
                    onClick={() => saveAs(value[0]?.secure_url, value[0]?.original_filename)}
                >
                    Download
                </Button>
            ),
        },
    ];

    return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
