import { Button, IconButton, useToast } from '@chakra-ui/react';
import React from 'react';
import { FaFileInvoice } from 'react-icons/fa';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const GeneratePDF = ({ title, data, columns }) => {
    const toast = useToast();

    let _data = null;
    if (Array.isArray(data)) _data = data;
    else {
        _data = data?.periods?.length ? data.periods : data?.courses?.length ? data.courses : [];
    }

    const downloadPDF = () => {
        if (_data?.length > 0 && columns.length > 0) {
            const doc = new jsPDF('p', 'mm', 'a4');
            doc.text(title, 10, 10);
            autoTable(doc, {
                head: [columns.map((col) => col.Header)],
                body: _data
                    .map((row) => Object.values(row))
                    .map((item) =>
                        item.map((val) => (typeof val === 'number' ? Math.round(val) : val)),
                    ),
            });

            doc.save(
                `${new Date().toLocaleDateString('en-GB')}_${title.replaceAll(' ', '_')}}.pdf`,
            );
        } else
            toast({
                title: `Error Exporting Data`,
                description: 'No data available to export',
                status: 'error',
            });
    };

    return (
        <Button variant="primary" onClick={() => downloadPDF()}>
            <IconButton
                size="sm"
                variant="link"
                color="white"
                icon={<FaFileInvoice fontSize="18px" />}
            />
            Export to PDF
        </Button>
    );
};

export default GeneratePDF;
