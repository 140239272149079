import React, { useState, useEffect } from 'react';
import {
    IconButton,
    Modal,
    ModalHeader,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Image,
    Flex,
    Box,
    Text,
    Badge,
} from '@chakra-ui/react';
import { PopConfirm } from '../../../../Nucleus';
import { FiEye } from 'react-icons/fi';
import { AiFillIdcard, AiFillFileText } from 'react-icons/ai';
import { GoCloudUpload, GoTrashcan } from 'react-icons/go';
import { useHttp } from '@builtbypixel/nucleus';
import IdUploader from './IdUploader';
import Slider from '../../../../components/Slider';
const ApproveDocumentsModal = ({
    user,
    uploadTypes,
    arr,
    setArr,
    id,
    index,
    onSuccess,
    idTypes,
    docType,
}) => {
    const [success, setSuccess] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [currentSlide, setCurrentSlide] = useState(0);

    const {
        isOpen: isUploaderOpen,
        onOpen: onUploaderOpen,
        onClose: onUploaderClose,
    } = useDisclosure();

    const [acceptStatus, setAcceptStatus] = useState(false);
    const Http = useHttp();
    //reset modal values on close
    useEffect(() => {
        if (isOpen) {
            setSuccess(false);
        }
    }, [isOpen]);

    const handleDelete = () => {
        const uploadId = user?.[docType][currentSlide]?.id;

        if(uploadId != 'undefined' || uploadId != null)
        {
            Http.delete(`/user-uploads-single/${uploadId}`).then((res) => {
                onSuccess();
                setSuccess(true);
            });
        }
    }

    const handleSubmit = (type, id) => {

        const returnObj = uploadTypes.find((el) => el.name === type);
        Http.post(`/approve-reject-document/${id}`, {
            status: returnObj,
        }).then((res) => {
            if (type === 'Approved') {
                setAcceptStatus(true);
            } else {
                setAcceptStatus(false);
            }
            onSuccess();
            setSuccess(true);
        });
    };
    const isImage = (url) => {
        return /\.(jpg|jpeg|png|webp|avif|gif|svg|heic)$/.test(url);
    };
    const rejectedIDs = user?.[docType]?.filter((el) => el.document_status.name === 'Rejected');
    const acceptedIDs = user?.[docType]?.filter((el) => el.document_status.name === 'Approved');

    return (
        <>
            {user?.[docType]?.length > 0 ? (
                docType === 'photo_uploads' ? 
                <AiFillIdcard
                    onClick={onOpen}
                    fontSize="24px"
                    style={{
                        padding: '0',
                        marginRight: '10px',
                        cursor: user?.[docType].length > 0 && 'pointer',
                    }}
                    fill={
                        rejectedIDs.length > 0
                            ? 'red'
                            : acceptedIDs.length > 0
                            ? 'green'
                            : '#ff5e00'
                    }
                /> : 
                <AiFillFileText
                    onClick={onOpen}
                    fontSize="24px"
                    style={{
                        padding: '0',
                        marginRight: '10px',
                        cursor: user?.[docType].length > 0 && 'pointer',
                    }}
                    fill={
                        acceptedIDs.length > 0
                            ? 'green'
                            : rejectedIDs.length > 0
                            ? 'red'
                            : '#ff5e00'
                    }
                />
            ) : (
                <GoCloudUpload
                    fontSize="18px"
                    style={{
                        padding: '0',
                        marginRight: '10px',
                    }}
                    cursor="pointer"
                    onClick={onUploaderOpen}
                />
            )}
            <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg" scrollBehavior="inside">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Flex alignItems="center">
                            <Text mr="15px">{user?.first_name + ' ' + user?.last_name} </Text>{' '}
                            <GoCloudUpload
                                fontSize="18px"
                                style={{
                                    padding: '0',
                                    marginRight: '10px',
                                }}
                                cursor="pointer"
                                onClick={onUploaderOpen}
                            />
                            <PopConfirm
                                onConfirm={() => handleDelete()}
                                title="Are you sure you want to delete this upload?"
                            >
                                <GoTrashcan
                                        fontSize="18px"
                                        style={{
                                            padding: '0',
                                            marginRight: '10px',
                                        }}
                                        cursor="pointer"
                                    />
                            </PopConfirm>
                        </Flex>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {user?.[docType]?.length > 0 && (
                            <Slider currentSlide={currentSlide} setCurrentSlide={setCurrentSlide}>
                                {user?.[docType]
                                    .slice(0)
                                    .reverse()
                                    .map((doc, i) => {
                                    return (
                                        <Box
                                            key={`slider-key-${i}`}
                                            className="keen-slider__slide"
                                            align="center"
                                            alignItems="center"
                                            justifyContent="center"
                                            alignContent="center"
                                            padding="5px"
                                        >
                                            {isImage(doc.document?.[0]?.url) ? (
                                                <Image
                                                    src={doc.document?.[0]?.url}
                                                    alt={user?.first_name + ' ' + user?.last_name}
                                                />
                                            ) : (
                                                <UploadObject doc={doc} uploadTypes={idTypes} />
                                            )}

                                            <Flex justify={'space-between'}>
                                                <Button
                                                    variant="primary"
                                                    mt="10px"
                                                    ml="0"
                                                    textAlign="right"
                                                    onClick={() => handleSubmit('Rejected', doc.id)}
                                                    // isLoading={loading}
                                                    // isDisabled={loading}
                                                >
                                                    Reject
                                                </Button>
                                                <Button
                                                    bg="green.300"
                                                    color="white"
                                                    mt="10px"
                                                    ml="0"
                                                    textAlign="right"
                                                    onClick={() => handleSubmit('Approved', doc.id)}

                                                    // isLoading={loading}
                                                    // isDisabled={loading}
                                                >
                                                    Approve
                                                </Button>
                                            </Flex>
                                        </Box>
                                    )})};
                            </Slider>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>

            <IdUploader
                isOpen={isUploaderOpen}
                onOpen={onUploaderOpen}
                onClose={onUploaderClose}
                user={user}
                arr={arr}
                setArr={setArr}
                id={id}
                index={index}
                onSuccess={onSuccess}
                idTypes={idTypes}
            />
        </>
    );
};

const UploadObject = ({ doc, uploadTypes }) => {
    const [fileTypeName, setFileTypeName] = useState(null);
    useEffect(() => {
        if (doc && uploadTypes.length > 0) {
            const correctType = uploadTypes.find((el) => el.id === doc.type_id);
            setFileTypeName(correctType.name);
        }
    }, [uploadTypes, doc]);

    return (
        <a target="_blank" href={`${doc.document?.url}`} rel="noreferrer">
            {fileTypeName}
            <AiFillFileText style={{ marginLeft: '5px' }} fontSize="36px" />
        </a>
    );
};
export default ApproveDocumentsModal;
