import { Field, useHttp } from '@builtbypixel/nucleus';
import { Grid, Stack } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import FormSection from '../../../components/FormSection';
import useSWR from 'swr';
import { useGet } from '../../../Nucleus';

const SubProviders = () => {
    const Http = useHttp();
    const [providers, setProviders] = useState([]);

    useEffect(() => {
        Http.get('/providers?simple=true&pagination=999&page=1').then((res) => {
            setProviders(res.data.data);
        });
    }, [setProviders]);

    return (
        <>
            <FormSection title="Sub Providers" mt="0px">
                <Grid gap="15px" templateColumns="1fr 1fr">
                    <Grid gap="15px" templateColumns="1fr">
                        <Stack spacing="10px" w="100%">
                            <Field
                                name="sub_providers"
                                component="repeater"
                                layout="vertical"
                                placeholder="Sub Providers"
                                fieldArray={[
                                    {
                                        name: 'provider',
                                        component: 'select',
                                        options: providers,
                                        labelKey: 'name',
                                        valueKey: 'id',
                                        label: 'Provider',
                                        placeholder: 'Provider',
                                        isRequired: true,
                                        rules: { required: true },
                                    },
                                    {
                                        name: 'provider_title',
                                        component: 'text',
                                        label: 'Provider Course Title',
                                        help: 'If the selected sub provider is the same as the main provider for the course then please leave this field blank',
                                        placeholder: 'Provider Course Title',
                                    },
                                    {
                                        name: 'provider_summary.description',
                                        component: 'richtext',
                                        label: 'Provider Course Summary',
                                        help: 'If the selected sub provider is the same as the main provider for the course then please leave this field blank',
                                        placeholder: 'Provider Course Summary',
                                    },
                                    {
                                        name: 'bundle_title',
                                        component: 'text',
                                        label: 'Provider Bundle Title',
                                        placeholder: 'Provider Bundle Title',
                                    },
                                    {
                                        name: 'bundle_summary.description',
                                        component: 'richtext',
                                        label: 'Provider Bundle Summary',
                                        placeholder: 'Provider Bundle Summary',
                                    },
                                ]}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </FormSection>
        </>
    );
};

export default SubProviders;
