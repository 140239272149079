import React, {useEffect, useState} from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';
import { IconButton, Switch } from '@chakra-ui/react';
import setup from './setup';
import { Button } from '@chakra-ui/react';
import { useHttp } from '@builtbypixel/nucleus';

// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const List = () => {
    const Http = useHttp()
    const [statuses, setStatuses] = useState([])
    const [types, setTypes] = useState([])
    useEffect(() => {
        Http.get("/enquiry-statuses").then(res => {
            setStatuses(res.data.data)
        })
        Http.get("/enquiry-types").then(res => {
            setTypes(res.data.data)
        })
    }, [])

    const columns = [
        {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ row }) => (
                <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
                    <Button variant="link" colorScheme="blue" rounded="md">
                        {row.original.name}
                    </Button>
                </LinkButton>
            ),
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'Type',
            accessor: 'enquiry_type.name',
        },
        {
            Header: 'Status',
            accessor: 'status.name',
        },
    ];
    const filters = [
        {
            component: 'select',
            label: 'Status',
            name: 'status',
            placeholder: 'Select Status',
            options: statuses,
            labelKey: 'name',
            valueKey: 'id',
        },
        {
            component: "select",
            label: "Type",
            name: "type",
            placeholder: "Select Type",
            options: types,
            labelKey: "name",
            valueKey: "id"
        }
    ];

    return <ListWrapper filters={filters} setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
