import React, { useMemo } from 'react';
import { EditView, AutoFields } from '@builtbypixel/nucleus';
import setup from '../setup';
import * as yup from 'yup';

const EditForm = () => {
    const useValidation = () => {
        return yup.object().shape({
            content: yup.object().shape({
                content: yup
                    .array()
                    .min(1)
                    .of(
                        yup.object().shape({
                            title: yup.object().shape({
                                text: yup.string().required('Please provide text'),
                                icon: yup.mixed().required('Please provide an image'),
                            }),
                        }),
                    ),
                about: yup.object().shape({
                    title: yup.object().shape({
                        text: yup.string().required('Please provide a title'),
                    }),
                    // videoUrl: yup.string().required('Please provide a video URL'),
                    // image: yup.required()
                }),
            }),
            // provider_documents: yup.array().min(1),
            // requirements: yup.array().min(1),
        });
    };

    const fields = useMemo(
        () => [
            {
                group: 'About',

                fields: [
                    {
                        name: 'title',
                        component: 'text',
                        placeholder: 'Title',
                        label: 'Title',
                    },
                    {
                        name: 'content.about.videoUrl',
                        component: 'text',
                        placeholder: 'Video Url',
                        label: 'Video Url (Embedded YouTube video only)',
                    },
                    {
                        name: 'content.about.image',
                        component: 'file',
                        placeholder: 'Image',
                        label: 'Image',
                        max: 1
                    },
                    {
                        name: 'content.about.content',
                        component: 'repeater',
                        placeholder: 'Sections',
                        label: 'Sections',
                        defaultValue: [],
                        fieldArray: [
                            {
                                name: 'title.text',
                                component: 'text',
                                placeholder: 'Title',
                                label: 'Title',
                            },
                            {
                                name: 'title.icon',
                                component: 'file',
                                label: 'Title Icon',
                            },
                            {
                                name: 'imageUrl',
                                component: 'file',
                                label: 'Image',
                            },
                            {
                                name: 'paragraph.text',
                                component: 'richtext',
                                placeholder: 'Description',
                                label: 'Description',
                            },
                        ],
                    },
                ],
            },
        ],
        [],
    );

    return (
        <EditView setup={setup} validationSchema={useValidation()}>
            <AutoFields fields={fields} />
        </EditView>
    );
};

export default EditForm;
