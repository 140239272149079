import React, { useEffect, useState } from 'react';
import { Field } from '@builtbypixel/nucleus';
import InputMask from 'react-input-mask';
import { Box, Flex, Icon, Image, Text, Input, Stack } from '@chakra-ui/react';
import { FiLock } from 'react-icons/fi';

import { useWatch } from 'react-hook-form';

const MaskedInput = ({
    value,
    onChange,
    mask,
    label,
    name,
    rules,
    placeholder,
    card,
    isCreditCard,
}) => {
    return (
        <InputMask mask={mask} value={value} onChange={onChange} maskChar="">
            {(inputProps) => (
                <Flex align="center" pos="relative">
                    {!card && isCreditCard && (
                        <Image
                            src="/images/blank.svg"
                            w="45px"
                            position="absolute"
                            left="5px"
                            opacity={0.3}
                            zIndex={400}
                        />
                    )}
                    {card && card === 'AMEX' && (
                        <Image
                            src="/images/amex.svg"
                            w="45px"
                            position="absolute"
                            left="5px"
                            zIndex={400}
                        />
                    )}
                    {card && card === 'Mastercard' && (
                        <Image
                            src="/images/mastercard.svg"
                            w="45px"
                            position="absolute"
                            left="5px"
                            zIndex={400}
                        />
                    )}
                    {card && card === 'Visa' && (
                        <Image src="/images/visa.svg" w="45px" position="absolute" left="5px" />
                    )}
                    <Input
                        name={name}
                        isCreditCard={isCreditCard}
                        label={label}
                        size="lg"
                        bg="white"
                        pl={isCreditCard && '55px'}
                        rules={rules}
                        {...inputProps}
                        placeholder={placeholder}
                    />
                </Flex>
            )}
        </InputMask>
    );
};

const CardDetails = () => {
    const [type, setType] = useState(null);
    const customer = useWatch({ name: 'customer' });
    const cardNumber = useWatch({ name: 'card_details.card_number' });

    useEffect(() => {
        if (cardNumber) {
            const _type = GetCardType(cardNumber);
            setType(_type);
        }
    }, [cardNumber]);

    return (
        <Flex direction="column" w="100%" bg="gray.100" rounded="md" p="15px">
            <Flex align="center" mb="15px">
                <Icon fontSize="22px" color="grey">
                    <FiLock />
                </Icon>
                <Text pl="10px" fontSize="18px" color="grey">
                    Secure Payment
                </Text>
            </Flex>
            {customer?.user_type.name === 'Business' && (
                <Stack spacing="20px" isInline mb="10px">
                    <Field
                        component="text"
                        name="customer.first_name"
                        label="Billing First Name"
                        rules={{ required: true }}
                        noMargin
                        bg="white"
                    />
                    <Field
                        component="text"
                        name="customer.last_name"
                        label="Billing Last Name"
                        rules={{ required: true }}
                        noMargin
                        bg="white"
                    />
                </Stack>
            )}

            <Field
                component="text"
                name="card_details.card_name"
                label="Name on card"
                placeholder="Name on card"
                rules={{ required: true }}
                bg="white"
            />

            <Field
                component={MaskedInput}
                mask={type === 'AMEX' ? '9999-999999-9999' : '9999-9999-9999-9999'}
                isCreditCard
                card={type}
                type="number"
                name="card_details.card_number"
                label="Card Number"
                placeholder="Card Number"
                rules={{ required: true }}
            />

            <Flex>
                <Box mr="15px">
                    <Field
                        component={MaskedInput}
                        mask="99/99"
                        name="card_details.expiry_date"
                        label="Expiry Date"
                        placeholder="MM/YY"
                        rules={{ required: true }}
                    />
                </Box>
                <Field
                    component={MaskedInput}
                    mask="999"
                    name="card_details.security_code"
                    label="CVC"
                    help={
                        type === 'AMEX'
                            ? '4 digits on the front of the card'
                            : '3 digits on the back of the card'
                    }
                    placeholder={type === 'AMEX' ? 'XXXX' : 'XXX'}
                    rules={{ required: true }}
                    maxWidth="150px"
                />
            </Flex>
        </Flex>
    );
};

function GetCardType(number) {
    // visa
    let re = new RegExp('^4');
    if (number.match(re) != null) return 'Visa';

    // Mastercard
    // Updated for Mastercard 2017 BINs expansion
    if (
        /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
            number,
        )
    )
        return 'Mastercard';

    // AMEX
    re = new RegExp('^3[47]');
    if (number.match(re) != null) return 'AMEX';

    // Discover
    re = new RegExp(
        '^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)',
    );
    if (number.match(re) != null) return 'Discover';

    // Diners
    re = new RegExp('^36');
    if (number.match(re) != null) return 'Diners';

    // Diners - Carte Blanche
    re = new RegExp('^30[0-5]');
    if (number.match(re) != null) return 'Diners - Carte Blanche';

    // JCB
    re = new RegExp('^35(2[89]|[3-8][0-9])');
    if (number.match(re) != null) return 'JCB';

    // Visa Electron
    re = new RegExp('^(4026|417500|4508|4844|491(3|7))');
    if (number.match(re) != null) return 'Visa Electron';

    return '';
}

export default CardDetails;
