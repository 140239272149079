import React from 'react';
import { Button, Text, Badge, Flex, Grid, Box } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useGet } from '@builtbypixel/nucleus';
import useSWR from 'swr';
import { useParams } from 'react-router';
import { LinkButton } from '@builtbypixel/nucleus';
import moment from 'moment';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import CustomTable from '../../../../components/common/CustomTable';
import { FaCheck } from 'react-icons/fa';
import { ListWrapper } from '@builtbypixel/nucleus';

const columns = [
    {
        Header: 'Booking ID',
        accessor: 'id',
        Cell: ({ value, row }) => (
            <LinkButton to={`/bookings/edit/${value}`}>
                <Button variant="link" colorScheme="blue" rounded="md">
                    {value} / {row?.original?.reference}
                </Button>
            </LinkButton>
        ),
    },

    {
        Header: 'Purchase Date',
        accessor: 'purchase_date',
        Cell: ({ row }) => <>{moment(row?.original?.created_at).format('DD/MM/YYYY')}</>,
    },
    {
        Header: 'Delegates',
        accessor: 'delegate_count',
        Cell: ({ value, row }) => {
            let { id } = useParams();

            let data = row?.original?.events.filter((ev) => Number(ev.id) === Number(id));
            const delegate_count = data?.[0]?.all_delegate_count;

            return (
                <Flex direction="column" {...row.getToggleRowExpandedProps()}>
                    {delegate_count > 0 ? (
                        <Button
                            colorScheme="blue"
                            variant="ghost"
                            justifyContent="flex-start"
                            size="sm"
                            rightIcon={row.isExpanded ? <FiChevronUp /> : <FiChevronDown />}
                        >
                            {delegate_count}
                        </Button>
                    ) : null}
                </Flex>
            );
        },
    },
    {
        Header: 'Booker',
        accessor: 'customer.email',
        Cell: ({ row }) => (
            <>
                <Box>{row?.original?.customer?.email ? row?.original?.customer?.email : null}</Box>
                {row?.original?.customer?.user_type?.name !== 'Business' && (
                    <Box>
                        <LinkButton
                            target="_blank"
                            to={`/personal/edit/${row?.original?.customer?.id}`}
                        >
                            <Button size="sm" variant="link" colorScheme="blue" rounded="md">
                                <Text color="gray.500" paddingTop="5px">
                                    {row?.original?.customer?.first_name}{' '}
                                    {row?.original?.customer?.last_name}
                                </Text>
                            </Button>
                        </LinkButton>
                    </Box>
                )}
                {row?.original?.customer?.company_name && (
                    <Box>
                        <LinkButton to={`/businesses/edit/${row?.original?.customer?.id}`}>
                            <Button size="sm" variant="link" colorScheme="blue" rounded="md">
                                <Text color="gray.500" paddingTop="5px">
                                    {row?.original?.customer?.company_name}
                                </Text>
                            </Button>
                        </LinkButton>
                    </Box>
                )}
            </>
        ),
    },
    {
        Header: 'User Type',
        accessor: 'customer.user_type.name',
    },
    {
        Header: 'Booking Total',
        accessor: 'amount_paid',
        Cell: ({ row }) => <>{`£${row?.original?.total}`}</>,
    },
    {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => {
            if (value.id === 2) {
                return <Badge colorScheme="green">{value.name}</Badge>;
            }
            if (value.id === 3) {
                return <Badge colorScheme="red">{value.name}</Badge>;
            }
            if (value.id === 4) {
                return <Badge colorScheme="orange">{value.name}</Badge>;
            } else {
                return <Badge>{value.name}</Badge>;
            }
        },
    },
];

const Delegates = ({ id: eventId }) => {
    const { id } = useParams();
    const renderRowSubComponent = React.useCallback(({ row }) => {
        const data = row?.original?.events.filter((ev) => Number(ev.id) === Number(id));

        return (
            <Flex w="100%" direction="column" p="5px">
                {data.map((event, i) => {
                    return event.all_delegates.map((del) => {
                        return (
                            <Grid
                                rounded="md"
                                shadow="sm"
                                mb="3px"
                                py="5px"
                                px="25px"
                                align="center"
                                templateColumns="1fr 1.5fr 1fr 1fr"
                                border="1px"
                                borderColor="gray.300"
                                w="100%"
                                fontSize="0.9em"
                                bg="white"
                            >
                                <Flex justifyContent="flex-start">
                                    <Flex direction="column">
                                        {del.id === '' ? (
                                            <Badge variant="solid" colorScheme="red">
                                                Unconfirmed Delegate
                                            </Badge>
                                        ) : (
                                            <Flex>
                                                <LinkButton
                                                    target="_blank"
                                                    to={`/personal/edit/${del.id}`}
                                                >
                                                    <Button
                                                        size="sm"
                                                        variant="link"
                                                        colorScheme="blue"
                                                        rounded="md"
                                                    >
                                                        <Text color="gray.500" paddingTop="5px">
                                                            {del.first_name} {del.last_name}
                                                        </Text>
                                                    </Button>
                                                </LinkButton>
                                            </Flex>
                                        )}
                                        {del.id === ''
                                            ? null
                                            : del.user_types?.name === 'Business' &&
                                              del.company_name && (
                                                  <Box>
                                                      <LinkButton
                                                          target="_blank"
                                                          to={`/businesses/edit/${del.id}`}
                                                      >
                                                          <Button
                                                              size="sm"
                                                              variant="link"
                                                              colorScheme="blue"
                                                              rounded="md"
                                                          >
                                                              <Text
                                                                  color="gray.500"
                                                                  paddingTop="5px"
                                                              >
                                                                  {del.company_name}
                                                              </Text>
                                                          </Button>
                                                      </LinkButton>
                                                  </Box>
                                              )}
                                    </Flex>
                                </Flex>
                                <Flex justifyContent="flex-start">
                                    <Text>{del.email !== 'Unassigned' ? del.email : null}</Text>
                                </Flex>
                                <Flex justifyContent="flex-start">
                                    <Text>
                                        {del.id !== '' && del.user_types?.name
                                            ? del.user_types?.name
                                            : null}
                                    </Text>
                                </Flex>
                            </Grid>
                        );
                    });
                })}
            </Flex>
        );
    }, []);

    const { data: bookings, mutate } = useSWR(
        `/bookings-by-event/${eventId ? eventId : id}`,
        useGet,
    );
    return (
        <Flex w="100%" direction="column" bg="white" rounded="md" p="15px" align="flex-start">
            <Text fontSize="17px" fontWeight="bold" mb="15px">
                Bookings
            </Text>
            <ListWrapper
                renderRowSubComponent={renderRowSubComponent}
                setup={{
                    title: 'bookings-by-event',
                    singular: 'bookings-by-event',
                    model: 'bookings-by-event',
                    canDelete: false,
                    canAddNew: true,
                    buttonText: 'Create Booking',
                    secondaryButton: true,
                    secondaryButtonText: 'Create Broker Booking',
                    canSearch: true,
                    endpoint: `bookings-by-event/${eventId ? eventId : id}`,
                    accessor: 'id',
                }}
                tableOnly
                columns={columns}
                // extraMenu={extraMenu}
                // {...props}
            />
            {/* <CustomTable
                data={bookings ? bookings.data : []}
                meta={bookings?.meta}
                columns={columns}
                renderRowSubComponent={renderRowSubComponent}
                onChangePage={(e) =>
                    mutate(`https://api.marbletraining.co.uk/api/bookings-by-event/1247?page=${e}`)
                }
            /> */}
        </Flex>
    );
};

export default Delegates;
