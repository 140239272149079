import React from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';
import setup from './setup';
import { Button } from '@chakra-ui/react';

// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const List = () => {
    const columns = [
        {
            Header: 'Customer',
            accessor: 'user',
            Cell: ({ row }) => (
                <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
                    <Button variant="link" colorScheme="blue" rounded="md">
                        {row.original.user.first_name} {row.original.user.last_name}
                    </Button>
                </LinkButton>
            ),
        },
        {
            Header: 'Event Code',
            accessor: 'event.event_code',
            Cell: ({ row }) => (
                <LinkButton to={`events/edit/${row.original.event.id}`}>
                    <Button variant="link" colorScheme="blue" rounded="md">
                        {row.original.event.event_code}
                    </Button>
                </LinkButton>
            ),
      
        },
        {
            Header: 'Course Name',
            accessor: 'event.course.title',
      
        },
        {
            Header: 'Original Total',
            accessor: 'total',
            Cell: ({ row }) => (
                <>
               {`£${row.original.total}`}
               </>
            ),
      
        },
        {
            Header: 'Refund Amount',
            accessor: 'refund',
            Cell: ({ row }) => (
                <>
               {`£${row.original.refund_amount}`}
               </>
            ),
      
        },
        {
            Header: 'Status',
            accessor: 'status.name',
      
        },
    ];

    return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
