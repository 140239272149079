import React, { useMemo } from 'react';
import { EditView, AutoFields } from '@builtbypixel/nucleus';
import setup from '../setup';
import { seo } from '../../../components/Seo';
import { Text, Box, Flex } from '@chakra-ui/react';

const EditForm = () => {
    const homeFields = useMemo(
        () => [
            {
                tab: 'General',
                group: 'Home Details',
                fields: [
                    {
                        name: 'title',
                        component: 'text',
                        placeholder: 'Page title',
                        label: 'Title',
                        rules: { required: true },
                        isRequired: true,
                    },
                    {
                        name: '',
                        component: () => <Text fontSize="18px">Hero Banner</Text>,
                    },
                    {
                        name: 'content.hero_banner.title.text',
                        component: 'text',
                        label: 'Banner Text',
                        rules: { required: true },
                        isRequired: true,
                    },
                    {
                        name: '',
                        component: () => <Text fontSize="18px">Client Reviews</Text>,
                    },
                    {
                        name: 'content.client_reviews.title',
                        component: 'text',
                        label: 'Title',
                    },
                    {
                        name: 'content.client_reviews.all_client',
                        component: 'text',
                        label: 'All Clients',
                    },
                    {
                        name: '',
                        component: () => <Text fontSize="18px">SEO Featured Description</Text>,
                    },
                    {
                        name: 'content.featured_courses.description',
                        component: 'richtext',
                        label: 'SEO Featured Description',
                    },
                ],
            },
            seo,
            {
                tab: 'About',
                group: 'About',
                fields: [
                    {
                        name: 'content.about.title.text',
                        component: 'text',
                        label: 'Title',
                    },
                    {
                        name: 'content.about.videoUrl',
                        component: 'text',
                        label: 'Video URL',
                    },
                    {
                        name: 'content.about.content',
                        component: 'repeater',
                        label: 'Content',
                        fieldArray: [
                            {
                                name: 'imageOrder',
                                component: 'text',
                                type: 'number',
                                label: 'Image Order',
                            },
                            {
                                name: 'imageUrl',
                                component: 'text',
                                label: 'Image URL',
                            },
                            {
                                name: 'paragraph.text',
                                component: 'textarea',
                                label: 'Paragraph',
                            },
                            {
                                name: 'title.text',
                                component: 'text',
                                label: 'Title',
                            },
                            {
                                name: 'title.icon',
                                component: 'text',
                                label: 'Icon URL',
                            },
                        ],
                    },
                ],
            },
        ],
        [],
    );
    return (
        <EditView setup={setup}>
            <AutoFields fields={homeFields} />
        </EditView>
    );
};

export default EditForm;
