import { EditView } from '@builtbypixel/nucleus';
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Switch,
    ModalFooter,
    Stack,
    Alert,
    Flex,
    AlertIcon,
    Divider,
    Box,
    Text,
    Input,
    Badge,
    useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import BookingType from './components/BookingType';
import { FiSave } from 'react-icons/fi';
import UserSettings from './components/UserSettings';
import setup from './setup';

import UserSummary from './components/UserSummary';
import CostSummary from './components/CostSummary.jsx';
import BookingConfirm from './components/BookingConfirm';
import { useHistory, useParams } from 'react-router';
import { useHttp } from '../../Nucleus';
import BookedBy from './components/BookedBy';
import Events from './components/Events';
import { useFormContext, useWatch } from 'react-hook-form';
import CancelBooking from './components/CancelBooking';
import BookingSummary from './components/BookingSummary';
import BookingTypeModal from './components/BookingTypeModal';
import BrokerSettings from './components/BrokerSettings';
import AdditionalBookingDetails from './components/Additional';

const Booking = ({ viewOnly, id: entryId }) => {
    const getURL = process.env.REACT_APP_API;
    const toast = useToast();
    const [submitting, isSubmitting] = useState(false);
    const [sage, setSage] = useState(null);
    const [bookingType, setBookingType] = useState(null);
    const [brokerBooking, setBrokerBooking] = useState(null);
    const [emailDelegates, setEmailDelegates] = useState(false);
    const Http = useHttp();
    const { type, id, broker } = useParams();
    const history = useHistory();

    useEffect(() => {
        if (broker) {
            setBrokerBooking('broker');
        }
    }, [setBrokerBooking]);

    useEffect(() => {
        setEmailDelegates(false);
    }, []);
    const downloadInvoice = (orderId) => {
        Http.get(`/generate-invoice/${orderId}`, { responseType: 'blob' })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `invoice-${orderId}.pdf`);
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const submitBooking = (values) => {
        console.log(values, 'values');
        isSubmitting(true);
        if (type === 'create') {
            Http.post('/purchase', values)
                .then((response) => {
                    if (response.data && !response.data.statusCode) {
                        toast({
                            status: 'success',
                            title: 'Booking created successfully',
                        });
                    } else {
                        setSage(response.data);
                        toast({
                            status: 'success',
                            title: 'Payment successful',
                        });
                    }
                    isSubmitting(false);
                    history.push('/bookings');
                })
                .catch((error) => {
                    isSubmitting(false);
                    toast({
                        status: 'error',
                        title: error?.response?.data?.errors?.message
                            ? error?.response?.data?.errors?.message
                            : 'Error processing payment',
                        duration: 10000,
                    });
                });
        } else if (values.card_details && values.card_details.card_number !== '') {
            Http.put(`/purchase/${entryId ? entryId : id}`, values)
                .then((response) => {
                    if (response.data && !response.data.statusCode) {
                        history.push('/bookings');
                        toast({
                            status: 'success',
                            title: 'Booking saved successfully',
                        });
                    } else {
                        setSage(response.data);
                        toast({
                            status: 'success',
                            title: 'Payment successful...',
                        });
                        setTimeout(() => {
                            history.push('/bookings');
                        }, 1000);
                    }
                    isSubmitting(false);
                })
                .catch((error) => {
                    isSubmitting(false);
                    toast({
                        status: 'error',
                        title: error?.response?.data?.errors?.message
                            ? error?.response?.data?.errors?.message
                            : 'Error processing payment',
                        duration: 10000,
                    });
                });
        } else {
            Http.put(`/bookings/${entryId ? entryId : id}`, values)
                .then((response) => {
                    if (response.data && !response.data.statusCode) {
                        //history.push('/bookings');
                        toast({
                            status: 'success',
                            title: 'Booking saved successfully',
                        });

                        setTimeout(() => {
                            window.location.reload(false);
                        }, 2000);
                    }
                    isSubmitting(false);
                })
                .catch((error) => {
                    let message;
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.errors &&
                        error.response.data.errors.message
                    ) {
                        message = error.response.data.errors.message;
                    } else {
                        message = 'Error processing booking';
                    }

                    isSubmitting(false);
                    toast({ status: 'error', title: message, duration: 10000 });
                });
        }
    };

    return (
        <EditView
            setup={setup}
            viewOnly={viewOnly}
            id={entryId ? entryId : id}
            hideSubmit
            defaultValues={{
                provisional_booking: true,
                credit_booking: true,
                events: [],
                send_del_emails: false,
            }}
            onSubmit={submitBooking}
            hideHeaderLinks
        >
            <Flex w="100%" minHeight="100vh" pos="relative" p="10px" flex={1}>
                <Flex direction="column" flex={1} maxWidth="calc(100% - 320px)">
                    {type === 'create' && brokerBooking ? (
                        <BrokerSettings brokerBooking={brokerBooking} />
                    ) : (
                        <UserSettings />
                    )}
                    {/* {type === 'create' && brokerBooking === 'normal' && <UserSettings />} */}
                    <UserSummary brokerBooking={brokerBooking} />

                    <AdditionalBookingDetails />
                    <Events />

                    {/* <EventData /> */}
                    {type === 'edit' && <BookedBy />}

                    {/* {type === 'create' && (
                        <BookingTypeModal
                            isOpen={isOpen}
                            onOpen={onOpen}
                            onClose={onClose}
                            setBrokerBooking={setBrokerBooking}
                        />
                    )} */}

                    {/* <Delegates /> */}
                    <BookingType />

                    <Flex justify="flex-start" align="center" pb="25px">
                        <SaveOnly
                            isLoading={submitting}
                            setEmailDelegates={setEmailDelegates}
                            emailDelegates={emailDelegates}
                        />
                        <BookingConfirm
                            isSubmitting={submitting}
                            sage={sage}
                            setBookingType={setBookingType}
                        />
                        <CancelBooking />
                    </Flex>
                </Flex>
                <Flex
                    w="325px"
                    ml="20px"
                    maxWidth="325px"
                    minHeight="500px"
                    flex={1}
                    direction="column"
                    bg="white"
                    rounded="md"
                    overflowY="auto"
                    pb="90px"
                >
                    <CostSummary />
                    <SageBadge />
                    {/* {bookingType && bookingType.name !== 'Provisional' && (
                        <Box p="20px">
                            <Text as="a" onClick={() => downloadInvoice(id)}>
                                <Button w="100%" colorScheme="blue" variant="outline">
                                    Download Invoice
                                </Button>
                            </Text>
                        </Box>
                    )} */}
                    <BookingSummary id={entryId} />
                </Flex>
            </Flex>
        </EditView>
    );
};

const SageBadge = () => {
    const invoice = useWatch({ name: 'invoice' });

    return invoice?.transferred_to_sage ? (
        <Flex rounded="md" bg="white" p="20px" direction="column">
            <Badge
                colorScheme="green"
                mb="20px"
                fontSize="20px"
                textAlign="center"
                rounded="lg"
                py="10px"
            >
                In Sage
            </Badge>
        </Flex>
    ) : (
        ''
    );
};

const SaveOnly = ({ isLoading, setEmailDelegates, emailDelegates }) => {
    const { submit, register } = useFormContext();

    const { isOpen, onClose, onOpen } = useDisclosure();
    const toggle = () => {
        setEmailDelegates((prevState) => !prevState);
    };

    return (
        <>
            <Button
                isLoading={isLoading}
                isDisabled={isLoading}
                onClick={() => onOpen()}
                colorScheme="blue"
                leftIcon={<FiSave fontSize="20px" />}
                mr="20px"
            >
                Save booking changes only
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Save Booking Changes</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex direction="column">
                            <Text mb="20px" fontSize="1.2rem">
                                Email Delegates?
                            </Text>
                            <Switch
                                size="lg"
                                {...register('send_del_emails')}
                                defaultChecked={emailDelegates}
                                isChecked={emailDelegates}
                                onChange={toggle}
                            />
                        </Flex>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="green" onClick={() => submit()} w="100%">
                            Confirm
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default Booking;
