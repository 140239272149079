import { EditView, useHttp, PopConfirm } from '@builtbypixel/nucleus';
import {
    Button,
    Flex,
    useToast,
    Text,
    Box,
    Tabs,
    Tab,
    TabList,
    TabPanels,
    TabPanel,
} from '@chakra-ui/react';
import React, { useState, useCallback } from 'react';
import { useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import setup from './setup';

import { BiCopy } from 'react-icons/bi';

import Seo from './components/Seo';
import Details from './components/Details';
import Content from './components/Content';
import LearningStylesRepeater from './components/LearningStylesRepeater';
import Reviews from './components/Reviews';
import Events from './components/Events';
import LocationContent from './components/LocationContent';
import NominalCodes from './components/NominalCodes';
import SubProviders from './components/SubProviders';

const EditForm = () => {
    const Http = useHttp();
    const { type, id } = useParams();

    const [cloneCourse, setCloneCourse] = useState(false);
    const [duplicating, setDuplicating] = useState(false);
    const toast = useToast();

    const cloneCourses = useCallback(() => {
        setCloneCourse(true);
        setDuplicating(true);
        Http.post(`/courses/clone/${id}`)
            .then((res) => {
                window.location.href = `/courses/edit/${res.data.data.id}`;
            })
            .catch((err) => {
                toast({
                    status: 'error',
                    message: 'Error cloning course',
                });
            });
        setDuplicating(false);
    }, [Http, id, toast]);

    return (
        <EditView
            setup={setup}
            isFullWidth
            defaultValues={{ duration: { start_time: '09:00', end_time: '17:00' } }}
        >
            <Box w="100%" display="block">
                <Flex justify="flex-start" w="100%" p="10px">
                    <CourseTitle />
                    {type === 'edit' && (
                        <PopConfirm
                            onConfirm={() => cloneCourses()}
                            title="Are you sure you want to clone this course?"
                        >
                            <Button
                                variant="primary"
                                size="sm"
                                leftIcon={<BiCopy />}
                                isLoading={duplicating}
                                isDisabled={duplicating}
                            >
                                Clone course
                            </Button>
                        </PopConfirm>
                    )}
                </Flex>
                <Flex w="100%" minHeight="100vh" bg="white">
                    <Tabs flex={1} minHeight="100vh">
                        <TabList>
                            <Tab>Course Information</Tab>
                            <Tab>Sub Providers</Tab>
                            <Tab>Delivery Methods</Tab>
                            <Tab>Course Content</Tab>
                            <Tab>Location Content</Tab>
                            <Tab>Reviews</Tab>
                            {type === 'edit' && <Tab>Events</Tab>}
                            <Tab>SEO</Tab>
                            <Tab>Nominal Codes</Tab>
                        </TabList>
                        <TabPanels bg="blackAlpha.100" px="0px" minHeight="100vh">
                            <TabPanel>
                                <Details />
                            </TabPanel>
                            <TabPanel>
                                <SubProviders />
                            </TabPanel>
                            <TabPanel>
                                <LearningStylesRepeater />
                            </TabPanel>
                            <TabPanel>
                                <Content />
                            </TabPanel>
                            <TabPanel>
                                <LocationContent />
                            </TabPanel>
                            <TabPanel>
                                <Reviews />
                            </TabPanel>
                            {type === 'edit' && (
                                <TabPanel>
                                    <Events />
                                </TabPanel>
                            )}
                            <TabPanel>
                                <Seo />
                            </TabPanel>
                            <TabPanel>
                                <NominalCodes />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Flex>
            </Box>
        </EditView>
    );
};

const CourseTitle = () => {
    const title = useWatch({ name: 'course_title' });

    return (
        <Flex align="center" mr="25px">
            <Text mr="25px" fontSize="1.3em" fontWeight="semibold">
                {title}
            </Text>
        </Flex>
    );
};

export default EditForm;
