import jobs from './containers/Jobs/routes';
import AdminUsers from './containers/AdminUser/routes';
import blogcategories from './containers/BlogCategories/routes';
import courses from './containers/CoursesNew/routes';
import Blogs from './containers/Blogs/routes';
import coursetypes from './containers/CourseTypes/routes';
import categories from './containers/Categories/routes';
import locations from './containers/Locations/routes';
import reviews from './containers/Reviews/routes';
import pages from './containers/Pages/routes';
import Dashboard from './containers/Dashboard/routes';
import Themes from './containers/Themes/routes';
import LocationTypes from './containers/LocationTypes/routes';
import CourseFeatures from './containers/CourseFeatures/routes';

import Cancellations from './containers/Cancellations/routes';
import Transfers from './containers/Rebookings/routes';
import BusinessUser from './containers/BusinessUser/routes';
import BrokerUser from './containers/BrokerUser/routes';
import UserTypes from './containers/UserTypes/routes';
import Notifications from './containers/Notifications/routes';
import JobApplications from './containers/JobApplications/routes';
import CancellationTypes from './containers/CancellationTypes/routes';
import Enquiries from './containers/Enquiries/routes';
import EnquiryTypes from './containers/EnquiryTypes/routes';
import EnquiryStatuses from './containers/EnquiryStatuses/routes';
import PersonalUser from './containers/PersonalUser/routes';
import Clients from './containers/Clients/routes';
import ClientReviews from './containers/ClientReviews/routes';
import RebookingTypes from './containers/RebookingTypes/routes';
import Trainers from './containers/Trainers/routes';
import Providers from './containers/Providers/routes';
import LearningStyles from './containers/LearningStyles/routes';
import CloneCourse from './containers/CloneCourse/routes';
import Events from './containers/EventsNew/routes';
import EventStatuses from './containers/EventStatuses/routes';
import Invoices from './containers/Invoices/routes';
import CreditNotes from './containers/CreditNotes/routes';
import TrainersCalendars from './containers/TrainersCalendar/routes';
import VatSettings from './containers/VatSettings/routes';
import TrainersStatuses from './containers/TrainerStatuses/routes';
import TrainerPrices from './containers/TrainerPrices/routes';
import TrainerTypes from './containers/Trainers/TrainerTypes/routes';
import Bookings from './containers/Bookings/routes';
import DelegateStatuses from './containers/DelegateStatuses/routes';
import CustomPages from './containers/CustomPages/routes';
import BookingStatuses from './containers/BookingStatuses/routes';
import FreeTrials from './containers/FreeTrials/routes';
import DurationStatus from './containers/DurationStatuses/routes';
import TrainerDocumentTypes from './containers/TrainerDocumentTypes/routes';
import CourseDocumentTypes from './containers/CourseDocumentTypes/routes';
import UserUploadTypes from './containers/UserUploadTypes/routes';
import Venues from './containers/Venues/routes';
import Refunds from './containers/Refunds/routes';
import RefundStatuses from './containers/RefundStatuses/routes';
import CustomerCertificates from './containers/CustomerCertificates/routes';
import Accreditation from './containers/Accreditation/routes';
import CourseReviews from './containers/CourseReviews/routes';
import DownloadsPage from './containers/DownloadsPage/routes';
import Downloads from './containers/Downloads/routes';
import DownloadCategories from './containers/DownloadCategories/routes';
import DocumentUploadTypes from './containers/DocumentUploadTypes/routes';

const routes = [
    ...Accreditation,
    ...AdminUsers,
    ...blogcategories,
    ...Blogs,
    ...Bookings,
    ...BrokerUser,
    ...BookingStatuses,
    ...BusinessUser,
    ...categories,
    ...Cancellations,
    ...CancellationTypes,
    ...Clients,
    ...ClientReviews,
    ...CloneCourse,
    ...courses,
    ...CourseDocumentTypes,
    ...CourseFeatures,
    ...CourseReviews,
    ...coursetypes,
    ...CreditNotes,
    ...CustomerCertificates,
    ...CustomPages,
    ...Dashboard,
    ...DelegateStatuses,
    ...DurationStatus,
    ...FreeTrials,
    ...Enquiries,
    ...EnquiryStatuses,
    ...EnquiryTypes,
    ...Events,
    ...EventStatuses,
    ...Invoices,
    ...jobs,
    ...JobApplications,
    ...LearningStyles,
    ...locations,
    ...LocationTypes,
    ...Notifications,
    ...pages,
    ...PersonalUser,
    ...Providers,
    ...RebookingTypes,
    ...Refunds,
    ...RefundStatuses,
    ...reviews,
    ...Themes,
    ...Trainers,
    ...TrainersCalendars,
    ...TrainerDocumentTypes,
    ...TrainerPrices,
    ...TrainersStatuses,
    ...TrainerTypes,
    ...Transfers,
    ...UserTypes,
    ...UserUploadTypes,
    ...VatSettings,
    ...Venues,
    ...Downloads,
    ...DownloadCategories,
    ...DownloadsPage,
    ...DocumentUploadTypes,
];

export default routes;
