import { Field } from '@builtbypixel/nucleus';

import React from 'react';
import FormSection from '../../../components/FormSection';

const Seo = () => {
    return (
        <>
            <FormSection title="Course Content" mt="0px">
                <Field name={`seo.title`} component="text" label="SEO Meta Title" />
                <Field name={`seo.description`} component="richtext" label="SEO Meta Description" />
                <Field
                    name={`seo.page_description`}
                    component="richtext"
                    label="SEO on page Description"
                />
            </FormSection>
        </>
    );
};

export default Seo;
