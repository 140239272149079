import React, { useMemo } from 'react';

import { EditView, AutoFields } from '@builtbypixel/nucleus';
import setup from '../setup';

const EditForm = () => {
    const fields = useMemo(
        () => [
            {
                group: 'Terms and Conditions',
                fields: [
                    {
                        name: 'title',
                        component: 'text',
                        placeholder: 'Title',
                        label: 'Title',
                        rules: { required: true },
                        isRequired: true,
                    },
                    {
                        name: 'content.page_text',
                        component: 'richtext',
                        placeholder: 'Content',
                        label: 'Content',
                        rules: { required: true },
                        isRequired: true,
                    },
                ],
            },
        ],
        [],
    );
    return (
        <EditView setup={setup}>
            <AutoFields fields={fields} />
        </EditView>
    );
};

export default EditForm;
