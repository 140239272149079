import React, { useMemo, useEffect, useState } from 'react';
import { AutoFields, EditView } from '@builtbypixel/nucleus';
import setup from './setup';
import { useHttp } from '@builtbypixel/nucleus';
import { seo } from '../../components/Seo';

const EditForm = () => {
    const [blogCategories, setBlogCategories] = useState([]);
    const Http = useHttp();
    useEffect(() => {
        Http.get('/blog-categories').then((res) => {
            setBlogCategories(res.data.data);
        });
    }, []);

    const fields = useMemo(
        () => [
            {
                tab: 'Blog Details',
                group: 'Blog Details',
                fields: [
                    {
                        name: 'category',
                        component: 'select',
                        isMulti: true,
                        options: blogCategories,
                        placeholder: 'Category',
                        labelKey: 'name',
                        valueKey: 'id',
                        label: 'Article Category',
                        variant: 'outline',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'title',
                        component: 'text',
                        placeholder: 'Title',
                        label: 'Title',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'image',
                        component: 'file',
                        placeholder: 'Image',
                        label: 'Image',
                        isRequired: true,
                        rules: { required: true },
                    },

                    {
                        name: 'excerpt',
                        component: 'textarea',
                        placeholder: 'Excerpt',
                        label: 'Excerpt',
                        type: 'text',
                        isRequired: true,
                        rules: { required: true, minLength: 10 },
                        help: 'minimum 10 characters',
                    },
                    {
                        name: 'paragraphs',
                        component: 'richtext',
                        placeholder: 'Blog Entry',
                        label: 'Blog Entry',
                        isRequired: true,
                        rules: { required: true, minLength: 50 },
                    },

                    {
                        name: 'published_date',
                        component: 'date',
                        placeholder: 'Published Date',
                        label: 'Published Date',
                    },
                    {
                        name: 'active',
                        component: 'switch',
                        label: 'Published?',
                    },
                ],
            },
            seo,
        ],
        [blogCategories],
    );
    return (
        <EditView setup={setup}>
            <AutoFields fields={fields} />
        </EditView>
    );
};

export default EditForm;
