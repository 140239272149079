import { useGet } from '@builtbypixel/nucleus';
import { Flex, Text, Button } from '@chakra-ui/react';
import Select from '../../../../Nucleus/Fields/Select';
import React from 'react';
import moment from 'moment';
import { useParams } from 'react-router';
import useSWR from 'swr';
import CustomTable from '../../../../components/common/CustomTable';

const columns = [
    {
        Header: 'Reference',
        id: 'name',
        accessor: 'name',
    },
    {
        Header: 'Due Date',
        id: 'invoice.id',
        accessor: 'due_date',

        Cell: ({ row }) => <Text>{moment(row.original.due_date).format('MMMM Do, YYYY')}</Text>,
    },

    {
        Header: 'Actions',
        accessor: 'document',

        Cell: ({ value }) => (
            <a target="_blank" rel="noreferrer" href={value?.[0]?.url}>
                <Button>Download Invoice</Button>{' '}
            </a>
        ),
    },
];

const Invoices = () => {
    const { id } = useParams();

    const { data: invoices } = useSWR(`/trainer-invoices/${id}`, useGet);

    return (
        <Flex w="100%" direction="column" bg="white" rounded="md" p="15px" align="flex-start">
            <Text fontSize="17px" fontWeight="bold" mb="15px">
                Trainer Invoices
            </Text>
            <CustomTable data={invoices ? invoices.data : []} columns={columns} />
        </Flex>
    );
};

export default Invoices;
