import React, { useState, createContext, createRef } from 'react';

import PropTypes from 'prop-types';
import { useTheme, Box, Spinner, Flex } from '@chakra-ui/react';

import UISelect, { components } from 'react-select';
import AsyncSelect from 'react-select/async';

const SelectContext = createContext();

const CustomControl = (props) => {
    const { isFocused } = props;

    const focusProps = () => {
        if (isFocused) {
            return {
                boxShadow: 'buttonFocus',
            };
        }
    };

    return (
        <components.Control {...props}>
            <Flex
                as="button"
                type="button"
                ref={props.innerRef}
                pl="0px"
                pr="5px"
                w="100%"
                disabled={props.isDisabled}
                {...focusProps()}
                maxHeight="none"
                rounded="md"
                border="1px"
                borderColor="gray.300"
                minWidth="100px"
                minHeight="20px"
            >
                {props.children}
            </Flex>
        </components.Control>
    );
};

const CustomLoader = (props) => {
    return <Spinner size={props.size === 'lg' ? 'md' : props.size} color="gray.400" mr="5px" />;
};

const CustomMenu = (props) => {
    return (
        <components.Menu {...props}>
            <Box
                rounded="7px"
                border="1px"
                boxShadow="md"
                borderColor="gray.300"
                mt="0px"
                bg="white"
                overflow="hidden"
                zIndex={9900}
                position="absolute"
                top={0}
                w="100%"
            >
                {props.children}
            </Box>
        </components.Menu>
    );
};

const Select = (props) => {
    const theme = useTheme();

    const {
        size,
        variant,
        isFullWidth,
        value,
        onChange,
        labelKey,
        valueKey,
        options,
        isAsync,
        cacheOptions = false,
        loadOptions,
        container,
        isHorizontal,
        defaultValue,
    } = props;
    const [selectedOption, setSelectedOption] = useState(null);

    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: '100%',
        }),
        control: (provided) => ({
            ...provided,
            outline: 0,
            border: 0,
            boxShadow: 'none',
            borderRadius: 0,
            backgroundColor: 'white',
            minHeight: size === 'sm' ? '25px' : '34px',
        }),
        input: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'white',
            color: theme.colors.global.text,
        }),
        menu: (provided) => ({
            ...provided,
            border: 0,
            boxShadow: 'none',
            background: 'transparent',
            zIndex: 9999,
        }),

        valueContainer: () => ({
            padding: size === 'sm' ? '0px 4px' : '2px 4px 2px 4px',
            width: '100%',
            display: isHorizontal ? 'flex' : 'block',
            marginBottom: '2px',
        }),

        dropdownIndicator: (base) => ({
            padding: '3px 5px',
            display: 'flex',
            alignItems: 'center',
        }),
        singleValue: (base) => ({
            ...base,
            color: theme.colors.gray[900],
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? 'white' : theme.colors.gray[900],
            backgroundColor: state.isSelected ? theme.colors.primary : 'transparent',
            fontWeight: state.isSelected ? 700 : 'normal',
            '&:hover': {
                background: !state.isSelected ? theme.colors.gray[100] : theme.colors.primary,
            },
            fontSize: size === 'sm' ? '12px' : '13px',
            padding: size === 'sm' ? '5px 8px' : '5px 10px',
        }),
        clearIndicator: (provided) => ({
            ...provided,
            padding: '2px',
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            marginLeft: 'auto',
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    };

    const selectRef = createRef();

    return (
        <SelectContext.Provider value={{ size, variant, isFullWidth, selectRef }}>
            {isAsync ? (
                <AsyncSelect
                    defaultValue={selectedOption ? selectedOption : defaultValue}
                    onChange={onChange || setSelectedOption}
                    value={value || selectedOption}
                    styles={customStyles}
                    defaultOptions={options}
                    cacheOptions={cacheOptions}
                    getOptionLabel={(option) => (labelKey ? option[labelKey] : option.label)}
                    getOptionValue={(option) => (valueKey ? option[valueKey] : option.value)}
                    components={{
                        Control: CustomControl,
                        LoadingIndicator: CustomLoader,
                        Menu: CustomMenu,
                    }}
                    loadOptions={loadOptions}
                    menuPortalTarget={
                        !container && typeof window !== 'undefined' ? document.body : container
                    }
                    {...props}
                />
            ) : (
                <UISelect
                    defaultValue={selectedOption ? selectedOption : defaultValue}
                    onChange={onChange || setSelectedOption}
                    value={value || selectedOption}
                    styles={customStyles}
                    options={options}
                    getOptionLabel={(option) => (labelKey ? option[labelKey] : option.label)}
                    getOptionValue={(option) => (valueKey ? option[valueKey] : option.value)}
                    components={{
                        Control: CustomControl,
                        LoadingIndicator: CustomLoader,
                        Menu: CustomMenu,
                    }}
                    menuPortalTarget={
                        !container && typeof window !== 'undefined' ? document.body : container
                    }
                    {...props}
                />
            )}
        </SelectContext.Provider>
    );
};

Select.displayName = 'Select';

Select.defaultProps = {
    variant: 'outline',
    size: 'md',
    isClearable: true,
    menuPlacement: 'auto',
    openMenuOnClick: true,
};

Select.propTypes = {
    /**
     * The variant type of the button
     */
    options: PropTypes.array,
    /**
     * The variant of the input
     */
    variant: PropTypes.oneOf(['outline', 'filled', 'flushed']),
    /**
     * How large should the button be?
     */
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    /**
     * Is disabled
     */
    isDisabled: PropTypes.bool,
    /**
     * Is loading
     */
    isLoading: PropTypes.bool,
    /**
     * Support multiple selected options
     */
    isMulti: PropTypes.bool,
};

export default Select;
