import { useToast as UIUseToast } from '@chakra-ui/react'

export const useToast = () => {
   const toast = UIUseToast()

   return ({ status, title, variant }) =>
      toast({
         title: title,
         status: status,
         position: 'top-right',
         isClosable: false,
         variant: variant
      })
}
