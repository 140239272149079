import React, { useEffect } from 'react';

import { Spinner } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { authAtom } from '../../state/auth';
import { logout } from '../../helpers';
import { useGet } from '../../hooks';
import useSWR from 'swr';

const AuthChecker = ({ children, isPrivate }) => {
    const [auth, setAuth] = useRecoilState(authAtom);
    const bypassAuth = process.env.REACT_APP_BYPASS_AUTH;
    const authPrefix = process.env.REACT_APP_AUTH_PREFIX;

    // STANDARD
    const {
        data: user,
        error: userError,
        isValidating,
    } = useSWR(
        bypassAuth === 'false' && !auth.currentUser
            ? authPrefix
                ? `${authPrefix}/current`
                : 'auth/current'
            : null,
        useGet,
    );

    //  const fetchUser = () => {
    //     Http.get(authPrefix
    //       ? `${authPrefix}/current`
    //       : 'auth/current').then(res => {
    //          setAuth((old) => ({
    //             ...old,
    //             currentUser: res.data.data,
    //             loading: false,
    //         }));
    //       })
    //  }

    useEffect(() => {
        if (bypassAuth === 'false') {
            if (user && user.data) {
                setAuth((old) => ({
                    ...old,
                    currentUser: user.data,
                    loading: false,
                }));
            }

            if (userError && isPrivate) {
                logout();
            }
        } else {
            const testUser = {
                first_name: 'Test',
                last_name: 'User',
                email: 'test@test.com',
                permissions: ['ALL'],
            };
            setAuth((old) => ({
                ...old,
                currentUser: testUser,
                loading: false,
            }));
        }
    }, [user, setAuth, bypassAuth, isPrivate, userError]);

    return isValidating ? <Spinner /> : children;
};

export default AuthChecker;
