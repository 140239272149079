import React from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';
import setup from './setup';
import { Button } from '@chakra-ui/react';
import moment from 'moment';

// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const List = () => {
    const columns = [
        {
            Header: 'Date Requested',
            accessor: 'transfer.created_at',
            Cell: ({ row }) => (
                <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
                    <Button variant="link" colorScheme="blue" rounded="md">
                        <>{moment(row.original.transfer.created_at).format('DD/MM/YYYY')}</>
                    </Button>
                </LinkButton>
            ),
        },
        {
            Header: 'Status',
            accessor: 'status.name',
        },
        {
            Header: 'First Name',
            accessor: 'user.first_name',
        },
        {
            Header: 'Last Name',
            accessor: 'user.last_name',
        },
    ];

    return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
