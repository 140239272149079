import React, { useEffect, useState, useCallback } from 'react';
import {
    Input,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
} from '@chakra-ui/react';
import debounce from 'lodash.debounce';

const PassPercentageField = ({ index, arr, setArr }) => {
    const [value, setValue] = useState(arr?.data?.[index]?.percentage);

    const handleValue = () => {
        let x = arr?.data;
        if (index || index === 0) {
            x[index] = {
                ...x[index],
                percentage: value,
            };

            setArr({ ...arr, data: x });
        }
    };

    useEffect(() => {
        if (value !== arr?.data?.[index]?.percentage) {
            submitValue(value);
        }

        return submitValue.cancel;
    }, [value]);

    const submitValue = useCallback(
        debounce(() => handleValue(), 1000),
        [value],
    );

    return (
        <NumberInput
            bg="white"
            value={value ? value : ''}
            precision={2}
            onChange={(e) => setValue(e)}
            min={0}
            max={100}
        >
            <NumberInputField fontSize="11px" placeholder={0.0} />
            <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
            </NumberInputStepper>
        </NumberInput>
    );
};

export default PassPercentageField;
