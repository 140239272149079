import React, { useEffect, useState } from 'react';
import { LinkButton, ListWrapper, Select, useGet } from '@builtbypixel/nucleus';
import setup from './setup';
import moment from 'moment';
import { Box, Button, Text, Stack, Tooltip, IconButton, useToast } from '@chakra-ui/react';
import useSWR, { useSWRConfig } from 'swr';
import { FaFileInvoice } from 'react-icons/fa';
import { useHttp } from '../../Nucleus';

// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const List = () => {
    const [allUsers, setAllUsers] = useState([]);
    const toast = useToast();
    const getURL = process.env.REACT_APP_API;
    const Http = useHttp();
    const { data: students } = useSWR('/students', useGet);
    const { data: businesses } = useSWR('/businesses', useGet);
    const { data: bookingStatus } = useSWR('booking-statuses', useGet);
    const { data: InvoiceStatus } = useSWR('invoice-statuses', useGet);

    const { mutate } = useSWRConfig();

    const downloadCreditNote = (creditNoteId) => {
        Http.get(`/generate-credit-note/${creditNoteId}`, { responseType: 'blob' })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `credit-note-${creditNoteId}.pdf`);
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (students && businesses && !allUsers.length === 0) {
            const newUser = [];
            const mergeUser = [...students.data, ...businesses.data];
            console.log(mergeUser, 'mergeUser');

            const allEmails = () => {
                mergeUser.map((user) => {
                    const userDetails = {
                        id: user.id,
                        email: user.email,
                    };
                    newUser.push(userDetails);
                });
                return newUser;
            };

            setAllUsers(allEmails);

            console.log(allEmails);
        }
    }, [students, businesses, allUsers]);

    useEffect(() => {
        console.log(allUsers);
    }, [allUsers]);

    const update_status = (e, credit_note_id) => {
        Http.put(`/credit-notes/${credit_note_id}`, { status: e })
            .then((res) => {
                toast({
                    status: 'success',
                    title: 'Status Updated',
                });
                // Http.get(`/credit-notes`)
                // .then((res) => {console.log(res)})
                // .catch((err) => {});
            })
            .catch((error) => {
                toast({
                    status: 'error',
                    message: 'Error cloning course',
                });
            });
    };

    const columns = [
        {
            Header: 'Actions',
            accessor: 'download',
            Cell: ({ row }) => (
                <Stack isInline spacing="15px">
                    <Tooltip label="Download Credit Note" _hover={{ cursor: 'pointer' }}>
                        <Text as="a" onClick={() => downloadCreditNote(row.original.id)}>
                            <IconButton
                                size="sm"
                                variant="link"
                                icon={<FaFileInvoice fontSize="18px" />}
                            />
                        </Text>
                    </Tooltip>
                </Stack>
            ),
            disableSortBy: true,
        },
        {
            Header: 'Credit Note',
            accessor: 'reference',
            Cell: ({ row }) => (
                // <LinkButton to={`${setup.model}/edit/${row.original.id}`}>
                //     <Button variant="link" colorScheme="blue" size="sm" rounded="md">
                <Text>
                    {row.original.id} / {row.original.reference}
                </Text>
                //     </Button>
                // </LinkButton>
            ),
        },
        {
            Header: 'Type',
            accessor: 'type.name',
            Cell: ({row}) => (
                row.original.type?.name ? row.original.type.name : 'Custom Credit Note'
            ),
        },
        {
            Header: 'Booking Reference',
            accessor: 'order.reference',
            Cell: ({ row }) => (
                <>
                    {row.original.order?.id ? 
                        <LinkButton to={`${setup.bookingsModel}/edit/${row.original.order?.id}`}>
                            <Button variant="link" colorScheme="blue" size="sm" rounded="md">
                                <>
                                    {row.original.order?.id} / {row.original.order?.reference}
                                </>
                            </Button>
                        </LinkButton> 
                    : 'N/A'}
                </>
            ),
        },
        {
            Header: 'Customer',
            accessor: 'order',
            Cell: ({ row }) => (
                <>
                    <Box>
                        {row.original.user?.email ? row.original.user?.email : null}
                    </Box>
                    {row.original.user?.user_type?.name !== 'Business' && (
                        <Box>
                            <LinkButton
                                target="_blank"
                                to={`Personal/edit/${row.original.user?.id}`}
                            >
                                <Button size="sm" variant="link" colorScheme="blue" rounded="md">
                                    <Text color="gray.500" paddingTop="5px">
                                        {row.original.user?.name}
                                    </Text>
                                </Button>
                            </LinkButton>
                        </Box>
                    )}
                    {row.original.user?.company_name && (
                        <Box>
                            <LinkButton to={`businesses/edit/${row.original.user?.id}`}>
                                <Button size="sm" variant="link" colorScheme="blue" rounded="md">
                                    <Text color="gray.500" paddingTop="5px">
                                        {row.original.user?.company_name}
                                    </Text>
                                </Button>
                            </LinkButton>
                        </Box>
                    )}
                </>
            ),
        },
        {
            Header: 'Total',
            accessor: 'total',
            Cell: ({ row, value }) => 
            <>
                <Text>£{row.original.total}</Text>
            </>
        },
        {
            Header: 'Status',
            accessor: 'status.name',
            Cell: ({ row }) =>
                console.log(row) ||
                (row.original.status.name === 'Unpaid' ? (
                    <Select
                        valueKey="id"
                        labelKey="name"
                        options={InvoiceStatus?.data}
                        name="Status"
                        onChange={(e) => update_status(e, row.original.id)}
                    />
                ) : (
                    <Text>{row.original.status.name}</Text>
                )),
        },
        {
            Header: 'Credit Note Raised',
            accessor: 'created_at',
            Cell: ({ value }) => moment(value).format('DD/MM/YYYY'),
        },
    ];

    const filters = [
        {
            component: 'select',
            label: 'Booking Status',
            name: 'status',
            placeholder: 'Select Status',
            options: InvoiceStatus?.data,
            labelKey: 'name',
            valueKey: 'id',
            defaultValue: { id: 3, name: 'Unpaid' },
        },
        {
            component: 'date',
            label: 'Invoice Raised',
            name: 'invoice_date',
            placeholder: 'Invoice Raised',
        },
    ];

    return <ListWrapper setup={setup} filters={filters} columns={columns} extraMenu={extraMenu} />;
};

export default List;
