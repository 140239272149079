import React, { useEffect } from 'react';
import { Field, useGet } from '@builtbypixel/nucleus';
import { Text, Flex, Grid, VisuallyHidden } from '@chakra-ui/react';
import PricingCreate from '../PricingCreate';
import useSWR from 'swr';
import { useFormContext, useWatch } from 'react-hook-form';
import FormSection from '../../../../components/FormSection';
import Pricing from '../Pricing';
import AddressFields from '../../../../components/AddressFields';

const TrainerSettings = () => {
    const { data: trainerStatus } = useSWR('/trainer-statuses', useGet);
    const { data: trainerTypes } = useSWR('/trainer-types', useGet);
    const { data: countries } = useSWR('/countries', useGet);
    const { setValue, watch } = useFormContext();

    const status = watch('status');
    const address = watch('address');
    const user_type = watch('user_type');

    useEffect(() => {
        if (!user_type && status) {
            setValue('user_type', { id: 4 });
        }
    }, [status]);

    useEffect(() => {
        if (!address?.address_country) {
            setValue('address', {
                ...address,
                address_country: {
                    id: 229,
                    name: 'United Kingdom',
                    code: 'GB',
                    currency_name: 'British pound',
                    dial_code: '+44',
                    currency_symbol: '£',
                    currency: 'GBP',
                    created_at: null,
                    updated_at: null,
                },
            });
        }
    }, [watch()]);

    return (
        <>
            <Flex direction="column" bg="white" rounded="md" p="15px">
                <Text fontSize="17px" fontWeight="bold" mb="15px">
                    Personal Information
                </Text>
                <Grid templateColumns="1fr 1fr 1fr" gap="15px">
                    <Field
                        name="first_name"
                        component="text"
                        label="First Name"
                        rules={{ required: true }}
                    />

                    <Field
                        name="last_name"
                        component="text"
                        label="Last Name"
                        rules={{ required: true }}
                    />

                    <Field
                        name="email"
                        component="text"
                        type="email"
                        label="Email Address"
                        rules={{ required: true }}
                    />
                    <Field name="phone_number" component="text" type="tel" label="Phone Number" />
                    <Field name="mobile_number" component="text" type="tel" label="Mobile Number" />
                    <Field
                        name="status"
                        component="select"
                        labelKey="name"
                        valueKey="id"
                        label="Status"
                        options={trainerStatus?.data}
                        rules={{ required: true }}
                    />
                    <Field
                        name="trainer_type"
                        component="select"
                        options={trainerTypes?.data}
                        labelKey="name"
                        valueKey="id"
                        label="Type"
                    />
                </Grid>
            </Flex>

            <PricingCreate />

            <Flex direction="column" bg="white" rounded="md" p="15px" mt="20px">
                <AddressFields />
            </Flex>
        </>
    );
};

export default TrainerSettings;
