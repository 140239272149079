import React, { useMemo, useEffect, useState } from "react";
import { AutoFields, EditView, useHttp } from "@builtbypixel/nucleus";
import {Text, useToast, Flex, Box, Button} from "@chakra-ui/react"
import setup from "./setup";
import defaultTheme from "./defaultTheme.json"


const EditForm = () => {
  const Http = useHttp()
  const toast = useToast()
  const resetTheme = () => {
    Http.put("/themes/1", defaultTheme).then(res => {
      toast({
        title: "Theme reset",
        description: "The theme has been reset to the default",
        status: "success",
        isClosable: true
      })
    }).catch(err => {
      toast({
        title: "Error",
        description: `There has been an error with your request. Error message: ${err}`,
        status: "error"
      })
    })
  }

  const fields = useMemo(
    () => [
      {
        group: "Hero Banner",
        fields: [
          {
            name: "content.hero_banner.background",
            component: "file",
            isMulti: true,
            placeholder: "Category",
            labelKey: "ackground url",
            // valueKey: 'id',
            label: "Background Image",
            variant: "outline",
            // isRequired: true,
            rules: { required: true },
          },
          {
            name: "content.hero_banner.title.styles.color",
            component: "colour",
            placeholder: "Title text colour",
            label: "Title text colour",
            // isRequired: true,
            rules: { required: true },
          },
          {
            name: "",
            component: () => <Text fontSize="24px">Header</Text>
          },
          {
            name: "content.header.styles.backgroundColor",
            component: "colour",
            placeholder: "background color",
            labelKey: "background colour",
            label: "background colour",
            rules: { required: true },
          },
          {
            name: "content.header.styles.color",
            component: "colour",
            // isMulti: true,
            placeholder: "colour",
            labelKey: "colour",
            // valueKey: 'id',
            label: "colour",
            // variant: 'outline',
            // isRequired: true,
            rules: { required: true },
          },
          {
            name: "content.header.styles.telephoneColor",
            component: "colour",
            placeholder: "Telephone colour",
            label: "Telephone colour",
            // isRequired: true,
            rules: { required: true },
          },
          {
            name: "content.header.styles.button.color",
            component: "colour",
            placeholder: "Button text colour",
            label: "Button text colour",
            // isRequired: true,
            rules: { required: true },
          },
          {
            name: "content.header.styles.button.backgroundColor",
            component: "colour",
            placeholder: "Button background colour",
            label: "Button background colour",
            // isRequired: true,
            rules: { required: true },
          },
          {
            name: "content.header.styles.button.border.color",
            component: "colour",
            placeholder: "Button border colour",
            label: "Button border colour",
            // isRequired: true,
            rules: { required: true },
          },
          {
            name: "",
            component: () => <Text fontSize="24px">Search Banner</Text>
          },
          {
            name: "content.search_banner.styles.button.backgroundColor",
            component: "colour",
            placeholder: "button background colour",
            label: "button background colour",
            rules: { required: true },
          },
          {
            name: "content.search_banner.styles.button.color",
            component: "colour",
            placeholder: "button text colour",
            label: "button text colour",
            rules: { required: true },
          },
          {
            name: "",
            component: () => <Text fontSize="24px">Featured Courses</Text>
          },
          {
            name: "content.featured_courses.backgroundColor",
            component: "colour",
            placeholder: "background colour",
            label: "background colour",
            rules: { required: true },
          },
          {
            name: "content.featured_courses.title.text",
            component: "text",
            placeholder: "title text",
            label: "title text",
            rules: { required: true },
          },
          {
            name: "content.featured_courses.title.color",
            component: "colour",
            placeholder: "title text colour",
            label: "title text colour",
            rules: { required: true },
          },
          {
            name: "",
            component: () => <Text fontSize="24px">About</Text>
          },
          {
            name: "content.about.title.styles.color",
            component: "colour",
            placeholder: "title text colour",
            label: "title text colour",
            rules: { required: true },
          },
          {
            name: "content.about.title.styles.fontWeight",
            component: "select",
            options: [
              {
                name: "bold",
                id: "1",
              },
              {
                name: "light",
                id: "2",
              },
              {
                name: "normal",
                id: "3",
              },
            ],
            labelKey: "name",

            placeholder: "font weight",
            label: "font weight",
            rules: { required: true },
          },
          {
            name: "content.about.paragraph.styles.color",
            component: "colour",
            placeholder: "paragraph text colour",
            label: "paragraph text colour",
            rules: { required: true },
          },
          {
            name: "content.about.backgroundColor",
            component: "colour",
            placeholder: "background colour",
            label: "background colour",
            rules: { required: true },
          },
          {
            name: "content.about.headerColor",
            component: "colour",
            placeholder: "header colour",
            label: "header colour",
            rules: { required: true },
          },
          {
            name: "",
            component: () => <Text fontSize="24px">Banner Style</Text>
          },
          {
            name: "content.bannerStyle.color",
            component: "colour",
            placeholder: "Banner colour",
            label: "banner colour",
            rules: { required: true },
          },
          {
            name: "",
            component: () => <Text fontSize="24px">Location Headline</Text>
          },
          {
            name: "content.location_headline.style.color",
            component: "colour",
            placeholder: "text colour",
            label: "text colour",
            rules: { required: true },
          },
          {
            name: "content.location_headline.button.style.backgroundColor",
            component: "colour",
            placeholder: "button colour",
            label: "button colour",
            rules: { required: true },
          },
          {
            name: "content.location_headline.button.style.borderColor",
            component: "colour",
            placeholder: "button border colour",
            label: "button border colour",
            rules: { required: true },
          },
          {
            name: "content.location_headline.button.style.color",
            component: "colour",
            placeholder: "button text colour",
            label: "button text colour",
            rules: { required: true },
          },
          {
            name: "",
            component: () => <Text fontSize="24px">Contact Banner</Text>
          },
          {
            name: "content.contactBanner.style.backgroundColor",
            component: "colour",
            placeholder: "background colour",
            label: "background colour",
            rules: { required: true },
          },
          {
            name: "content.contactBanner.style.color",
            component: "colour",
            placeholder: "colour",
            label: "colour",
            rules: { required: true },
          },
          {
            name: "content.contactBanner.button.backgroundColor",
            component: "text",
            placeholder: "button background colour",
            label: "button background colour",
            rules: { required: true },
          },
          {
            name: "content.contactBanner.button.borderColor",
            component: "colour",
            placeholder: "button border colour",
            label: "button border colour",
            rules: { required: true },
          },
          {
            name: "content.contactBanner.button.color",
            component: "colour",
            placeholder: "button text colour",
            label: "button text colour",
            rules: { required: true },
          },
          {
            name: "",
            component: () => <Text fontSize="24px">Location Highlights</Text>
          },
          {
            name: "content.location_hightlights.style.backgroundColor",
            component: "colour",
            placeholder: "background colour",
            label: "background colour",
            rules: { required: true },
          },
          {
            name: "content.location_hightlights.style.color",
            component: "colour",
            placeholder: "colour",
            label: "colour",
            rules: { required: true },
          },
          {
            name: "",
            component: () => <Text fontSize="24px">Call to Action</Text>
          },
          {
            name: "content.callToAction.button.style.color",
            component: "colour",
            placeholder: "button colour",
            label: "button colour",
            rules: { required: true },
          },
          {
            name: "content.callToAction.button.style,backgroundColor",
            component: "colour",
            placeholder: "button background colour",
            label: "button background colour",
            rules: { required: true },
          },
          {
            name: "content.callToAction.button.style.borderColor",
            component: "colour",
            placeholder: "button border colour",
            label: "button border colour",
            rules: { required: true },
          },
          {
            name: "content.callToAction.style.backgroundColor",
            component: "colour",
            placeholder: "style background colour",
            label: "style background colour",
            rules: { required: true },
          },
          {
            name: "content.callToAction.style.color",
            component: "colour",
            placeholder: "style colour",
            label: "style colour",
            rules: { required: true },
          },
          {
            name: "",
            component: () => <Text fontSize="24px">Filters</Text>
          },
          {
            name: "content.filters.styles.color",
            component: "colour",
            placeholder: "filter colour",
            label: "filter colour",
            rules: { required: true },
          },
          {
            name: "",
            component: () => <Text fontSize="24px">Working Clients</Text>
          },
          {
            name: "content.working_clients.styles.backgroundColor",
            component: "colour",
            placeholder: "background colour",
            label: "background colour",
            rules: { required: true },
          },
          {
            name: "content.working_clients.styles.color",
            component: "colour",
            placeholder: "colour",
            label: "colour",
            rules: { required: true },
          },
          {
            name: "content.working_clients.styles.fontWeight",
            component: "select",
            options: [
              {
                name: "bold",
                id: "1",
              },
              {
                name: "light",
                id: "2",
              },
              {
                name: "normal",
                id: "3",
              },
            ],
            labelKey: "name",
            valueKey: "id",
            placeholder: "font weight",
            label: "font weight",
            rules: { required: true },
          },
          {
            name: "",
            component: () => <Text fontSize="24px">Slim Contact Banner</Text>
          },
          {
            name: "content.slim_contact_banner.backgroundColor",
            component: "colour",
            placeholder: "background colour",
            label: "background colour",
            rules: { required: true },
          },
          {
            name: "content.slim_contact_banner.color",
            component: "colour",
            placeholder: "colour",
            label: "colour",
            rules: { required: true },
          },
          {
            name: "content.slim_contact_banner.button.backgroundColor",
            component: "colour",
            placeholder: "button background colour",
            label: "button background colour",
            rules: { required: true },
          },
          {
            name: "content.slim_contact_banner.button.color",
            component: "colour",
            placeholder: "button colour",
            label: "button colour",
            rules: { required: true },
          },
          {
            name: "content.slim_contact_banner.button.borderColor",
            component: "colour",
            placeholder: "button border colour",
            label: "button border colour",
            rules: { required: true },
          },
          {
            name: "",
            component: () => <Text fontSize="24px">Highlights</Text>
          },
          {
            name: "content.highlights.backgroundColor",
            component: "colour",
            placeholder: "background colour",
            label: "background colour",
            rules: { required: true },
          },
          {
            name: "content.highlights.color",
            component: "colour",
            placeholder: "colour",
            label: "colour",
            rules: { required: true },
          },
          {
            name: "content.highlights.iconColor",
            component: "colour",
            placeholder: "icon colour",
            label: "icon colour",
            rules: { required: true },
          },
          {
            name: "",
            component: () => <Text fontSize="24px">Mobile Menu</Text>
          },
          {
            name: "content.mobileMenu.backgroundColor",
            component: "colour",
            placeholder: "background colour",
            label: "background colour",
            rules: { required: true },
          },
          {
            name: "content.mobileMenu.iconColor",
            component: "colour",
            placeholder: "icon colour",
            label: "icon colour",
            rules: { required: true },
          },
          {
            name: "",
            component: () => <Text fontSize="24px">Common Button</Text>
          },
          {
            name: "content.commonButton.hover.backgroundColor",
            component: "colour",
            placeholder: "Common button Hover background colour",
            label: "Common button Hover background colour",
            rules: { required: true },
          },
          {
            name: "content.commonButton.hover.color",
            component: "colour",
            placeholder: "Common button Hover color",
            label: "Common button Hover color",
            rules: { required: true },
          },
          {
            name: "content.commonButton.backgroundColor",
            component: "colour",
            placeholder: "Common button background colour",
            label: "Common button background colour",
            rules: { required: true },
          },
          {
            name: "content.commonButton.border",
            component: "text",
            placeholder: "Common button border",
            label: "Common button border",
            rules: { required: true },
          },
          {
            name: "content.commonButton.color",
            component: "color",
            placeholder: "Common button colour",
            label: "Common button colour",
            rules: { required: true },
          },
          {
            name: "",
            component: () => <Text fontSize="24px">List Title</Text>
          },
          {
            name: "content.listTitle.color",
            component: "colour",
            placeholder: "List Title colour",
            label: "List Title colour",
            rules: { required: true },
          },
          {
            name: "",
            component: () => <Text fontSize="24px">Job Headings</Text>
          },
          {
            name: "content.jobHeading.title.color",
            component: "colour",
            placeholder: "Job Heading Title Color",
            label: "Job Heading Title Color",
            rules: { required: true },
          },
          {
            name: "content.jobHeading.description.color",
            component: "colour",
            placeholder: "Job Heading description colour",
            label: "Job Heading description colour",
            rules: { required: true },
          },
          {
            name: "",
            component: () => <Text fontSize="24px">Customer Reviews</Text>
          },
          {
            name: "content.customerReviews.color",
            component: "colour",
            placeholder: "Customer Reviews Text Color",
            label: "Customer Reviews Text Color",
            rules: { required: true },
          },
          {
            name: "content.customerReviews.fontWeight",
            component: "select",
            options: [
              {
                name: "bold",
                id: "1",
              },
              {
                name: "light",
                id: "2",
              },
              {
                name: "normal",
                id: "3",
              },
            ],
            labelKey: "name",
            placeholder: "font weight",
            label: "font weight",
            rules: { required: true },
          },
          {
            name: "content.customerReviews.backgroundColor",
            component: "colour",
            placeholder: "Customer Reviews Background Color",
            label: "Customer Reviews Background Color",
            rules: { required: true },
          },
        ],
      },
    ],
    []
  );
  return (
    <EditView setup={setup}>
      <Box bg="white" width="100%">
        <Button bg="#e5e8af" _hover={{ backgroundColor: "#d2da3f" }} style={{ marginLeft: 15, marginTop: 10 }} onClick={() => resetTheme()}>Reset Theme</Button>
        <AutoFields fields={fields} />
      </Box>
    </EditView>
  );
};

export default EditForm;
