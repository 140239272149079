import React from 'react';
import { Flex, Tabs, TabPanels, TabPanel } from '@chakra-ui/react';
import EditMenu from './Menu';
import { EditView } from '@builtbypixel/nucleus';

import setup from './setup';
import Details from '../BusinessUser/components/Details';
import Events from '../BusinessUser/components//Events';
import Bookings from '../BusinessUser/components/Bookings';
import { useParams } from 'react-router-dom';
import Summary from '../PersonalUser/components/Summary';

const EditForm = ({ onSubmit }) => {
    const { id, type } = useParams();

    return (
        <EditView setup={setup} isFullWidth onSubmit={onSubmit}>
            <Flex w="100%" minHeight="100vh" bg="white">
                <Tabs flex={1} minHeight="100vh" isLazy>
                    <EditMenu />
                    <TabPanels bg="blackAlpha.100" px="0px" minHeight="100vh">
                        <TabPanel>
                            <Details />
                        </TabPanel>
                        {type === 'edit' && (
                            <TabPanel>
                                <Events />
                            </TabPanel>
                        )}
                        {type === 'edit' && (
                            <TabPanel>
                                <Bookings />
                            </TabPanel>
                        )}
                        {type === 'edit' && (
                            <TabPanel>
                                <Summary id={id} />
                            </TabPanel>
                        )}
                    </TabPanels>
                </Tabs>
            </Flex>
        </EditView>
    );
};

export default EditForm;
