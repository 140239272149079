import React from 'react';
import { Grid, Flex, Text, Box } from '@chakra-ui/react';
import { Field, useGet } from '@builtbypixel/nucleus';
import useSWR from 'swr';
import { useWatch } from 'react-hook-form';

const Duration = () => {
    const { data: durationStatus } = useSWR('/duration-statuses', useGet);
    const values = useWatch({ name: `time_to_complete[0]` });

    return (
        <Flex direction="column" bg="white" rounded="md" p="15px" mt="25px">
            <Text fontSize="17px" fontWeight="bold" mb="15px">
                Event duration
            </Text>
            <Grid templateColumns="1fr 1fr 1fr 1fr 1fr" gap="20px">
                {durationStatus && durationStatus.data.length > 0 && (
                    <>
                        {durationStatus.data.map((each, i) => (
                            <Box key={`box_timeto${i}`}>
                                <Field
                                    name={`time_to_complete[0].${each.name}`}
                                    component="text"
                                    type="number"
                                    label={each.name}
                                    placeholder={each.name}
                                    noMargin
                                    defaultValue={values ? values[each.name] : null}
                                />
                            </Box>
                        ))}
                    </>
                )}
            </Grid>
        </Flex>
    );
};

export default Duration;
