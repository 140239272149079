import React from 'react';
import {
    Flex,
    Modal,
    ModalHeader,
    ModalBody,
    ModalOverlay,
    ModalCloseButton,
    ModalContent,
    Button,
    ModalFooter,
    useDisclosure,
} from '@chakra-ui/react';
import { FiPlus } from 'react-icons/fi';
import Form from './Form';

const EventSearch = () => {
    const { isOpen, onClose, onOpen } = useDisclosure();

    return (
        <Flex mb="25px" direction="column" w="100%" align="flex-start">
            <Button leftIcon={<FiPlus />} colorScheme="blue" onClick={() => onOpen()}>
                Add event to booking
            </Button>
            <Modal size="lg" isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent minWidth={{ base: '100%', xl: '1200px' }}>
                    <ModalCloseButton />
                    <ModalHeader>Add an event</ModalHeader>
                    <ModalBody>
                        <Form />
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => onClose()}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    );
};

export default EventSearch;
