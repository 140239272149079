import React, { useMemo, useEffect, useState } from 'react';
import { AutoFields, EditView } from '@builtbypixel/nucleus';
import setup from './setup';
import { useHttp } from '@builtbypixel/nucleus';

const EditForm = () => {


    const fields = useMemo(
        () => [
            {
                group: 'Page Settings',
                fields: [
      
                    {
                        name: 'title',
                        component: 'text',
                        placeholder: 'Title',
                        label: 'Title',
                        isRequired: true,
                        rules: { required: true },
                    },
                    
                ],
            },
        ],
        [],
    );
    return (
        <EditView setup={setup}>
            <AutoFields fields={fields} />
        </EditView>
    );
};

export default EditForm;
