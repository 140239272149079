import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from '@emotion/styled';

const StyledScrollContainer = styled.div`
    .ps {
        overflow: hidden !important;
        overflow-anchor: none;
        -ms-overflow-style: none;
        touch-action: auto;
        -ms-touch-action: auto;
    }

    /*
   * Scrollbar rail styles
   */
    .ps__rail-x {
        display: none;
        opacity: 0;
        transition: background-color 0.2s linear, opacity 0.2s linear;
        -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
        height: 15px;
        /* there must be 'bottom' or 'top' for ps__rail-x */
        bottom: 0px;
        /* please don't change 'position' */

        position: absolute;
        z-index: 1000;
    }

    .ps__rail-y {
        display: none;
        opacity: 0;
        transition: background-color 0.2s linear, opacity 0.2s linear;
        -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
        width: 15px;
        /* there must be 'right' or 'left' for ps__rail-y */
        right: 0;
        /* please don't change 'position' */
        position: absolute;
        z-index: 1000;
    }

    .ps--active-x > .ps__rail-x,
    .ps--active-y > .ps__rail-y {
        display: block;
        background-color: transparent;
        z-index: 1000;
    }

    .ps:hover > .ps__rail-x,
    .ps:hover > .ps__rail-y,
    .ps--focus > .ps__rail-x,
    .ps--focus > .ps__rail-y,
    .ps--scrolling-x > .ps__rail-x,
    .ps--scrolling-y > .ps__rail-y {
        opacity: 0.6;
    }

    .ps .ps__rail-x:hover,
    .ps .ps__rail-y:hover,
    .ps .ps__rail-x:focus,
    .ps .ps__rail-y:focus,
    .ps .ps__rail-x.ps--clicking,
    .ps .ps__rail-y.ps--clicking {
        background-color: #eee;
        //opacity: 0.9;
    }

    /*
   * Scrollbar thumb styles
   */
    .ps__thumb-x {
        background-color: rgba(0, 0, 0, 0.95);
        border-radius: 6px;
        transition: background-color 0.2s linear, height 0.2s ease-in-out;
        -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
        height: 6px;
        /* there must be 'bottom' for ps__thumb-x */
        bottom: 2px;
        /* please don't change 'position' */
        position: absolute;
    }

    .ps__thumb-y {
        background-color: rgba(0, 0, 0, 0.95);
        border-radius: 6px;
        transition: background-color 0.2s linear, width 0.2s ease-in-out;
        -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
        width: 6px;
        /* there must be 'right' for ps__thumb-y */
        right: 2px;
        /* please don't change 'position' */
        position: absolute;
    }

    .ps__rail-x:hover > .ps__thumb-x,
    .ps__rail-x:focus > .ps__thumb-x,
    .ps__rail-x.ps--clicking .ps__thumb-x {
        background-color: #999;
        height: 8px;
    }

    .ps__rail-y:hover > .ps__thumb-y,
    .ps__rail-y:focus > .ps__thumb-y,
    .ps__rail-y.ps--clicking .ps__thumb-y {
        background-color: #999;
        width: 8px;
    }

    /* MS supports */
    @supports (-ms-overflow-style: none) {
        .ps {
            overflow: auto !important;
        }
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .ps {
            overflow: auto !important;
        }
    }
    .scrollbar-container {
        position: relative;
        height: 100%;
    }
    position: relative;
    height: 100%;
    width: 100%;
    //overflow: hidden;
`;

const ScrollContainer = ({ children, ...rest }) => {
    return (
        <StyledScrollContainer>
            <PerfectScrollbar
                {...rest}
                // options={{ scrollYMarginOffset: 80, scrollXMarginOffset: 80 }}
            >
                {children}
            </PerfectScrollbar>
        </StyledScrollContainer>
    );
};

export default ScrollContainer;
