import React from 'react';
import useSWR from 'swr';
import { Box } from '@chakra-ui/react';
import { useGet, Field } from '@builtbypixel/nucleus';

const CustomerDetails = () => {
    const { data: countries } = useSWR('/countries', useGet);

    return (
        <Box pr="20px" justify="flex-start" h="auto">
            <Field
                component="text"
                name="customer.address.address_line_one"
                label="Address Line One"
                rules={{ required: true }}
                size="sm"
            />
            <Field
                component="text"
                name="customer.address.address_line_two"
                label="Address Line Two"
                size="sm"
            />

            <Field
                component="text"
                name="customer.address.address_line_three"
                label="Address Line Three"
                size="sm"
            />
            <Field
                component="text"
                name="customer.address.address_city"
                label="City"
                rules={{ required: true }}
                size="sm"
            />

            <Field
                component="text"
                name="customer.address.address_postcode"
                label="PostCode"
                size="sm"
            />
            <Field
                component="select"
                options={countries?.data}
                labelKey="name"
                valueKey="id"
                name="customer.address.address_country"
                label="Country"
                rules={{ required: true }}
                defaultValue={{
                    code: 'GB',
                    created_at: null,
                    currency: 'GBP',
                    currency_name: 'British pound',
                    currency_symbol: '£',
                    dial_code: '+44',
                    id: 229,
                    name: 'United Kingdom',
                    updated_at: null,
                }}
                size="sm"
            />
        </Box>
    );
};

export default CustomerDetails;
