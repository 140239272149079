import React from 'react';
import { LinkButton, useGet } from '@builtbypixel/nucleus';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import useSWR from 'swr';

import { useParams } from 'react-router';

import CustomTable from '../../../../components/common/CustomTable';

// const CompanyLinkButton = (row) => (
//     <LinkButton target="_blank" to={`/businesses/edit/${row.original.id}`}>
//         <Button variant="link" colorScheme="blue" rounded="md" size="sm">
//             {row.original.company_name}
//         </Button>
//     </LinkButton>
// );

const columns = [
    {
        Header: 'Booking Reference',
        accessor: 'booking.reference',
        Cell: ({ row }) => (
            <LinkButton target="_blank" to={`/bookings/edit/${row.original.booking.id}`}>
                <Button variant="link" colorScheme="blue" rounded="md" size="sm">
                    {row.original.booking.reference}
                </Button>
            </LinkButton>
        ),
    },
    {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ row }) => (
            <>
                <Box>{row.original.email ? row.original.email : null}</Box>
                {row.original.user_types?.name !== 'Business' && (
                    <Box>
                        <LinkButton target="_blank" to={`Personal/edit/${row.original.id}`}>
                            <Button size="sm" variant="link" colorScheme="blue" rounded="md">
                                <Text color="gray.500" paddingTop="5px">
                                    {row.original.first_name} {row.original.last_name}
                                </Text>
                            </Button>
                        </LinkButton>
                    </Box>
                )}
                {row.original.company_name && (
                    <Box>
                        <LinkButton to={`businesses/edit/${row.original.id}`}>
                            <Button size="sm" variant="link" colorScheme="blue" rounded="md">
                                <Text color="gray.500" paddingTop="5px">
                                    {row.original.company_name}
                                </Text>
                            </Button>
                        </LinkButton>
                    </Box>
                )}
            </>
        ),
    },
    {
        Header: 'User Type',
        accessor: 'user_types.name',
        Cell: ({ row }) => (
            <>
                {row.original.user_types.name}
                {/* {row.original.user_types.name === 'Personal'
                ? row.original.user_types.name 
                :  CompanyLinkButton(row)
                ?  CompanyLinkButton(row)
                : 'Business - no company name provided'} */}
            </>
        ),
    },
    {
        Header: 'Employer',
        accessor: 'company_name',
        Cell: ({ row }) => (
            <>
                {row.original.company_name === null ? (
                    'None'
                ) : (
                    <LinkButton target="_blank" to={`/businesses/edit/${row.original.id}`}>
                        <Button variant="link" colorScheme="blue" rounded="md" size="sm">
                            {row.original.company_name}
                        </Button>
                    </LinkButton>
                )}
            </>
        ),
    },
    {
        Header: 'Status',
        accessor: 'status.name',
    },
    {
        Header: 'Certificate',
        accessor: 'test',
        Cell: () => <Text>N/A</Text>,
    },
];

const ProvisionalDelegates = ({ id: eventId }) => {
    const { id } = useParams();

    const { data: ProvisionalDelegates } = useSWR(
        `/events-delegates-provisional/${eventId ? eventId : id}`,
        useGet,
    );

    return (
        <Flex w="100%" direction="column" bg="white" rounded="md" p="15px" align="flex-start">
            <Text fontSize="17px" fontWeight="bold" mb="15px">
                Provisional Delegates
            </Text>
            <CustomTable
                data={ProvisionalDelegates ? ProvisionalDelegates.data : []}
                columns={columns}
            />
        </Flex>
    );
};

export default ProvisionalDelegates;
