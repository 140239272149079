/* global localStorage, */

import axios from 'axios'
import { checkAuthTokenExpiry, generateSlugFromSite, logout } from '../helpers'

const hasToken = JSON.parse(
   localStorage.getItem(`${generateSlugFromSite()}-token`)
)
const site = JSON.parse(localStorage.getItem(`${generateSlugFromSite()}-site`))

if (hasToken) {
   axios.defaults.headers.common.Authorization = `Bearer ${hasToken.token}`
}

const api = process.env.REACT_APP_API
const prefix = process.env.REACT_APP_API_PREFIX
const authPrefix = process.env.REACT_APP_AUTH_PREFIX;
const bypassAuth = process.env.REACT_APP_BYPASS_AUTH;

export const useHttp = () => {
   const baseApi = `${api}/${prefix}/${site?.path}`
   axios.defaults.baseURL = baseApi
   axios.defaults.headers.common.Accept = 'application/json'
   axios.defaults.headers.common['Content-Type'] = 'application/json'

   // Check if local token is expired
   axios.interceptors.request.use((config) => {
      checkAuthTokenExpiry();

      return config;
   });

   // Check if remote token is expired
   axios.interceptors.response.use((response) => response, (error) => {
      if (
          bypassAuth === "false"
          && error.response.config.url !== `${authPrefix || "auth"}/current`
          && error.response.status === 401
      ) {
         logout();
      }

      return Promise.reject(error);
   });

   return axios
}
