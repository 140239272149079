import React, { useMemo } from 'react';
import { AutoFields, EditView, useGet } from '@builtbypixel/nucleus';
import * as yup from 'yup';

import setup from './setup';
import useSWR from 'swr';

const EditForm = () => {
    const useValidation = () => {
        return yup.object().shape({
            name: yup.string().required('Please provide a name'),
            description: yup.string().required('Please provide a description'),
        });
    };

    const { data: providers } = useSWR(`/provider-select`, useGet);

    const fields = useMemo(
        () => [
            {
                group: 'General',
                tab: 'General',
                fields: [
                    {
                        name: 'name',
                        component: 'text',
                        placeholder: 'Name',
                        label: 'Name',
                    },
                    {
                        name: 'description',
                        component: 'text',
                        placeholder: 'Description',
                        label: 'Description',
                    },
                    {
                        name: 'image',
                        component: 'file',
                        placeholder: 'Provider Image',
                        label: 'Provider Image',
                        max: 1,
                    },
                    {
                        name: 'prefix',
                        component: 'text',
                        placeholder: 'Prefix',
                        label: 'Prefix',
                    },
                    {
                        name: 'sub_providers',
                        component: 'repeater',
                        placeholder: 'Sub Categories',
                        label: 'Sub Categories',
                        defaultValue: [],
                        fieldArray: [
                            {
                                name: 'name',
                                component: 'text',
                                placeholder: 'Name',
                                label: 'Name',
                            },
                        ],
                    },
                    {
                        name: 'active',
                        component: 'switch',
                        placeholder: 'Active',
                        label: 'Active',
                    },
                ],
            },
            {
                group: 'Costs',
                tab: 'Costs',
                fields: [
                    {
                        name: 'costs',
                        component: 'repeater',
                        fieldArray: [
                            {
                                name: 'type',
                                component: 'text',
                                placeholder: 'Name',
                                label: 'Name',
                            },
                            {
                                name: 'price',
                                component: 'text',
                                type: 'number',
                                placeholder: 'Cost',
                                label: 'Cost',
                                help: 'Excluding VAT',
                            },
                        ],
                        placeholder: 'cost',
                        label: 'Cost',
                    },
                ],
            },
            {
                group: 'Documents',
                tab: 'Documents',
                fields: [
                    {
                        name: 'provider_documents',
                        component: 'repeater',
                        fieldArray: [
                            {
                                name: 'document.url',
                                component: 'file',
                                placeholder: 'Document',
                                label: 'Document',
                            },
                            {
                                name: 'document.name',
                                component: 'text',
                                placeholder: 'Document Name',
                                label: 'Document Name',
                            },
                        ],
                        placeholder: 'Document',
                        label: 'Document',
                        help: 'The documents the are common to all courses for this provider.',
                    },
                ],
            },
            {
                group: 'Requirements',
                tab: 'Requirements',
                fields: [
                    {
                        name: 'requirements',
                        component: 'repeater',
                        placeholder: 'Requirements',
                        label: 'Requirements',
                        fieldArray: [
                            {
                                name: 'Criteria',
                                component: 'text',
                                placeholder: 'Criteria',
                                label: 'Criteria',
                            },
                        ],
                    },
                ],
            },

            {
                group: 'Seo',
                tab: 'Seo',
                fields: [
                    {
                        name: 'seo_content.title',
                        component: 'text',
                        label: 'SEO Meta Title',
                    },
                    {
                        name: 'seo_content.description',
                        component: 'richtext',
                        placeholder: 'SEO Meta Description',
                        label: 'SEO Description',
                    },
                    {
                        name: 'seo_content.page_description',
                        component: 'richtext',
                        placeholder: 'SEO Public Description',
                        label: 'SEO on page description',
                    },
                ],
            },
        ],
        [],
    );
    return (
        <EditView setup={setup} validationSchema={useValidation()}>
            <AutoFields fields={fields} />
        </EditView>
    );
};

export default EditForm;
