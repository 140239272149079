const modelName = 'Course Document Types';
const modelNameSingular = 'Course Document Type';
const endpoint = '/course-document-types';

const setup = {
    title: modelName,
    singular: modelNameSingular,
    model: modelName.replace(/\s+/g, '-').toLowerCase(),
    canDelete: true,
    canAddNew: true,
    canSearch: true,
    endpoint,
    accessor: 'id',
};

export default setup;
