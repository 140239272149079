import React, { useState, useEffect } from 'react';
import {
    ModalBody,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    Button,
    ModalHeader,
    ModalCloseButton,
    Grid,
    Text,
    Alert,
    AlertIcon,
    Box,
    Divider,
} from '@chakra-ui/react';
import useSWR from 'swr';
import { Field, useGet, useHttp } from '@builtbypixel/nucleus';
import { useWatch, FormProvider, useForm, useFormContext } from 'react-hook-form';
import AddressFields from '../../../../components/AddressFields';

const BrokerForm = ({ email, onClose }) => {
    const Http = useHttp();

    const [loading, setLoading] = useState();
    const [error, setError] = useState(false);
    const { setValue } = useFormContext();

    const methods = useForm();
    const { handleSubmit } = methods;

    const createUser = (data) => {
        setLoading(true);

        let endpoint =
            data.user_type.name === 'Personal'
                ? '/students'
                : data.user_type.name === 'Broker'
                ? '/brokers'
                : '/businesses';

        Http.post(`${endpoint}`, data)
            .then((res) => {
                setError(false);
                setLoading(false);
                setValue('customer', res.data.data);
                onClose();
            })
            .catch((error) => {
                setError(error?.response?.data?.errors?.message);
                setLoading(false);
            });
    };

    return (
        <>
            <ModalOverlay />
            <ModalContent maxWidth="900px">
                <ModalCloseButton />
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(createUser)}>
                        <Form isLoading={loading} email={email} error={error} />
                    </form>
                </FormProvider>
            </ModalContent>
        </>
    );
};

const Form = ({ isLoading, email, error }) => {
    const { data: userTypes } = useSWR('/user-types', useGet);
    const { data: countries } = useSWR('/countries', useGet);
    const { data: brokerTypes } = useSWR('/broker-types', useGet);

    const type = useWatch({ name: 'user_type' });
    const newEmailAddress = useWatch({ name: 'email' });

    const { setValue } = useFormContext();

    useEffect(() => {
        if (newEmailAddress && newEmailAddress.length > 0) {
            setValue('customer.email', newEmailAddress);
        }
    }, [newEmailAddress]);
    console.log(type, '!!!!!!!!! type');
    console.log(userTypes, '!!!!!!!!! typuserTypesuserTypese');

    return (
        <>
            <ModalHeader>Create New Broker</ModalHeader>
            <ModalBody height={'650px'}>
                <Box overflowY={'scroll'}>
                    <Grid templateColumns="1fr 1fr" gap="0px 20px" mb="15px">
                        <Box opacity={0.5}>
                            <Field
                                noMargin
                                component="select"
                                name="user_type"
                                options={
                                    userTypes
                                        ? userTypes.data.filter((e) => e.name !== 'Trainer')
                                        : []
                                }
                                label="Account Type"
                                labelKey="name"
                                valueKey="id"
                                isDisabled={true}
                                defaultValue={{
                                    id: 5,
                                    name: 'Broker',
                                }}
                                rules={{ required: true }}
                            />
                        </Box>
                        <Field
                            noMargin
                            component="text"
                            name="email"
                            label="Email Address"
                            rules={{ required: true }}
                            defaultValue={email}
                        />
                    </Grid>
                    {type && type.name === 'Personal' && (
                        <Grid templateColumns="1fr 1fr" gap="0px 20px" mb="15px">
                            <Field
                                noMargin
                                component="text"
                                name="first_name"
                                label="First Name"
                                rules={{ required: true }}
                            />
                            <Field
                                noMargin
                                component="text"
                                name="last_name"
                                label="Last Name"
                                rules={{ required: true }}
                            />
                        </Grid>
                    )}
                    {console.log(type?.name, '!!!!type.name ')}{' '}
                    <Grid templateColumns="1fr 1fr 1fr" gap="0px 20px" mb="15px">
                        <Field
                            noMargin
                            component="text"
                            name="first_name"
                            label="Administrator First Name"
                            rules={{ required: true }}
                        />
                        <Field
                            noMargin
                            component="text"
                            name="last_name"
                            label="Administrator Last Name"
                            rules={{ required: true }}
                        />
                        <Field
                            noMargin
                            component="text"
                            name="company_name"
                            label="Company Name"
                            rules={{ required: true }}
                        />
                    </Grid>
                    <Grid templateColumns="1fr 1fr" gap="0px 20px" mb="15px">
                        <Field
                            noMargin
                            component="text"
                            type="tel"
                            name="phone_number"
                            label="Phone Number"
                            rules={{ required: true }}
                        />
                        <Field
                            noMargin
                            component="text"
                            type="tel"
                            name="mobile"
                            label="Mobile Number"
                        />
                    </Grid>
                    <Divider />
                    <AddressFields />
                    <Divider />
                    <Text fontWeight="bold" my="20px">
                        Broker Details
                    </Text>
                    <Grid templateColumns="1fr 1fr" gap="0px 20px" mb="15px">
                        <Field
                            noMargin
                            name="broker_type"
                            label="Broker Type"
                            component="select"
                            labelKey="name"
                            valueKey="id"
                            options={brokerTypes?.data}
                            help="type of broker"
                        />
                        <Field
                            name="domain"
                            label="Broker Domain"
                            placeholder="Enter Broker Domain"
                            component="text"
                        />
                        <Field
                            name="commission"
                            label="Broker Commission"
                            placeholder="Enter Broker Comission (%)"
                            component="text"
                            help="The percentage comission given to the broker"
                        />
                        <Field
                            name="payment_terms"
                            component="number"
                            label="Payment terms (days)"
                        />
                    </Grid>
                    {error && (
                        <Alert status="error">
                            <AlertIcon /> An error occurred creating this user. {error}
                        </Alert>
                    )}
                </Box>
            </ModalBody>
            <ModalFooter>
                <Button
                    isLoading={isLoading}
                    isDisabled={isLoading}
                    type="submit"
                    colorScheme="blue"
                >
                    Create Broker
                </Button>
            </ModalFooter>
        </>
    );
};

export default BrokerForm;
