import React from 'react';
import EventCalendar from './EventCalendar';
import { Flex } from '@chakra-ui/react';

const Availability = () => {
    return (
        <Flex w="100%" bg="white">
            <EventCalendar />
        </Flex>
    );
};

export default Availability;
