import { Button, IconButton, useToast } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { FaFileInvoice } from 'react-icons/fa';
import { useCSVDownloader } from 'react-papaparse';

const GenerateCSV = ({ title, data, columns }) => {
    const toast = useToast();
    const { CSVDownloader, Type } = useCSVDownloader();
    const [enableDownload, setEnableDownload] = useState(false);

    let _data = null;
    if (Array.isArray(data)) _data = data;
    else {
        _data = data?.periods?.length ? data.periods : data?.courses?.length ? data.courses : [];
    }

    useEffect(() => {
        if (_data?.length > 0) setEnableDownload(true);
        else setEnableDownload(false);
    }, [data]);

    if (enableDownload) {
        return (
            <CSVDownloader
                type={Type.Button}
                filename={`${new Date().toLocaleDateString('en-GB')}_${title.replaceAll(' ', '_')}`}
                bom={true}
                config={{
                    delimiter: ';',
                }}
                data={_data}
            >
                <Button variant="primary">
                    <IconButton
                        size="sm"
                        variant="link"
                        color="white"
                        icon={<FaFileInvoice fontSize="18px" />}
                    />
                    Export to CSV
                </Button>
            </CSVDownloader>
        );
    } else {
        return (
            <Button
                variant="primary"
                onClick={() =>
                    toast({
                        title: `Error Exporting Data`,
                        description: 'No data available to export',
                        status: 'error',
                    })
                }
            >
                <IconButton
                    size="sm"
                    variant="link"
                    color="white"
                    icon={<FaFileInvoice fontSize="18px" />}
                />
                Export to CSV
            </Button>
        );
    }
};

export default GenerateCSV;
