import { Stack, Button, Text, Flex, Box, useDisclosure } from '@chakra-ui/react';
import React from 'react';
// import Section from '../Section';
import { useFormContext, useWatch } from 'react-hook-form';
import { useParams } from 'react-router';
import BookingSummaryModal from './components/BookingSummaryModal';

const BookingSummary = ({ id }) => {
    const { isOpen, onClose, onOpen } = useDisclosure();

    return (
        <>
            {
                <Box p="20px" mt={-5}>
                    <Text as="a">
                        <Button onClick={onOpen} w="100%" colorScheme="blue" variant="outline">
                            View Summary
                        </Button>
                    </Text>
                </Box>
            }

            <BookingSummaryModal id={id} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
        </>
    );
};

export default BookingSummary;
