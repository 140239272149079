import { Tab, TabList } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useWatch, useFormContext } from 'react-hook-form';
import { useState } from 'react';

const EditMenu = () => {
    const { type, model } = useParams();
    const [doHide, setDoHide] = useState(false);
    const event_status = useWatch({
        name: 'event_status',
    });

    useEffect(() => {
        if (event_status) {
            setDoHide(hideTabs.includes(event_status?.id));
        }
    }, [event_status]);

    const hideTabs = [6, 7];

    return (
        <TabList pt="10px" pl="10px" w="100%" key={`list-header-${event_status?.length}`}>
            <Tab>Event</Tab>
            <Tab>Discounts</Tab>
            {type === 'edit' && (
                <>
                    <Tab>Delegates</Tab>

                    <Tab
                        isDisabled={doHide}
                        width={doHide && 0}
                        padding={doHide && 0}
                        overflow={doHide && 'hidden'}
                    >
                        Provisional Delegates
                    </Tab>
                    <Tab
                        isDisabled={doHide}
                        width={doHide && 0}
                        padding={doHide && 0}
                        overflow={doHide && 'hidden'}
                    >
                        Preorder Delegates
                    </Tab>

                    <Tab>Bookings</Tab>
                    <Tab>Trainer Materials</Tab>
                    <Tab>Completion Documents</Tab>

                    <Tab>Trainer Details</Tab>
                    <Tab>Learner uploads</Tab>
                    <Tab>Misc Costs</Tab>
                </>
            )}
        </TabList>
    );
};

export default EditMenu;
