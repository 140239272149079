import React, { useEffect, useState } from 'react';
import { Select } from '@chakra-ui/react';

const StatusDropdown = ({ row, statuses, update_status }) => {
    const [selectValue, setSelectValue] = useState('');

    useEffect(() => {
        setSelectValue(row.original.Status);
    }, []);

    return (
        <Select onChange={(e) => update_status(e, row.original.Number, row.original.Type)}>
            <option disabled selected value>
                Select
            </option>
            {statuses?.data?.map((status) => {
                return (
                    <>
                        <option selected={selectValue.name === status.name} value={status.id}>
                            {status.name}
                        </option>
                    </>
                );
            })}
        </Select>
    );
};

export default StatusDropdown;
