import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

const Section = ({ children, title, ...rest }) => {
    return (
        <Flex rounded="md" bg="white" mb="25px" p="20px" direction="column" {...rest}>
            {title && (
                <Text fontWeight="bold" fontSize="16px" mb="15px">
                    {title}
                </Text>
            )}
            <Flex w="100%">{children}</Flex>
        </Flex>
    );
};

export default Section;
