import React, { useMemo, useState, useEffect } from 'react';
import { AutoFields, EditView } from '@builtbypixel/nucleus';
import setup from './setup';
import { useHttp } from '@builtbypixel/nucleus';


const EditForm = () => {
  const [refunds, setRefunds] = useState([]);

  const Http = useHttp();

  useEffect(()=> {

    Http.get(`/refund-statuses`).then((res) => {
      setRefunds(res.data.data);
  });
  },[])

    const fields = useMemo(
        () => [
            {
                group: 'Refunds',
                fields: [
                    {
                        name: 'user.first_name',
                        component: 'text',
                        placeholder: 'First Name',
                        label: 'First Name',
                        isRequired: true,
                        rules: { required: true },
                        isDisabled: true,
                    },
                    {
                        name: 'user.last_name',
                        component: 'text',
                        placeholder: 'Last Name',
                        label: 'Last Name',
                        isRequired: true,
                        rules: { required: true },
                        isDisabled: true,
                    },
                    {
                        name: 'event.event_code',
                        component: 'text',
                        placeholder: 'Event Code',
                        label: 'Event Code',
                        isRequired: true,
                        rules: { required: true },
                        isDisabled: true,
                    },
                    {
                        name: 'event.course.title',
                        component: 'text',
                        placeholder: 'Course Name',
                        label: 'Course Name',
                        isRequired: true,
                        rules: { required: true },
                        isDisabled: true,
                    },
                    {
                        name: 'total',
                        component: 'text',
                        placeholder: 'Total',
                        type: 'number',
                        label: 'Total (£)',
                        isRequired: true,
                        rules: { required: true },
                        isDisabled: true,
                    },
                    {
                        name: 'refund_amount',
                        component: 'text',
                        placeholder: 'Refund Amount',
                        label: 'Refund Amount (£)',
                        isRequired: true,
                        rules: { required: true },
                        isDisabled: true,
                    },
                    {
                        name: 'refund.status',
                        component: 'select',
                        placeholder: 'Refund Status',
                        options: refunds,
                        labelKey: 'name',
                        valueKey: 'id',
                        label: 'Refund Status',
                        variant: 'outline',
                        rules: { required: true },
                        isRequired: true,
                    },
                ],
            },
        ],
        [refunds],
    );
    return (
        <EditView setup={setup}>
            <AutoFields fields={fields} />
        </EditView>
    );
};

export default EditForm;
