import React, { useContext } from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';

import Select from 'react-select';
import Datepicker from '../../../Nucleus/Fields/DatePicker';

import { FiguresContext } from '.';

// NOTE- the select fiels for this component now uses a slightly different Select component tweaked by Matt called 'modifiedSelect'
// This has been used to fix the issue of the value not appearing in the select when it changes
// The original Select component has not been changed

const Filters = ({ startDate, endDate, users, trainers, deliveryMethods }) => {
    const { filters, setFilters } = useContext(FiguresContext);

    const setFilter = (name, value) => {
        let _filters = { ...filters };
        _filters[name] = value;
        setFilters(_filters);
    };

    return (
        <>
            <SimpleGrid columns={2} gap="10px">
                <Box>
                    <Datepicker
                        onChange={(e) => setFilter('startDate', e)}
                        placeholder="Start Date"
                        value={startDate}
                    />
                </Box>
                <Box>
                    <Datepicker
                        onChange={(e) => setFilter('endDate', e)}
                        placeholder="End Date"
                        value={endDate}
                    />
                </Box>
            </SimpleGrid>

            <SimpleGrid columns={2} gap="10px" mt="5px">
                <Select
                    options={trainers}
                    isClearable
                    isOptionSelected={(o) => o.id === filters.trainerID}
                    formatOptionLabel={(e) => e.name}
                    placeholder="Select Trainer"
                    onChange={(e) => setFilter('trainerID', e ? e.id : null)}
                ></Select>
                <Box>
                    <Select
                        options={deliveryMethods}
                        isClearable
                        isOptionSelected={(o) => o.id === filters.delivery_method}
                        formatOptionLabel={(e) => e.name}
                        placeholder="Select Delivery Method"
                        onChange={(e) => setFilter('deliveryMethodID', e ? e.id : null)}
                    ></Select>
                </Box>
            </SimpleGrid>
        </>
    );
};

export default Filters;
