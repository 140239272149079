import { Field, useHttp } from '@builtbypixel/nucleus';
import { Flex, Text, Button, Select } from '@chakra-ui/react';
import CustomTable from '../../../../components/common/CustomTable';
import { useWatch } from 'react-hook-form';
import { FiExternalLink } from 'react-icons/fi';
import { AiFillDelete } from 'react-icons/ai';
import dayjs from 'dayjs';
import { PopConfirm } from '../../../../Nucleus';

const TrainerDocuments = ({ type }) => {
    const uploadsWatch = useWatch({ name: type });
    const Http = useHttp();

    const handleRemoveDocument = (url) => {
        Http.delete('trainer-event-document', { data: { url: url } }).then((res) => {
            window.location.reload();
        });
    };

    const columns = [
        {
            Header: 'File Name',
            accessor: 'name',
            Cell: ({ row }) =>
                console.log('DOCS', row.original.document?.url) || (
                    <a target="_blank" href={`${row.original.document?.[0]?.url}`} rel="noreferrer">
                        <Button variant="link" colorScheme="blue" rounded="md">
                            <Text mr="10px">{row.original.name}</Text>
                        </Button>
                    </a>
                ),
        },
        {
            Header: 'Created At',
            accessor: 'created_at',
            Cell: ({ value }) => dayjs(value).format('DD/MM/YYYY'),
        },
        {
            Header: 'View',
            accessor: 'document',
            Cell: ({ row }) =>
                console.log('DOCS', row.original?.document?.url) || (
                    <a target="_blank" href={`${row.original?.document?.url}`} rel="noreferrer">
                        <Button variant="link" colorScheme="blue" rounded="md">
                            <FiExternalLink />
                        </Button>
                    </a>
                ),
        },
        {
            Header: 'Delete',
            accessor: 'id',
            Cell: ({ row }) => (
                <PopConfirm
                    onConfirm={() => handleRemoveDocument(row.original.document[0].url)}
                    title="Are you sure you want to remove document?"
                >
                    <Button variant="link" colorScheme="red" rounded="md">
                        <AiFillDelete />
                    </Button>
                </PopConfirm>
            ),
        },
    ];
    return (
        <>
            <Flex
                mt="25px"
                w="100%"
                direction="column"
                bg="white"
                rounded="md"
                p="15px"
                align="flex-start"
            >
                <CustomTable
                    data={uploadsWatch ? uploadsWatch : []}
                    columns={columns}
                    documentStatuses={[]}
                />
            </Flex>
        </>
    );
};

export default TrainerDocuments;
