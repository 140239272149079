import React, { useState } from 'react';
import { LinkButton, ListWrapper, useHttp, PopConfirm } from '@builtbypixel/nucleus';
import setup from './setup';
import {Button, useToast } from '@chakra-ui/react';
import { BiCopy } from 'react-icons/bi';
import BulkUploadModal from '../../components/Modals/BulkUploadModal';
import MiniEditView from '../../Nucleus/components/EditView/Mini.jsx'

const List = () => {
    const [duplicating, setDuplicating] = useState(false);
    const Http = useHttp();
    const toast = useToast();
    const cloneCourse = (id) => {
        setDuplicating(true);

        Http.post(`/courses/clone/${id}`)
            .then((res) => {
                window.location.href = `/courses/edit/${res.data.data.id}`;
            })
            .catch((err) => {
                toast({
                    status: 'error',
                    title: 'Error cloning course',
                });
            });

        setDuplicating(false);
    };

    const extraMenu = () => {
        return (
            <MiniEditView>
                <BulkUploadModal />
            </MiniEditView>
        );
    }

    const columns = [
        {
            Header: 'Course Code',
            accessor: 'course_code',
            Cell: ({ row }) => (
                <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
                    <Button variant="link" colorScheme="blue" rounded="md" size="sm">
                        {row.original.course_code}
                    </Button>
                </LinkButton>
            ),
        },
        {
            Header: 'Provider',
            accessor: 'provider.name',
        },
        {
            Header: 'Title',
            accessor: 'title',
        },
        {
            Header: 'Featured',
            accessor: 'featured',
            Cell: ({ row }) => <>{row.original.featured === true ? 'Yes' : 'No'}</>,
        },
        {
            Header: 'Published',
            accessor: 'published',
            Cell: ({ row }) => <>{row.original.published === true ? 'Yes' : 'No'}</>,
        },
        {
            Header: 'Clone course',
            accessor: 'id',
            Cell: ({ row }) => (
                <div>
                    <PopConfirm
                        onConfirm={() => cloneCourse(row.original.id)}
                        title={`Are you sure you want to clone "${row.original.title}".`}
                    >
                        <Button
                            variant="primary"
                            leftIcon={<BiCopy />}
                            isLoading={duplicating}
                            isDisabled={duplicating}
                            rounded="lg"
                        >
                            Clone course
                        </Button>
                    </PopConfirm>
                </div>
            ),
        },
    ];

    return (
        <>
            <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />
        </>
    );
};

export default List;
