import React from 'react';
import { Flex, Switch, Text, Box, Button } from '@chakra-ui/react';
import { FaRegChartBar, FaTable } from 'react-icons/fa';

const SwitchToggle = ({ toggle, toggleView, eLearning }) => {
    return (
        <Flex justify="center" align="center">
            <Box mr={2}>
                {eLearning ? (
                    <FaTable fontSize={18} />
                ) : toggleView ? (
                    <FaTable fontSize={18} />
                ) : (
                    <FaRegChartBar fontSize={18} />
                )}
            </Box>
            <Switch
                size="md"
                isChecked={toggleView}
                defaultChecked={toggleView}
                onChange={toggle}
                position="relative"
            />
            {eLearning ? (
                <Box ml={2}>
                    {toggleView ? (
                        <Text>Show all courses</Text>
                    ) : (
                        <Text>Show e-learning courses</Text>
                    )}
                </Box>
            ) : (
                <Box ml={2}>
                    {toggleView ? (
                        <Text>Switch to Table View</Text>
                    ) : (
                        <Text>Switch to Chart View</Text>
                    )}
                </Box>
            )}
        </Flex>
    );
};

export default SwitchToggle;
