import { Field } from '@builtbypixel/nucleus';
import React from 'react';

const RepeaterList = ({ field, counter, type }) => {
    return (
        <Field
            name={`learningItem.${counter}.costs`}
            component="repeater"
            fieldArray={[
                {
                    name: 'type',
                    component: 'text',
                    label: 'Type',
                },
                {
                    name: 'price',
                    component: 'text',
                    label: 'Price',
                },
            ]}
        />
    );
};

export default RepeaterList;
