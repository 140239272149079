import React from 'react';
import Table from '../../../Nucleus/components/Table';
const CustomTable = ({
    columns,
    data,
    hidePagination,
    rootData,
    getTrProps,
    renderRowSubComponent,
    onChangePageSize,
    onChangePage,
    meta,
    pagination,
    pageSize,
    setPageSize,
    setPagination,
}) => {
    return (
        <>
            {console.log(onChangePage, onChangePageSize, 'what is onChangePageSize')}
            <Table
                // onChangePageSize={onChangePageSize}
                // onChangePage={onChangePage}
                columns={columns}
                data={{ data }}
                meta={meta}
                hidePagination={hidePagination}
                rootData={rootData}
                renderRowSubComponent={renderRowSubComponent}
                getTrProps={getTrProps}
                custom={true}
                // pageSize={pageSize}
                // pagination={pagination}
                canPreviousPage={true}
                canNextPage={true}
                manualPagination={true}
                pageSize={pageSize}
                pagination={pagination}
                setPageSize={setPageSize}
                setPagination={setPagination}
            />
        </>
    );
};

export default CustomTable;
