export const cancellations = {
    tab: 'Refunds',
    group: 'Refunds',
    fields: [
        {
            name: 'cancellations',
            component: 'repeater',
            placeholder: 'Cancellations',
            label: 'Refunds',
            defaultValue: [],
            rules: { required: true },
            isRequired: true,
            fieldArray: [
                {
                    name: 'cancellation.days',
                    component: 'text',
                    type: 'number',
                    placeholder: 'Days before start',
                    label: 'Days before start',
                },
                {
                    name: 'cancellation.discount',
                    component: 'text',
                    type: 'number',
                    placeholder: 'Discount',
                    label: 'Discount',
                },
            ],
        },
        {
            name: 'cancellation.discount',
            component: 'text',
            type: 'number',
            placeholder: 'Refund (%)',
            label: 'Refund (%)',
        },
    ],
};
