import * as yup from 'yup';

const useValidation = () => {
    return yup.object().shape({
        content: yup.object().shape({
            heading: yup.string().required('Please provide a heading'),
            contact: yup.object().shape({
                name: yup.string().required('Please provide a name'),
                address: yup.object().shape({
                    line_one: yup.string().required('Please provide the first line of the address'),
                    line_two: yup
                        .string()
                        .required('Please provide the second line of the address'),
                    line_three: yup.string(),
                    town: yup.string().required('Please provide the city/town'),
                    postcode: yup.string().required('Please provide the postcode'),
                    country: yup.string().required('Please provide the country'),
                }),
                phone: yup.string().required('Please provide a phone number'),
            }),
            form_title: yup.string().required('Please provide a form title'),
            office_hours: yup
                .array()
                .min(1)
                .of(
                    yup.object().shape({
                        time_span: yup.string().required('Please provide opening day(s)'),
                        status: yup.string().required('Please provide opening hours'),
                    }),
                ),
            highlights: yup
                .array()
                .min(1)
                .of(
                    yup.object().shape({
                        title: yup.string().required('Please provide a title'),
                        text: yup.string().required('Please provide some text'),
                    }),
                ),
        }),
    });
};

export default useValidation;
