import React, { useMemo } from 'react';
import { AutoFields, EditView } from '@builtbypixel/nucleus';
import { useState, useEffect } from 'react';
import { useHttp } from '@builtbypixel/nucleus';
import setup from './setup';
import { useParams } from 'react-router-dom';

const EditForm = () => {
    const [status, setStatus] = useState([]);
    const { id } = useParams();

    const Http = useHttp();

    useEffect(() => {
        Http.get(`/invoice-statuses`).then((res) => {
            setStatus(res.data.data);
        });
        return () => {};
    }, []);
    const fields = useMemo(
        () => [
            {
                group: 'Invoice',
                fields: [
                    {
                        name: 'order.reference',
                        component: 'text',
                        label: 'Order reference',
                        disabled: true,
                    },
                    {
                        name: 'po_number',
                        component: 'text',
                        label: 'PO Number',
                    },
                    {
                        name: 'invoice',
                        component: 'file',
                        label: 'Invoice',
                    },

                    {
                        name: 'order.courses[0].name',
                        component: 'text',
                        label: 'Course Name',
                        disabled: true,
                    },
                    {
                        name: 'order.event.event_code',
                        component: 'text',
                        label: 'Event Code',
                        disabled: true,
                    },
                    {
                        name: 'invoice_date',
                        component: 'text',
                        label: 'Invoice Date',
                        disabled: true,
                    },
                    {
                        name: 'due_date',
                        component: 'text',
                        label: 'Due Date',
                        disabled: true,
                    },
                    {
                        name: 'status',
                        component: 'select',
                        placeholder: 'Status',
                        options: status,
                        labelKey: 'name',
                        valueKey: 'id',
                        label: 'Invoice Status',
                        variant: 'outline',
                    },
                    {
                        name: 'order.total',
                        component: 'text',
                        label: 'Order Total',
                        disabled: true,
                    },
                ],
            },
        ],
        [status],
    );
    return (
        <EditView setup={setup}>
            <AutoFields fields={fields} />
        </EditView>
    );
};

export default EditForm;
