import React, { useMemo } from 'react';
import { EditView, AutoFields } from '@builtbypixel/nucleus';
import setup from '../setup';
import { seo } from '../../../components/Seo';
import { Text, Box, Flex } from '@chakra-ui/react';

const EditForm = () => {
    const homeFields = useMemo(
        () => [
            {
                fields: [
                    {
                        name: 'content.seo.title',
                        component: 'text',
                        label: 'SEO Title',
                    },

                    {
                        name: 'content.seo.description',
                        component: 'richtext',
                        label: 'SEO Description',
                    },
                    {
                        name: 'content.seo.page_description',
                        component: 'richtext',
                        label: 'Page Description 1',
                    },
                ],
            },
        ],
        [],
    );
    return (
        <EditView setup={setup}>
            <AutoFields fields={homeFields} />
        </EditView>
    );
};

export default EditForm;
