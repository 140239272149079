import React, { useState, useEffect, useRef } from 'react';
import {
    Grid,
    Button,
    Alert,
    AlertIcon,
    Text,
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
} from '@chakra-ui/react';
import { Field, LinkButton, useGet, useHttp } from '@builtbypixel/nucleus';
import FormSection from '../../../components/FormSection';
import { useParams } from 'react-router-dom';
import { useFieldArray, useWatch, useFormContext } from 'react-hook-form';
import { FaFileAlt } from 'react-icons/fa';
import { GrAddCircle } from 'react-icons/gr';
import moment from 'dayjs';
import CustomTable from '../../../components/common/CustomTable';
import LocalFileUpload from '../../../Nucleus/Fields/File/Standard.jsx';

const Certificates = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { id } = useParams();

    const [event_id, set_event_id] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const { setValue } = useFormContext();
    const [selectedCertificate, setSelectedCertificate] = useState(null);
    const Http = useHttp();

    const { fields } = useFieldArray({ name: 'certificate' });
    // const certificate = useWatch({ name: 'certificate.certificate' });

    const save_certificate = () => {
        setLoading(true);
        Http.post(`/event-upload-certificate/${id}/${event_id}`, {
            certificate: selectedCertificate,
            order_id: '',
        })
            .then((res) => {
                setSuccess(true);
                setLoading(false);
                setError(false);
                onClose();
            })
            .catch((err) => {
                setLoading(false);
                setSuccess(false);
                setError(true);
                onClose();
            });
    };

    console.log(fields, 'fields');

    const columns = [
        {
            Header: 'ID',
            accessor: 'id',
        },
        {
            Header: 'Course',
            accessor: 'event.course.title',
        },
        {
            Header: 'Delivery',
            accessor: 'event.learningItem.name',
        },
        {
            Header: 'Start Date',
            accessor: 'event.start_date',
            Cell: ({ value }) => (
                <Text>{moment(value, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YYYY - HH:mm')}</Text>
            ),
        },
        {
            Header: 'End Date',
            accessor: 'event.end_date',
            Cell: ({ value }) => (
                <Text>{moment(value, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YYYY - HH:mm')}</Text>
            ),
        },
        {
            Header: 'Duration',
            accessor: 'event.duration',
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <Box>
                    {row.original.certificate?.length === 0 ? (
                        <Button
                            size="sm"
                            onClick={() => {
                                set_event_id(row.original.event.id);
                                onOpen();
                            }}
                        >
                            Upload Certificate
                        </Button>
                    ) : (
                        <>
                            <LinkButton
                                target="_blank"
                                rel="noreferrer"
                                to={row.original.certificate?.[0]?.url}
                            >
                                <Button size="sm" target="_blank">
                                    View
                                    <FaFileAlt style={{ marginLeft: 5 }} />
                                </Button>
                            </LinkButton>
                            <Button
                                target="_blank"
                                onClick={() => {
                                    set_event_id(row.original.event.id);
                                    onOpen();
                                }}
                                size="sm"
                                variant="outline"
                            >
                                New
                                <GrAddCircle style={{ marginLeft: 5 }} />
                            </Button>
                        </>
                    )}
                </Box>
            ),
        },
    ];

    return (
        <FormSection title="Certificates">
            {success && (
                <Alert mt={0} mb={5} title="Upload Succesful" status="success" hasIcon={true}>
                    Certificate has been uploaded
                </Alert>
            )}
            {error && (
                <Alert mt={0} mb={5} title="Error" status="error" hasIcon={true}>
                    Error uploading certificate
                </Alert>
            )}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Upload Certificate</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <LocalFileUpload onChange={(e) => setSelectedCertificate(e)} max={1} />

                        {/* <Field 
                            name="certificate.certificate"
                            component="file"
                            max={1}
                        /> */}
                        <Button colorScheme="blue" mr={3} onClick={() => save_certificate()}>
                            {loading ? '...Submitting' : 'Save'}
                        </Button>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <CustomTable data={fields ? fields : []} columns={columns} />
        </FormSection>
    );
};

export default Certificates;
