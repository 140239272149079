import React, { useEffect } from 'react';
import { Text, Flex, Box, Stack, Badge, Tooltip, SimpleGrid } from '@chakra-ui/react';

import Table from '../../../components/common/CustomTable';
import LoadingSpinner from '../../../components/LoadingSpinner';
import FormSection from '../../../components/FormSection';
import formatCurrency from '../../../utils/formatCurrency';
import DataView from '../components/DataView';

const TableView = ({ data, loading, setExportData, title, exportData }) => {
    const columns = [
        {
            Header: 'Name',
            accessor: 'trainer',
        },
        {
            Header: 'No. of Delegates',
            accessor: 'delegate_count',
        },
        {
            Header: 'Pass %',
            accessor: 'pass_percentage',
            Cell: ({ value }) => <>{`${Math.round(value)}%`}</>,
        },
        {
            Header: 'Fail %',
            accessor: 'failed_percentage',
            Cell: ({ value }) => <>{`${Math.round(value)}%`}</>,
        },
        {
            Header: 'Unknown %',
            accessor: 'unknown_percentage',
            Cell: ({ value }) => <>{`${Math.round(value)}%`}</>,
        },
    ];

    useEffect(() => {
        setExportData({
            ...exportData,
            [title.replaceAll(' ', '_')]: { data: data?.data ? data?.data : [], columns },
        });
    }, [data]);

    return (
        <>
            {loading || !data ? (
                <LoadingSpinner />
            ) : data.length !== 0 ? (
                <>
                    <Table columns={columns} data={data?.data ? data?.data : []} rootData={data} />
                    {/* <SimpleGrid columns={3} gap="0px 20px">
                        <FormSection>
                            <DataView
                                title="Total Sales"
                                value={`${formatCurrency(data?.data?.total_sales)}`}
                            />
                        </FormSection>
                        <FormSection>
                            <DataView title="Total Bookings" value={data?.data?.total_bookings} />
                        </FormSection>
                        <FormSection>
                            <DataView
                                title="Total Profit/Loss"
                                value={`${formatCurrency(data?.data?.total_profit_loss)}`}
                            />
                        </FormSection>
                    </SimpleGrid> */}
                </>
            ) : (
                <Text>No data available.</Text>
            )}
        </>
    );
};

export default TableView;
