import React from 'react';

import { EditView } from '@builtbypixel/nucleus';

import setup from './setup';
import Form from './Form';

const EditForm = ({ onSubmit, viewOnly, id }) => {
    return (
        <EditView
            setup={setup}
            isFullWidth
            onSubmit={onSubmit}
            viewOnly={viewOnly}
            id={id}
            defaultValues={{ start_time: '09:00', end_time: '17:00', published: true }}
        >
            <Form id={id} />
        </EditView>
    );
};

export default EditForm;
