import React, { useState, useEffect } from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';

import SalesFigures from './SalesFigures';
import CourseSales from './CourseSales';
import CourseProfitability from './CourseProfitability';
import PassRateByCourse from './PassRateByCourse';
import PassRateByTrainer from './PassRateByTrainer';

import Toolbar from './components/Toolbar';
import moment from 'moment';
import FormSection from '../../components/FormSection';
import OverallFigures from './OverallFigures';

import { useHttp, useToast } from '@builtbypixel/nucleus';

const Dashboard = () => {
    const currentDay = moment().format('YYYY-MM-DD');
    const [startDate, setStartDate] = useState(currentDay);
    const [endDate, setEndDate] = useState(currentDay);
    const [deliveryMethods, setDeliveryMethods] = useState([]);
    const [exportData, setExportData] = useState([]);
    const Http = useHttp();
    const toast = useToast();

    useEffect(() => {
        Http.get(`/learning-styles`)
            .then((res) => {
                setDeliveryMethods(res.data.data);
            })
            .catch((err) => {
                toast({
                    status: 'error',
                    message: 'Error fetching delivery methods',
                });
            });
    }, []);

    return (
        <Box width="100%" padding="20px" bg="rgba(0,0,0,0.05)">
            <Box width="100%">
                <Text fontSize="22px" fontWeight="bold" paddingTop="large" paddingBottom="medium">
                    Dashboard
                </Text>
            </Box>

            <FormSection>
                <Toolbar
                    startDate={currentDay}
                    endDate={currentDay}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                />
            </FormSection>

            <OverallFigures startDate={startDate} endDate={endDate} />
            <Flex width="100%" flexWrap="wrap">
                <FormSection
                    title="Sales Figures"
                    w="100%"
                    exportPDFButton={false}
                    exportCSVButton={true}
                    exportData={exportData}
                >
                    <SalesFigures
                        setExportData={setExportData}
                        exportData={exportData}
                        title="Sales Figures"
                    />
                </FormSection>
                <FormSection
                    title="Course Sales"
                    w="49.5%"
                    exportPDFButton={false}
                    exportCSVButton={true}
                    exportData={exportData}
                >
                    <CourseSales
                        deliveryMethods={deliveryMethods}
                        setExportData={setExportData}
                        exportData={exportData}
                        title="Course Sales"
                    />
                </FormSection>
                <FormSection
                    title="Course Profitability"
                    w="49.5%"
                    ml="1%"
                    exportPDFButton={false}
                    exportCSVButton={true}
                    exportData={exportData}
                >
                    <CourseProfitability
                        title="Course Profitability"
                        deliveryMethods={deliveryMethods}
                        setExportData={setExportData}
                        exportData={exportData}
                    />
                </FormSection>
                <FormSection
                    title="Pass Rate By Course"
                    w="49.5%"
                    exportPDFButton={false}
                    exportCSVButton={true}
                    exportData={exportData}
                >
                    <PassRateByCourse
                        deliveryMethods={deliveryMethods}
                        setExportData={setExportData}
                        exportData={exportData}
                        title="Pass Rate By Course"
                    />
                </FormSection>
                <FormSection
                    title="Pass Rate By Trainer"
                    w="49.5%"
                    ml="1%"
                    exportPDFButton={false}
                    exportCSVButton={true}
                    exportData={exportData}
                >
                    <PassRateByTrainer
                        deliveryMethods={deliveryMethods}
                        setExportData={setExportData}
                        exportData={exportData}
                        title="Pass Rate By Trainer"
                    />
                </FormSection>
            </Flex>
        </Box>
    );
};

export default Dashboard;
