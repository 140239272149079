import { Stack, Button, Text, Flex } from '@chakra-ui/react';
import React from 'react';
import { MdAccessTime } from 'react-icons/md';
import Section from '../Section';
import { BsCreditCard } from 'react-icons/bs';
import { GiWallet } from 'react-icons/gi';
import { useFormContext, useWatch } from 'react-hook-form';
import { useParams } from 'react-router';

const BookingType = () => {
    const isProvisional = useWatch({ name: 'provisional_booking' });
    const isCreditBooking = useWatch({ name: 'credit_booking' });
    const customer = useWatch({ name: 'customer' });
    const { setValue } = useFormContext();
    const { type } = useParams();

    const setProvisionalBooking = () => {
        setValue('provisional_booking', true);
        setValue('future_payment', true);
        setValue('credit_booking', false);
    };

    const setStandardBooking = () => {
        setValue('provisional_booking', false);
        setValue('future_payment', false);
        setValue('credit_booking', false);
    };

    // const setCreditBooking = () => {
    //     setValue('provisional_booking', false);
    //     setValue('future_payment', false);
    //     setValue('credit_booking', true);
    // };

    return type === 'create' ? (
        <Section title="Booking Type" mb="20px">
            <Flex w="100%" direction="column">
                <Stack isInline spacing="25px" w="100%">
                    <Button
                        size="lg"
                        isFullWidth
                        variant="outline"
                        border={isProvisional ? '2px' : '1px'}
                        leftIcon={<MdAccessTime fontSize="22px" />}
                        colorScheme={isProvisional ? 'blue' : 'gray'}
                        onClick={() => setProvisionalBooking()}
                    >
                        Provisional
                    </Button>
                    <Button
                        size="lg"
                        isFullWidth
                        variant="outline"
                        border={!isProvisional && !isCreditBooking ? '2px' : '1px'}
                        leftIcon={<BsCreditCard fontSize="22px" />}
                        colorScheme={!isProvisional && !isCreditBooking ? 'blue' : 'gray'}
                        onClick={() => setStandardBooking()}
                    >
                        {customer?.payment_terms !== 0
                            ? `Card Payment / Raise Invoice`
                            : `Card Payment`}
                    </Button>
                    {/* <Button
                        size="lg"
                        isFullWidth
                        variant="outline"
                        border={!isProvisional && isCreditBooking ? '2px' : '1px'}
                        leftIcon={<GiWallet fontSize="22px" />}
                        colorScheme={!isProvisional && isCreditBooking ? 'blue' : 'gray'}
                        onClick={() => setCreditBooking()}
                    >
                        Credit Terms
                    </Button> */}
                </Stack>
                {isProvisional && <Text mt="20px">No payment is required at this time.</Text>}
            </Flex>
        </Section>
    ) : null;
};

export default BookingType;
