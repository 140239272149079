const modelName = 'Course Features';
const modelNameSingular = 'Course Feature';
const endpoint = '/all-features';

const setup = {
    title: modelName,
    singular: modelNameSingular,
    model: modelName.replace(/\s+/g, '-').toLowerCase(),
    canDelete: true,
    canAddNew: true,
    canSearch: true,
    endpoint,
    accessor: 'id',
};

export default setup;
