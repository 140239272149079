import React, { useMemo } from 'react'
import { AutoFields, EditView } from '@builtbypixel/nucleus'

import setup from './setup'

const EditForm = () => {
   const fields = useMemo(
      () => [
         {
            group: 'Free Trial Request',
            fields: [
               {
                  name: 'course.name',
                  component: 'text',
                  placeholder: 'Course',
                  label: 'Course',
                  disabled: true
               },
               {
                  name: 'first_name',
                  component: 'text',
                  placeholder: 'First Name',
                  label: 'First Name',
                  disabled: true
               },
               {
                  name: 'last_name',
                  component: 'text',
                  placeholder: 'Last Name',
                  label: 'Last Name',
                  disabled: true
               },
               {
                  name: 'email',
                  component: 'text',
                  placeholder: 'Email Address',
                  label: 'Email Address',
                  type: 'email',
                  disabled: true
               },
               {
                  name: 'phone_number',
                  component: 'text',
                  placeholder: 'Phone Number',
                  label: 'Phone Number',
                  type: 'number',
                  disabled: true
               },
               {
                  name: 'message',
                  component: 'textarea',
                  placeholder: 'Message',
                  label: 'Message',
                  disabled: true
               },
               {
                  name: "expiry_date",
                  component: "date",
                  label: "Expires"
               },
            ]
         }
      ],
      []
   )
   return (
      <EditView setup={setup}>
         <AutoFields fields={fields} />
      </EditView>
   )
}

export default EditForm
