import { Flex, Text, Button, Input, Spinner } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useGet, Select, useHttp } from '@builtbypixel/nucleus';
import useSWR, { mutate } from 'swr';
import { useParams } from 'react-router';
import { FiExternalLink } from 'react-icons/fi';
import { GoTrashcan } from 'react-icons/go';
import { PopConfirm } from '../../../../Nucleus';

import CustomTable from '../../../../components/common/CustomTable';

const LearnerUploads = ({ id: eventId }) => {
    const [success, setSuccess] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [arr, setArr] = useState([]);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    const { data: delegates } = useSWR(`/events-learner-uploads/${eventId ? eventId : id}`, useGet);

    const { data: documentStatuses } = useSWR('/document-statuses', useGet);

    const Http = useHttp();

    useEffect(() => {
        if (delegates && delegates.data) setArr(delegates);
    }, [delegates]);
    const handleChangeStatus = (selectedStatus, rowItem) => {
        const updateObject = {
            document_status_id: selectedStatus.id,
        };

        Http.put(`/user-uploads/${rowItem.id}`, updateObject)
            .then((res) => {
                mutate(`/events-learner-uploads/${id}`);
            })
            .catch((err) => {
                console.log('error', err);
            });
    };

    const handleDelete = (docId) => {
        if (docId != 'undefined' || docId != null) {
            Http.delete(`/user-uploads-single/${docId}`)
                .then((res) => {
                    mutate(`/events-learner-uploads/${id}`);
                })
                .catch((err) => {
                    console.log('error', err);
                });
        }
    };

    const get_event_delegates = () => {
        setLoading(true);
        Http.get(`/events-learner-uploads/${eventId ? eventId : id}?search=${searchInput}`).then(
            (res) => {
                setArr(res.data);
                setLoading(false);
            },
        );
    };
    useEffect(() => {
        if (delegates && delegates.data) {
            return setArr(delegates);
        }
    }, [delegates]);

    const columns = [
        {
            Header: 'Name',
            accessor: 'user.first_name',
            Cell: ({ row }) => (
                <Text>
                    {row.original.user.first_name} {row.original.user.last_name}
                </Text>
            ),
        },
        {
            Header: 'Email',
            accessor: 'user.email',
        },
        {
            Header: 'Document Type',
            accessor: 'document_type.name',
            Cell: ({ row }) => (
                <a target="_blank" href={`${row.original.document.url}`} rel="noreferrer">
                    <Button variant="link" colorScheme="blue" rounded="md">
                        {row.original.document_type.name}
                        <FiExternalLink style={{ marginLeft: '5px' }} />
                    </Button>
                </a>
            ),
        },
        {
            Header: 'File',
            accessor: 'document_status.name',
            Cell: ({ row }) => (
                <Select
                    key={`doc-type-${row.original.id}`}
                    valueKey="id"
                    labelKey="name"
                    options={documentStatuses?.data}
                    name="documentStatus"
                    value={row.original.document_status}
                    onChange={(e) => handleChangeStatus(e, row.original)}
                />
            ),
        },
        {
            Header: '',
            accessor: 'id',
            Cell: ({ row }) => (
                <PopConfirm
                    onConfirm={() => handleDelete(row?.original?.id)}
                    title="Are you sure you want to delete this upload?"
                >
                    <GoTrashcan
                        fontSize="18px"
                        style={{
                            padding: '0',
                            marginRight: '10px',
                        }}
                        cursor="pointer"
                    />
                </PopConfirm>
            ),
        },
    ];

    return (
        <Flex w="100%" direction="column" bg="white" rounded="md" p="15px" align="flex-start">
            <Text fontSize="17px" fontWeight="bold" mb="15px">
                Learner uploads
            </Text>
            <Flex width="50%" gap="10px" justifyContent="space-between" direction="row">
                <Input
                    w="85%"
                    placeholder="Enter a name"
                    onChange={(e) => setSearchInput(e.target.value)}
                />
                <Button onClick={() => get_event_delegates()} isDisabled={loading}>
                    {loading ? (
                        <Spinner
                            thickness="4px"
                            speed="0.35s"
                            emptyColor="#F28E51"
                            color="#1BE5A0"
                            size="lg"
                        />
                    ) : (
                        'Search'
                    )}
                </Button>
            </Flex>

            {arr.data?.length > 0 && (
                <>
                    <CustomTable
                        data={arr ? arr?.data : delegates ? delegates.data : []}
                        columns={columns}
                        documentStatuses={documentStatuses}
                    />
                </>
            )}
        </Flex>
    );
};

export default LearnerUploads;
