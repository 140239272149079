import React from 'react';
import { EditView, Field, useGet } from '@builtbypixel/nucleus';
import setup from './setup';

import { Flex, Stack } from '@chakra-ui/react';

import useSWR from 'swr';
import FormSection from '../../components/FormSection';
import AddressSearch from './AddressSearch';

const EditForm = () => {
    const { data: parkingTypes } = useSWR('/parking', useGet);
    const { data: locations } = useSWR('/locations', useGet);
    const { data: costTypes } = useSWR('/cost-statuses', useGet);

    return (
        <EditView setup={setup}>
            <Flex direction="column" w="100%" h="100%" px="10px">
                <Flex direction="column" maxWidth="1000px">
                    <FormSection title="Venue Details">

                        <Field
                            name="title"
                            component="text"
                            placeholder="Venue Title"
                            label="Title"
                        />
                        <Stack spacing="15px" isInline>
                            <Field
                                name="location"
                                component="select"
                                options={locations ? locations.data : []}
                                labelKey="location_name"
                                valueKey="id"
                                label="Location"
                            />
                            <Field
                                name="parking"
                                component="select"
                                options={parkingTypes ? parkingTypes.data : []}
                                labelKey="name"
                                valueKey="id"
                                label="Parking"
                            />
                        </Stack>
                    </FormSection>
                    <FormSection title="Location">
                        <Field component={AddressSearch} name="address" />
                    </FormSection>
                    <FormSection title="Costs">
                        <Field
                            component="repeater"
                            name="costs"
                            fieldArray={[
                                {
                                    name: 'type',
                                    component: 'text',
                                    label: 'Name',
                                },
                                {
                                    name: 'price',
                                    component: 'text',
                                    label: 'Cost (£)',
                                },
                                {
                                    name: 'status',
                                    component: 'select',
                                    options: costTypes ? costTypes.data : [],
                                    rules: { required: true },
                                    labelKey: 'name',
                                    valueKey: 'id',
                                    label: 'Cost Type',
                                },
                            ]}
                        />
                    </FormSection>
                    <FormSection title="Custom Course Learning Instructions">
                        <Field
                            name={`learning_instructions.content`}
                            component="richtext"
                            label="Custom Course Learning Instructions"
                            placeholder="Enter here..."
                            disableMedia={true}
                        />
                    </FormSection>
                </Flex>
            </Flex>
        </EditView>
    );
};

export default EditForm;
