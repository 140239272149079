import { Field } from '@builtbypixel/nucleus';
import React, { useEffect } from 'react';
import FormSection from '../../../components/FormSection';
import { useFormContext, useWatch } from 'react-hook-form';
import { Box, Divider } from '@chakra-ui/react';

const NominalCodes = () => {
    const { getValues, setValue } = useFormContext();

    const learning_items = useWatch({ name: 'learningItem' });
    const nominal_codesWatch = useWatch({ name: 'nominal_codes' });

    useEffect(() => {
        if (nominal_codesWatch?.length === 0) {
            setValue('nominal_codes', {});
        }
    }, [nominal_codesWatch]);

    const handleUpdateNominalValue = (entry, value, itemID) => {
        const nominal = entry + '.nominal_code';
        const id = entry + '.id';
        setValue(nominal, value);
        setValue(id, itemID);
    };
    return (
        <>
            <FormSection title="Nominal Codes" mt="0px">
                <Field
                    name="nominal_codes.course"
                    component="text"
                    label="Course Nominal Code"
                    placeholder="Course Nominal Code"
                    // rules={{ required: true }}
                />
                <Divider />
                <Box marginBottom="25px"></Box>
                {learning_items?.map((item, index) => {
                    if (item.active) {
                        return (
                            <>
                                <Field
                                    name={`nominal_codes.delivery_methods[${index}].nominal_code`}
                                    component="text"
                                    label={`${item.name} Nominal Code`}
                                    placeholder={`${item.name} Nominal Code`}
                                    onChange={(e) => {
                                        handleUpdateNominalValue(
                                            `nominal_codes.delivery_methods[${index}]`,
                                            e,
                                            item.id,
                                        );
                                    }}
                                />

                                {item?.locations?.length > 0 &&
                                    item?.locations?.map((loc, j) => (
                                        <Box key={`${loc.location_name}-${j}`} ml="40px">
                                            <Field
                                                name={`nominal_codes.delivery_methods[${index}].locations[${j}].nominal_code`}
                                                component="text"
                                                label={`${loc.location_name} Nominal Code`}
                                                placeholder={`${loc.location_name} Nominal Code`}
                                                onChange={(e) => {
                                                    handleUpdateNominalValue(
                                                        `nominal_codes.delivery_methods[${index}].locations[${j}]`,
                                                        e,
                                                        loc.id,
                                                    );
                                                }}
                                            />
                                        </Box>
                                    ))}
                            </>
                        );
                    }
                })}
            </FormSection>
        </>
    );
};

export default NominalCodes;
