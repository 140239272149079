import React, { useEffect, useState } from 'react';
import { Field, LinkButton, useGet, usePost, useHttp } from '@builtbypixel/nucleus';
import {
    Button,
    Badge,
    Flex,
    Text,
    useDisclosure,
    Alert,
    Input,
    Switch,
    FormControl,
    FormLabel,
    FormHelperText,
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Spinner,
    Image,
    useToast,
} from '@chakra-ui/react';
import { FcCancel } from 'react-icons/fc';

import { useParams } from 'react-router';
import { FaFileAlt } from 'react-icons/fa';
import { FiChevronDown, FiTrash } from 'react-icons/fi';

import CustomTable from '../../../../components/common/CustomTable';

import dayjs from 'dayjs';
import IdModal from './IdModal';
import { useWatch, useFormContext } from 'react-hook-form';
import ReasonField from './ReasonField';
import moment from 'moment';
import useSWR, { useSWRConfig } from 'swr';
import { useRowSelect } from 'react-table';
import PassPercentageField from './PassPercentageField';
import EmailDelegate from './EmailDelegate';
import LocalFileUpload from '../../../../Nucleus/Fields/File/Standard.jsx';
// const CompanyLinkButton = (row) => (
//     <LinkButton target="_blank" to={`/businesses/edit/${row.original.id}`}>
//         <Button variant="link" colorScheme="blue" rounded="md" size="sm">
//             {row.original.company_name}
//         </Button>
//     </LinkButton>
// );
import ListView from '../../../../Nucleus/components/ListView';

const Delegates = ({ id: eventId, delegatesData, tableOnly }) => {
    console.log(useParams(), '!!!!!! ALL PARAMS');
    const Http = useHttp();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { id, type } = useParams();
    const [delegate_id, set_delegate_id] = useState('');
    const [booking_id, setBookingId] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [saveLoading, setSaveLoading] = useState(false);
    const [saveError, setSaveError] = useState(null);
    const [saveSuccess, setSaveSuccess] = useState(null);
    const [courseStatus, setCourseStatus] = useState(null);
    const [doAttendance, setDoAttendace] = useState(false);
    const [arr, setArr] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [uploadTypes, setUploadTypes] = useState([]);

    const [idTypes, setIdTypes] = useState([]);
    const { getValues, setValue } = useFormContext();
    const toast = useToast();
    const { mutate } = useSWRConfig();
    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {
        Http.get('user-upload-types').then((res) => {
            setIdTypes(res.data.data);
        });
    }, []);
    const handleSave = (sendEmail) => {
        arr.send_email = sendEmail;

        setSaveLoading(true);
        Http({
            method: doAttendance ? 'put' : 'post',
            url: `/delegate-progression/${id}`,
            data: arr,
        })
            .then((res) => {
                setSaveSuccess(true);
                setSaveLoading(false);
                setSaveError(false);
            })
            .catch((err) => {
                setSaveSuccess(false);
                setSaveLoading(false);
                setSaveError(true);
            });
    };

    const event_status = useWatch({
        name: 'event_status',
    });

    const datesWatch = useWatch({
        name: 'dates',
    });

    useEffect(() => {
        if (isDayBeforeEndDate) {
            Http.get('/delegate-progression-statuses').then((res) => {
                setCourseStatus(res.data.data);
            });
        }
    }, [event_status]);

    useEffect(() => {
        Http.get('document-statuses').then((res) => setUploadTypes(res.data.data));
    }, []);

    const get_event_delegates = () => {
        setLoading(true);
        Http.get(`/events-delegates/${eventId ? eventId : id}?search=${searchInput}`).then(
            (res) => {
                setSearchData(res.data);
                setLoading(false);
            },
        );
    };

    let isDayBeforeEndDate = getValues('day_before_end_date');

    const passPercentage = isDayBeforeEndDate
        ? [
              {
                  Header: 'Percentage',
                  accessor: 'percentage',
                  Cell: ({ row }) => {
                      const id = row?.original?.id;
                      let index = arr?.data?.findIndex((element) => element?.id === id);

                      return <PassPercentageField arr={arr} setArr={setArr} index={index} />;
                  },
              },
          ]
        : [];

    const passOrFailField = isDayBeforeEndDate
        ? [
              {
                  Header: 'Pass / Fail',
                  accessor: 'completion_status',
                  Cell: ({ row, data }) => {
                      const _id = row.original.id;

                      return (
                          <Flex
                              mt="2em"
                              width="110px"
                              direction="column"
                              align="center"
                              justify="center"
                          >
                              <>
                                  <Box position="relative">
                                      <Field
                                          component="select"
                                          name={`${row.original.completion_status}`}
                                          labelKey="name"
                                          valueKey="id"
                                          options={courseStatus}
                                          value={
                                              row.original?.completion_status
                                                  ? row.original?.completion_status
                                                  : null
                                          }
                                          onChange={(val) => {
                                              let x = data;
                                              let index = data?.findIndex(
                                                  (element) => element.id === _id,
                                              );

                                              if (index || index === 0) {
                                                  x[index] = {
                                                      ...x[index],
                                                      completion_status: val,
                                                      id: x[index].id,
                                                  };

                                                  setArr({ ...arr, data: x });
                                              }
                                          }}
                                      />
                                  </Box>
                                  {row.original.completion_status?.name && (
                                      <EmailDelegate id={id} arr={arr} row={row} />
                                  )}
                              </>
                          </Flex>
                      );
                  },
              },
          ]
        : [];

    const reasonField = isDayBeforeEndDate
        ? [
              {
                  Header: 'Comments',
                  accessor: 'reason',
                  Cell: ({ row }) => {
                      const id = row.original.id;
                      let index = arr?.data?.findIndex((element) => element.id === id);

                      return <ReasonField arr={arr} setArr={setArr} index={index} />;
                  },
              },
          ]
        : [];

    /*

        {
            Header: 'Cetificate',
            accessor: 'last_name',
            Cell: ({ row }) => (
                <>
                    {console.log(row.original, '!!!!!!ORIGNIALLL!')}

                    {row.original.certificate?.length === 0 ? (
                        <Button
                            size="sm"
                            onClick={() => {
                                set_delegate_id(row.original.id);
                                setBookingId(row.original.booking.id);
                                onOpen();
                            }}
                        >
                            Upload Certificate
                        </Button>
                    ) : (
                        <>
                            <LinkButton
                                target="_blank"
                                rel="noreferrer"
                                to={row.original.certificate?.[0]?.certificate?.[0]?.url}
                            >
                                <Button
                                    size="sm"
                                    target="_blank"
                                    href={row.original.certificate?.certificate?.[0]?.url}
                                >
                                    <FaFileAlt />
                                </Button>
                            </LinkButton>
                            <Button
                                target="_blank"
                                onClick={() => {
                                    set_delegate_id(row.original.id);
                                    setBookingId(row.original.booking.id);
                                    onOpen();
                                }}
                                ml={5}
                                size="sm"
                                variant="outline"
                            >
                                Add New
                            </Button>
                        </>
                    )}
                </>
            ),
        },
            */
    const handleDeleteCert = (userId) => {
        Http.delete(`/event-upload-certificate/${userId}/${id}`)
            .then((res) => {
                mutate(`/events-delegates/${eventId ? eventId : id}`);
                return toast({
                    status: 'success',
                    title: 'certificate was deleted successfully',
                    position: 'top',
                });
            })
            .catch((err) => {
                return toast({
                    status: 'error',
                    title: err,
                    position: 'top',
                });
            });
    };

    const certificateField = isDayBeforeEndDate
        ? [
              {
                  Header: 'Certificate',
                  accessor: 'certificate',
                  Cell: ({ row }) => {
                      console.log(row.original, 'ROW');
                      const hasAtLeastOneCertificate = (data) => {
                          let result = data?.some((item) => {
                              return item?.certificate.length === 0;
                          });

                          return result;
                      };

                      return (
                          <>
                              {row.original.certificate?.length === 0 ||
                              hasAtLeastOneCertificate(row.original.certificate) ? (
                                  <Button
                                      size="sm"
                                      onClick={() => {
                                          set_delegate_id(
                                              row.original.id === ''
                                                  ? row.original.customer.id
                                                  : row.original.id,
                                          );
                                          onOpen();
                                      }}
                                  >
                                      Upload Certificate
                                  </Button>
                              ) : (
                                  <Flex>
                                      {row.original.certificate?.[0]?.certificate?.[0]?.url && (
                                          <LinkButton
                                              target="_blank"
                                              rel="noreferrer"
                                              to={
                                                  row.original.certificate?.[0]?.certificate?.[0]
                                                      ?.url
                                              }
                                              style={{ cursor: 'pointer' }}
                                          >
                                              <FaFileAlt
                                                  style={{
                                                      cursor: 'pointer',
                                                      fontSize: '18px',
                                                      marginTop: '5px',
                                                  }}
                                              />
                                          </LinkButton>
                                      )}
                                      <Button
                                          target="_blank"
                                          onClick={() => {
                                              set_delegate_id(
                                                  row.original.id === ''
                                                      ? row.original.customer.id
                                                      : row.original.id,
                                              );
                                              onOpen();
                                          }}
                                          size="md"
                                          variant="link"
                                      >
                                          +
                                      </Button>
                                      <Button
                                          target="_blank"
                                          onClick={() => {
                                              handleDeleteCert(row.original.id);
                                          }}
                                          size="md"
                                          variant="link"
                                      >
                                          <FiTrash />
                                      </Button>
                                  </Flex>
                              )}
                          </>
                      );
                  },
              },
          ]
        : [];

    // const { data: delegates } = useSWR(
    //     delegatesData
    //         ? null
    //         : `/events-delegates/${eventId ? eventId : id}${
    //               searchInput ? '?search=' + searchInput : null
    //           }`,
    //     useGet,
    // );
    const { data: delegates } = useSWR(
        delegatesData ? null : `/events-delegates/${eventId ? eventId : id}`,
        useGet,
    );

    const certificate = useWatch({ name: 'certificate' });

    useEffect(() => {
        if (delegates && delegates.data) setArr(delegates);
    }, [delegates]);

    const save_certificate = () => {
        setLoading(true);

        Http.post(`/event-upload-certificate/${delegate_id ?? ''}/${eventId ? eventId : id}`, {
            certificate,
            order_id: booking_id,
            delegate_id: delegate_id,
        })
            .then((res) => {
                console.log(res);
                setSuccess(true);
                setLoading(false);
                setError(false);
                onClose();
                mutate(`/events-delegates/${eventId ? eventId : id}`);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                setSuccess(false);
                setError(true);
                onClose();
            });
    };

    const delegateColumn = {
        Header: 'Delegate',
        accessor: 'first_name',
        Cell: ({ row }) => (
            <>
                <>
                    <Flex justify={'start'} alignItems="center" mb="5px">
                        {row.original.id === '' ? (
                            <Badge variant="solid" colorScheme="red">
                                Unconfirmed Delegate
                            </Badge>
                        ) : (
                            <LinkButton target="_blank" to={`/personal/edit/${row.original.id}`}>
                                <Button size="sm" variant="link" colorScheme="blue" rounded="md">
                                    <Text color="gray.500">
                                        {row.original.first_name} {row.original.last_name}
                                    </Text>
                                </Button>
                            </LinkButton>
                        )}
                        <Text mx="5px" color="gray.500">
                            /
                        </Text>

                        <LinkButton
                            target="_blank"
                            to={`/bookings/edit/${row.original.booking.id}`}
                            style={{ marginLeft: '2px' }}
                        >
                            <Button variant="link" colorScheme="blue" rounded="md" size="sm">
                                {' '}
                                {row.original.booking.reference}
                            </Button>
                        </LinkButton>

                        {row.original.cancellations?.length > 0 && (
                            <Box ml="1em">
                                <FcCancel />
                            </Box>
                        )}
                    </Flex>
                    <Box>{row.original.email ? row.original.email : null}</Box>

                    {row.original.user_types?.name === 'Business' && row.original.company_name && (
                        <Box>
                            <LinkButton to={`/businesses/edit/${row.original.id}`}>
                                <Button size="sm" variant="link" color="gray.500" rounded="md">
                                    <Text color="gray.500" paddingTop="5px">
                                        {row.original.company_name}
                                    </Text>
                                </Button>
                            </LinkButton>
                        </Box>
                    )}
                </>
            </>
        ),
    };
    const onLearnerUploadSuccess = () => {
        mutate(`/events-delegates/${eventId ? eventId : id}`);
        mutate(`/events-learner-uploads/${eventId ? eventId : id}`);
    };
    const columns = [
        {
            Header: 'ID',
            accessor: 'photo_uploads',
            Cell: ({ value, row, data }) => {
                const id = row.original.id;
                let index = data?.findIndex((element) => element.id === id);

                return (
                    <Flex align="center">
                        <IdModal
                            user={row?.original}
                            uploadTypes={uploadTypes}
                            arr={data}
                            setArr={setArr}
                            id={id}
                            index={index}
                            onSuccess={onLearnerUploadSuccess}
                            idTypes={idTypes}
                            docType="photo_uploads"
                        />
                    </Flex>
                );
            },
        },
        {
            Header: 'Bundle',
            accessor: 'id',
            Cell: ({ value, row }) =>
                row?.original?.is_bundle ? (
                    <Image ml="0.5em" src="/bundle_icon.png" height="25px" width="25px" />
                ) : (
                    ''
                ),
        },
        delegateColumn,
        {
            Header: 'Booker',
            accessor: 'booker',
            Cell: ({ row }) => {
                return (
                    <>
                        <Box>
                            {row.original.customer?.email ? row.original.customer.email : null}
                        </Box>
                        {row.original.customer?.user_types?.name !== 'Business' && (
                            <Box>
                                <LinkButton
                                    target="_blank"
                                    to={`/personal/edit/${row.original.customer?.id}`}
                                >
                                    <Button
                                        size="sm"
                                        variant="link"
                                        colorScheme="blue"
                                        rounded="md"
                                    >
                                        <Text color="gray.500" paddingTop="5px">
                                            {row.original.customer?.first_name}{' '}
                                            {row.original.customer?.last_name}
                                        </Text>
                                    </Button>
                                </LinkButton>
                            </Box>
                        )}
                        {row.original.customer?.company_name && (
                            <Box>
                                <LinkButton to={`/businesses/edit/${row.original.customer.id}`}>
                                    <Button
                                        size="sm"
                                        variant="link"
                                        colorScheme="blue"
                                        rounded="md"
                                    >
                                        <Text color="gray.500" paddingTop="5px">
                                            {row.original.customer?.company_name}
                                        </Text>
                                    </Button>
                                </LinkButton>
                            </Box>
                        )}
                    </>
                );
            },
        },
        {
            Header: 'Purchase Date',
            accessor: 'purchase_date',
            Cell: ({ row }) => <>{moment(row.original.created_at).format('DD/MM/YYYY')}</>,
        },
        {
            Header: 'Uploads',
            accessor: 'learner_uploads',
            Cell: ({ value, row, data }) => {
                const id = row.original.id;
                let index = data?.findIndex((element) => element.id === id);

                return (
                    <IdModal
                        user={row?.original}
                        uploadTypes={uploadTypes}
                        arr={arr}
                        setArr={setArr}
                        id={id}
                        index={index}
                        onSuccess={get_event_delegates}
                        idTypes={idTypes}
                        docType="learner_uploads"
                    />
                );
            },
        },
        ...certificateField,
        ...passOrFailField,
        ...passPercentage,
        ...reasonField,
    ];

    const attendanceColumns = [
        delegateColumn,

        {
            Header: 'Attendance',
            accessor: 'dates',
            Cell: ({ value, row, data }) => {
                const id = row.original.id;

                let index = data?.findIndex((element) => element.id === id);

                return (
                    <Flex w="100%">
                        {row.original.id !== '' &&
                            row?.original?.dates?.map((each, j) => {
                                const thisDate = datesWatch.find((el) => el.id === each.date_id);

                                return (
                                    <FormControl key={`whole-form-${j}`}>
                                        {index === 0 && (
                                            <FormLabel>
                                                {dayjs(thisDate?.start).format('DD/MM/YY')}
                                            </FormLabel>
                                        )}

                                        <Flex
                                            width="100%"
                                            px="0.5em"
                                            borderRight="1px solid #e2e8f0"
                                        >
                                            <Box mr="2em">
                                                <Switch
                                                    key={`attend-${j}`}
                                                    size="md"
                                                    isChecked={each['attended']}
                                                    defaultChecked={each['attended']}
                                                    onChange={(val) => {
                                                        let x = data;
                                                        if (index || index === 0) {
                                                            x[index].dates[j]['attended'] =
                                                                val.target.checked;

                                                            setArr({ ...arr, data: x });
                                                        }
                                                    }}
                                                />
                                                <FormHelperText fontSize="10px">
                                                    Attended
                                                </FormHelperText>
                                            </Box>

                                            <Box>
                                                <Switch
                                                    key={`complete-${j}`}
                                                    colorScheme="green"
                                                    size="md"
                                                    isChecked={each['completed']}
                                                    defaultChecked={each['completed']}
                                                    onChange={(val) => {
                                                        let x = data;
                                                        if (index || index === 0) {
                                                            x[index].dates[j]['completed'] =
                                                                val.target.checked;
                                                            setArr({ ...arr, data: x });
                                                        }
                                                    }}
                                                />
                                                <FormHelperText fontSize="10px">
                                                    Completed
                                                </FormHelperText>
                                            </Box>
                                            <Box ml="5em">
                                                <Switch
                                                    key={`complete-${j}`}
                                                    colorScheme="red"
                                                    size="md"
                                                    isChecked={
                                                        each['completed'] && each['attended']
                                                    }
                                                    onChange={(val) => {
                                                        let x = data;
                                                        if (index || index === 0) {
                                                            x[index].dates[j]['completed'] =
                                                                val.target.checked;
                                                            x[index].dates[j]['attended'] =
                                                                val.target.checked;
                                                            setArr({ ...arr, data: x });
                                                        }
                                                    }}
                                                />
                                                <FormHelperText fontSize="10px">
                                                    Set All
                                                </FormHelperText>
                                            </Box>
                                        </Flex>
                                    </FormControl>
                                );
                            })}
                    </Flex>
                );
            },
        },
    ];

    const hasCancelled = (row) => {
        return row.original.cancellations?.length === 0 ? false : true;
    };

    const getTrProps = (row, datas) => {
        const user = datas?.data?.find((el) => el.id === row.original.id);
        const status = user?.completion_status?.name;

        if (row) {
            return {
                style: {
                    background: hasCancelled(row)
                        ? '#d1cfcf'
                        : status === 'Failed'
                        ? '#f3bfbf'
                        : '#adf3bc',
                },
            };
        } else return {};
    };

    return (
        <>
            {success && (
                <Alert mt={5} title="Upload Successful" status="success" hasIcon={true}>
                    Certificate has been uploaded
                </Alert>
            )}
            {error && (
                <Alert mt={5} title="Error" status="error" hasIcon={true}>
                    Error uploading certificate
                </Alert>
            )}
            {saveSuccess && (
                <Alert mt={5} title="Upload Succesful" status="success" hasIcon={true}>
                    Delegates Have Been Saved
                </Alert>
            )}
            {saveError && (
                <Alert mt={5} title="Error" status="error" hasIcon={true}>
                    Error Saving Delegates
                </Alert>
            )}
            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Upload Certificate</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Field
                            name="expiry_date"
                            component="date"
                            placeholder="Select Expiry Date"
                            label="Select Expiry Date (optional)"
                        />
                        <LocalFileUpload onChange={(e) => setValue('certificate', e)} max={1} />

                        {/* <Field name="certificate" component="file" max={1} /> */}
                        {/* <Button colorScheme="blue" mr={3} onClick={() => save_certificate()}>
                            {loading ? '...Submitting' : 'Save'}
                        </Button> */}
                    </ModalBody>
                    {/* <ModalFooter>
                        <Button isLoading={loading} colorScheme="blue" mr={3} onClick={onClose}>
                    </ModalBody> */}
                    <ModalFooter>
                        <Button colorScheme="green" mr={5} onClick={() => save_certificate()}>
                            {loading ? '...Submitting' : 'Save'}
                        </Button>
                        <Button colorScheme="blue" onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Flex
                direction="column"
                bg="white"
                rounded="md"
                p="15px"
                align="flex-start"
                overflowX="scroll"
            >
                <Flex width="100%" align="center" justify="space-between">
                    <Text fontSize="17px" fontWeight="bold" mb="15px">
                        Delegates
                    </Text>
                    <Flex width="50%" gap="10px" justifyContent="space-between" direction="row">
                        <Input
                            w="85%"
                            placeholder="Enter a name"
                            onChange={(e) => setSearchInput(e.target.value)}
                        />
                        <Button onClick={() => get_event_delegates()} isDisabled={loading}>
                            {loading ? (
                                <Spinner
                                    thickness="4px"
                                    speed="0.35s"
                                    emptyColor="#F28E51"
                                    color="#1BE5A0"
                                    size="lg"
                                />
                            ) : (
                                'Search'
                            )}
                        </Button>
                    </Flex>
                    {arr?.data?.length > 0 ? (
                        <Flex style={{ zIndex: '9999' }}>
                            <Button
                                colorScheme={doAttendance ? 'red' : 'blue'}
                                mr="10px"
                                onClick={() => setDoAttendace(!doAttendance)}
                            >
                                {doAttendance ? 'Back' : 'Mark Attendance'}
                            </Button>

                            <Menu style={{ zIndex: '9999' }}>
                                <MenuButton
                                    _hover={{ bg: '#15cc65' }}
                                    color="white"
                                    bg="#27aD60"
                                    as={Button}
                                    rightIcon={saveLoading ? <Spinner /> : <FiChevronDown />}
                                    loading
                                    style={{ zIndex: '9999' }}
                                >
                                    Save
                                </MenuButton>
                                <MenuList style={{ zIndex: '9999' }}>
                                    <MenuItem
                                        style={{ zIndex: '9999' }}
                                        onClick={() => handleSave(0)}
                                    >
                                        Save
                                    </MenuItem>
                                    <MenuItem
                                        style={{ zIndex: '9999' }}
                                        onClick={() => handleSave(true)}
                                    >
                                        Save & Email
                                    </MenuItem>
                                </MenuList>
                            </Menu>

                            {/* <Button>{saveLoading ? '...Sending' : 'Email Delegates'}</Button>
                            <Button _hover={{ bg: '#15cc65' }} color="white" bg="#27aD60">
                                {saveLoading ? '...Submitting' : 'Save Delegates'}
                            </Button> */}
                        </Flex>
                    ) : (
                        <Text>There are currently no delegates assigned to this event</Text>
                    )}
                </Flex>
                {type === 'edit' && (
                    <ListView
                        tableOnly
                        searchData={searchData}
                        columns={doAttendance ? attendanceColumns : columns}
                        noInitalFetch
                        getTrProps={getTrProps}
                        setup={{
                            endpoint: `/events-delegates/${eventId ? eventId : id}`,
                            title: 'Events',
                            canSelect: false,
                        }}
                    />
                )}
                {/* {arr.data?.length > 0 && (
                    <>
                        <CustomTable
                            data={arr ? arr.data : []}
                            // data={delegates ? delegates.data : []}
                            columns={doAttendance ? attendanceColumns : columns}
                            hidePagination={tableOnly}
                            getTrProps={getTrProps}
                        />
                    </>
                )} */}
            </Flex>
        </>
    );
};

export default Delegates;
