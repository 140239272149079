import { Field } from '@builtbypixel/nucleus';
import { Flex, Text, Box } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import TrainerAvailibility from '../TrainerAvailibility';

const TrainerDetails = () => {
    const trainersList = useWatch({ name: 'trainers' });

    return (
        <Flex w="100%" direction="column" bg="white" rounded="md" p="15px" align="flex-start">
            {trainersList && trainersList.length > 0 && (
                <>
                    <Text fontSize="17px" fontWeight="bold" mb="15px">
                        Assigned Trainer Details
                    </Text>
                    {trainersList.map((trainer) => (
                        <>
                        <Box width="100%">
                            <Box width="100%">
                                <Text padding="5px">Name: {trainer.name}</Text>
                                <Text padding="5px">Email: {trainer.email}</Text>
                                <Text padding="5px">Price: £{trainer.price}</Text>
                            </Box>
                            <Text fontWeight="bold" padding="5px">
                                Don't forgot to save if you change trainers
                            </Text>
                            
                        </Box>
                        
                        <TrainerAvailibility />
                        </>
                    ))}
                </>
            )}
        </Flex>
    );
};

export default TrainerDetails;
