import React from 'react';
import { Input } from '@chakra-ui/react';

const Text = (props) => {
    const { onFocus, onChange, onBlur, value, defaultValue, maxLength, children, type, ...rest } =
        props;
    return (
        <Input
            onChange={(e) => onChange(e.target.value)}
            onBlur={() => {
                onBlur && onBlur();
            }}
            onFocus={() => {
                onFocus && onFocus();
            }}
            value={value ? value : defaultValue ? defaultValue : ''}
            required={false}
            isRequired={false}
            minWidth={type === 'number' ? '100px' : '200px'}
            type={type}
            maxLength={maxLength}
            {...rest}
        />
    );
};

export default Text;
