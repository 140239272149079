import React, { useMemo } from "react";
import { AutoFields, EditView } from "@builtbypixel/nucleus";

import setup from "./setup";

const EditForm = () => {
  const fields = useMemo(
    () => [
      {
        group: "Job Application",
        fields: [
          {
            name: "job.job_name",
            component: "text",
            placeholder: "Job Name",
            label: "Job Name",
            isDisabled: true,
          },
          {
            name: "name",
            component: "text",
            isDisabled: true,
            placeholder: "Applicant Name",
            label: "Applicant Name",
          },
          {
            name: "email",
            component: "text",
            isDisabled: true,
            placeholder: "Applicant Email",
            label: "Applicant Email",
          },
          {
            name: "message",
            component: "textarea",
            isDisabled: true,
            placeholder: "Applicant Message",
            label: "Applicant Message",
          },
          // FIX NEEDED
          //  {
          //    name: "document.url",
          //    component: "file",
          //    placeholder: "Applicant CV",
          //    label: "Applicant CV",
          //    isDisabled: true,
          //  },
        ],
      },
    ],
    []
  );
  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
