import React from 'react';
import { EditView, Field, useGet } from '@builtbypixel/nucleus';
import setup from './setup';

import { Flex, Stack } from '@chakra-ui/react';

import useSWR from 'swr';

const EditForm = () => {

    return (
        <EditView setup={setup}>
            <Flex direction="column" w="100%" h="100%" px="10px">
                <Flex direction="column" maxWidth="1000px">
                    
                </Flex>
            </Flex>
        </EditView>
    );
};

export default EditForm;
