import { Tab, TabList } from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
const EditMenu = () => {
    const { type } = useParams();
    return (
        <TabList pt="10px" pl="10px" w="100%">
            <Tab>Details</Tab>

            {type === 'edit' && (
                <>
                    <Tab>Events</Tab>
                    <Tab>Bookings</Tab>
                    <Tab>Certificates</Tab>
                    <Tab>Summary</Tab>
                </>
            )}
        </TabList>
    );
};

export default EditMenu;
