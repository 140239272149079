import React, { useMemo, useState, useEffect } from 'react';
import { AutoFields, EditView } from '@builtbypixel/nucleus';
import { useHttp } from '@builtbypixel/nucleus';
import setup from './setup';

const EditForm = () => {
  const [cancellationStatus, setCancellationStatus] = useState([]);

  const Http = useHttp();

  useEffect(() => {
    Http.get(`/cancellation-statuses`).then((res) => {
      setCancellationStatus(res.data.data);
    });
    return () => {};
  }, []);
  const fields = useMemo(
    () => [
      {
        group: 'Refund requests',
        tab: 'User Information',
        fields: [
          {
            name: 'user.first_name',
            component: 'text',
            placeholder: 'First Name',
            label: 'First Name',
            disabled: true,
          },
          {
            name: 'user.last_name',
            component: 'text',
            placeholder: 'Last Name',
            label: 'Last Name',
            disabled: true,
          },
          {
            name: 'user.email',
            component: 'text',
            placeholder: 'Email',
            label: 'Email',
            disabled: true,
          },
        ],
      },
      {
        group: 'Course information',
        tab: 'Course Information',
        fields: [
          {
            name: 'course.name',
            component: 'text',
            placeholder: 'Course Name',
            label: 'Course Name',
            disabled: true,
          },
        ],
      },
      {
        group: 'Refund processing',
        tab: 'Processing',
        fields: [
          {
            name: 'cancellation.created_at',
            component: 'date',
            label: 'Requested Date',
            placeholder: 'Requested Date',
          },
          {
            name: 'cancellation.days',
            component: 'text',
            type: 'number',
            label: 'Discount band day',
            placeholder: 'Discount band day',
            disabled: true,
          },
          {
            name: 'cancellation.discount',
            component: 'text',
            type: 'number',
            label: 'Discount percent',
            placeholder: 'Discount percent',
            disabled: true,
          },
          {
            name: 'status',
            component: 'select',
            options: cancellationStatus,
            placeholder: 'Status',
            labelKey: 'name',
            valueKey: 'id',
            label: 'Status',
            variant: 'outline',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'internal_notes',
            component: 'textarea',
            placeholder: 'Internal notes',
            label: 'Internal notes',
            isRequired: true,
            rules: { required: true },
          },
        ],
      },
    ],
    [cancellationStatus]
  );
  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
