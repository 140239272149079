import React, { useMemo } from 'react'
import { AutoFields, EditView } from '@builtbypixel/nucleus'

import setup from './setup'

import { useEffect, useState } from 'react'
import { useHttp } from '@builtbypixel/nucleus'

const EditForm = () => {

   const [course, setCourse] = useState([])
   const Http = useHttp()

   const customLabel = (label)=>{
     return label.title
   }

   useEffect(()=>{
      Http.get("/courses")
      .then((res) =>{setCourse(res.data.data)})

   },[])
   // console.log(course)



   const fields = useMemo(
      () => [
         {
            group: 'Reviews',
            fields: [
               {
                  name: 'course_name',
                  options: course,
                  formatOptionLabel: customLabel,
                  component: 'select',
                  // labelKey: 'course.course_slug',
                  valueKey: 'id',
                  placeholder: 'Course Name',
                  label: 'Course Name',
                  isRequired: true,
                  rules: { required: true }
               },
               {
                  name: 'course_content',
                  component: 'text',
                  placeholder: 'Course Content',
                  label: 'Course Content',
                  isRequired: true,
                  rules: { required: true }
               },
               {
                  name: 'course_trainer',
                  component: 'text',
                  placeholder: 'Course trainer',
                  label: 'Course Trainer',
                  isRequired: true,
                  rules: { required: true }
               },
               {
                  name: 'comments',
                  component: 'textarea',
                  placeholder: 'Comments',
                  label: 'Comments',
                  isRequired: true,
                  rules: { required: true }
               },
               {
                  name: 'course_rating',
                  component: 'text',
                  type:'number',
                  placeholder: 'Course rating',
                  label: 'Course rating',
                  isRequired: true,
                  rules: { required: true }
               },
               {
                  name: 'course_name.id',
                  component: 'text',
                  type:'number',
                  placeholder: 'Course id',
                  label: 'Course id',
                  isRequired: true,
                  rules: { required: true }
               },
            ]
         }
      ],
      [course]
   )
   return (
      <EditView setup={setup}>
         <AutoFields fields={fields} />
      </EditView>
   )
}

export default EditForm
