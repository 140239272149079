import React, { useContext, useEffect, useState, useRef } from 'react';
import FroalaEditor from 'froala-editor';
import styled from '@emotion/styled';
import { useTheme } from '@chakra-ui/react';
import { useHttp } from '../../hooks';
import { CloudinaryUploadContext } from '../../Core/Kernel';
import { useRecoilState } from 'recoil';
import { mediaAtom as _mediaAtom } from '../../state/media';

const apiUrl = process.env.REACT_APP_API;
/* eslint-disable */
const log = () => {
    if (editorRef.current) {
        console.log(editorRef.current.getContent());
    }
};
const StyledContainer = styled.div`
    color: ${(props) => props.theme.colors.global.text};
    z-index: 0;
    position: relative;
    h1 {
        font-size: 2rem;
    }
    h2 {
        font-size: 1.5rem;
    }
    h3 {
        font-size: 1.1rem;
    }
    .fr-toolbar {
        background-color: transparent;
        border-radius: 10px;
        color: ${(props) => props.theme.colors.global.text};
        padding: 3px 0px;
        border: 0;
        z-index: 5000 !important;
        .fr-newline {
            background-color: white;
        }
        .fr-btn-grp {
            margin-left: 0px;
        }
        .fr-command.fr-btn {
            margin: 0;
            color: ${(props) => props.theme.colors.global.text};
            &:hover {
                background-color: ${(props) => props.theme.colors.global.elementBg};
            }
            svg {
                height: 20px;
            }
            &.fr-active svg path {
                fill: ${(props) => props.theme.colors.secondary};
            }
            &:not(.fr-active) svg path {
                fill: ${(props) => props.theme.colors.global.text};
            }

            &.fr-btn-active-popup {
                background: ${(props) => props.theme.colors.secondary};
                svg path {
                    fill: white;
                }
            }
        }
    }
    .fr-second-toolbar #fr-logo {
        display: none;
    }
    .fr-second-toolbar .fr-counter {
        font-size: 12px;
        padding-top: 3px;
    }
    .fr-popup {
        background-color: ${(props) => props.theme.colors.global.elementBgAlt};
        color: ${(props) => props.theme.colors.global.text};
        z-index: 9999;
        .fr-buttons {
            background-color: ${(props) => props.theme.colors.global.elementBgAlt};
        }
        .fr-input-line {
            padding: 8px 0px;

            label {
                top: 23px;
                background: transparent;
                color: ${(props) => props.theme.colors.global.text};
            }
            input {
                background: ${(props) => props.theme.colors.whiteAlpha[100]};
                border-color: ${(props) => props.theme.colors.inputs.borderColour};
                color: ${(props) => props.theme.colors.global.text};
                border-width: 1px;
                padding-bottom: 0px;
                padding-top: 6px;

                &:focus {
                    border-color: ${(props) => props.theme.colors.primary};
                    border-width: 1px;
                }
            }
        }
        .fr-checkbox input:checked + span {
            background: ${(props) => props.theme.colors.primary};
            border-color: ${(props) => props.theme.colors.primary};
            svg path {
                fill: white;
            }
        }
    }
    .fr-box {
        .fr-wrapper {
            border-radius: 5px;
            background: ${(props) => props.theme.colors.richText.editorBg};
            color: ${(props) => props.theme.colors.global.text};
            border: ${(props) => `1px solid ${props.theme.colors.inputs.borderColour}`};
            border-top: ${(props) =>
                `1px solid ${props.theme.colors.inputs.borderColour} !important`};
            cursor: text;
        }
        .fr-element {
            color: ${(props) => props.theme.colors.global.text};
            padding: 7px 10px;
            a: {
                text-decoration: underline;
                color: ${(props) => props.theme.colors.secondary};
            }
        }
        .fr-placeholder {
            color: ${(props) => props.theme.colors.gray[300]};
            opacity: 0.9;
        }
    }
`;

const RichTextEditor = ({ onChange, value, placeholder, disableMedia, maxCharacters, name }) => {
    const theme = useTheme();
    const Http = useHttp();
    const { show } = useContext(CloudinaryUploadContext);
    const [mediaAtom, setMediaAtom] = useRecoilState(_mediaAtom);
    const [editor, setEditor] = useState(null);
    const [ready, setReady] = useState(false);
    const [froalaEditor, setFroalaEditor] = useState(null);

    useEffect(() => {
        if (value) {
            setReady(true);
            setEditor(value);
        } else {
            setReady(true);
            setEditor(null);
        }
    }, [value]);

    useEffect(() => {
        FroalaEditor.DefineIcon('cloudinaryImage', {
            NAME: 'Insert Image',
            SVG_KEY: 'insertImage',
        });
        FroalaEditor.RegisterCommand('cloudinaryImage', {
            title: 'Upload Media',
            focus: false,
            undo: false,
            refreshAfterCallback: false,
            callback: function () {
                show(name);
            },
        });
        FroalaEditor.RegisterCommand('insertCloudinaryImage', {
            title: 'Upload Media',
            focus: false,
            undo: false,
            refreshAfterCallback: false,
            callback: function () {
                const field = mediaAtom.find((f) => f.name === name);
                if (field) {
                    if (field.files[0].resource_type === 'video') {
                        froalaEditor.video.insert(
                            `<video playsinline controls controlsList="nodownload" src="${field.files[0].secure_url}" />`,
                            null,
                            null,
                            null,
                        );
                    } else {
                        froalaEditor.image.insert(field.files[0].secure_url, null, null, null);
                    }
                }
            },
        });
    }, [show, name]);

    const fEditor = useRef();
    const editorRef = useRef(null);

    useEffect(() => {
        if (ready) {
            var _editor = new FroalaEditor(
                fEditor.current,
                {
                    extra_liners:
                        "['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'pre', 'ul', 'ol', 'table', 'dl']",
                    zIndex: 9999,
                    videoUploadURL: `${apiUrl}/file-upload`,
                    toolbarVisibleWithoutSelection: true,
                    toolbarButtons: [
                        [
                            'bold',
                            'italic',
                            'underline',
                            'align',
                            'fontSize',
                            'paragraphFormat',
                            'paragraphStyle',
                            'textColor',
                        ],
                        [
                            !disableMedia && 'insertLink',
                            // !disableMedia && process.env.REACT_APP_UPLOAD_METHOD !== 'cloudinary'
                            !disableMedia && 'insertImage',
                            !disableMedia && 'cloudinaryImage',

                            'insertTable',
                            'emoticons',
                            'specialCharacters',
                        ],
                        ['formatOL', 'formatUL'],
                    ],
                    pluginsEnabled: [
                        'align',
                        'charCounter',
                        'codeBeautifier',
                        'codeView',
                        'colors',
                        'draggable',
                        'emoticons',
                        'entities',
                        'fontFamily',
                        'fontSize',
                        'fullscreen',
                        'help',
                        'image',
                        'inlineClass',
                        'inlineStyle',
                        'link',
                        'lists',
                        'paragraphFormat',
                        'paragraphStyle',
                        'print',
                        'quote',
                        'save',
                        'specialCharacters',
                        'table',
                        'url',
                        'video',
                        'wordPaste',
                    ],
                    key: 'te1C2sA5B5E6E5B4I4jC1QUd1Xd1OZJ1ABVJRDRNGGUE1ITrE1D4A3A10B1C6B5B1E4H3==',
                    maxCharacters: maxCharacters ? maxCharacters : 999999,
                    charCounterMax: maxCharacters ? maxCharacters : -1,
                    placeholderText: placeholder,
                    videoUpload: false,
                    toolbarInline: true,
                    toolbarSticky: true,
                    listAdvancedTypes: true,
                    pastePlain: true,
                    events: {
                        blur: function () {
                            onChange(this.html.get());
                            localStorage.setItem('activeMediaField', name);
                        },
                        'video.uploaded': function (res) {
                            const video = JSON.parse(res);

                            const data = { link: video.secure_url };

                            this.video.insert(
                                `<video playsinline controls controlsList="nodownload" src="${video.secure_url}" />`,
                                null,
                                null,
                                this.video.get(),
                            );
                            return data;
                        },
                        'image.beforeUpload': function (images) {
                            // Before image is uploaded
                            const data = new FormData();
                            data.append('file', images[0]);

                            Http.post('/file-upload', data, {
                                headers: {
                                    accept: 'application/json',
                                    'Accept-Language': 'en-US,en;q=0.8',
                                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                                },
                            })
                                .then((res) => {
                                    console.log(res);
                                    this.image.insert(
                                        `${res.data.secure_url}`,
                                        null,
                                        null,
                                        this.image.get(),
                                    );
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                            return false;
                        },
                    },
                },
                function () {
                    // Call the method inside the initialized event.
                    _editor.html.set(editor);
                },
            );

            setFroalaEditor(_editor);
        }
        return () => {
            if (editor) {
                _editor.destroy();
                setFroalaEditor(null);
            }
        };
    }, [ready]);

    useEffect(() => {
        const field = mediaAtom.find((f) => f.name === name);
        if (field && field.files.length !== 0) {
            FroalaEditor.COMMANDS.insertCloudinaryImage.callback.call();
        }
    }, [mediaAtom]);

    return (
        <>
            <StyledContainer theme={theme}>
                <textarea ref={fEditor} onChange={(e) => setEditor(e.target.value)}>
                    {editor}
                </textarea>
            </StyledContainer>
        </>
    );
};

export default RichTextEditor;
