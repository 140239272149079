import React, { useEffect } from 'react';
import { Text, Flex, Box, Spinner } from '@chakra-ui/react';

import Table from '../../../components/common/CustomTable/';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { SimpleGrid } from '@chakra-ui/react';
import FormSection from '../../../components/FormSection';
import formatCurrency from '../../../utils/formatCurrency';
import DataView from '../components/DataView';

const TableView = ({ data, loading, setExportData, exportData, title }) => {
    const columns = [
        {
            Header: 'Name',
            accessor: 'name',
        },

        {
            Header: 'Sales',
            accessor: 'sales',
            Cell: ({ row }) => <>{`${formatCurrency(row.original.sales)}`}</>,
        },
        {
            Header: 'Bookings',
            accessor: 'bookings',
        },
    ];

    useEffect(() => {
        setExportData({
            ...exportData,
            [title.replaceAll(' ', '_')]: { data: data?.data ? data?.data : [], columns },
        });
    }, [data]);

    return (
        <>
            {loading || !data ? (
                <LoadingSpinner />
            ) : data.length !== 0 ? (
                <>
                    <Table
                        columns={columns}
                        data={data?.data?.courses ? data?.data?.courses : []}
                    />
                    <SimpleGrid columns={2} gap="0px 20px">
                        <FormSection>
                            <DataView
                                title="Total Sales"
                                value={`${formatCurrency(data?.data?.total_sales)}`}
                            />
                        </FormSection>{' '}
                        <FormSection>
                            <DataView title="Total Bookings" value={data?.data?.total_bookings} />
                        </FormSection>
                    </SimpleGrid>
                </>
            ) : (
                <Text>No data available.</Text>
            )}
        </>
    );
};

export default TableView;
