import React, { useEffect, useState } from 'react';
import {
    IconButton,
    Stack,
    Button,
    Alert,
    AlertIcon,
    Flex,
    Input,
    InputGroup,
    InputRightElement,
} from '@chakra-ui/react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { FiTrash } from 'react-icons/fi';
import { FcCancel } from 'react-icons/fc';
import { AiFillCloseCircle } from 'react-icons/ai';
import EmailSearch from './EmailSearch';
import { Date, PopConfirm } from '../../../../Nucleus';
import { useHttp, Field } from '@builtbypixel/nucleus';
import { useParams } from 'react-router-dom';

const DelegatesList = ({ index, event, order_line }) => {
    const { getValues, setValue, reset } = useFormContext();
    const status = useWatch({ name: 'status' });
    const [unconfirmedCancelledDelegates, setUnconfirmedCancelledDelegates] = useState([]);
    const broker = useWatch({ name: 'broker' });
    const { fields, append, remove } = useFieldArray({ name: `events[${index}].delegates` });
    const Http = useHttp();
    const { id } = useParams();

    useEffect(() => {
        const qty = getValues(`events[${index}].quantity`);
        var times =
            parseInt(qty) -
            fields.length -
            event.cancellations.filter((item) => item.delegate.length === 0).length;
        if (times !== 0) {
            for (var i = 0; i < times; i++) {
                append({ first_name: '', last_name: '', email: '', from_api: false });
            }
        }
    }, [index, append, fields, getValues]);

    useEffect(() => {
        const filter_unconfirmed = event.cancellations.filter((item) => item.delegate.length === 0);
        setUnconfirmedCancelledDelegates(filter_unconfirmed);
    }, []);

    const removeDelegate = (i, delegate) => {
        const qty = getValues(`events[${index}].quantity`);
        remove(i);
        setValue(`events[${index}].quantity`, parseInt(qty) - 1);
        if (delegate?.email) {
            Http.post(`/remove-delegate/${id}`, {
                email: delegate.email,
                order_line_id: order_line,
                event: {
                    id: event.id,
                },
            });
        }
    };
    const addDelegate = () => {
        const qty = getValues(`events[${index}].quantity`);
        append({});
        setValue(`events[${index}].quantity`, parseInt(qty) + 1);
    };

    return (
        <>
            <Stack w="100%" spacing={'0px'} py={'0px'}>
                {fields &&
                    fields.map((field, i) => (
                        <DelegateRow
                            removeDelegate={removeDelegate}
                            delegate={field}
                            key={field.id}
                            index={i}
                            eventIndex={index}
                            event={event}
                            fields={fields}
                            broker={broker}
                            cancelled={
                                event?.cancellations?.find(
                                    (item) => item.delegate.id === field.id || item.id === field.id,
                                )
                                    ? true
                                    : false
                            }
                        />
                    ))}
            </Stack>

            {unconfirmedCancelledDelegates.length !== 0 && (
                <Stack mb={10}>
                    {unconfirmedCancelledDelegates.map((item, index) => {
                        return (
                            <DelegateRow
                                event={event}
                                delegate={item}
                                type={'cancelled'}
                                cancelled={true}
                            />
                        );
                    })}
                </Stack>
            )}

            {status.name === 'Provisional' && (
                <Flex align="center">
                    <Button
                        size="sm"
                        colorScheme="blue"
                        variant="outline"
                        onClick={() => addDelegate()}
                    >
                        Add New Delegate
                    </Button>
                    <Alert bg="white">
                        <AlertIcon />
                        Note: The booking cost will change
                    </Alert>
                </Flex>
            )}
        </>
    );
};

const DelegateRow = ({ delegate, index, eventIndex, removeDelegate, broker, event, cancelled }) => {
    const { type } = useParams();
    const [dirtyFields, setDirtyFields] = useState('no match');
    const [showWithSpaces, setShowWithSpaces] = useState('');
    function addSpaceEveryTwoCharacters(str) {
        return str.replace(/(.{2})/g, '$1 ');
    }
    function removeSpaces(str) {
        return str.replace(/\s/g, '');
    }

    function removeCharactersAfter(str) {
        return str.substring(0, 13);
    }
    const handleChangeAndValidate = (name, value) => {
        //first we check for valid NI

        const niRegex = /^[A-CEGHJ-PR-TW-Z]{1}[A-CEGHJ-NPR-TW-Z]{1}[0-9]{6}[A-D]{1}$/i;

        let niWithNoSpaces = removeCharactersAfter(value);

        niWithNoSpaces = removeSpaces(niWithNoSpaces);
        setShowWithSpaces(addSpaceEveryTwoCharacters(niWithNoSpaces));

        if (niWithNoSpaces.match(niRegex)) {
            setDirtyFields('YAY😱');
            //it matches so add some spaces in
            setValue(name, addSpaceEveryTwoCharacters(niWithNoSpaces));
        } else {
            setDirtyFields('no match');
            setValue(name, '');
        }
        // setShowWithSpaces(addSpaceEveryTwoCharacters(niWithNoSpaces));
    };
    const { setValue, getValues, reset } = useFormContext();

    return cancelled ? (
        <Flex mt="5px" align="center">
            <Input
                component={'text'}
                isDisabled={true}
                size="sm"
                defaultValue={delegate?.first_name || 'Cancelled'}
                mr="1.2em"
                bg="#edf2f7"
            />
            <Input
                component={'text'}
                isDisabled={true}
                // defaultValue="Cancelled"
                defaultValue={delegate?.last_name || 'Cancelled'}
                size="sm"
                mr="1.2em"
                bg="#edf2f7"
            />
            <Input
                component={'text'}
                isDisabled={true}
                // defaultValue="Cancelled"
                defaultValue={delegate?.email || 'Cancelled'}
                size="sm"
                mr="1.2em"
                bg="#edf2f7"
            />
            <IconButton variant="link" fontSize="14px">
                <FcCancel />
            </IconButton>
        </Flex>
    ) : (
        <Stack isInline align="center" spacing="15px" py="10px">
            <Field
                eventIndex={eventIndex}
                index={index}
                name={`events[${eventIndex}].delegates[${index}].first_name`}
                component={DelegateField}
                defaultValue={delegate.first_name}
                isDisabled={delegate.from_api}
                placeholder="First Name"
                noMargin
                size="sm"
            />
            <Field
                eventIndex={eventIndex}
                index={index}
                name={`events[${eventIndex}].delegates[${index}].middle_name`}
                component={DelegateField}
                defaultValue={delegate.middle_name}
                isDisabled={delegate.from_api}
                placeholder="Middle Name"
                noMargin
                size="sm"
            />

            <Field
                eventIndex={eventIndex}
                index={index}
                name={`events[${eventIndex}].delegates[${index}].last_name`}
                component={DelegateField}
                defaultValue={delegate.last_name}
                isDisabled={delegate.from_api}
                placeholder="Last Name"
                noMargin
                size="sm"
            />
            <EmailSearch
                eventIndex={eventIndex}
                delegateIndex={index}
                broker={broker}
                type={type}
            />
            {/* <Field
                eventIndex={eventIndex}
                index={index}
                name={`events[${eventIndex}].delegates[${index}].national_insurance`}
                component={DelegateField}
                defaultValue={delegate.national_insurance}
                isDisabled={delegate.from_api}
                placeholder="National Insurance"
                noMargin
                size="sm"
            /> */}
            <Flex direction={'column'} minWidth="130px" w="100%">
                <InputGroup size="sm">
                    <Input
                        placeholder="National Insurance Number"
                        pr="5px"
                        bg="white"
                        isDisabled={delegate.from_api}
                        // isDisabled={true}
                        label="National Insurance"
                        textTransform={'uppercase'}
                        _placeholder={{
                            textTransform: 'capitalize',
                        }}
                        value={
                            getValues(
                                `events[${eventIndex}].delegates[${index}].national_insurance`,
                            )
                                ? getValues(
                                      `events[${eventIndex}].delegates[${index}].national_insurance`,
                                  )
                                : showWithSpaces
                        }
                        defaultValue={getValues('national_insurance')}
                        onChange={(e) =>
                            handleChangeAndValidate(
                                `events[${eventIndex}].delegates[${index}].national_insurance`,
                                e.target.value,
                            )
                        }
                    />
                    <InputRightElement
                        width="1.5rem"
                        cursor={'pointer'}
                        onClick={() => {
                            reset();
                            handleChangeAndValidate('national_insurance', '');
                        }}
                    >
                        <AiFillCloseCircle />
                    </InputRightElement>
                </InputGroup>
            </Flex>
            <Field
                eventIndex={eventIndex}
                index={index}
                name={`events[${eventIndex}].delegates[${index}].dob`}
                component={DelegateField}
                defaultValue={delegate.dob}
                isDisabled={delegate.from_api}
                placeholder="Date of Birth"
                noMargin
                type="date"
                size="sm"
            />
            <PopConfirm
                onConfirm={() => removeDelegate(index, delegate)}
                title="Are you sure you want to remove this delegate?"
            >
                <IconButton variant="link" fontSize="14px">
                    <FiTrash />
                </IconButton>
            </PopConfirm>
        </Stack>
    );
};

const DelegateField = ({
    onChange,
    value,
    defaultValue,
    eventIndex,
    index,
    isDisabled,
    placeholder,
    type,
}) => {
    const [val, setVal] = useState('');

    useEffect(() => {
        if (val.length === 0 && value) {
            setVal(value);
        }
    }, [value, val.length]);
    return (
        <Input
            type={type ?? 'text'}
            onChange={(e) => setVal(e.target.value)}
            name={`events[${eventIndex}].delegates[${index}].first_name`}
            component={DelegateField}
            defaultValue={value ? value : defaultValue ? defaultValue : ''}
            rules={{ required: true }}
            placeholder={placeholder}
            noMargin
            isDisabled={isDisabled}
            onBlur={() => onChange(val)}
            size="sm"
        />
    );
};

export default DelegatesList;
