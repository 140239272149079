export const seo = {
    tab: 'SEO',
    fields: [
        {
            name: 'seo.title',
            component: 'text',
            placeholder: 'SEO title',
            label: 'SEO Title',
        },
        {
            name: 'seo.description',
            component: 'richtext',
            placeholder: 'SEO description',
            label: 'SEO Description',
        },
        {
            name: 'seo.page_description',
            component: 'richtext',
            label: 'Page Description',
        },
    ],
};
