import React from 'react';
import { Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import setup from './setup';
import { ListWrapper } from '@builtbypixel/nucleus';

// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const List = () => {
    const columns = [
        {
            Header: 'Page',
            accessor: 'title',
            Cell: ({ row }) => (
                <Link to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
                    <Button colorScheme="blue" variant="link">
                        {row.original.title}
                    </Button>
                </Link>
            ),
        },
        {
            Header: 'id',
            accessor: 'id',
        },
        // {
        //     Header: 'Slug',
        //     accessor: 'slug',
        //     Cell: ({ row }) => <pre>{row.original.slug}</pre>,
        // },
    ];

    return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
