import React, { useMemo } from "react";
import { AutoFields, EditView } from "@builtbypixel/nucleus";

import setup from "./setup";

const EditForm = () => {
  const fields = useMemo(
    () => [
      {
        group: "Custom Page",
        fields: [
          {
            name: "title",
            component: "text",
            placeholder: "Title",
            label: "Title",
            isRequired: true,
            rules: { required: true },
          },
          {
            name: "content.content",
            component: "richtext",
            placeholder: "content",
            label: "Content",
            isRequired: true,
            rules: { required: true },
          },
          {
            name: "published",
            component: "switch",
            placeholder: "",
            label: "Published",
          },
          //  {
          //    name: "phone",
          //    component: "text",
          //    placeholder: "Phone Number",
          //    label: "Phone Number",
          //    type: "number",
          //    isRequired: true,
          //    rules: { required: true },
          //  },

          //  {
          //    name: "image",
          //    component: "file",
          //    max: 2,
          //    label: "Category Image",
          //    isRequired: true,
          //    rules: { required: true },
          //  },
        ],
      },
    ],
    []
  );
  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
