const formatCurrency = (value) => {
    if (!value || value === 0 || isNaN(value)) return '0.00';
    if (value) {
        return Number(value).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'GBP',
        });
    }
};

export default formatCurrency;
