import React from 'react';
import { Box, Flex, Text, Select, Grid } from '@chakra-ui/react';
import ImageOrFile from './ImageOrFile';
import moment from 'moment';
import { useHttp, useToast } from '../../../../Nucleus';

const Document = ({ file, documentStatuses }) => {
    const Http = useHttp();
    const toast = useToast();

    const handleChangeStatus = (document, status) => {
        const updateObj = {
            name: document.name,
            status_id: status,
            document: document.document,
            trainer_document_type: document.trainer_document_type,
            expiry_date: document.expiry_date,
            trainer: { id: document.from_trainer_id },
        };
        document.status = status;
        Http.put(`trainer-uploads/${document.id}`, updateObj)
            .then((res) => {
                toast({
                    status: 'success',
                    title: 'Document status updated',
                });
            })
            .catch((err) => {
                toast({
                    status: 'error',
                    title: 'Error updating document',
                });
            });
    };
    return (
        <Grid
            border="1px"
            borderColor="gray.200"
            my="20px"
            p="15px"
            w="100%"
            borderRadius={'3px'}
            templateColumns="64px 1fr 1fr 1fr"
            alignItems="center"
            bg={moment(Date.now()).isBefore(file.expiry_date) ? 'red.100' : 'green.100'}
        >
            {file?.document?.map((eachDoc) => (
                <ImageOrFile url={eachDoc.url} alt={file.name} />
            ))}{' '}
            <Text fontWeight={'bold'}>{file?.trainer_document_type?.name}</Text>
            <Text>Expiry: {file.expiry_date}</Text>
            {documentStatuses && (
                <Select
                    placeholder="Set document status"
                    onChange={(e) => handleChangeStatus(file, e.target.value)}
                    background="white"
                >
                    {documentStatuses?.data?.map((status) => (
                        <option selected={status.id === file?.status_id?.id} value={status.id}>
                            {status.name}
                        </option>
                    ))}
                </Select>
            )}
        </Grid>
    );
};

export default Document;
