import React from 'react';
import { LinkButton, ListWrapper, useHttp, useGet } from '@builtbypixel/nucleus';
import setup from './setup';
import useSWR from 'swr';
import { FaFileInvoice } from 'react-icons/fa';
import { Button, Box, Text } from '@chakra-ui/react';

// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const List = () => {

    const { data: courses } = useSWR('/all-courses', useGet);
    const { data: events } = useSWR('/all-events', useGet);

    const columns = [
        {
            Header: 'Actions',
            accessor: 'download',
            Cell: ({ row }) => (
                <LinkButton
                    target="_blank"
                    rel="noreferrer"
                    to={row.original.certificate?.[0]?.url}
                >
                    <Button
                        size="sm"
                        target="_blank"
                        href={row.original.certificate?.[0]?.url}
                    >
                        <FaFileInvoice />
                    </Button>
                </LinkButton>
            ),
            disableSortBy: true,
        },
        {
            Header: 'Customer',
            accessor: 'delegate.first_name',
            disableSortBy: true,
            Cell: ({ row }) => (
                <>
                <Box>{row.original.delegate?.email ? row.original.delegate?.email : null}</Box>
                    {row.original.delegate?.user_type.name !== 'Business' && (
                        <Box>
                            <LinkButton target="_blank" to={`Personal/edit/${row.original.delegate?.id}`}>
                                <Button size="sm" variant="link" colorScheme="blue" rounded="md">
                                    <Text color="gray.500" paddingTop="5px">
                                        {row.original.delegate?.first_name}{' '}
                                        {row.original.delegate?.last_name}
                                    </Text>
                                </Button>
                            </LinkButton>
                        </Box>
                    )}
                    {row.original.delegate?.company_name && (
                        <Box>
                            <LinkButton to={`businesses/edit/${row.original.delegate?.id}`}>
                                <Button size="sm" variant="link" colorScheme="blue" rounded="md">
                                    <Text color="gray.500" paddingTop="5px">
                                        {row.original.delegate.company_name}
                                    </Text>
                                </Button>
                            </LinkButton>
                        </Box>
                    )}
                </>
            ),
        },
        {
            Header: 'Phone',
            accessor: 'delegate.phone'
        },
        {
            Header: 'Course',
            accessor: 'event.course_name',
            Cell: ({ row }) => (
                <>
                    <Box>
                        <LinkButton target="_blank" to={`events/edit/${row.original.event?.id}`}>
                            <Button size="sm" variant="link" colorScheme="blue" rounded="md">
                                <Text paddingTop="5px">
                                    {row.original.event?.event_code} / {' '}
                                    {row.original.event?.course_name}
                                </Text>
                            </Button>
                        </LinkButton>
                    </Box>
                </>
            ),
        },
        {
            Header: 'Expiry Date',
            accessor: 'expiry_date'
        }
    ];

    const filters = [
        {
            component: 'date',
            label: 'Expiry Start',
            name: 'start',
            placeholder: 'Expiry Start',
        },
        {
            component: 'date',
            label: 'Expiry End',
            name: 'end',
            placeholder: 'Expiry End',
        },
        {
            component: 'select',
            label: 'Select Course',
            options: courses?.data,
            labelKey: 'title',
            valueKey: 'id',
            name: 'course',
            placeholder: 'Select Course',
        },
        {
            component: 'select',
            label: 'Select Event',
            options: events?.data,
            labelKey: 'event_code',
            valueKey: 'id',
            name: 'event',
            placeholder: 'Select Event',
        }
    ];

    return <ListWrapper filters={filters} setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
