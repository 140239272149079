import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import Section from '../Section';
import { Divider, Text, Stack, Flex, Button } from '@chakra-ui/react';
import AddressEdit from './AddressEdit';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { LinkButton } from '@builtbypixel/nucleus';

const UserSummary = ({ brokerBooking }) => {
    const { type } = useParams();
    const user = useWatch({ name: brokerBooking === 'broker' ? 'broker' : 'customer' });
    // const broker = useWatch({ name: 'broker' });

    const { setValue } = useFormContext();

    useEffect(() => {}, [user?.email]);

    return user ? (
        <>
            <Section
                title={
                    <Flex w="100%">
                        <Text>{brokerBooking === 'broker' ? 'Broker' : 'Customer'}</Text>
                        {type === 'create' && (
                            <Button
                                onClick={() =>
                                    setValue(
                                        brokerBooking === 'broker' ? 'broker' : 'customer',
                                        null,
                                    )
                                }
                                colorScheme="blue"
                                variant="link"
                                ml="auto"
                            >
                                {`Change ${brokerBooking === 'broker' ? 'Broker' : 'Customer'}`}
                            </Button>
                        )}
                    </Flex>
                }
                w="100%"
                mb="0px"
            >
                <Stack w="100%" spacing="10px" alignItems="flex-start">
                    <LinkButton
                        target="_blank"
                        rel="noreferrer"
                        to={`/${
                            user.user_type?.name === 'Broker'
                                ? 'brokers'
                                : user.user_type?.name === 'Business'
                                ? 'businesses'
                                : user.user_type?.name
                        }/edit/${user.id}`}
                    >
                        <Button variant="link" colorScheme="blue" rounded="md">
                            {user.first_name} {user.last_name}
                        </Button>
                    </LinkButton>
                    <Flex align="center">
                        <Text mr="20px">{user.email}</Text>
                        {user.user_type?.name === 'Broker' && (
                            <>
                                <Text
                                    fontSize="12px"
                                    bg="gray.600"
                                    px="10px"
                                    py="3px"
                                    rounded="full"
                                    color="white"
                                >
                                    {user.company_name} - {user.account_number}
                                </Text>
                            </>
                        )}
                        {!user?.broker_type?.name && user.discount_amount && (
                            <Text
                                fontSize="12px"
                                ml="10px"
                                bg="blue.100"
                                px="10px"
                                py="3px"
                                rounded="full"
                                color="blue.700"
                            >
                                Eligible for {user.discount_amount}% discount
                            </Text>
                        )}
                        {user.commission && (
                            <Text
                                fontSize="12px"
                                ml="10px"
                                bg="blue.100"
                                px="10px"
                                py="3px"
                                rounded="full"
                                color="blue.700"
                            >
                                Eligible for {+user.commission}% commision
                            </Text>
                        )}

                        {user?.broker_type?.name && (
                            <Text
                                fontSize="12px"
                                ml="10px"
                                bg="blue.400"
                                px="10px"
                                py="3px"
                                rounded="full"
                                color="white"
                            >
                                {user?.broker_type.name} Broker
                            </Text>
                        )}
                    </Flex>
                    <Divider />
                    <Stack isInline spacing="4px">
                        <Text>{user?.address?.address_line_one}</Text>

                        <Text>{user?.address?.address_line_two}</Text>

                        <Text>{user?.address?.address_line_three}</Text>

                        <Text>{user?.address?.address_city}</Text>

                        <Text>
                            {user?.address?.address_country
                                ? `${user.address?.address_country.name}`
                                : null}
                        </Text>

                        <Text>{user?.address?.address_postcode}</Text>
                    </Stack>
                    {/* <AddressEdit /> */}
                </Stack>
            </Section>
        </>
    ) : null;
};

export default UserSummary;
