import { Flex, Text, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { PopConfirm, useHttp } from '../../../../Nucleus';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const EmailDelegate = ({ id, arr, row }) => {
    const [loading, setLoading] = useState(false);

    const Http = useHttp();
    const toast = useToast();

    const emailUser = (userId) => {
        setLoading(true);
        let user = arr?.data?.find((user) => user.id === userId);
        Http({
            method: 'post',
            url: `/send-delegate-progression/${id}`,
            data: user,
        })
            .then(() => {
                setLoading(false);

                toast({
                    title: 'Success',
                    description: 'Email sent!',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            })
            .catch((err) => {
                setLoading(false);

                toast({
                    title: 'Error',
                    description: 'There was a problem emailing the delegate.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            });
    };

    return (
        <Flex
            align="center"
            justify="center"
            cursor="pointer"
            _hover={{ color: '#FF7760' }}
            direction="column"
        >
            <Flex align="center" justify="center">
                <PopConfirm
                    onConfirm={() => emailUser(row.original.id)}
                    isLoading={loading}
                    title="Are you sure you want to email this user?"
                >
                    <Text color="black" fontWeight={'bold'}>
                        Send Email
                    </Text>
                    {row?.original?.email_sent && (
                        <Text as="small" whiteSpace="nowrap">
                            Last sent:{' '}
                            {dayjs(row?.original?.email_sent).format('DD/MM/YYYY hh:mm a')}
                        </Text>
                    )}
                </PopConfirm>
            </Flex>
        </Flex>
    );
};

export default EmailDelegate;
