import { EditView } from '@builtbypixel/nucleus';
import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';

import setup from './setup';

import { Flex, Tabs, TabPanels, TabPanel, TabList, Tab } from '@chakra-ui/react';

import TrainerDetails from './components/TrainerDetails';
import Documents from './components/Documents';
import Availability from './components/Availability';
import Invoices from './components/Invoices';
import RequiredDocuments from './components/UploadedDocuments/Required';

import UploadedDocuments from './components/UploadedDocuments';
// import EditUploadedDocuments from './components/EditUploadedDocuments';

const EditForm = () => {
    const { type } = useParams();

    return (
        <EditView setup={setup}>
            <Flex w="100%" minHeight="100vh" bg="white">
                <Tabs flex={1} minHeight="100vh" isLazy>
                    <TabList pt="10px" pl="10px" w="100%">
                        <Tab>Trainer Details</Tab>

                        {type === 'edit' && (
                            <>
                                <Tab>Availability</Tab>
                                <Tab>Invoices</Tab>
                                <Tab>Required Documents</Tab>
                                <Tab>Completion Uploads</Tab>
                            </>
                        )}
                    </TabList>
                    <TabPanels bg="blackAlpha.100" px="0px" minHeight="100vh">
                        <TabPanel>
                            <TrainerDetails />
                        </TabPanel>

                        <TabPanel p="0px">
                            <Suspense fallback={<div>fddsd</div>}>
                                <Availability />
                            </Suspense>
                        </TabPanel>
                        <TabPanel p="0px">
                            <Invoices />
                        </TabPanel>
                        <TabPanel p="0px">
                            <Suspense fallback={<div>loading</div>}>
                                <RequiredDocuments />
                            </Suspense>
                        </TabPanel>
                        <TabPanel p="0px">
                            <Suspense fallback={<div>loading</div>}>
                                <UploadedDocuments />
                            </Suspense>
                        </TabPanel>
                        {/* <TabPanel p="0px">
                            <Suspense fallback={<div>loading</div>}>
                                <EditUploadedDocuments />
                            </Suspense>
                        </TabPanel> */}
                    </TabPanels>
                </Tabs>
            </Flex>
        </EditView>
    );
};

export default EditForm;
