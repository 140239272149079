import React, { useMemo, useEffect, useState } from 'react';
import { AutoFields, EditView, useHttp } from '@builtbypixel/nucleus';
import setup from './setup';
const EditForm = () => {
    const [clients, setClient] = useState([]);

    const Http = useHttp();

    useEffect(() => {
        Http.get(`/courses?pagination=9999`).then((res) => {
            setClient(res.data.data);
        });
        return () => {};
    }, []);

    const fields = useMemo(
        () => [
            {
                group: 'Course Reviews',
                fields: [
                    {
                        name: 'users_name',
                        component: 'text',
                        placeholder: 'Individual Name',
                        label: 'Individual Name',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'company_name',
                        component: 'text',
                        placeholder: 'Client Name',
                        label: 'Client Name',
                        isRequired: true,
                        rules: { required: true },
                    },

                    {
                        name: 'comments',
                        component: 'textarea',
                        placeholder: 'Review text',
                        label: 'Review text',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'course',
                        component: 'select',
                        options: clients,
                        placeholder: 'Course Name',
                        labelKey: 'title',
                        valueKey: 'id',
                        label: 'Course Name',
                        variant: 'outline',
                    },
                ],
            },
        ],
        [clients],
    );
    return (
        <EditView setup={setup}>
            <AutoFields fields={fields} />
        </EditView>
    );
};

export default EditForm;
