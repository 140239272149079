import React, { useState } from 'react';

import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    Button,
} from '@chakra-ui/react';

import View from '../../../containers/PersonalUser';
import FormSection from '../../../components/FormSection';

import { useFormContext } from 'react-hook-form';
import CustomTable from '../../../components/common/CustomTable';

const Employees = () => {
    const { getValues } = useFormContext();

    const employees = getValues('employees');
    const [view, setView] = useState(null);

    const columns = [
        {
            Header: 'ID',
            accessor: 'id',
        },
        {
            Header: 'First Name',
            accessor: 'first_name',
        },
        {
            Header: 'Last Name',
            accessor: 'last_name',
        },
        {
            Header: 'Email',
            accessor: 'email',
        },

        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <Button colorScheme="blue" size="sm" onClick={() => setView(row.original)}>
                    Quick View
                </Button>
            ),
        },
    ];

    return (
        <>
            <FormSection title="Employees" mt="0px">
                <CustomTable columns={columns} data={employees || []} />
                <Modal
                    scrollBehavior="inside"
                    size="xl"
                    isOpen={view !== null}
                    isCentered
                    onClose={() => setView(null)}
                    motionPreset="none"
                >
                    <ModalOverlay />
                    <ModalContent maxWidth="97%">
                        <ModalCloseButton />
                        <ModalHeader borderBottom="1px" borderColor="gray.200">
                            {view?.first_name} {view?.last_name}
                        </ModalHeader>

                        <ModalBody p="0px">
                            <View viewOnly id={view?.id} />
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </FormSection>
        </>
    );
};

export default Employees;
