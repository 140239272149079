import {
    Flex,
    Text,
    Grid,
    InputLeftAddon,
    InputRightAddon,
    Input,
    InputGroup,
    Button,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';
import { Field, useGet } from '@builtbypixel/nucleus';
import useSWR from 'swr';
import moment from 'moment';
import { useParams } from 'react-router-dom';

const Discounts = () => {
    const { type } = useParams();
    const { data } = useSWR('/discount-types', useGet);
    const { getValues, setValue } = useFormContext();
    const [currentEndDate, setCurentEndDate] = useState(null);
    const [currentStartDate, setCurrentStartDate] = useState(null);
    const end_date = useWatch({ name: 'end_date' });

    useEffect(() => {
        if (type === 'edit') {
            const discountEnd = getValues('discount_end');
            const discountStart = getValues('discount_start');
            setCurentEndDate(
                discountEnd === null ? null : moment(discountEnd).format('DD-MM-YYYY HH:mm:ss'),
            );
            setCurrentStartDate(
                discountStart === null ? null : moment(discountStart).format('DD-MM-YYYY HH:mm:ss'),
            );
        }
    }, []);

    useEffect(() => {
        setValue('discount_end', currentEndDate);
        setValue('discount_start', currentStartDate);
    }, [currentEndDate, currentStartDate]);

    const matchEndDate = () => {
        setValue('discount_end', moment(end_date).format('DD-MM-YYYY HH:mm:ss'));
        setCurentEndDate(end_date);
    };

    return (
        <Flex w="100%" direction="column" bg="white" rounded="md" p="15px" align="flex-start">
            <Text fontSize="17px" fontWeight="bold" mb="15px">
                Event Discount
            </Text>
            <Grid templateColumns="1fr 1fr" gap="30px" w="100%">
                <Field
                    name="discount_type"
                    component="select"
                    labelKey="name"
                    valueKey="id"
                    label="Discount Type"
                    options={data?.data}
                />
                <Field
                    name="discount"
                    component={Value}
                    labelKey="name"
                    valueKey="id"
                    label="Discount Type"
                    options={data?.data}
                />
            </Grid>
            <Grid templateColumns="1fr 1fr" gap="30px" w="100%">
                <Field
                    name="discount_start"
                    component="date"
                    label="Start Date"
                    dateFormat="MMMM Do yyyy"
                    isClearable={true}
                    emptyLabel={'Select Date'}
                    manualValUpdate={currentStartDate}
                    manualValUpdateHelper={setCurrentStartDate}
                    showPopperArrow={false}
                    disabledKeyboardNavigation
                    shouldCloseOnSelect
                />
                <Field
                    name="discount_end"
                    component="date"
                    dateFormat="MMMM Do yyyy"
                    label="End Date"
                    isClearable={true}
                    manualValUpdate={currentEndDate}
                    manualValUpdateHelper={setCurentEndDate}
                    emptyLabel={'Select Date'}
                    showPopperArrow={false}
                    disabledKeyboardNavigation
                    shouldCloseOnSelect
                />
            </Grid>
            <Button ml="auto" size="sm" onClick={() => matchEndDate()}>
                Match end date to event
            </Button>
        </Flex>
    );
};

const Value = ({ value, onChange }) => {
    const type = useWatch({ name: 'discount_type' });

    return (
        <InputGroup>
            {!type || (type && type.id === 2 && <InputLeftAddon children="£" />)}
            <Input
                isDisabled={!type}
                placeholder="Enter discount value"
                value={value}
                onChange={onChange}
                type="number"
            />
            {type?.id === 1 && <InputRightAddon children="%" />}
        </InputGroup>
    );
};

export default Discounts;
