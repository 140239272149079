import React, { useMemo } from 'react';
import { AutoFields, EditView, Field } from '@builtbypixel/nucleus';
import { useState, useEffect } from 'react';
import { useHttp } from '@builtbypixel/nucleus';
import setup from './setup';
import { Flex, useToast, Text, Button, Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { useGet } from '@builtbypixel/nucleus';
import UserSummary from './../Bookings/components/UserSummary';
import UserSettings from './../Bookings/components/UserSettings';

const EditForm = () => {
    const { id } = useParams();

    const { data: status } = useSWR(`/invoice-statuses`, useGet);
    
    const fields = useMemo(
        () => [
            {
                fields: [
                    {
                        name: 'amount',
                        component: 'text',
                        label: 'Amount',
                    },
                    {
                        name: 'status',
                        component: 'select',
                        placeholder: 'Status',
                        options: status?.data,
                        labelKey: 'name',
                        valueKey: 'id',
                        label: 'Credit Note Status',
                        variant: 'outline',
                    },
                    {
                        name: 'comment',
                        component: 'textarea',
                        label: 'Comments',
                    }
                ],
            },
        ],
        [status],
    );

    return (
        
        <EditView setup={setup}>
            <Flex w="100%" direction="column" minHeight="10vh" p="10px">
                <Flex direction="column" flex={1} maxWidth="calc(100% - 0px)">
                    <UserSettings />
                    <UserSummary />
                </Flex>
                <Flex direction="column" flex={1} maxWidth="calc(100% - 0px)" bg="white" mt="20px" p="15px" borderRadius="5px">
                    <Text fontSize={{ base: '1em', xl: '17px' }} fontWeight="bold" mb="15px">Credit Note Details</Text>
                    <Field 
                        name="total" 
                        component="text" 
                        label="Total Amount (excl VAT)" 
                    />
                    <Field 
                        name="status" 
                        component="select" 
                        label="Status" 
                        options={status?.data} 
                        labelKey="name"
                        valueKey="id" 
                    />
                    <Field 
                        name="description" 
                        component="textarea" 
                        label="Description" 
                        rules={{reqired: true}}
                    />
                </Flex>
            </Flex>
            {/* <AutoFields fields={fields} /> */}
        </EditView>
    );
};

export default EditForm;
