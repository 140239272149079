import React, { useMemo } from "react";
import { AutoFields, EditView } from "@builtbypixel/nucleus";
import * as yup from "yup";

import setup from "./setup";

const EditForm = () => {
  const useValidation = () => {
    return yup.object().shape({
      job_name: yup.string().required("Please enter a job name"),
      job_description: yup.string().required("Please enter a job description"),
      salary: yup.number("Must be a number").required("Please enter a salary"),
      requirements: yup
        .array()
        .min(1)
        .of(
          yup.object().shape({
            requirement: yup.string().required("Please add a requirement"),
          })
        ),
    });
  };
  const fields = useMemo(
    () => [
      {
        group: "Job Details",
        fields: [
          {
            name: "job_name",
            component: "text",
            placeholder: "Job Name",
            label: "Job Name",
          },
          {
            name: "job_description",
            component: "richtext",
            placeholder: "Description",
            label: "Description",
          },
          {
            name: "salary",
            component: "text",
            type: "number",
            placeholder: "Salary",
            label: "Salary",
          },
          {
            name: "active",
            component: "switch",
            placeholder: "Active",
            label: "Active",
          },
          {
            name: "requirements",
            component: "repeater",
            placeholder: "Requirements",
            label: "Requirements",
            defaultValue: [],
            fieldArray: [
              {
                name: "requirement",
                component: "text",
                placeholder: "Requirement",
                label: "Requirements",
              },
            ],
          },
        ],
      },
    ],
    []
  );
  return (
    <EditView setup={setup} validationSchema={useValidation()}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
