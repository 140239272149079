import React from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';
import { Button, Text, Badge, Box } from '@chakra-ui/react';
import moment from 'moment';
import setup from './setup';

// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const List = () => {

    const current_date = moment().format('YYYY-MM-DD');

    const columns = [
        {
            Header: 'Name',
            accessor: 'first_name',
            Cell: ({ row }) => (
                <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
                    <Button variant="link" colorScheme="blue" rounded="md">
                        {row.original.first_name + ' ' + row.original.last_name}
                    </Button>
                </LinkButton>
            ),
        },
        {
            Header: 'Email Address',
            accessor: 'email',
        },
        {
            Header: 'Course',
            accessor: 'course_name',
            Cell: ({ row }) => row.original.course.name,
        },
        {
            Header: "Request Date",
            accessor: "request_date",
            Cell: ({ row }) => (
                <>
                    {row.original.request_date !== null && 
                        <Text>{moment(row.original.request_date).format('YYYY-MM-DD')}</Text>
                    }
                </>
            ),
        },
        {
            Header: "Expiry Date",
            accessor: 'expiry_date',
            Cell: ({ row }) => (
                <>
                    {row.original.expiry_date !== null 
                        ? <Box>
                            <Text>{moment(row.original.expiry_date).format('YYYY-MM-DD')}</Text>
                            {moment(current_date).isAfter(moment(row.original.expiry_date).format('YYYY-MM-DD'))
                                ?   <Badge mt={2} colorScheme='red'>EXPIRED BY {row.original.days_until_expire} days</Badge>
                                :   <Badge mt={2} colorScheme='green'>
                                        {row.original.days_until_expire} Days til expiry
                                    </Badge>
                            }
                          </Box>
                        : <Text>Not set</Text>
                    }
                </>
            )
        },
    ];

    const filters = [
        {
            component: 'select',
            label: 'Status',
            name: 'status',
            placeholder: 'Select Status',
            options: [
                {
                    id: 1,
                    name: 'Expired'
                },
                {
                    id: 2,
                    name: 'On-Going'
                }
            ],
            labelKey: 'name',
            valueKey: 'id',
        },
        {
            component: 'date',
            label: 'Expiry Date',
            name: 'expiry_date',
            placeholder: 'Expiry Date',
        },
        {
            component: 'date',
            label: 'Request Date',
            name: 'request_date',
            placeholder: 'Request Date',
        },
    ];


    return <ListWrapper filters={filters} setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
