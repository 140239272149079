import React from 'react';
import Duration from './Duration';
import AccessTime from './AccessTime';

const ElearningTimes = ({ name }) => {
    return (
        <div>
            <Duration name={name} />
            <AccessTime name={name} />
        </div>
    );
};

export default ElearningTimes;
