import React, {
   useCallback,
   memo,
   cloneElement,
   Children,
   isValidElement,
   useState,
   useEffect
} from 'react'

import { Form } from '../../components/Form'
import {
   Flex,
   Button,
   Text,
   Box,
   Skeleton,
   Stack,
   IconButton
} from '@chakra-ui/react'
import useSWR from 'swr'
import { useGet, useHttp } from '../../hooks'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import { useParams, useHistory } from 'react-router-dom'
import InfoBar from './InfoBar'
import DeveloperTools from './DeveloperTools'
import MoreOptions from './MoreOptions'
import { useFormContext } from 'react-hook-form'
import ErrorMessage from './ErrorMessage'
import SuccessMessage from './SuccessMessage'
import { formAtom } from '../../state/form'
import { useRecoilState, useRecoilValue } from 'recoil'
import { RiCodeSSlashLine } from 'react-icons/ri'

const SubmitButton = memo(({ setup }) => {
   const params = useParams()
   const { loading } = useRecoilValue(formAtom)
   const isEdit = params.type === 'edit'
   const { submit } = useFormContext()

   return (
      <Button
         variant='success'
         color='white'
         onClick={() => submit()}
         isLoading={loading}
         loadingText='Submitting '
      >
         {isEdit ? `Save ${setup.singular}` : `Submit ${setup.singular}`}
      </Button>
   )
})

const FormColumn = memo((props) => {
   const { isEdit, data, children } = props

   return (
      <Flex w='100%' flex={1} align='center' direction='column'>
         {isEdit ? (
            data ? (
               children
            ) : (
               <Box w='90%' h='100%' maxWidth={600} mt={10}>
                  <Skeleton variant='rect' h='100px' mb='5px' />
                  <Skeleton variant='rect' mb='5px' />
                  <Skeleton h='30px' mb='5px' />
                  <Skeleton mb='5px' />
                  <Skeleton variant='rect' mb='5px' />
                  <Skeleton h='30px' mb='5px' />
               </Box>
            )
         ) : (
            Children.map(children, (child) => {
               if (isValidElement(child)) {
                  return cloneElement(child, props)
               }
               return child
            })
         )}
      </Flex>
   )
})

const EditView = memo((props) => {
   const {
      children,
      setup,
      isFullWidth,
      noPadding,
      validationSchema,
      ignoreValues
   } = props
   const params = useParams()
   const Http = useHttp()
   const isEdit = params.type === 'edit'
   const isCreate = params.type === 'create'
   const [devTools, setDevTools] = useState(false)

   const history = useHistory()
   const [formState, setFormState] = useRecoilState(formAtom)

   const { data } = useSWR(
      params.type === 'edit' ? `${setup.endpoint}/${params.id}` : null,
      useGet
   )

   React.useState(() => {
      setFormState((old) => ({ ...old, errors: null, success: false }))
   }, [])

   /* eslint-disable */
   const onSubmit = useCallback((values) => {
      setFormState((old) => ({ ...old, loading: true }))
      if (isEdit) {
         Http.put(`${setup.endpoint}/${params.id}`, values)
            .then((res) => {
               setFormState((old) => ({
                  ...old,
                  loading: false,
                  errors: null,
                  success: true
               }))
            })
            .catch((err) => {
               setFormState((old) => ({
                  ...old,
                  loading: false,
                  errors: err,
                  success: false
               }))
            })
      }
      if (isCreate) {
         Http.post(setup.endpoint, values)
            .then(() => {
               setFormState((old) => ({
                  ...old,
                  loading: false,
                  errors: null,
                  success: true
               }))
               history.push(`/${setup.model}`)
            })
            .catch((err) => {
               setFormState((old) => ({
                  ...old,
                  loading: false,
                  errors: err,
                  success: false
               }))
            })
      }
   }, [])

   useEffect(() => {
      if (formState.errors) {
         setTimeout(() => {
            setFormState((old) => ({ ...old, errors: null }))
         }, 7000)
      }
   }, [formState.errors])

   useEffect(() => {
      if (formState.success === true) {
         setTimeout(() => {
            setFormState((old) => ({ ...old, success: false }))
         }, 7000)
      }
   }, [formState.success])

   return (
      <Form
         data={data && data.data}
         onSubmit={onSubmit}
         validationSchema={validationSchema}
         ignoreValues={ignoreValues}
      >
         <Flex
            align='center'
            direction='column'

         >


            <ErrorMessage />
            <SuccessMessage />

            <Flex w='100%' flex={1} justify='flex-start'>
               <FormColumn
                  data={data && data}
                  isEdit={isEdit}
                  children={children}
                  isFullWidth={isFullWidth}
               />
               <InfoBar />

            </Flex>
         </Flex>
      </Form>
   )
})

EditView.defaultProps = {
   isFullWidth: true
}

export default EditView
