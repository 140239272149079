import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import CustomTable from '../../../../components/common/CustomTable';
import FormSection from '../../../../components/FormSection';
import EventSearch from '../EventSearch';
import {
    Flex,
    Text,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Button,
    Alert,
    AlertIcon,
} from '@chakra-ui/react';
import moment from 'moment';
import { PopConfirm } from '../../../../Nucleus';
import { useParams } from 'react-router';
import Delegates from './Delegates';

const Events = () => {
    const { type } = useParams();
    const events = useWatch({ name: 'events' });
    const status = useWatch({ name: 'status' });
    const { setValue } = useFormContext();

    const changeQuantity = (index, value) => {
        let _events = [...events];
        _events[index].quantity = value;
        setValue('events', _events);
    };

    const changePrice = (index, value) => {
        let _events = [...events];
        _events[index].price = value;
        setValue('events', _events);
    };

    const remove = (index) => {
        let _events = [...events];
        _events.splice(index, 1);
        setValue('events', _events);
    };

    const columns = [
        {
            Header: 'Name',
            accessor: 'course.title',
            Cell: ({ value, row }) => (
                <Flex justify="center" direction="column">
                    <Text fontWeight="bold">{row.original.provider.name}</Text>
                    <Text>{value}</Text>
                </Flex>
            ),
        },
        {
            Header: 'Date',
            accessor: 'start_date',
            Cell: ({ value }) => (
                <>
                    <Text>{moment(value, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YYYY - HH:mm')}</Text>
                </>
            ),
        },
        {
            Header: 'Location',
            accessor: 'locations',
            Cell: ({ value, row }) =>
                value ? (
                    <Text>
                        {value.location_name} - {row.original.venues[0].title}
                    </Text>
                ) : (
                    <Text>e-Learning</Text>
                ),
        },
        {
            Header: 'Price',
            accessor: 'price',
            Cell: ({ value, row }) => (
                <NumberInput
                    maxWidth="110px"
                    minWidth="110px"
                    bg="white"
                    value={value}
                    min={1}
                    defaultValue={value}
                    onChange={(e) => changePrice(row.id, e)}
                >
                    <NumberInputField />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
            ),
        },
        {
            Header: 'Discount',
            accessor: 'discount',
            Cell: ({ value, row }) => {
                return (
                    <Flex>
                        {' '}
                        <Text>{value}</Text>{' '}
                        {row?.original?.discount_type?.name === 'Price' ? (
                            <Text ml="5px"> GBP </Text>
                        ) : (
                            <Text>%</Text>
                        )}
                    </Flex>
                );
            },
        },
        {
            Header: 'Delegates',
            accessor: 'availability',
            Cell: ({ value, row }) => (
                <>
                    <Text>
                        {row.original.delegate_count}/{row.original.availability}
                    </Text>
                </>
            ),
        },
        {
            Header: 'Delegates Qty',
            accessor: 'quantity',
            Cell: ({ value, row }) => (
                <NumberInput
                    maxWidth="110px"
                    minWidth="110px"
                    bg="white"
                    value={value}
                    min={0.5}
                    max={row.original.availability - row.original.delegate_count}
                    onChange={(e) => changeQuantity(row.id, e)}
                >
                    <NumberInputField />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
            ),
        },
        {
            Header: '',
            accessor: 'id',
            Cell: ({ row }) => (
                <PopConfirm
                    onConfirm={() => remove(row.id)}
                    title="Are you sure you want to remove this event from this booking?"
                >
                    <Button size="sm" variant="outline" colorScheme="red">
                        Remove
                    </Button>
                </PopConfirm>
            ),
        },
    ];

    return (
        <FormSection
            title={
                <Flex w="100%">
                    <Text>Events in booking</Text>
                    {type === 'create' && (
                        <Text ml="auto" fontSize="13px" fontWeight="normal" color="gray.600">
                            Delegates can be confirmed once booking has been made
                        </Text>
                    )}
                </Flex>
            }
            mb="20px"
        >
            {events.length === 0 && (
                <Alert mb="10px" status="warning">
                    <AlertIcon />
                    You must add events to the booking
                </Alert>
            )}
            {(type === 'create' || status?.name === 'Provisional') && <EventSearch />}

            {type === 'create' && (
                <CustomTable hidePagination columns={columns} data={events || []} />
            )}
            {type === 'edit' && <Delegates />}
        </FormSection>
    );
};

export default Events;
