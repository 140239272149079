import React, { useEffect } from 'react';
import { Input } from '@chakra-ui/react';
import InputMask from 'react-input-mask';

const Mask = (props) => {
    const { onChange, value, children, defaultValue, ...rest } = props;

    useEffect(() => {
        if (defaultValue && value === null) {
            onChange(defaultValue);
        }
    }, [defaultValue, onChange]);

    return (
        <InputMask
            onChange={(v) => onChange(v)}
            value={value ? value : defaultValue ? defaultValue : null}
            {...rest}
        >
            {(inputProps) => <Input bg="white" {...inputProps} type="tel" />}
        </InputMask>
    );
};

export default Mask;
