import React, { useMemo } from 'react';
import { AutoFields, EditView } from '@builtbypixel/nucleus';
import TrainersComponent from './Fields/TrainersComponent';
import setup from './setup';
const EditForm = () => {
    const fields = useMemo(
        () => [
            {
                // tab: "Trainers",
                fields: [
                    {
                        name: 'calendar',
                        component: TrainersComponent,
                        label: 'Date',
                        disabled: 'true',
                    },
                ],
            },
        ],
        [],
    );
    return (
        <EditView setup={setup} isFullWidth>
            <AutoFields fields={fields} />
        </EditView>
    );
};

export default EditForm;
