import { Flex, Box, Input, Text } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Field } from '@builtbypixel/nucleus';
import { useParams } from 'react-router';

const AddressSearch = () => {
    const ref = React.useRef(null);
    const { type } = useParams();

    const [search_places, setPlaces] = useState([]);
    const searchInput = React.useRef(null);
    const { setValue, getValues } = useFormContext();

    const lat = useWatch({ name: 'latitude' });
    const lng = useWatch({ name: 'longitude' });

    const handleScriptLoad = () => {
        let map;
        if (ref.current) {
            const _lat = getValues('latitude');
            const _lng = getValues('longitude');

            map = new window.google.maps.Map(ref.current, {
                center: {
                    lat: _lat ? parseFloat(_lat) : 51.749933,
                    lng: _lng ? parseFloat(_lng) : 0.1278,
                },
                zoom: _lat ? 16 : 10,
                mapTypeControl: false,
            });

            if (type === 'edit' && _lat && _lng) {
                var myLatLng = new window.google.maps.LatLng(parseFloat(_lat), parseFloat(_lng));
                var myMarkerOptions = {
                    position: myLatLng,
                    map: map,
                };
                new window.google.maps.Marker(myMarkerOptions);
            }

            // Create the search box and link it to the UI element.

            const searchBox = new window.google.maps.places.SearchBox(searchInput.current);

            // Bias the SearchBox results towards current map's viewport.
            map.addListener('bounds_changed', () => {
                searchBox.setBounds(map.getBounds());
            });

            let markers = [];

            // Listen for the event fired when the user selects a prediction and retrieve
            // more details for that place.
            searchBox.addListener('places_changed', () => {
                const places = searchBox.getPlaces();

                setPlaces(places);

                if (places.length === 0) {
                    return;
                }

                // Clear out the old markers.
                markers.forEach((marker) => {
                    marker.setMap(null);
                });
                markers = [];

                // For each place, get the icon, name and location.
                const bounds = new window.google.maps.LatLngBounds();

                places.forEach((place) => {
                    if (!place.geometry || !place.geometry.location) {
                        console.log('Returned place contains no geometry');
                        return;
                    }

                    // Create a marker for each place.
                    markers.push(
                        new window.google.maps.Marker({
                            map,

                            title: place.name,
                            position: place.geometry.location,
                        }),
                    );
                    if (place.geometry.viewport) {
                        // Only geocodes have viewport.
                        bounds.union(place.geometry.viewport);
                    } else {
                        bounds.extend(place.geometry.location);
                    }
                });
                map.fitBounds(bounds);
            });
        }
    };

    useEffect(() => {
        const loadScript = (url, callback) => {
            let script = document.createElement('script');
            script.type = 'text/javascript';

            if (script.readyState) {
                script.onreadystatechange = function () {
                    if (script.readyState === 'loaded' || script.readyState === 'complete') {
                        script.onreadystatechange = null;
                        callback();
                    }
                };
            } else {
                script.onload = () => handleScriptLoad();
            }

            script.src = url;
            document.getElementsByTagName('head')[0].appendChild(script);
        };
        setTimeout(() => {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=AIzaSyBdDvl-XkDr4b2ADHbV1pdqMl-xijAUdRE&libraries=places`,
            );
        }, 1000);
    }, []);

    useEffect(() => {
        const getAddressComponent = (type) => {
            let res;
            search_places[0].address_components.forEach((comp) => {
                if (comp.types.some((e) => e === type)) {
                    res = comp.long_name;
                }
            });
            return res;
        };

        if (search_places.length !== 0) {
            setValue('latitude', search_places[0].geometry.location.lat());
            setValue('longitude', search_places[0].geometry.location.lng());

            if (search_places.length !== 0) {
                setValue(
                    'address_line_one',
                    `${getAddressComponent('street_number') ?? ''} ${
                        getAddressComponent('route') ?? ''
                    }`,
                );
                setValue('address_line_two', `${getAddressComponent('locality') ?? ''}`);
                setValue('city', `${getAddressComponent('postal_town') ?? ''}`);
                setValue('postcode', `${getAddressComponent('postal_code') ?? ''}`);
            }
        }
    }, [search_places, setValue]);

    return (
        <Flex w="100%" direction="column">
            <Input
                ref={searchInput}
                placeholder="Search addresses ..."
                variant="filled"
                name="cc"
                autoComplete="off"
            />
            <Flex w="100%" py="20px">
                <Box flex={2} w="500px" h="300px" ref={ref}></Box>
                <Flex flex={1} direction="column" px="10px" w="100%">
                    <Field
                        name="address_line_one"
                        component="text"
                        placeholder="Address Line 1"
                        noMargin
                        roundedBottom="0px"
                        borderBottom="0px"
                        rules={{ required: true }}
                    />
                    <Field
                        name="address_line_two"
                        component="text"
                        placeholder="Address Line 2"
                        noMargin
                        rounded="0px"
                        borderBottom="0px"
                    />
                    <Field
                        name="address_line_three"
                        component="text"
                        placeholder="Address Line 3"
                        noMargin
                        rounded="0px"
                        borderBottom="0px"
                    />
                    <Field
                        name="city"
                        borderBottom="0px"
                        component="text"
                        noMargin
                        placeholder="City"
                        rounded="0px"
                        rules={{ required: true }}
                    />
                    <Field
                        name="postcode"
                        component="text"
                        placeholder="Postcode"
                        noMargin
                        roundedTop="0px"
                        rules={{ required: true }}
                    />
                    <Text as="pre" mt="10px">
                        Latitude: {lat}
                    </Text>
                    <Text as="pre">Longitude: {lng}</Text>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default AddressSearch;
