import React, { useState, useCallback, useEffect } from 'react';
import {
    Input,
    Text,
    Modal,
    Button,
    useDisclosure,
    InputGroup,
    InputRightElement,
    Spinner,
    Box,
} from '@chakra-ui/react';
import { useHttp, Select, useGet } from '@builtbypixel/nucleus';
import debounce from 'lodash.debounce';
import CustomTable from '../../../../components/common/CustomTable';
import useSWR from 'swr';

import { useFormContext, useWatch } from 'react-hook-form';

import FormSection from '../../../../components/FormSection';
import BrokerForm from './BrokerForm';

const BrokerSearch = ({ brokerBooking }) => {
    const Http = useHttp();

    const { setValue, getValues } = useFormContext();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState(null);
    const [searchTerm, setSearchTerm] = useState(null);
    const customer = useWatch({ name: 'broker' });
    const [results, setResults] = useState([]);

    const changeSearch = () => {
        setSearchTerm(search);
        setValue('email', search);
    };

    const _searchUsers = useCallback(debounce(changeSearch, 500), [search]);
    console.log(customer, 'customer -this');
    useEffect(() => {
        const searchUsers = () => {
            setLoading(true);
            Http.get('/broker-users', {
                params: {
                    search: searchTerm,
                },
            }).then((res) => {
                setResults(res.data.data);

                setLoading(false);
                if (res.data.data.length === 0) {
                    onOpen();
                }
            });
        };
        if (searchTerm) {
            searchUsers();
        }
    }, [searchTerm, Http]);

    useEffect(() => {
        if (search && search.length > 3) {
            _searchUsers();
        }
        if (search && search.length === 0) {
            _searchUsers();
        }

        // Cancel the debounce on useEffect cleanup.
        return _searchUsers.cancel;
    }, [_searchUsers, search]);

    const selectUser = (user) => {
        setValue('broker', user);
        setValue('customer', user);

        if (user.email) {
            setValue('email', user.email);
        }
    };

    const columns = [
        {
            Header: 'Company',
            accessor: 'company_name',
        },
        {
            Header: 'Name',
            accessor: 'first_name',
            Cell: ({ row }) => (
                <>
                    <Text fontWeight="bold">
                        {row.original.first_name} {row.original.last_name}
                    </Text>
                </>
            ),
        },
        {
            Header: 'Type',
            accessor: 'user_type',
            Cell: ({ row }) => <>{row.original.user_type?.name}</>,
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: '',
            accessor: 'id',
            Cell: ({ row }) => (
                <Button
                    onClick={() => selectUser(row.original)}
                    size="xs"
                    colorScheme="blue"
                    ml="auto"
                >
                    Select User
                </Button>
            ),
        },
    ];

    return !customer?.user_type ? (
        <FormSection title="Add Broker" direction="column" w="100%" mt="0px">
            <Text mb="8px">Broker Email Address</Text>
            <InputGroup mb="10px">
                <Input
                    isFullWidth
                    variant="filled"
                    placeholder="Enter email address or surname"
                    onChange={(e) => setSearch(e.target.value)}
                    defaultValue={customer?.email}
                    value={customer?.email}
                />
                <InputRightElement children={loading ? <Spinner /> : null} />
            </InputGroup>

            {results.length !== 0 && <CustomTable data={results} columns={columns} />}
            <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside">
                <BrokerForm email={searchTerm} onClose={onClose} />
            </Modal>
        </FormSection>
    ) : null;
};

export default BrokerSearch;
