import React from 'react';
import useSWR from 'swr';
import {
    BarChart,
    Bar,
    ResponsiveContainer,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from 'recharts';
import renderToolTip from '../components/Tooltip';
import LoadingSpinner from '../../../components/LoadingSpinner';

const ChartView = ({ data, loading }) => {
    if (loading) <LoadingSpinner />;

    // setting domain to [0, auto] wasn't working so we manuually work out the maxValue for chart
    // we add £100 to each line to give the chart a little blank space at the top, and a full view when sales = 0
    const maxValue = Math.max(
        ...data?.data?.periods?.map((e) => {
            return parseInt(e.sales) + 100;
        }),
    );

    return (
        <>
            <ResponsiveContainer width="100%" height={350}>
                <BarChart width={600} height={300} data={data?.data?.periods}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    {/* <YAxis
                        tickFormatter={(tick) =>
                            '£' + (tick / 100).toLocaleString() + ' M'
                        }
                    /> */}
                    <YAxis type="number" domain={[0, maxValue]} />
                    <Tooltip content={renderToolTip} />
                    <Legend />
                    <Bar dataKey={'sales'} barSize={30} fill="#FA674D" />
                </BarChart>
            </ResponsiveContainer>
        </>
    );
};

export default ChartView;
