import React, { useState, useEffect } from 'react';
import {
    Grid,
    ModalCloseButton,
    Text,
    Flex,
    Alert,
    AlertIcon,
    Button,
    Modal,
    useDisclosure,
    ModalOverlay,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalFooter,
} from '@chakra-ui/react';
import { MdCheck } from 'react-icons/md';
import { useFormContext, useWatch } from 'react-hook-form';
import Item from './Item';
import { useParams } from 'react-router-dom';
import { Field, useHttp } from '@builtbypixel/nucleus';
import FormSection from '../../../../components/FormSection';

const LearningStylesRepeater = () => {
    const { getValues, setValue } = useFormContext();
    const [learningStyles, setLearningStyles] = useState(null);
    const [learningArray, setLearningArray] = useState([]);
    const Http = useHttp();
    const { type } = useParams();
    // const learningArray = useWatch({ name: 'learningItem' });

    useEffect(() => {
        if (type === 'create') {
            if (learningArray.length === 0) {
                Http.get(`/learning-styles`).then((resy) => {
                    const learningData = resy.data.data;
                    const activeLearningStyles = learningData.filter((x) => x.active);
                    if (type === 'create') {
                        setValue('learningItem', activeLearningStyles);
                    }
                    setLearningArray(learningData);
                });
            }
        } else {
            const formLearningArray = getValues('learningItem');
            setLearningArray(formLearningArray);
        }
    }, [Http, getValues, learningArray.length, setValue, type]);

    useEffect(() => {
        if (!learningStyles && isArray(learningArray)) {
            const delearningArray = learningArray?.filter(
                (thing, index, self) => index === self.findIndex((t) => t.name === thing.name),
            );
            setLearningStyles(delearningArray);
        }
    }, [learningArray, learningStyles]);

    function isArray(obj) {
        return Object.prototype.toString.call(obj) === '[object Array]';
    }

    return (
        <FormSection title="Delivery Methods" mt="0px">
            <Alert status="warning" fontSize="sm">
                <AlertIcon />
                All active delivery methods are prepopulated. Amend as needed or delete if not
                needed for this course.
            </Alert>

            <Grid
                isInline
                gap="15px"
                bg="gray.50"
                p="20px"
                rounded="md"
                w="100%"
                templateColumns="repeat(5,1fr)"
            >
                {learningArray &&
                    learningArray?.length > 0 &&
                    learningArray?.map((individual, i) => (
                        <Flex
                            bg="white"
                            rounded="md"
                            shadow="sm"
                            border="1px"
                            borderColor="gray.100"
                            align="center"
                            p="15px"
                            pos="relative"
                            direction="column"
                            key={`learning-item-${i}`}
                        >
                            <Text fontWeight="bold" mb="20px">
                                {individual.name}
                            </Text>
                            <Flex justify="center">
                                <Field
                                    size="lg"
                                    colorScheme="green"
                                    component="switch"
                                    name={`learningItem.${i}.active`}
                                />
                            </Flex>
                            <LearningStyleBlock index={i} item={individual} />
                        </Flex>
                    ))}
            </Grid>
        </FormSection>
    );
};

const LearningStyleBlock = ({ index, item }) => {
    const { type } = useParams();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const active = useWatch({
        name: `learningItem.${index}.active`,
    });
    const { submit } = useFormContext();

    return (
        <Flex align="center" justify="center" w="100%">
            <Button
                leftIcon={active ? <MdCheck /> : null}
                colorScheme={active ? 'blue' : 'gray'}
                isFullWidth
                isDisabled={!active}
                onClick={() => onOpen()}
                size="md"
            >
                Manage
            </Button>

            <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="inside">
                <ModalOverlay />
                <ModalContent maxWidth="1200px">
                    <ModalCloseButton />
                    <ModalHeader>Manage {item.name}</ModalHeader>
                    <ModalBody>
                        <Item field={item} counter={index} type={type} />
                    </ModalBody>
                    <ModalFooter justify="flex-end">
                        <Button
                            onClick={() => {
                                submit();
                                onClose();
                            }}
                            colorScheme="green"
                        >
                            Save & Exit
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    );
};

export default LearningStylesRepeater;
