import React, { useMemo } from 'react';
import { AutoFields, EditView } from '@builtbypixel/nucleus';
import setup from './setup';
import {seo} from '../../components/Seo';

import useValidation from './validation';

const EditForm = () => {
    const fields = useMemo(
        () => [
            {
                tab: 'Location Details',
                group: 'Location Details',
                fields: [
                    // {
                    //   name: "location_type",
                    //   component: "select",
                    //   options: locationType,
                    //   placeholder: "Location Type",
                    //   labelKey: "location_type",
                    //   valueKey: "id",
                    //   label: "Location Type",
                    //   isRequired: true,
                    //   rules: { required: true },
                    // },
                    {
                        name: 'location_name',
                        component: 'text',
                        placeholder: 'Location',
                        label: 'Location Name',
                        // isRequired: true,
                        // rules: { required: true },
                    },
                    {
                        name: 'content.backgroundHeroUrl',
                        component: 'file',
                        label: 'Banner Image',
                        // isRequired: true,
                        // rules: { required: true },
                    },
                    {
                        name: 'content.headline.title',
                        component: 'text',
                        placeholder: 'Enter Title',
                        label: 'Title',
                        // isRequired: true,
                        // rules: { required: true },
                    },
                    {
                        name: 'content.headline.details',
                        component: 'textarea',
                        placeholder: 'Enter details',
                        label: 'Details',
                        // isRequired: true,
                        // rules: { required: true },
                    },
                    {
                        name: 'content.headline.button_text',
                        component: 'text',
                        placeholder: 'Click here',
                        label: 'Button Text',
                        // isRequired: true,
                        // rules: { required: true },
                    },
                ],
            },
            {
                tab: 'Location Details',
                group: 'Contact Section',
                fields: [
                    {
                        name: 'content.contactBanner.title',
                        component: 'text',
                        placeholder: 'Contact us',
                        label: 'Title',
                        // isRequired: true,
                        // rules: { required: true },
                    },
                    {
                        name: 'content.contactBanner.text',
                        component: 'text',
                        label: 'Contact Text',
                        // isRequired: true,
                        // rules: { required: true },
                    },
                    {
                        name: 'content.contactBanner.button',
                        component: 'text',
                        label: 'Button Text',
                        // isRequired: true,
                        // rules: { required: true },
                    },
                ],
            },
            {
                tab: 'Calls to action',
                group: 'CTA',
                fields: [
                    {
                        name: 'cta',
                        component: 'repeater',
                        placeholder: 'Enter Title',
                        label: 'Add calls to action',
                        layout: 'vertical',
                        fieldArray: [
                            {
                                name: 'title',
                                component: 'text',
                                placeholder: 'Enter Title',
                                label: 'Title',
                                // isRequired: true,
                                // rules: { required: true },
                            },
                            {
                                name: 'paragraph',
                                component: 'textarea',
                                placeholder: '',
                                label: 'CTA Paragraph',
                                // isRequired: true,
                                // rules: { required: true },
                            },
                            {
                                name: 'button.text',
                                component: 'text',
                                placeholder: '',
                                label: 'Button Text',
                                // isRequired: true,
                                // rules: { required: true },
                            },
                            {
                                name: 'button.route',
                                component: 'text',
                                placeholder: '',
                                label: 'Button Link',
                                help: 'Enter the relative path eg. /contact',
                                // isRequired: true,
                                // rules: { required: true },
                            },
                        ],
                    },
                ],
            },
            {
                tab: 'Highlights',
                group: 'Highlights',
                fields: [
                    {
                        name: 'highlights',
                        component: 'repeater',
                        placeholder: 'Enter Title',
                        label: 'Add highlights',
                        layout: 'vertical',
                        fieldArray: [
                            {
                                name: 'title',
                                component: 'text',
                                placeholder: 'Enter Title',
                                label: 'Title',
                                // isRequired: true,
                                // rules: { required: true },
                            },
                            {
                                name: 'paragraph',
                                component: 'textarea',
                                placeholder: '',
                                label: 'Paragraph',
                                // isRequired: true,
                                // rules: { required: true },
                            },
                            {
                                name: 'icon',
                                component: 'text',
                                placeholder: '',
                                label: 'Button Text',
                                // isRequired: true,
                                // rules: { required: true },
                            },
                        ],
                    },
                ],
            },
            seo
        ],

        [],
    );
    return (
        <EditView setup={setup} validationSchema={useValidation()}>
            <AutoFields fields={fields} />
        </EditView>
    );
};

export default EditForm;
