import {
    Grid,
    Modal,
    Box,
    ModalBody,
    ModalOverlay,
    ModalCloseButton,
    ModalContent,
    Button,
    useDisclosure,
    FormControl,
    Form,
    Alert,
    AlertIcon,
    ModalFooter,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { BiCloudUpload, BiDownload } from 'react-icons/bi';
import useSWR from 'swr';
import { useGet, Field, useHttp } from '@builtbypixel/nucleus';
import { useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';
import TableView from './BookingSummaryTable';
import Summary from '../../../../PersonalUser/components/Summary';

const BookingSummaryModal = ({ id, isOpen, onClose }) => {
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered size="6xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody p="10">
                        <Summary type="order" id={id} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default BookingSummaryModal;
